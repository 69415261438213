import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import useAutoSubmitForm from '@acdc/shared/src/utils/useAutoSubmitForm';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import InlineInputLabel from '../../ui/InlineInputLabel';
import type { ToolOverrideFormValue } from './ToolOverrideForm';

export interface ToolOverrideFormInnerProps {
    tool: ToolResponse | undefined;
    getToolOverrideId: () => string | null;
    disableEnabledInput?: boolean | undefined;
}
function ToolOverrideFormInner({
    tool,
    getToolOverrideId,
    disableEnabledInput,
}: ToolOverrideFormInnerProps) {
    const pendingSave = useAutoSubmitForm(getToolOverrideId);
    const isFullySaved: boolean = Boolean(getToolOverrideId() && !pendingSave);
    const { values } = useFormikContext<ToolOverrideFormValue>();

    return (
        <>
            <InlineInputLabel
                htmlFor="toolEnabled"
                label="Visibilité de l'outil"
            >
                <Field
                    component={Switch}
                    id="toolEnabled"
                    name="enabled"
                    type="checkbox"
                    data-testid="disabledSwitch"
                    disabled={disableEnabledInput || false}
                    color="success"
                />
            </InlineInputLabel>
            {tool?.canUpdateTitle && (
                <InlineInputLabel htmlFor="toolTitle" label="Titre de l'outil">
                    <Field
                        component={TextField}
                        id="toolTitle"
                        name="title"
                        size="small"
                        disabled={false} // pas disabled pendant un submit
                        focused={
                            isFullySaved && values.title ? true : undefined
                        } // pour afficher la couleur
                        color={
                            isFullySaved && values.title ? 'success' : 'primary'
                        }
                        fullWidth
                        InputProps={{
                            placeholder: tool?.title || '',
                        }}
                    />
                </InlineInputLabel>
            )}
        </>
    );
}

export default ToolOverrideFormInner;
