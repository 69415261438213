import { useCallback } from 'react';
import PresentationFlowCode from '@acdc/shared/src/features/presentationFlow/PresentationFlowCode.enum';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useSelectedAgency from '../agency/useSelectedAgency';
import useAgency from '../agency/useAgency';

/**
 * Retourne une fonction qui indique si un PresentationFlow est activé dans l'agence sélectionnée.
 * La fonction retourne undefined si les données ne sont pas encore chargées.
 */
function useIsPresentationFlowEnabled(): (
    presentationFlow: string | PresentationFlowResponse | undefined
) => boolean | undefined {
    const selectedAgency = useAgency(useSelectedAgency()[0]);

    return useCallback(
        (presentationFlow: string | PresentationFlowResponse | undefined) => {
            if (!selectedAgency || !presentationFlow) {
                return undefined;
            }

            if (selectedAgency.syndicEnabled) {
                // Actuellement le seul cas où un PresentationFlow est désactivé
                // est si l'agence n'a pas les modules syndic activés.
                return true;
            }

            return !matchOnId(
                presentationFlow,
                `/presentation-flows/${PresentationFlowCode.SYNDIC}`
            );
        },
        [selectedAgency]
    );
}

export default useIsPresentationFlowEnabled;
