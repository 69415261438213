import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';

const fileModuleCodes = [ModuleCode.IMAGE, ModuleCode.PDF] as string[];

function hasContentModuleEditableFile(contentModule: ContentModuleResponse) {
    if (!fileModuleCodes.includes(contentModule.module?.code as string)) {
        return false;
    }

    return contentModule.settingValues?.collection.some((settingValue) => {
        return (
            settingValue.moduleSetting?.code ===
                ModuleSettingCode.IMAGE_EDITABLE &&
            settingValue.value === 'true'
        );
    });
}

export default hasContentModuleEditableFile;
