import { useContext } from 'react';
import { ToolCategoriesCtx } from './ToolCategoriesProvider';

/**
 * Retourne toutes les rubriques d'outils.
 */
function useToolCategories() {
    return useContext(ToolCategoriesCtx);
}

export default useToolCategories;
