import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useAutoSubmitForm from '@acdc/shared/src/utils/useAutoSubmitForm';
import InlineInputLabel from '../../../ui/InlineInputLabel';
import ServiceFormDialogButton from '../../service/ServiceFormDialogButton';

export interface ToggleServiceAgencyFormInnerProps {
    getId: () => string | null;
    service: ServiceResponse;
    /**
     * Désactiver le formulaire ?
     */
    disabled?: boolean;
    sortOrderIfUp: number | null;
}

function ToggleServiceAgencyFormInner({
    getId,
    service,
    disabled,
    sortOrderIfUp,
}: ToggleServiceAgencyFormInnerProps) {
    useAutoSubmitForm(getId);
    const { setFieldValue } = useFormikContext();
    const inputId = `enabledSwitch${service.id}`;

    return (
        <InlineInputLabel
            htmlFor={inputId}
            label={`${service.label}`}
            labelActions={
                service.agency && (
                    <ServiceFormDialogButton
                        value={service}
                        agency={service.agency}
                        buttonComponent={IconButton}
                        buttonProps={{
                            color: 'primary',
                            size: 'small',
                        }}
                    >
                        <EditIcon aria-label={`Éditer ${service.label}`} />
                    </ServiceFormDialogButton>
                )
            }
        >
            <Field
                component={Switch}
                type="checkbox"
                name="enabled"
                id={inputId}
                data-testid="ToggleServiceAgency"
                // || false est requis, on n'accepte pas undefined car le form
                // ne doit pas se désactiver pendant les submits
                disabled={disabled || false}
                color="success"
            />
            <IconButton
                title="Remonter"
                onClick={() => {
                    setFieldValue('sortOrder', sortOrderIfUp);
                }}
                disabled={sortOrderIfUp === null}
            >
                <ArrowUpwardIcon />
            </IconButton>
        </InlineInputLabel>
    );
}

export default ToggleServiceAgencyFormInner;
