import React, { useMemo } from 'react';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { formatISO, isValid } from 'date-fns';
import CONFIGS from '../configs';

export const CacheAgeContext = React.createContext<
    [Date | undefined, (age: Date | undefined) => void]
>([undefined, () => {}]);

export interface CacheAgeProviderProps {
    children: React.ReactNode;
}

function CacheAgeProvider({ children }: CacheAgeProviderProps) {
    const [ageStr, setAgeStr] = usePersistentState<string | undefined>(
        CONFIGS.storeAgeKey,
        undefined
    );
    const value = useMemo<
        [Date | undefined, (age: Date | undefined) => void]
    >(() => {
        let age = ageStr ? new Date(ageStr) : undefined;
        if (!isValid(age)) {
            age = undefined;
        }
        const setAge = (a: Date | undefined) => {
            setAgeStr(a ? formatISO(a) : undefined);
        };

        return [age, setAge];
    }, [ageStr, setAgeStr]);

    return (
        <CacheAgeContext.Provider value={value}>
            {children}
        </CacheAgeContext.Provider>
    );
}

export default CacheAgeProvider;
