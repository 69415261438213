import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { useMemo } from 'react';
import hasValue from '@acdc/shared/src/utils/hasValue';
import { getServiceAgency } from '../serviceAgency/useServiceAgency';

export function sortServices(
    services: ServiceResponse[],
    agency: AgencyResponse
) {
    const sorted = services.sort((s1, s2) => {
        const sa1 = getServiceAgency(s1, agency);
        const sa2 = getServiceAgency(s2, agency);

        const sortOrder1 = hasValue(sa1?.sortOrder)
            ? sa1?.sortOrder
            : s1.sortOrder;
        const sortOrder2 = hasValue(sa2?.sortOrder)
            ? sa2?.sortOrder
            : s2.sortOrder;

        return (sortOrder1 || 0) - (sortOrder2 || 0);
    });

    return sorted;
}

/**
 * Retourne les services triés par sortOrder du service ou du ServiceAgency de l'agence.
 */
function useSortedServices(
    services: ServiceResponse[] | undefined | null,
    agency: AgencyResponse | undefined | null
) {
    return useMemo(() => {
        if (!services || !agency) {
            return null;
        }

        return sortServices(services, agency);
    }, [services, agency]);
}

export default useSortedServices;
