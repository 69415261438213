import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
} from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import AgencyTogglePackageAgencyForms from '../../packageAgency/AgencyTogglePackageAgencyForms';
import usePackages from '../usePackages';
import CustomizePackageAccordion from './CustomizePackageAccordion';
import accordionSummarySx from './accordionSummarySx';
import accordionDetailsSx from './accordionDetailsSx';
import ServiceAgencyForms from '../../serviceAgency/ServiceAgencyForms';
import CreateServiceButton from '../../service/CreateServiceButton';
import UseNewPackagesForm from '../../agency/UseNewPackagesForm/UseNewPackagesForm';

export interface PackagesConfiguratorProps {
    agency: AgencyResponse;
}

const accordionTransitionProps = {
    unmountOnExit: true,
};

const createServiceButtonWrapperSx = {
    px: 2,
    mt: 1,
    backgroundColor: 'white', // on override le fond grisé de accordionDetailsSx
};

/**
 * Tout ce qu'il faut pour configurer les Packs et leurs Services dans une Agency.
 */
function PackagesConfigurator({ agency }: PackagesConfiguratorProps) {
    const packs = usePackages();
    const [expandedSection, setExpandedSection] = useState<string | false>(
        false
    );

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedSection(isExpanded ? panel : false);
        };

    if (!packs) {
        return null;
    }

    return (
        <>
            <UseNewPackagesForm value={agency} />
            <Accordion
                expanded={expandedSection === 'enablePacks'}
                onChange={handleChange('enablePacks')}
                square
                TransitionProps={accordionTransitionProps}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="enablePacks-body"
                    id="enablePacks-header"
                    sx={accordionSummarySx}
                >
                    Activation des packs
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsSx}>
                    <AgencyTogglePackageAgencyForms agency={agency} />
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'serviceAgencies'}
                onChange={handleChange('serviceAgencies')}
                square
                TransitionProps={accordionTransitionProps}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="serviceAgencies-body"
                    id="serviceAgencies-header"
                    sx={accordionSummarySx}
                >
                    Configurer les prestations
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsSx}>
                    <ServiceAgencyForms agency={agency} />

                    <Box sx={createServiceButtonWrapperSx}>
                        <CreateServiceButton agency={agency} />
                    </Box>
                </AccordionDetails>
            </Accordion>

            {packs.map((pack) => (
                <CustomizePackageAccordion
                    key={`${pack.id}`}
                    pack={pack}
                    agency={agency}
                    expanded={
                        expandedSection ===
                        `customisePack${getIdFromIri(entityToId(pack))}`
                    }
                    onAccordionChange={handleChange(
                        `customisePack${getIdFromIri(entityToId(pack))}`
                    )}
                    accordionTransitionProps={accordionTransitionProps}
                />
            ))}
        </>
    );
}

export default PackagesConfigurator;
