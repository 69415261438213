import { Field, useFormikContext } from 'formik';
import React from 'react';
import CheckButton from '@acdc/shared/src/formik/CheckButton';
import { Grid } from '@mui/material';

const itemSx = {
    '& .MuiCheckbox-root': {
        width: '100%',
        '& .MuiButton-root': {
            width: '100%',
        },
    },
};

export interface ButtonPickerMultipleProps {
    name: string;
    options: any[];
    getOptionLabel: (option: any) => string;
    getOptionValue: (option: any) => string;
    getOptionWidth?: (option: any) => number;

    /**
     * Les valeurs pour lesquelles la sélection multiple n'est pas possible.
     */
    uniqueOptions?: any[] | null | undefined;
}

function ButtonPickerMultiple({
    name,
    options,
    getOptionLabel,
    getOptionValue,
    getOptionWidth,
    uniqueOptions,
}: ButtonPickerMultipleProps) {
    const { values, setFieldValue, handleChange } = useFormikContext<any>();

    return (
        <Grid container justifyContent="center">
            {options.map((option) => {
                const optionLabel = getOptionLabel(option);
                const optionValue = getOptionValue(option);
                return (
                    <Grid
                        key={optionLabel}
                        item
                        xs={12}
                        sm={getOptionWidth ? getOptionWidth(option) : 12}
                        sx={itemSx}
                    >
                        <Field
                            component={CheckButton}
                            type="checkbox"
                            name={name}
                            value={optionValue}
                            label={optionLabel}
                            onChange={(e: any) => {
                                if (
                                    !e.target.checked ||
                                    !uniqueOptions ||
                                    !uniqueOptions.length
                                ) {
                                    // si on uncheck une option ou qu'on n'a pas d'options uniques à gérer, on laisse le comportement par défaut
                                    handleChange(e);
                                    return;
                                }

                                if (uniqueOptions.includes(optionValue)) {
                                    // si on check une option unique, on uncheck toutes les autres
                                    setFieldValue(name, [optionValue]);
                                } else {
                                    // si on check une option non-unique on uncheck toutes les options uniques
                                    setFieldValue(name, [
                                        ...values[name].filter(
                                            (v: any) =>
                                                !uniqueOptions.includes(v)
                                        ),
                                        e.target.value,
                                    ]);
                                }
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default ButtonPickerMultiple;
