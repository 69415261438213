import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import { Offline as SentryOffline } from '@sentry/integrations';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CONFIGS from './configs';

if (CONFIGS.sentryDsn) {
    Sentry.init({
        dsn: CONFIGS.sentryDsn,
        integrations: [new SentryOffline()],
        tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
