import { MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

function MenuItemDialogButton(props: MenuItemProps<'button'>) {
    return (
        <MenuItem component="button" data-testid="UserMenuCollab" {...props} />
    );
}

export default MenuItemDialogButton;
