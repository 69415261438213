import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

const h6Style: SxProps<Theme> = {
    textTransform: 'uppercase',
    color: 'common.black',
    fontSize: '1rem',
    fontWeight: '600',
};

interface QuotationResultHeaderProps {
    labelsHeading: string;
    hideFrequencyHeading?: boolean;
    sx?: SxProps<Theme>;
    containerProps?: any;
}

function QuotationResultHeader({
    labelsHeading,
    hideFrequencyHeading,
    sx,
    containerProps,
}: QuotationResultHeaderProps) {
    return (
        <Box
            sx={{ display: 'flex', flexFlow: 'row nowrap', px: 2, ...sx }}
            {...containerProps}
        >
            <Typography variant="h6" sx={h6Style}>
                {labelsHeading}
            </Typography>
            {!hideFrequencyHeading && (
                <>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="p"
                            sx={h6Style}
                            data-testid="quotation-month-heading"
                        >
                            mois
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            minWidth: '140px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="p"
                            sx={h6Style}
                            data-testid="quotation-year-heading"
                        >
                            année
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default QuotationResultHeader;
