const accordionDetailsSx = {
    px: 0,
    '& > *': {
        py: '2px',
        px: 2,
        '&:nth-child(even)': {
            backgroundColor: 'grey.50',
        },
    },
};

export default accordionDetailsSx;
