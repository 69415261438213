function numberBetween(from: number, to: number): number {
    return (to - from) / 2 + from;
}

/**
 * @see https://stackoverflow.com/a/17369245
 */
function countDecimals(val: number): number {
    if (Math.floor(val) === val) return 0;

    const str = val.toString();

    if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
        return +str.split('-')[1] || 0;
    }
    if (str.indexOf('.') !== -1) {
        return +str.split('.')[1].length || 0;
    }

    return +str.split('-')[1] || 0;
}

/**
 * @see https://www.peterlunch.com/snippets/javascript-round
 */
function truncateDecimals(val: number, nbrOfDecimals: number): number {
    return +`${Math.floor(
        // Math.floor('1.1e+2') est valide en js mais typescript aime pas le fait d'envoyer un string
        `${val}e+${nbrOfDecimals}` as unknown as number
    )}e-${nbrOfDecimals}`;
}

/**
 * Retourne un nombre décimal situé entre les 2 valeurs données.
 * Le nombre doit être si possible exactement entre les 2 valeurs mais
 * il doit économiser au maximum les décimales.
 * Exemple:
 * - entre 5 et 7 retourne 6
 * - entre 5 et 6 retourne 5.5
 * - entre 5 et 5.5 retourne 5.2 (et non pas 5.25)
 * - entre 5 et 5.2 retourne 5.1
 * - entre 5 et 5.1 retourne 5.05
 * - entre 5.59999 et 5.61112 retourne 5.6 (et non pas 5,605555)
 */
function numberBetweenWithFewestDecimalPlaces(from: number, to: number) {
    if (from >= to) {
        return from;
    }

    const between = numberBetween(from, to);
    const betweenDecimals = countDecimals(between);

    for (let i = 0; i < betweenDecimals; i += 1) {
        const rounded = truncateDecimals(between, i);
        if (rounded > from) {
            return rounded;
        }
    }

    return between;
}

export default numberBetweenWithFewestDecimalPlaces;
