import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListToolOverridesResponse from '@acdc/shared/src/features/toolOverride/ListToolOverridesResponse';
import ToolOverrideResponse from '@acdc/shared/src/features/toolOverride/ToolOverrideResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const TOOL_OVERRIDE_FRAGMENT = gql`
    fragment ToolOverrideFields on ToolOverride {
        id
        disabled
        title
        agency {
            id
        }
        tool {
            id
        }
    }
`;

export const GET_TOOL_OVERRIDES = gql`
    ${TOOL_OVERRIDE_FRAGMENT}
    query GetToolOverrides($agencyCode: String) {
        toolOverrides(itemsPerPage: 10000, agency_code: $agencyCode) {
            collection {
                ...ToolOverrideFields
            }
        }
    }
`;

const emptyResult: ToolOverrideResponse[] = [];

/**
 * Retourne tous les ToolOverrides.
 */
const useToolOverrides = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListToolOverridesResponse>(
        GET_TOOL_OVERRIDES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des configurations d'outils.",
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<ToolOverrideResponse[] | undefined>(() => {
        return data?.toolOverrides?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useToolOverrides;
