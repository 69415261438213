import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FullPageLayout from '../../layout/FullPageLayout';
import CategorySkeleton from '../category/CategorySkeleton';
import Content from '../content/Content';
import useEditMode from '../template/useEditMode';
import useTool from './useTool';
import useFilterVisibleContentTool from './useFilterVisibleContentTool';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import { useHeaderHeight } from '../../layout/HeaderHeightProvider';
import useIsToolCategoryEnabled from '../toolsCategory/useIsToolCategoryEnabled';

function ToolPage() {
    const { toolId } = useParams();
    const tool = useTool(`/tools/${toolId}`);
    const [editMode] = useEditMode();
    const agency = useAgency(useSelectedAgency()[0]);
    const visibleContentTool = useFilterVisibleContentTool(
        tool,
        agency,
        editMode
    );
    const [headerHeight] = useHeaderHeight();
    const isToolCategoryEnabled = useIsToolCategoryEnabled();
    const navigate = useNavigate();

    useEffect(() => {
        if (isToolCategoryEnabled(tool?.toolCategory) === false) {
            navigate('/rubriques');
        }
    }, [tool?.toolCategory, isToolCategoryEnabled, navigate]);

    if (!tool) {
        return <CategorySkeleton pt={headerHeight} />;
    }

    return (
        <FullPageLayout disabledTracking>
            {visibleContentTool?.toolContents?.collection.map((toolContent) => (
                <React.Fragment key={toolContent.id}>
                    <Content
                        content={toolContent}
                        editMode={editMode}
                        toolId={tool.id}
                    />
                </React.Fragment>
            ))}
            {visibleContentTool?.toolContents?.collection.length === 0 && (
                <Box className="section" mt={2}>
                    <Alert severity="error">Cet outil est vide</Alert>
                </Box>
            )}
        </FullPageLayout>
    );
}

export default ToolPage;
