import React, { useEffect } from 'react';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { Box, InputAdornment } from '@mui/material';
import QuotationFormValue from './QuotationFormValue';
import computePreviewTotalRentalFees from './computePreviewTotalRentalFees';

/**
 * Champ contenant la prévisualisation de l'honoraire de location dans le formulaire QuotationForm.
 * La valeur est calculée automatiquement à partir des champs avec les names "subscribedLandDeclaration", "initialInventoryFee" et "biddingAndNegotiation".
 */
function PreviewTotalRentalFees({
    label,
    name,
    ...props
}: FieldAttributes<any>) {
    const {
        values: {
            surface,
            visitFee,
            visitFeeUnrounded,
            initialInventoryFee,
            biddingAndNegotiation,
        },
        setFieldValue,
    } = useFormikContext<QuotationFormValue>();

    useEffect(() => {
        const newVal = computePreviewTotalRentalFees(
            surface,
            initialInventoryFee,
            biddingAndNegotiation,
            visitFee,
            visitFeeUnrounded
        );
        if (newVal === null) {
            setFieldValue(name, '');
        } else {
            setFieldValue(name, newVal);
        }
    }, [
        surface,
        visitFee,
        visitFeeUnrounded,
        initialInventoryFee,
        biddingAndNegotiation,
        setFieldValue,
        name,
    ]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: 'solid thin',
                borderRadius: 1,
                px: 2,
                pt: 2,
                margin: 'auto !important',
            }}
        >
            {label}{' '}
            <Field
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                    ),
                    inputProps: {
                        'data-testid': 'previewTotalRentalFeesInput',
                    },
                    size: 'small',
                    style: {
                        maxWidth: '150px',
                        fontWeight: 'bold',
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    alignItems: 'flex-end',
                    '&>.MuiFormHelperText-root': {
                        // on repositionne les messages d'erreur en absolute pour ne pas décaler les inputs.
                        position: 'absolute',
                        top: '34px',
                    },
                }}
                component={TextField}
                type="number"
                margin="none"
                name={name}
                {...props}
            />
        </Box>
    );
}

export default PreviewTotalRentalFees;
