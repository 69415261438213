import React from 'react';
import { TextField } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import { Field, useFormikContext } from 'formik';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { Box, MenuItem } from '@mui/material';
import enumValues from '@acdc/shared/src/utils/enumValues';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import InlineInputLabel from '../../../ui/InlineInputLabel';
import type { ServicePackageOverridePriceFormValue } from './ServicePackageOverridePriceForm';

export interface ServicePackageOverridePriceFormInnerProps {
    getId: () => string | null;
    saved: boolean;
    service: ServiceResponse;
}

const inputsWrapperProps = { sx: { width: '200px', minWidth: '200px' } };

const priceInputProps = {
    inputProps: {
        type: 'number',
        min: '0',
        step: '0.01',
        'data-testid': 'ServicePackageOverridePriceFormInner',
    },
    sx: {
        '&.MuiOutlinedInput-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
};

const unitInputProps = {
    sx: {
        '&.MuiOutlinedInput-root': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '& .MuiSelect-select.MuiOutlinedInput-input': {
                // réduction du paddingRight pour laisser plus de place aux longues unités
                paddingRight: '22px',
            },
        },
    },
};

function ServicePackageOverridePriceFormInner({
    getId,
    saved,
    service,
}: ServicePackageOverridePriceFormInnerProps) {
    const { values } = useFormikContext<ServicePackageOverridePriceFormValue>();
    const pricePendingSave = useAutoSubmitSingleFieldForm('price', getId);
    const unitPendingSave = useAutoSubmitSingleFieldForm('unit', getId);
    const isValid: boolean = Boolean(
        saved && !pricePendingSave && !unitPendingSave && values.price !== ''
    );

    return (
        <InlineInputLabel
            htmlFor={`${service.id}price`}
            label={`${service.label}`}
            inputWrapperProps={inputsWrapperProps}
        >
            <Box display="flex">
                <Field
                    component={TextField}
                    name="price"
                    id={`${service.id}price`}
                    color={isValid ? 'success' : 'primary'}
                    focused={isValid ? true : undefined} // pour afficher la couleur
                    disabled={false} // il ne faut pas bloquer le champ pendant une sauvegarde
                    required
                    size="small"
                    InputProps={priceInputProps}
                />
                <Field
                    component={TextField}
                    name="unit"
                    id={`${service.id}unit`}
                    label="Unité"
                    fullWidth
                    select
                    size="small"
                    color={isValid ? 'success' : 'primary'}
                    focused={isValid ? true : undefined} // pour afficher la couleur
                    disabled={false} // il ne faut pas bloquer le champ pendant une sauvegarde
                    InputProps={unitInputProps}
                >
                    {enumValues(ServiceUnit).map((unit) => (
                        <MenuItem key={unit} value={unit}>
                            {unit}
                        </MenuItem>
                    ))}
                </Field>
            </Box>
        </InlineInputLabel>
    );
}

export default ServicePackageOverridePriceFormInner;
