import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import type UserModel from '@acdc/shared/src/features/user/User.model';
import type MutateUserResponse from '@acdc/shared/src/features/user/MutateUserResponse';
import { entityToId, filterString } from '@acdc/shared/src/utils/form-helpers';
import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { useAlert } from '@acdc/shared/src/tools/alert';
import type CreateProfilePictureResponse from '@acdc/shared/src/features/profilePicture/CreateProfilePictureResponse';
import type { UserFormValue } from './UserForm';
import userFormSchema from './userFormSchema';

const UPDATE_USER = gql`
    mutation UpdateUser($input: updateUserInput!) {
        updateUser(input: $input) {
            user {
                id
            }
        }
    }
`;

const CREATE_PROFILE_PICTURE = gql`
    mutation CreateProfilePicture($input: createProfilePictureInput!) {
        createProfilePicture(input: $input) {
            profilePicture {
                id
            }
        }
    }
`;

function useSubmitUserForm(
    userId?: string,
    onSuccess?: ((res: UserResponse) => void) | undefined,
    onError?: ((err: ApolloError) => void) | undefined
) {
    const isUpdate = Boolean(userId);
    if (!isUpdate) {
        throw Error("Création d'un utilisateur non supportée.");
    }

    const [updateUser] = useMutation(UPDATE_USER, {
        update(cache) {
            cache.evict({ fieldName: 'user' });
        },
    });
    const [createProfilePicture] = useMutation(CREATE_PROFILE_PICTURE);
    const setAlert = useAlert();

    return useCallback(
        (
            values: UserFormValue,
            { setSubmitting, setErrors }: FormikHelpers<UserFormValue>
        ) => {
            Promise.resolve()
                // upload des fichiers d'abord
                .then(() => {
                    if (values.photo.length === 0) {
                        return null;
                    }
                    const input = {
                        file: values.photo[0],
                    };
                    return createProfilePicture({ variables: { input } }).catch(
                        (err: ApolloError) => {
                            handleApolloError(
                                setErrors,
                                setAlert,
                                userFormSchema,
                                onError,
                                'photo'
                            )(err);

                            throw Error('cancelled');
                        }
                    );
                })
                // maj du user
                .then(
                    (
                        profilePictureResponse: FetchResult<CreateProfilePictureResponse> | null
                    ) => {
                        const input: DeepPartial<UserModel> = {
                            // pas lastname, firstname et email comme c'est non modifiable
                            secondaryEmail: filterString(values.secondaryEmail),
                            phone: filterString(values.phone),
                            profession: filterString(values.profession),
                            comment: filterString(values.comment),
                        };

                        if (isUpdate) {
                            input.id = userId;
                        }

                        if (profilePictureResponse) {
                            input.photo = entityToId(
                                profilePictureResponse.data
                                    ?.createProfilePicture?.profilePicture
                            );
                        }

                        return updateUser({ variables: { input } }).catch(
                            (err: ApolloError) => {
                                handleApolloError(
                                    setErrors,
                                    setAlert,
                                    userFormSchema,
                                    onError
                                )(err);

                                throw Error('cancelled');
                            }
                        );
                    }
                )
                .finally(() => {
                    setSubmitting(false);
                })
                .then((res: FetchResult<MutateUserResponse>) => {
                    const resItem: UserResponse | null | undefined = isUpdate
                        ? res.data?.updateUser?.user
                        : res.data?.createUser?.user;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onSuccess && onSuccess(resItem);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            isUpdate,
            userId,
            updateUser,
            createProfilePicture,
            setAlert,
            onSuccess,
            onError,
        ]
    );
}

export default useSubmitUserForm;
