import React, { useEffect, useState } from 'react';
import { Box, Button, Fade } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const IDLE_TIME_BEFORE_HIDE = 3000;
const IGNORED_NODE_NAMES = ['input', 'button', 'a'];

const wrapperSx = {
    boxSizing: 'border-box',
    position: 'fixed',
    minWidth: '120px',
    minHeight: '258px',
    pr: '40px',
    pb: '50px',
    right: 0,
    bottom: 0,
    zIndex: 1150, // sous le menu de navigation qui est à 1200
};

const buttonSx = {
    display: 'flex',
    width: '80px',
    height: '80px',
    mt: 3,
    alignItems: 'center',
    justifyContents: 'center',
    opacity: 0.6,
};

function FullPageNavigationButtons() {
    const [showButtons, setShowButtons] = useState<boolean>(true);
    /**
     * Une variable a incrémenter à chaque fois qu'une activité est détectée
     * et que cette activité doit relancer le timer qui veut masquer les boutons.
     */
    const [activities, setActivities] = useState<number>(0);
    const [hovered, setHovered] = useState<boolean>(false);

    useEffect(() => {
        let container: Element | null = null;
        let handler: EventListenerOrEventListenerObject | null = null;
        if (!hovered) {
            // eslint-disable-next-line prefer-destructuring
            container = document.querySelectorAll('#fullpage')[0];
            handler = (e) => {
                if (
                    IGNORED_NODE_NAMES.includes(
                        (e as any).target?.nodeName.toLowerCase()
                    )
                ) {
                    return;
                }

                setShowButtons((curr) => !curr);
            };

            container.addEventListener('click', handler);
        }

        return () => {
            if (container && handler) {
                container.removeEventListener('click', handler);
            }
        };
    }, [setShowButtons, hovered]);

    useEffect(() => {
        let timeout: any = null;
        if (showButtons && !hovered) {
            timeout = setTimeout(() => {
                setShowButtons(false);
            }, IDLE_TIME_BEFORE_HIDE);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [showButtons, setShowButtons, activities, hovered]);

    return (
        <Box
            sx={wrapperSx}
            onMouseEnter={() => {
                setHovered(true);
                setShowButtons(true);
            }}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            <Fade in={showButtons}>
                <div>
                    <Button
                        aria-label="Page précédente"
                        sx={buttonSx}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setActivities((curr) => curr + 1);
                            (window as any)?.fullpage_api?.moveSectionUp();
                        }}
                    >
                        <ArrowUpwardIcon fontSize="large" />
                    </Button>
                    <Button
                        aria-label="Page suivante"
                        sx={buttonSx}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setActivities((curr) => curr + 1);
                            (window as any)?.fullpage_api?.moveSectionDown();
                        }}
                    >
                        <ArrowDownwardIcon fontSize="large" />
                    </Button>
                </div>
            </Fade>
        </Box>
    );
}

export default FullPageNavigationButtons;
