import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderPreview from './HeaderPreview';
import LayoutSkeleton from './LayoutSkeleton';
import SizeInterface from './SizeInterface';

const gutter: SizeInterface = { xs: '2%', md: '5%' };
const width: SizeInterface = { xs: '94%', md: '90%' };

function LayoutPreview() {
    return (
        <LayoutSkeleton width={width} gutter={gutter}>
            <HeaderPreview width={width} gutter={gutter} />
            <Outlet />
        </LayoutSkeleton>
    );
}

export default LayoutPreview;
