/**
 * voir la doc de api/src/Enum/DiscoveryCriteriaTypeEnum.php
 */
enum DiscoveryCriteriaType {
    GROUP = 'group',
    BUTTON_PICKER = 'button_picker',
    BUTTON_PICKER_MULTIPLE = 'button_picker_multiple',
    BUTTON_PICKER_NUMBER = 'button_picker_number',
    BUTTON_PICKER_BOOL = 'button_picker_bool',
    RADIOGROUP = 'radiogroup',
    SELECT = 'select',
    SELECT_MULTIPLE = 'select_multiple',
    INPUT_NUMBER = 'input_number',
    INPUT_TEXT = 'input_text',
    SWITCH = 'switch',
    SWITCH_WITH_INDETERMINATE = 'switch_with_indeterminate',
    SLIDER = 'slider',
    YEAR_OF_CONSTRUCTION = 'year_of_construction',
    COUNTER = 'counter',
    MAX_RENT_CALCULATOR = 'max_rent_calculator',
    LOCATION_SELECT = 'location_select',
}

export default DiscoveryCriteriaType;
