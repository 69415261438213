import { Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import { TextField } from 'formik-mui';
import SubmitButton from '@acdc/shared/src/ui/SubmitButton';
import { Stack } from '@mui/material';
import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import Tool from '@acdc/shared/src/features/tool/Tool.model';
import useSubmitSendQuotation from './useSubmitSendQuotation';
import ToolSelect from '../../tools/ToolSelect';

const submitButtonProps = {
    sx: { width: '100%' },
    'data-testid': 'submitButton',
};
export interface SendQuotationFormValue {
    clientEmailAddress: string;
    tools: (Tool | undefined)[];
}

const initValue = (value: DeepPartial<Quotation>): SendQuotationFormValue => ({
    clientEmailAddress: initString(value.clientEmailAddress),
    tools: [],
});

export const sendQuotationFormSchema = Yup.object().shape({
    clientEmailAddress: Yup.string().email().label("L'email").required(),
    tools: Yup.mixed().label('Liste des outils').optional(),
});

export type SendQuotationFormProps = {
    value?: DeepPartial<Quotation> | undefined;
    onSuccess?: () => void;
};

function SendQuotationForm({ value, onSuccess }: SendQuotationFormProps) {
    const initialValues = useMemo(() => initValue(value || {}), [value]);

    const submit = useSubmitSendQuotation(value, onSuccess);

    return (
        <Formik
            validationSchema={sendQuotationFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <Stack spacing={2} mt={2}>
                    <Field
                        component={TextField}
                        name="clientEmailAddress"
                        id="clientEmailAddressInput"
                        label="Adresse email"
                        required
                        fullWidth
                        InputProps={{
                            'data-testid': 'clientEmailAddressInput',
                        }}
                        type="email"
                    />
                    <Field
                        component={ToolSelect}
                        name="tools"
                        label="Outils à envoyer avec le devis"
                        id="toolSelect"
                        inputProps={{
                            'data-testid': 'toolSelect',
                        }}
                    />
                    <SubmitButton buttonProps={submitButtonProps} />
                </Stack>
            </Form>
        </Formik>
    );
}

export default SendQuotationForm;
