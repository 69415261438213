import React from 'react';
import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderSkeleton from './HeaderSkeleton';
import SizeInterface from './SizeInterface';

interface HeaderPreviewProps {
    width: SizeInterface;
    gutter: SizeInterface;
}

function HeaderPreview({ width, gutter }: HeaderPreviewProps) {
    return (
        <HeaderSkeleton width={width} gutter={gutter}>
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <IconButton
                    data-testid="MenuSideBarButton"
                    disabled
                    sx={{
                        border: '1px solid black',
                        marginLeft: '2rem',
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
        </HeaderSkeleton>
    );
}

export default HeaderPreview;
