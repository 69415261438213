import type ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';

const findImageOverrideBySettingCode = (
    code: ModuleSettingCode,
    imageOverrides: ImageOverrideResponse[] | undefined
) => {
    return imageOverrides?.find(
        (imageOverride) => imageOverride?.moduleSetting?.code === code
    );
};

export default findImageOverrideBySettingCode;
