import React from 'react';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../configs';

export const PresentationFlowProgressCtx = React.createContext<
    [string | undefined, SetValue<string>]
>([undefined, () => {}]);

export interface PresentationFlowProgressProviderProps {
    children?: React.ReactNode;
}

function PresentationFlowProgressProvider({
    children,
}: PresentationFlowProgressProviderProps) {
    const state = usePersistentState<string>(
        CONFIGS.presentationProgressLocalStorageKey
    );

    return (
        <PresentationFlowProgressCtx.Provider value={state}>
            {children}
        </PresentationFlowProgressCtx.Provider>
    );
}

export default PresentationFlowProgressProvider;
