import React from 'react';
import { Stack } from '@mui/material';
import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import ContentHeading from '../../content/ContentHeading';
import QuotationRentResult from './QuotationRentResult';
import QuotationManagementResult from './QuotationManagementResult';
import QuotationRentTotals from '../QuotationRentTotals.model';
import QuotationManagementTotals from '../QuotationManagementTotals.model';
import QuotationActions from '../QuotationActions';
import BigRoundedButton from '../../../ui/buttons/BigRoundedButton';
import SendQuotationFormButton from '../SendQuotationFormButton';
import useIsExport from '../../useIsExport';

const sendQuotationButtonProps = {
    color: 'primary',
};

export interface QuotationResultUiProps {
    rentTotals: QuotationRentTotals | undefined;
    managementTotals: QuotationManagementTotals | undefined;
    onBack?: () => void;
    valueToSend?: DeepPartial<Quotation>;
    hideActions?: boolean;
}

function QuotationResultUi({
    rentTotals,
    managementTotals,
    onBack,
    valueToSend,
    hideActions,
}: QuotationResultUiProps) {
    const isExport = useIsExport();
    const shouldShowRentSection =
        (isExport && rentTotals?.totalLocationFee !== undefined) ||
        Boolean(!isExport && rentTotals);
    const shouldShowManagementSection =
        (isExport && managementTotals?.totalAnnualGross !== undefined) ||
        Boolean(!isExport && managementTotals);
    const shouldShowAlternativeText =
        isExport && !shouldShowRentSection && !shouldShowManagementSection;
    return (
        <Stack direction="column" spacing={2}>
            <div>
                <ContentHeading
                    mb={isExport ? 4 : undefined}
                    mt={isExport ? 0 : undefined}
                >
                    Votre devis
                </ContentHeading>
            </div>
            {shouldShowRentSection && (
                <QuotationRentResult rentTotals={rentTotals!} />
            )}
            {shouldShowManagementSection && (
                <QuotationManagementResult
                    managementTotals={managementTotals!}
                />
            )}
            {shouldShowAlternativeText && <div>Aucune donnée saisie</div>}
            {!hideActions && (
                <QuotationActions sx={{ mt: 2 }}>
                    <BigRoundedButton
                        data-testid="backToQuotationFormButton"
                        color="primary"
                        onClick={onBack}
                    >
                        Retour
                    </BigRoundedButton>
                    <SendQuotationFormButton
                        value={valueToSend}
                        buttonComponent={BigRoundedButton}
                        buttonProps={sendQuotationButtonProps}
                    >
                        Envoyer le devis
                    </SendQuotationFormButton>
                </QuotationActions>
            )}
        </Stack>
    );
}

export default QuotationResultUi;
