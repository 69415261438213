import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import useRefreshApiData from './useRefreshApiData';

export interface RefreshApiDataButtonProps {
    children: React.ReactNode;
    component?: React.ComponentType<{
        onClick?: () => void;
        disabled?: boolean;
    }>;
}

function RefreshApiDataButton({
    children,
    component,
}: RefreshApiDataButtonProps) {
    const ButtonComponent = component || Button;
    const isOnline = useOnlineStatus();
    const { refreshApiData, loading } = useRefreshApiData();

    const handleClick = useCallback(() => {
        refreshApiData();
    }, [refreshApiData]);

    return (
        <ButtonComponent onClick={handleClick} disabled={!isOnline || loading}>
            {children}
        </ButtonComponent>
    );
}

export default RefreshApiDataButton;
