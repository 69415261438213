import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import React from 'react';
import useLoadAgencies from './useLoadAgencies';

export const AgenciesCtx = React.createContext<AgencyResponse[] | undefined>(
    undefined
);

export interface AgenciesProviderProps {
    children?: React.ReactNode;
    agency?: string | AgencyResponse | null | undefined;
    skipAgencyRequests?: boolean | undefined;
    enabledPreloadImage?: boolean | undefined;
}

function AgenciesProvider({
    children,
    agency,
    skipAgencyRequests,
    enabledPreloadImage,
}: AgenciesProviderProps) {
    const agencies = useLoadAgencies(
        agency,
        skipAgencyRequests,
        enabledPreloadImage
    );

    return (
        <AgenciesCtx.Provider value={agencies}>{children}</AgenciesCtx.Provider>
    );
}

export default AgenciesProvider;
