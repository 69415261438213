import React from 'react';
import { Box, Skeleton } from '@mui/material';

export interface SelectAgencySkeletonProps {
    pt?: any | undefined;
}

function SelectAgencySkeleton({ pt }: SelectAgencySkeletonProps) {
    return (
        <Box data-testid="SelectAgencySkeleton" pt={pt}>
            <Skeleton width="50%" />
            <Skeleton width="30%" />
        </Box>
    );
}

export default SelectAgencySkeleton;
