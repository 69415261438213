import { initString } from '@acdc/shared/src/utils/form-helpers';
import { useEffect, useMemo } from 'react';
import type UserModel from '@acdc/shared/src/features/user/User.model';
import { ApiFile } from '@acdc/shared/src/ui/Dropzone';
import useLazyUserToUpdate from './useLazyUserToUpdate';
import type { UserFormValue } from './UserForm';
import CONFIGS from '../../configs';

const initValue = (value: DeepPartial<UserModel>): UserFormValue => ({
    lastname: initString(value?.lastname),
    firstname: initString(value?.firstname),
    email: initString(value?.email),
    phone: initString(value?.phone),
    secondaryEmail: initString(value?.secondaryEmail),
    profession: initString(value?.profession),
    comment: initString(value?.comment),
    photo: [],
});

const useUserFormInitialValues = (
    value: DeepPartial<UserModel> | undefined
): [UserFormValue | null, ApiFile[]] => {
    const isUpdate: boolean = !!value?.id;

    // chargement de la valeur de l'api si besoin
    const [query, { data: userResponse }] = useLazyUserToUpdate(value?.id);
    useEffect(() => {
        if (isUpdate) {
            query();
        }
    }, [query, isUpdate]);

    const userFromResponse = useMemo<DeepPartial<UserModel> | undefined>(() => {
        if (!userResponse?.user) {
            return undefined;
        }
        // on doit convertir le UserResponse en UserModel pour le form
        return {
            id: userResponse?.user?.id,
            ...(userResponse?.user as any),
        };
    }, [userResponse?.user]);

    const initialPhotos = useMemo<ApiFile[]>(() => {
        if (!userResponse?.user?.photo?.publicPath) {
            return [];
        }

        return [
            {
                ...userResponse?.user?.photo,
                publicUrl:
                    CONFIGS.apiEntrypoint + userResponse.user.photo.publicPath,
            },
        ];
    }, [userResponse]);

    const initialValue = isUpdate ? userFromResponse : value;

    const initialFormValues: UserFormValue = useMemo(
        () => initValue(initialValue || {}),
        [initialValue]
    );

    const isLoaded: boolean = isUpdate ? Boolean(userResponse) : true;

    return [isLoaded ? initialFormValues : null, initialPhotos];
};

export default useUserFormInitialValues;
