import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import React, { useCallback, useMemo, useRef } from 'react';
import { Form, Formik } from 'formik';
import { entityToId, initBool } from '@acdc/shared/src/utils/form-helpers';
import DiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/DiscoveryCriteriaOverrideResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import DiscoveryCriteriaOverride from '@acdc/shared/src/features/discoveryCriteriaOverride/DiscoveryCriteriaOverride.model';
import { ApolloError } from '@apollo/client';
import DiscoveryCriteriaOverrideFormInner from './DiscoveryCriteriaOverrideFormInner';
import useSubmitDiscoveryCriteriaOverride from './useSubmitDiscoveryCriteriaOverride';

export interface DiscoveryCriteriaOverrideFormValue {
    enabled: boolean;
}

export const discoveryCriteriaOverrideFormSchema = Yup.object().shape({
    enabled: Yup.bool().label('La visibilité de la page').required(),
});

const initValue = (
    discoveryCriteria: DiscoveryCriteriaOverrideResponse
): DiscoveryCriteriaOverrideFormValue => ({
    enabled: !initBool(discoveryCriteria.disabled, false),
});

export interface DiscoveryCriteriaOverrideFormProps {
    value: DiscoveryCriteriaOverrideResponse | null | undefined;
    discoveryCriteria: DiscoveryCriteriaResponse;
    agency: AgencyResponse;
    onSuccess?: ((res: DiscoveryCriteriaOverrideResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

function DiscoveryCriteriaOverrideForm({
    value,
    discoveryCriteria,
    agency,
    onSuccess,
    onError,
}: DiscoveryCriteriaOverrideFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const fixedProperties = useMemo<DeepPartial<DiscoveryCriteriaOverride>>(
        () => ({
            criteria: entityToId(discoveryCriteria) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [discoveryCriteria, agency]
    );

    const submit = useSubmitDiscoveryCriteriaOverride(
        getId,
        setId,
        discoveryCriteriaOverrideFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );
    const initialValue = useMemo(() => initValue(value || {}), [value]);

    return (
        <Formik
            validationSchema={discoveryCriteriaOverrideFormSchema}
            initialValues={initialValue}
            onSubmit={(
                values: DiscoveryCriteriaOverrideFormValue,
                formikHelpers
            ) => {
                submit(values, formikHelpers);
            }}
        >
            {() => (
                <Form>
                    <DiscoveryCriteriaOverrideFormInner
                        getDiscoveryCriteriaOverrideId={getId}
                        discoveryCriteria={discoveryCriteria}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default DiscoveryCriteriaOverrideForm;
