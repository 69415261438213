import { useContext } from 'react';
import { ServicesCtx } from './ServicesProvider';

/**
 * Retourne toutes les Services.
 */
function useServices() {
    return useContext(ServicesCtx);
}

export default useServices;
