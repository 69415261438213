import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import QuotationForm from '../features/quotation/QuotationForm';
import Packages from '../features/package/Packages';
import useLoadQuotation from '../features/quotation/useLoadQuotation';
import { quotationResponseToQuotationUiData } from '../features/quotation/convertQuotationResponse';
import useSelectedPackageId from '../features/package/useSelectedPackageId';
import QuotationResultUi from '../features/quotation/QuotationResult/QuotationResultUi';
import QuotationRentTotals from '../features/quotation/QuotationRentTotals.model';
import QuotationManagementTotals from '../features/quotation/QuotationManagementTotals.model';

function QuotationExport() {
    const { quotationId } = useParams();
    const quotation = useLoadQuotation(
        quotationId ? `/quotations/${quotationId}` : undefined
    );
    const quotationUiData = useMemo(() => {
        if (!quotation) {
            return null;
        }
        return quotationResponseToQuotationUiData(quotation);
    }, [quotation]);
    const [, setSelectedPackageId] = useSelectedPackageId();

    useEffect(() => {
        if (quotation?.pack) {
            setSelectedPackageId(entityToId(quotation.pack) || '');
        }
    }, [quotation, setSelectedPackageId]);

    if (!quotation || !quotationUiData) {
        return null;
    }

    return (
        <>
            <Box height="100vh" className="export-page">
                <QuotationForm value={quotationUiData.formValues} />
            </Box>
            <Box height="100vh" className="export-page">
                <Stack direction="column" spacing={2}>
                    <QuotationResultUi
                        rentTotals={
                            quotationUiData.rentTotals as QuotationRentTotals
                        }
                        managementTotals={
                            quotationUiData.managementTotals as QuotationManagementTotals
                        }
                        hideActions
                    />
                </Stack>
            </Box>
            <Packages headingId="packs-pdf" isExport />
        </>
    );
}

export default QuotationExport;
