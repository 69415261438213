import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function StoreIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50" data-testid="StoreIcon">
            <path d="M28.246 48.257h-6.747V37.884h6.747zM8.992 33.182h31.761v15.075H29.47V37.272a.612.612 0 00-.612-.613h-7.971a.612.612 0 00-.613.613v10.985H8.991zm31.761-21.61v13.1H29.47v-9.738a.612.612 0 00-.612-.612h-7.971a.612.612 0 00-.613.612v9.738H8.991v-13.1zM8.973 1.741h31.799l1.19 8.607H7.782zm28.264 24.156h3.516v6.062h-3.516zm-4.701 0h3.477v6.062h-3.477zm-4.701 0h3.477v6.062h-3.477zm-4.701 0h3.477v6.062h-3.477zm5.111-1.224h-6.747v-9.126h6.747zm-9.812 1.224h3.477v6.062h-3.477zm-4.701 0h3.477v6.062h-3.477zm-4.74 0h3.516v6.062H8.992zM2.574 48.259a.612.612 0 100 1.225h44.462a.612.612 0 100-1.225h-5.06l.001-36.685h.687a.61.61 0 00.606-.695l-1.359-9.831a.612.612 0 00-.606-.529H8.44a.613.613 0 00-.606.529l-1.36 9.83a.611.611 0 00.607.696h.688v36.685" />
        </SvgIcon>
    );
}

export default StoreIcon;
