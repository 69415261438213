import { Form, Formik } from 'formik';
import React, {
    useMemo,
    useRef,
    useCallback,
    useState,
    useEffect,
} from 'react';
import { ApolloError } from '@apollo/client';
import {
    entityToId,
    filterString,
    initString,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import PackageAgency from '@acdc/shared/src/features/packageAgency/PackageAgency.model';
import LabelOverridePackageAgencyFormInner from './LabelOverridePackageAgencyFormInner';
import useSubmitPackageAgency from '../useSubmitPackageAgency';
import useOverridedPackage from '../../package/useOverridedPackage';

export interface LabelOverridePackageAgencyFormValue {
    labelOverride: string;
}

const initValue = (
    value: PackageAgencyResponse
): LabelOverridePackageAgencyFormValue => ({
    labelOverride: initString(value.labelOverride),
});

const filterFormValues = (
    formValue: LabelOverridePackageAgencyFormValue
): DeepPartial<PackageAgency> => ({
    labelOverride: filterString(formValue.labelOverride),
});

export const labelOverridePackageAgencyFormSchema = Yup.object().shape({
    labelOverride: Yup.string().label('Le libellé').optional(),
});

export type LabelOverridePackageAgencyFormProps = {
    value?: PackageAgencyResponse | undefined;
    pack: PackageResponse;
    agency: AgencyResponse;
    onSuccess?: (res: PackageAgencyResponse) => void;
    onError?: (err: ApolloError) => void;
};

function LabelOverridePackageAgencyForm({
    value,
    pack,
    agency,
    onSuccess,
    onError,
}: LabelOverridePackageAgencyFormProps) {
    const overridedPack = useOverridedPackage(pack);
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: LabelOverridePackageAgencyFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const [saved, setSaved] = useState(
        Boolean(value?.id && value.labelOverride)
    );

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<PackageAgency>>(
        () => ({
            package: entityToId(pack) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [pack, agency]
    );

    const submit = useSubmitPackageAgency<LabelOverridePackageAgencyFormValue>(
        getId,
        setId,
        setSaved,
        labelOverridePackageAgencyFormSchema,
        onSuccess,
        onError,
        fixedProperties,
        filterFormValues
    );

    useEffect(() => {
        if (value?.id && !getId()) {
            // si l'entité a été créée depuis ailleurs
            // on set l'id utilisé dans useSubmitPackageAgency
            // pour assurer une requete Update et pas Create
            setId(value.id);
        }
    }, [value, getId, setId]);

    return (
        <Formik
            validationSchema={labelOverridePackageAgencyFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <LabelOverridePackageAgencyFormInner
                    pack={pack}
                    getId={getId}
                    saved={saved}
                    labelOverridePlaceholder={overridedPack?.label}
                />
            </Form>
        </Formik>
    );
}

export default LabelOverridePackageAgencyForm;
