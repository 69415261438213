import { Box, Button, StepLabel } from '@mui/material';
import React from 'react';
import { Link, To } from 'react-router-dom';
import CircleStepIcon from './CircleStepIcon';

export interface MenuStepProps {
    children: React.ReactNode;
    to?: To;
    onClick?: (ev: any) => void;
    actions?: React.ReactNode;
    sx?: any;
    button?: boolean;
    disabled?: boolean;
}

function MenuStep({
    children,
    to,
    onClick,
    actions,
    sx,
    button,
    disabled,
}: MenuStepProps) {
    const linkProps: any = button
        ? {}
        : {
              to,
              component: Link,
          };
    return (
        <StepLabel sx={{ width: '100%' }} StepIconComponent={CircleStepIcon}>
            <Box
                sx={
                    actions
                        ? {
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                          }
                        : {}
                }
            >
                <Button
                    {...linkProps}
                    onClick={onClick}
                    className="active MenuStep-Button"
                    sx={(theme) => ({
                        [theme.breakpoints.down('lg')]: {
                            fontSize: '0.6rem',
                        },
                        [theme.breakpoints.up('lg')]: {
                            fontSize: '0.7rem',
                        },
                        ...sx,
                    })}
                    disabled={disabled}
                >
                    {children}
                </Button>
                {actions && (
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        {actions}
                    </Box>
                )}
            </Box>
        </StepLabel>
    );
}

export default MenuStep;
