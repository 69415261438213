import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { useEffect, useRef, useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useNotificationQueue from './useNotificationQueue';
import useUpdateReadNotification from '../useUpdateReadNotification';
import useDeleteNotificationUser from '../useDeleteNotificationUser';

function NotificationQueueHandler() {
    const { queueUpdate, setQueueUpdate } = useNotificationQueue();
    const { queueDelete, setQueueDelete } = useNotificationQueue();

    const isOnline = useOnlineStatus();
    const isProcessing = useRef<boolean>(false);
    const [shouldRetry, setShouldRetry] = useState<boolean>(false);
    const updateReadNotification = useUpdateReadNotification();
    const deleteNotificationUser = useDeleteNotificationUser();
    const apollo = useApolloClient();

    useEffect(() => {
        if (isProcessing.current) {
            setShouldRetry(true);
            return;
        }

        if (!isOnline || (!queueUpdate.length && !queueDelete.length)) {
            return;
        }
        isProcessing.current = true;

        const updatePromises = queueUpdate.map((queueItem) => {
            if (
                queueDelete.some((itemToDelete) =>
                    matchOnId(itemToDelete, queueItem)
                )
            ) {
                // pas besoin d'updateRead si on delete
                return null;
            }
            return updateReadNotification(queueItem).catch(
                (err: ApolloError) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    apollo.cache.evict({ fieldName: 'notificationUsers' });
                    return Promise.resolve();
                }
            );
        });

        const deletePromises = queueDelete.map((queueItem) =>
            deleteNotificationUser(queueItem).catch((err: ApolloError) => {
                // eslint-disable-next-line no-console
                console.error(err);
                apollo.cache.evict({ fieldName: 'notificationUsers' });
                return Promise.resolve();
            })
        );

        Promise.all([updatePromises, deletePromises]).then(() => {
            isProcessing.current = false;
            setShouldRetry(false);
        });

        // clean de la file d'attente
        setQueueUpdate([]);
        setQueueDelete([]);
    }, [
        isOnline,
        shouldRetry,
        setShouldRetry,
        queueUpdate,
        queueDelete.length,
        setQueueUpdate,
        queueDelete,
        setQueueDelete,
        updateReadNotification,
        deleteNotificationUser,
        apollo,
    ]);

    return null;
}

export default NotificationQueueHandler;
