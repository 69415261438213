import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import type ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import findImageOverrideBySettingCode from './findImageOverrideBySettingCode';

export interface ModuleSettingCodes {
    [code: string]: ImageOverrideResponse | undefined;
}

const findImageOverrideByMultipleSettingCodes = (
    codes: ModuleSettingCode[] | undefined,
    imageOverrides: ImageOverrideResponse[] | undefined
) => {
    if (!codes) {
        return undefined;
    }

    return codes.reduce((curr: ModuleSettingCodes, code: ModuleSettingCode) => {
        const imageOverride = findImageOverrideBySettingCode(
            code,
            imageOverrides
        );
        if (imageOverride) {
            // eslint-disable-next-line no-param-reassign
            curr[code] = imageOverride;
        }

        return curr;
    }, {});
};
export default findImageOverrideByMultipleSettingCodes;
