import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { getToolOverride } from '../toolOverride/useToolOverride';

function isToolEnabled(
    tool: string | ToolResponse | undefined | null,
    selectedAgency: AgencyResponse | undefined
) {
    const currentToolOverride = getToolOverride(tool, selectedAgency);

    return !currentToolOverride || !currentToolOverride.disabled;
}

export default isToolEnabled;
