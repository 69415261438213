import React, { useMemo } from 'react';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { Box } from '@mui/material';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import useAgency from '../../agency/useAgency';
import useSelectedAgency from '../../agency/useSelectedAgency';

function getStatus(
    servicePackageOverride: ServicePackageOverrideResponse | undefined
): 'included' | 'disabled' | 'charged' {
    if (!servicePackageOverride) {
        return 'included';
    }
    if (servicePackageOverride.unit === ServiceUnit.DISABLED) {
        return 'disabled';
    }
    if (servicePackageOverride.price || servicePackageOverride.price === 0) {
        return 'charged';
    }
    return 'included';
}

function formatUnit(unit: string | undefined, splitUnit: boolean | undefined) {
    if (!unit) {
        return null;
    }
    const fontSize = '0.8rem';
    if (!splitUnit) {
        return (
            <Box component="span" fontSize={fontSize}>
                {unit}
            </Box>
        );
    }

    let baseUnit: React.ReactNode = unit;
    if (unit.length > 12 && unit.includes('/')) {
        // par exemple "€/intervention" est trop long donc on veut que "intervention" soit à la ligne.
        const parts = unit.split('/', 2);
        baseUnit = (
            <>
                {parts[0]}/
                <br />
                {parts[1]}
            </>
        );
    }

    return (
        <Box component={unit.length > 1 ? 'div' : 'span'} fontSize={fontSize}>
            {baseUnit}
        </Box>
    );
}

export interface ServicePriceProps {
    service: ServiceResponse;
    pack: PackageResponse | undefined;
    /**
     * Faut il faire revenir l'unité à la ligne si elle est trop longue ?
     */
    splitUnit?: boolean;
}

/**
 * Retourne soit le montant du Service (avec l'unité) soit un icon qui indique que le service est inclu dans le pack.
 */
function ServicePrice({ service, pack, splitUnit }: ServicePriceProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const servicePackageOverride = useMemo(
        () =>
            agency?.servicePackageOverrides?.collection?.find(
                (sp) =>
                    matchOnId(sp.service, service) &&
                    matchOnId(sp.package, pack)
            ),
        [service, agency, pack]
    );
    const status = useMemo(
        () => getStatus(servicePackageOverride),
        [servicePackageOverride]
    );

    switch (status) {
        case 'included':
            return <CheckCircleRoundedIcon color="success" />;
        case 'charged':
            return (
                <>
                    <strong>{servicePackageOverride?.price}</strong>
                    {formatUnit(servicePackageOverride?.unit, splitUnit)}
                </>
            );
        case 'disabled':
            return <CancelIcon color="error" />;
        default:
            // Normalement impossible car toutes les valeurs possibles ont un case.
            return <></>;
    }
}

export default ServicePrice;
