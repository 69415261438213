import React from 'react';
import MultiAuth from '@acdc/shared/src/features/auth/MultiAuth';
import { Box } from '@mui/material';
import CONFIGS from '../../configs';

function Connexion() {
    const {
        enableCasAuthentication,
        casEntrypoint,
        frontEntrypoint,
        authStorageKeys,
        userDataStorageKeys,
    } = CONFIGS;

    return (
        <Box sx={{ height: '100vh' }}>
            <MultiAuth
                validationUrl={`${frontEntrypoint}/auth/validation`}
                casEntrypoint={casEntrypoint}
                authStorageKeys={authStorageKeys}
                userDataStorageKeys={userDataStorageKeys}
                enableCasAuthentication={enableCasAuthentication === 'true'}
            />
        </Box>
    );
}

export default Connexion;
