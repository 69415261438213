import { Button, CheckboxProps, Checkbox } from '@mui/material';
import React from 'react';

export type CheckButtonOwnProps = {
    label: string;
};

export type CheckButtonProps = CheckButtonOwnProps & CheckboxProps;

/**
 * Comme un Checkbox mais présenté avec un bouton à la place d'une box.
 */
function CheckButton({ label, ...props }: CheckButtonProps) {
    return (
        <Checkbox
            {...props}
            disableRipple
            icon={
                <Button type="button" variant="contained" role="presentation">
                    {label}
                </Button>
            }
            checkedIcon={
                <Button
                    type="button"
                    variant="contained"
                    color="success"
                    role="presentation"
                >
                    {label}
                </Button>
            }
            onChange={(...args) => {
                return props.onChange && props.onChange(...args);
            }}
        />
    );
}

export default CheckButton;
