import { useState, useCallback, useEffect } from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// see https://www.toplyne.io/blog/pwa-update-notifications-in-a-react-app

function useServiceWorker() {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
        null
    );
    const [showReload, setShowReload] = useState<boolean>(false);

    /**
     * callback appellée quand une mise à jour du service worker est
     * détectée mais qu'elle est mise en attente.
     */
    const onSWUpdate = useCallback(
        (registration: ServiceWorkerRegistration) => {
            setShowReload(true);
            setWaitingWorker(registration.waiting);
        },
        []
    );

    /**
     * recharge la page en mettant à jour le service worker
     */
    const reloadPage = useCallback(() => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    }, [waitingWorker]);

    // active le serviceWorker avec la callback onSWUpdate
    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https:
        // cra.link/PWA
        serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    }, [onSWUpdate]);

    return { showReload, waitingWorker, reloadPage };
}

export default useServiceWorker;
