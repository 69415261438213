import { Theme } from '@mui/material';
import React from 'react';
import usePackage from '../../package/usePackage';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import QuotationManagementTotals from '../QuotationManagementTotals.model';
import QuotationStack from '../QuotationStack';
import QuotationResultHeader from './QuotationResultHeader';
import QuotationResultRow from './QuotationResultRow';
import useOverridedPackage from '../../package/useOverridedPackage';

export interface QuotationManagementResultProps {
    managementTotals: QuotationManagementTotals;
}

function QuotationManagementResult({
    managementTotals,
}: QuotationManagementResultProps) {
    const selectedPack = usePackage(useSelectedPackageId()[0]);
    const overridedPack = useOverridedPackage(selectedPack);
    const $hasNet =
        managementTotals.totalAnnualNet !== managementTotals.totalAnnualGross;

    return (
        <QuotationStack
            title="Gestion"
            color={(theme: Theme) => theme.palette.warning.main}
            spacing={0.5}
        >
            <QuotationResultHeader labelsHeading="Revenus bruts estimés" />

            {(managementTotals.monthlyRent !== undefined ||
                managementTotals.annualRent !== undefined) && (
                <QuotationResultRow
                    variant="values"
                    label="Loyers H.C"
                    monthly={managementTotals.monthlyRent!}
                    annual={managementTotals.annualRent!}
                    containerProps={{ 'data-testid': 'rentRow' }}
                />
            )}
            {(managementTotals.monthlyProvisionsOnCharges !== undefined ||
                managementTotals.annualProvisionsOnCharges !== undefined) && (
                <QuotationResultRow
                    variant="values"
                    label="Provisions sur charges"
                    monthly={managementTotals.monthlyProvisionsOnCharges!}
                    annual={managementTotals.annualProvisionsOnCharges!}
                    containerProps={{
                        'data-testid': 'provisionsOnChargesRow',
                    }}
                />
            )}
            {managementTotals.annualWasteTaxe !== undefined && (
                <QuotationResultRow
                    variant="values"
                    label="Taxe d'ordures ménagères"
                    annual={managementTotals.annualWasteTaxe!}
                    containerProps={{ 'data-testid': 'wasteTaxeRow' }}
                />
            )}
            <QuotationResultRow
                variant="total"
                label="Total revenus bruts"
                monthly={managementTotals.totalMonthlyGross}
                annual={managementTotals.totalAnnualGross}
                containerProps={{ 'data-testid': 'totalGrossRow' }}
            />
            {$hasNet && (
                <>
                    <QuotationResultHeader
                        labelsHeading={`Souscription "pack${
                            overridedPack ? ` ${overridedPack.label}` : ''
                        }"`}
                        hideFrequencyHeading
                        containerProps={{
                            'data-testid': 'packHeader',
                        }}
                    />
                    {(managementTotals.monthlyManagementFee !== undefined ||
                        managementTotals.annualManagementFee !== undefined) && (
                        <QuotationResultRow
                            variant="values"
                            label="Honoraires de gestion"
                            monthly={managementTotals.monthlyManagementFee}
                            annual={managementTotals.annualManagementFee!}
                            containerProps={{
                                'data-testid': 'managementFeeRow',
                            }}
                        />
                    )}
                    {(managementTotals.monthlyUnpaidRentInsurance !==
                        undefined ||
                        managementTotals.annualUnpaidRentInsurance !==
                            undefined) && (
                        <QuotationResultRow
                            variant="values"
                            label="Assurance loyers impayés (G.L.I)"
                            monthly={
                                managementTotals.monthlyUnpaidRentInsurance!
                            }
                            annual={managementTotals.annualUnpaidRentInsurance!}
                            containerProps={{
                                'data-testid': 'unpaidRentInsuranceRow',
                            }}
                        />
                    )}
                    {managementTotals.annualNonOccupantOwnerInsurance !==
                        undefined && (
                        <QuotationResultRow
                            variant="values"
                            label="Assurance propriétaire non occupant (P.N.O)"
                            monthly={
                                managementTotals.monthlyNonOccupantOwnerInsurance!
                            }
                            annual={
                                managementTotals.annualNonOccupantOwnerInsurance!
                            }
                            containerProps={{
                                'data-testid': 'nonOccupantOwnerInsuranceRow',
                            }}
                        />
                    )}
                    <QuotationResultRow
                        variant="values"
                        label="Total montants déductibles des revenus locatifs"
                        boldValues
                        monthly={managementTotals.totalMonthlyFees}
                        annual={managementTotals.totalAnnualFees}
                        containerProps={{ 'data-testid': 'totalFeesRow' }}
                    />
                    <QuotationResultRow
                        variant="total"
                        label="Total revenus reversés"
                        monthly={managementTotals.totalMonthlyNet}
                        annual={managementTotals.totalAnnualNet}
                        containerProps={{ 'data-testid': 'totalNetRow' }}
                    />
                </>
            )}
        </QuotationStack>
    );
}

export default QuotationManagementResult;
