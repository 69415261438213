import React, { useState } from 'react';
import LeftDrawerState from './LeftDrawerState';

export const LeftSideBarStateCtx = React.createContext<
    [LeftDrawerState, React.Dispatch<React.SetStateAction<LeftDrawerState>>]
>([{ state: false }, () => {}]);

export interface LeftSideBarStateProps {
    children?: React.ReactNode;
}

function LeftSideBarStateProvider({ children }: LeftSideBarStateProps) {
    const state = useState<LeftDrawerState>({ state: false });

    return (
        <LeftSideBarStateCtx.Provider value={state}>
            {children}
        </LeftSideBarStateCtx.Provider>
    );
}

export default LeftSideBarStateProvider;
