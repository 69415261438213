import React from 'react';
import ThemeProvider from '@mui/system/ThemeProvider';
import BrowserRouter from '@acdc/shared/src/router/BrowserRouter';
import AlertProvider from '@acdc/shared/src/tools/alert/AlertProvider';
import CssBaseline from '@mui/material/CssBaseline';
import PermissionsProvider from '@acdc/shared/src/security/PermissionsProvider';
import {
    getRoles,
    isAuthenticated as apolloIsAuthenticated,
} from '@acdc/shared/src/apollo/auth-tokens';
import ACDC_ROLES_HIERARCHY from '@acdc/shared/src/security/ACDC_ROLES_HIERARCHY';
import OnlineStatusProvider from '@acdc/shared/src/tools/use-online-status/OnlineStatusProvider';
import DateFnsUtilsFR from '@acdc/shared/src/date-fns/DateFnsUtilsFR';
import frLocale from 'date-fns/locale/fr';
import { LocalizationProvider } from '@mui/lab';
import Pages from './pages/Pages';
import theme from './mui/theme';
import CONFIGS from './configs';
import SelectedAgencyProvider from './features/agency/SelectedAgencyProvider';
import SelectedPackageProvider from './features/package/SelectedPackageProvider';
import PresentationFlowProgressProvider from './features/presentationFlow/PresentationFlowProgressProvider';
import EditModeProvider from './features/template/EditModeProvider';
import LeftSideBarStateProvider from './layout/LeftSideBar/LeftSideBarStateProvider';
import PersistedApolloProvider from './apollo/PersistedApolloProvider';
import CurrentPresentationProvider from './features/presentation/CurrentPresentationProvider';
import ServiceWorker from './ServiceWorker';
import HeaderHeightProvider from './layout/HeaderHeightProvider';
import PreferencesProvider from './features/preferences/PreferencesProvider';

const getUserRoles = () => getRoles(CONFIGS.authStorageKeys) || [];
const isAuthenticated = () => apolloIsAuthenticated(CONFIGS.authStorageKeys);

function App() {
    return (
        <ServiceWorker>
            <OnlineStatusProvider>
                <PersistedApolloProvider>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider
                            dateAdapter={DateFnsUtilsFR}
                            locale={frLocale}
                        >
                            <CssBaseline>
                                <PermissionsProvider
                                    getUserRoles={getUserRoles}
                                    rolesHierarchy={ACDC_ROLES_HIERARCHY}
                                    isAuthenticated={isAuthenticated}
                                    loginRoute="/auth/connexion"
                                >
                                    <HeaderHeightProvider>
                                        <AlertProvider>
                                            <PreferencesProvider>
                                                <CurrentPresentationProvider>
                                                    <PresentationFlowProgressProvider>
                                                        <SelectedAgencyProvider>
                                                            <SelectedPackageProvider>
                                                                <EditModeProvider>
                                                                    <LeftSideBarStateProvider>
                                                                        <BrowserRouter>
                                                                            <Pages />
                                                                        </BrowserRouter>
                                                                    </LeftSideBarStateProvider>
                                                                </EditModeProvider>
                                                            </SelectedPackageProvider>
                                                        </SelectedAgencyProvider>
                                                    </PresentationFlowProgressProvider>
                                                </CurrentPresentationProvider>
                                            </PreferencesProvider>
                                        </AlertProvider>
                                    </HeaderHeightProvider>
                                </PermissionsProvider>
                            </CssBaseline>
                        </LocalizationProvider>
                    </ThemeProvider>
                </PersistedApolloProvider>
            </OnlineStatusProvider>
        </ServiceWorker>
    );
}

export default App;
