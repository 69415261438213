import { DISCOVERY_VALUE_DELIMITER } from '@acdc/shared/src/features/discovery/Discovery.model';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';

/**
 * Converti une valeur string en un tableau d'ids (iris) de DiscoveryChoices.
 * La valeur fournie doit avoir été générée par discoveryChoicesToDiscoveryValue().
 * Attention: cette fonction ne fait que split la valeur, elle ne vérifie pas que les items sont des iris
 * de DiscoveryChoices. Soyez sùr que vous l'utilisez sur la valeur d'un DiscoveryCriteria qui a des choices.
 *
 * @param discoveryValue Un DiscoveryValue ou directement son DiscoveryValue.value.
 */
function discoveryValueToDiscoveryChoiceIds(
    discoveryValue: string | DiscoveryValue
): string[] {
    const val: string =
        typeof discoveryValue === 'string'
            ? discoveryValue
            : discoveryValue.value;

    if (!val) return [];

    return val.split(DISCOVERY_VALUE_DELIMITER);
}

export default discoveryValueToDiscoveryChoiceIds;
