import React from 'react';

export const IsExportCtx = React.createContext<boolean>(false);

export interface SelectedPackageProviderProps {
    children?: React.ReactNode;
    value: boolean;
}

function IsExportProvider({ children, value }: SelectedPackageProviderProps) {
    return (
        <IsExportCtx.Provider value={value}>{children}</IsExportCtx.Provider>
    );
}

export default IsExportProvider;
