import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import React from 'react';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import useLoadDiscoveryForms from './useLoadDiscoveryForms';

export const DiscoveryFormsCtx = React.createContext<
    DiscoveryFormResponse[] | undefined
>(undefined);

export interface DiscoveryFormsProviderProps {
    children?: React.ReactNode;
    agency?: string | AgencyResponse | null | undefined;
    skipAgencyRequests?: boolean | undefined;
}

function DiscoveryFormsProvider({
    children,
    agency,
    skipAgencyRequests,
}: DiscoveryFormsProviderProps) {
    const items = useLoadDiscoveryForms(agency, skipAgencyRequests);

    return (
        <DiscoveryFormsCtx.Provider value={items}>
            {children}
        </DiscoveryFormsCtx.Provider>
    );
}

export default DiscoveryFormsProvider;
