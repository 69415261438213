import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import AppModuleCard from '../features/appModule/AppModuleCard';
import PresentationIcon from '../ui/icons/PresentationIcon';
import DiscoveryIcon from '../ui/icons/DiscoveryIcon';

function HomePage() {
    return (
        <Box paddingTop={4}>
            <Typography variant="h2" component="h1" marginBottom={5}>
                Modules disponibles
            </Typography>
            <Stack
                spacing={4}
                marginTop={2}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
            >
                <AppModuleCard
                    label="Présentation de services"
                    icon={PresentationIcon}
                    to="/mes-rendez-vous"
                />
                <AppModuleCard
                    label="Découverte projet"
                    icon={DiscoveryIcon}
                    to="/decouvertes"
                />
            </Stack>
        </Box>
    );
}

export default HomePage;
