/**
 * Ajoute une nouvelle valeur dans un tableau. Si la nouvelle valeur
 * match avec un des item existant, l'item est remplacé, sinon la nouvelle
 * valeur est ajoutée en fin de tableau.
 * Le résultat est une nouvelle instance du tableau, le tableau initial n'est
 * pas modifié.
 * @param arr
 * @param value
 * @param match Indique si une des valeur du tableau match avec la nouvelle valeur. Si retourne true l'item sera remplacé par la nouvelle valeur.
 * @param map Si on remplace une valeur existante permet de transformer la valeur avant le remplacement en fonction de la valeur actuelle.
 * @return Nouvelle instance du tableau avec la nouvelle valeur
 */
function arrayMapOrPush<T = any>(
    arr: T[],
    value: T,
    match: (value: T, arrItem: T) => boolean,
    map?: (value: T, arrItem: T) => T
) {
    let replaced = false;
    const res = arr.map((i) => {
        if (match(value, i)) {
            replaced = true;

            if (map) {
                return map(value, i);
            }
            return value;
        }

        return i;
    });

    if (replaced === false) {
        return [...arr, value];
    }

    return res;
}

export default arrayMapOrPush;
