import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import React, { useCallback, useMemo, useState } from 'react';
import useDebounce from '@acdc/shared/src/utils/useDebounce';
import { unaccent } from '@acdc/shared/src/utils/display-helpers';
import useLocations from '../../../search/useLocations';

const MAX_OPTIONS = 100;

function filterOptions(
    options: string[],
    inputValue: string,
    maxOptions: number
) {
    const inputWords = unaccent(inputValue.toLowerCase()).split(' ');

    const res: string[] = [];

    for (let i = 0; i < options.length; i += 1) {
        const optionWords = unaccent(options[i].toLowerCase()).split(' ');

        // Pour que l'option soit retourné il faut que tous les mots de l'input recherché soient présents dans l'option et qu'ils soient tous au début d'un mot de l'option.
        // exemple si on cherche "paris 75"
        // - on veut que "Paris 75000" soit retourné
        // - mais pas "Fooparis 75000" car "paris" n'est pas au début d'un mot mais en fin
        // - ni "Parisot 82160" car "75" n'est pas au début d'un mot
        if (
            inputWords.every((inputWord) =>
                optionWords.some((optionWord) =>
                    optionWord.startsWith(inputWord)
                )
            )
        ) {
            res.push(options[i]);
        }

        if (res.length >= maxOptions) {
            break;
        }
    }

    return res;
}

function dontFilter(val: any) {
    return val;
}

export interface LocationSelectProps
    extends Omit<AutocompleteProps, 'options' | 'filterOptions'> {}

function LocationSelect(props: LocationSelectProps) {
    const [inputValue, setInputValue] = useState('');
    const handleDebounceChange = useCallback(
        (ev: any) => {
            setInputValue(ev.target.value);
        },
        [setInputValue]
    );
    const [, onInputChange]: any = useDebounce('', handleDebounceChange);
    const search = useLocations();
    const options = useMemo(() => {
        if (!search?.options?.length) {
            return [];
        }

        if (!inputValue) {
            return search.options.slice(0, MAX_OPTIONS);
        }

        return filterOptions(search.options, inputValue, MAX_OPTIONS);
    }, [search, inputValue]);

    return (
        <Autocomplete
            {...props}
            options={options}
            filterOptions={dontFilter}
            onInputChange={onInputChange}
            placeholder="Recherchez une ville ou un code postal"
        />
    );
}

export default LocationSelect;
