import React from 'react';
import { IconButton } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import DefaultHeader, { DefaultHeaderProps } from '../DefaultHeader';
import useDiscoveryDrawer from './useDiscoveryDrawer';

export interface DiscoveryHeaderProps extends DefaultHeaderProps {}

function DiscoveryHeader({
    children,
    ...defaultHeaderProps
}: DiscoveryHeaderProps) {
    const { toggleDrawer } = useDiscoveryDrawer();

    return (
        <DefaultHeader {...defaultHeaderProps}>
            {children}
            <IconButton onClick={toggleDrawer} size="small">
                <ListIcon fontSize="large" />
            </IconButton>
        </DefaultHeader>
    );
}

export default DiscoveryHeader;
