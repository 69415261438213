import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { entityToId, initBool } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/ServiceAgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import ServiceAgency from '@acdc/shared/src/features/serviceAgency/ServiceAgency.model';
import hasValue from '@acdc/shared/src/utils/hasValue';
import ToggleServiceAgencyFormInner from './ToggleServiceAgencyFormInner';
import useSubmitToggleServiceAgency from './useSubmitToggleServiceAgency';

export interface ToggleServiceAgencyFormValue {
    /**
     * enabled et pas disabled comme dans ServiceAgency pour que le
     * switch s'affiche comme il faut.
     */
    enabled: boolean;
    sortOrder: number | null;
}

const initValue = (
    value: ServiceAgencyResponse
): ToggleServiceAgencyFormValue => ({
    enabled: !initBool(value.disabled, true),
    sortOrder: hasValue(value.sortOrder) ? value.sortOrder! : null,
});

export const toggleServiceAgencyFormSchema = Yup.object().shape({
    enabled: Yup.bool().label("L'état d'activation du pack").required(),
    sortOrder: Yup.number().label('Position de trie').nullable().optional(),
});

export type ToggleServiceAgencyFormProps = {
    value?: ServiceAgencyResponse | undefined;
    service: ServiceResponse;
    agency: AgencyResponse;
    onSuccess?: (res: ServiceAgencyResponse) => void;
    onError?: (err: ApolloError) => void;
    /**
     * Le nouveau sortOrder a assigné si on veut remonter le service dans la liste.
     * null si on ne peut pas remonter.
     */
    sortOrderIfUp: number | null;
};

function ToggleServiceAgencyForm({
    value,
    service,
    agency,
    onSuccess,
    onError,
    sortOrderIfUp,
}: ToggleServiceAgencyFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: ToggleServiceAgencyFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<ServiceAgency>>(
        () => ({
            service: entityToId(service) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [service, agency]
    );

    const submit = useSubmitToggleServiceAgency(
        getId,
        setId,
        toggleServiceAgencyFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={toggleServiceAgencyFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <ToggleServiceAgencyFormInner
                    getId={getId}
                    service={service}
                    sortOrderIfUp={sortOrderIfUp}
                />
            </Form>
        </Formik>
    );
}

export default ToggleServiceAgencyForm;
