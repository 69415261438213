import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import type ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import { Theme } from '@mui/material';
import SharedFileResponse from '@acdc/shared/src/features/sharedFile/SharedFileResponse';
import ModuleFilePicker from '@acdc/shared/src/features/moduleFile/ModuleFilePicker';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import type { PartnersFormValues } from './PartnersForm';

const colorSelect = (theme: Theme) => theme.palette.background.rainbow;

interface PartnersFormInnerProps {
    sharedFiles: SharedFileResponse[] | undefined;
    placeHolderPartners: ModuleFile[];
    apiEntrypoint: string;
}

function PartnersFormInner({
    sharedFiles,
    placeHolderPartners,
    apiEntrypoint,
}: PartnersFormInnerProps) {
    const { values, handleChange, isSubmitting } =
        useFormikContext<PartnersFormValues>();
    useAutoSubmitSingleFieldForm('files');

    return (
        <>
            {values && (
                <>
                    <ModuleFilePicker
                        value={values.files}
                        onChange={handleChange}
                        disableDropzone={false}
                        sharedFiles={sharedFiles}
                        name="files"
                        placeholder={placeHolderPartners}
                        disabled={isSubmitting}
                        apiEntrypoint={apiEntrypoint}
                        multiple={false}
                        colorSelect={colorSelect}
                    />
                    <ErrorMessage name="files" component={ErrorHelperText} />
                </>
            )}
        </>
    );
}

export default PartnersFormInner;
