import React, { useCallback, useEffect, useMemo } from 'react';
import ReactFullpage, { fullpageApi } from '@fullpage/react-fullpage';
import countChildren from '@acdc/shared/src/utils/countChildren';
import usePresentationFlowProgress from '../features/presentationFlow/usePresentationFlowProgress';
import FullPageRouterListener from './FullPageRouterListener';
import { usePresentationStatisticQueue } from '../features/presentationStatistic/PresentationStatisticQueue';
import useCurrentPresentation from '../features/presentation/useCurrentPresentation';
import { useHeaderHeight } from './HeaderHeightProvider';
import FullPageNavigationButtons from './FullPageNavigationButtons';
import usePreferences from '../features/preferences/usePreferences';
import CustomReactFullpage from '../react-fullpage/ReactFullpage';

/**
 * Les éléments dans lesquels Fullpage doit laisser le scroll normal.
 *
 */
const NORMAL_SCROLL_ELEMENTS = [
    // une classe générique à placer sur des éléments scrollables
    '.FullPageScrollable',
    // le menu de navigation à droite
    '#sideBar',
    // les modals (Drawer du mode édition, modal de visualisation de module PDF)
    '.MuiModal-root',
    // la liste des choix d'un Autocomplete (selection d'outils à joindre au devis)
    '.MuiAutocomplete-popper',
].join(', ');

export interface FullPageLayoutProps {
    children: React.ReactNode;
    /**
     * Si true la progression de la présentation n'est pas suivie
     */
    disabledTracking?: boolean;
}

/**
 * Affiche les children dans un container FullPage.
 * Les children doivent avoir la class ".section".
 * Attendez que vos données soient chargés avant de rendre FullPageLayout. Si
 * vous modifiez les sections (dans les children) après avoir rendu FullPageLayout
 * il peut y avoir des bugs (paddingTop manquant par exemple).
 */
function FullPageLayout({ children, disabledTracking }: FullPageLayoutProps) {
    const [progress, setProgress] = usePresentationFlowProgress();
    const { addVisitedContent } = usePresentationStatisticQueue();
    const [currentPresentation] = useCurrentPresentation();
    const [headerHeight] = useHeaderHeight();
    const [preferences] = usePreferences();

    const hasMultipleChildren = useMemo(() => {
        return countChildren(children, 2) > 1;
    }, [children]);

    const moveToInitialAnchor = useCallback(() => {
        if (window.location.hash) {
            return;
        }

        (window as any)?.fullpage_api?.silentMoveTo(
            progress ||
                document
                    .querySelector('[data-anchor]')
                    ?.getAttribute('data-anchor')
        );

        // on ne définie pas la dépendance à progress parcequ'on ne veut que sa valeur initiale
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const afterRender = useCallback(() => {
        moveToInitialAnchor();

        if (preferences?.navigateWithButtons) {
            (window as any)?.fullpage_api?.setAllowScrolling(false, 'down, up');
            window.setTimeout(() => {
                // ça ne fonctionne pas toujours donc au cas où on retente après un délai
                // see https://github.com/alvarotrigo/react-fullpage/issues/170#issuecomment-551699433
                (window as any)?.fullpage_api?.setAllowScrolling(
                    false,
                    'down, up'
                );
            }, 200);
        }
    }, [moveToInitialAnchor, preferences]);

    const render = useCallback(
        // eslint-disable-next-line react/no-unused-prop-types
        ({ fullpageApi: api }: { state: any; fullpageApi: fullpageApi }) => (
            <ReactFullpage.Wrapper>
                <FullPageRouterListener fullpageApi={api}>
                    {children}
                </FullPageRouterListener>
            </ReactFullpage.Wrapper>
        ),
        [children]
    );

    const onLeave = useCallback(
        (current: any, to: any) => {
            !disabledTracking && setProgress(to?.anchor || undefined);
            return true;
        },
        [setProgress, disabledTracking]
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!currentPresentation?.uid) {
                return;
            }

            // progress est vide si on lance la présentation et qu'on est sur la première page
            // donc on doit trouver l'ancre active dans ce cas.
            const contentSlug =
                progress ||
                (window as any)?.fullpage_api?.getActiveSection()?.anchor;

            if (!contentSlug) {
                return;
            }
            // on note chaque page sur laquelle on reste au moins 5 secondes
            addVisitedContent(currentPresentation.uid, contentSlug);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [addVisitedContent, progress, currentPresentation]);

    return (
        <>
            {preferences?.navigateWithButtons && hasMultipleChildren && (
                <FullPageNavigationButtons />
            )}
            <CustomReactFullpage
                normalScrollElements={NORMAL_SCROLL_ELEMENTS}
                scrollingSpeed={1000} /* Options here */
                scrollHorizontally
                verticalCentered={false}
                fitToSection={false}
                scrollOverflow={false}
                animateAnchor
                // scrollBar
                render={render}
                onLeave={onLeave}
                afterRender={afterRender}
                paddingTop={headerHeight}
            />
        </>
    );
}

export default FullPageLayout;
