import { matchOnId } from './form-helpers';

/**
 * Retourne une copie de premier tableau sans les items du second tableau.
 * Le matching entre les items des tableaux se fait en fonction de l'id de items.
 */
function arrayDiffById<T = any, R = any>(items: T[], itemsToRemove: R[]): T[] {
    return items.filter((i) => !itemsToRemove.some((r) => matchOnId(i, r)));
}

export default arrayDiffById;
