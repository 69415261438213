import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { useMemo } from 'react';
import serviceIsEnabled from './serviceIsEnabled';
import serviceHasPriceInSomeEnabledPackage from './serviceHasPriceInSomeEnabledPackage';
import serviceIsDisabledInSomeEnabledPackage from './serviceIsDisabledInSomeEnabledPackage';
import useServicesOfAgency from './useServicesOfAgency';

/**
 * Un service est additional si il est activé dans l'agence
 * et qu'il a un prix défini dans un des packs actifs
 * ou qu'il est désactivé dans un des packs actifs.
 */
function useAdditionalServices(agency: AgencyResponse | undefined) {
    const services = useServicesOfAgency(agency);
    return useMemo(() => {
        if (!agency || !services) {
            return [];
        }
        const additionalServices = services?.filter((service) => {
            if (!serviceIsEnabled(service, agency)) {
                return false;
            }

            if (serviceIsDisabledInSomeEnabledPackage(service, agency)) {
                return true;
            }

            return serviceHasPriceInSomeEnabledPackage(service, agency);
        });

        return additionalServices;
    }, [services, agency]);
}

export default useAdditionalServices;
