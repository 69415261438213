import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import uid from '@acdc/shared/src/utils/uid';
import React from 'react';
import useContent from '../content/useContent';
import Content from '../content/Content';

interface CoverPageProps {
    coverPageId: string;
    exportPdf: boolean;
}

function CoverPage({ coverPageId, exportPdf }: CoverPageProps) {
    const content = useContent(coverPageId, ContentType.CONTENT);

    return (
        <>
            {content && (
                <Content key={uid()} content={content} exportPdf={exportPdf} />
            )}
        </>
    );
}

export default CoverPage;
