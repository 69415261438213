import Discovery from '@acdc/shared/src/features/discovery/Discovery.model';
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

const CREATE_DISCOVERY = gql`
    mutation CreateDiscovery($input: createDiscoveryInput!) {
        createDiscovery(input: $input) {
            clientMutationId
        }
    }
`;

function useSendDiscovery() {
    const [createDiscovery] = useMutation(CREATE_DISCOVERY);

    return useCallback(
        (discovery: DeepPartial<Discovery>) => {
            createDiscovery({ variables: { input: discovery } });
        },
        [createDiscovery]
    );
}

export default useSendDiscovery;
