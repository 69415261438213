import { Grid } from '@mui/material';
import React from 'react';
import usePackageAgenciesOf from '../packageAgency/usePackageAgenciesOf';
import useSelectedAgency from '../agency/useSelectedAgency';
import PackageTeaser from './PackageTeaser';
import PackageTeasersListSkeleton from './PackageTeasersListSkeleton';

/**
 * Liste d'aperçus de Packages.
 */
function PackageTeasersList() {
    const packageAgencies = usePackageAgenciesOf(useSelectedAgency()[0], true);

    if (!packageAgencies?.length) {
        return <PackageTeasersListSkeleton />;
    }

    return (
        <Grid container data-testid="PackageTeasersList" spacing={4}>
            {packageAgencies?.map((packageAgency) => (
                <Grid item key={`${packageAgency.id}`} sm>
                    <PackageTeaser
                        packageId={`${packageAgency?.package?.id}`}
                        rate={packageAgency.rate}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default PackageTeasersList;
