export enum DrawerStateEnum {
    FULLSCREEN = 'fullscreen',
    OPEN = 'open',
    CLOSE = 'close',
}

interface DrawerState {
    currentState: DrawerStateEnum;
    previousState: DrawerStateEnum;
}

export default DrawerState;
