import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import type MutateToolOverrideResponse from '@acdc/shared/src/features/toolOverride/MutateToolOverrideResponse';
import type CreateToolOverrideResponse from '@acdc/shared/src/features/toolOverride/CreateToolOverrideResponse';
import type UpdateToolOverrideResponse from '@acdc/shared/src/features/toolOverride/UpdateToolOverrideResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import { FormikHelpers } from 'formik';
import type ToolOverride from '@acdc/shared/src/features/toolOverride/ToolOverride.model';
import type ToolOverrideResponse from '@acdc/shared/src/features/toolOverride/ToolOverrideResponse';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId, filterString } from '@acdc/shared/src/utils/form-helpers';
import { GET_TOOL_OVERRIDES, TOOL_OVERRIDE_FRAGMENT } from './useToolOverrides';
import {
    type ToolOverrideFormProps,
    type ToolOverrideFormValue,
} from './ToolOverrideForm';
import useSelectedAgency from '../agency/useSelectedAgency';

export const UPDATE_TOOL_OVERRIDE = gql`
    ${TOOL_OVERRIDE_FRAGMENT}
    mutation UpdateToolOverride($input: updateToolOverrideInput!) {
        updateToolOverride(input: $input) {
            toolOverride {
                ...ToolOverrideFields
            }
        }
    }
`;

export const CREATE_TOOL_OVERRIDE = gql`
    ${TOOL_OVERRIDE_FRAGMENT}
    mutation CreateToolOverride($input: createToolOverrideInput!) {
        createToolOverride(input: $input) {
            toolOverride {
                ...ToolOverrideFields
            }
        }
    }
`;

function doSubmit(
    formValues: ToolOverrideFormValue,
    getId: () => string | null,
    createToolOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateToolOverrideResponse>>,
    updateToolOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateToolOverrideResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ToolOverride>
) {
    return Promise.resolve()
        .then(() => {
            const input: DeepPartial<ToolOverride> = {
                disabled: !formValues.enabled,
                title: filterString(formValues.title),
                ...fixedProperties,
            };

            if (getId()) {
                input.id = getId() || undefined;
            }

            return input;
        })
        .then((input) =>
            (
                (getId() ? updateToolOverride : createToolOverride) as (
                    options: MutationFunctionOptions
                ) => Promise<FetchResult<MutateToolOverrideResponse>>
            )({ variables: { input } }).catch((err: ApolloError) => {
                handleError()(err);

                throw Error('cancelled');
            })
        );
}

const useSubmitToolOverride = (
    getId: () => string | null,
    setId: (id: string) => void,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ToolOverrideFormProps['onSuccess'],
    onError: ToolOverrideFormProps['onError'],
    fixedProperties: DeepPartial<ToolOverride>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createToolOverride] = useMutation(CREATE_TOOL_OVERRIDE, {
        // ajout du nouveau ToolOverride dans le cache de la requete GET_TOOL_OVERRIDES
        update(cache, result) {
            const item = result.data?.createToolOverride
                ?.toolOverride as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_TOOL_OVERRIDES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        toolOverrides: {
                            ...data.toolOverrides,
                            collection: [
                                ...data.toolOverrides.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [updateToolOverride] = useMutation(UPDATE_TOOL_OVERRIDE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: ToolOverrideFormValue,
            { setSubmitting, setErrors }: FormikHelpers<ToolOverrideFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createToolOverride,
                updateToolOverride,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateToolOverrideResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: ToolOverrideResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateToolOverride?.toolOverride
                            : res.data?.createToolOverride?.toolOverride;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    setId(resItem.id);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createToolOverride,
            updateToolOverride,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            onSuccess,
        ]
    );
};

export default useSubmitToolOverride;
