import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import usePackageAgencyOfPackage from '../../packageAgency/usePackageAgencyOfPackage';
import QuotationFormValue from './QuotationFormValue';

function AutoUpdatePackFee() {
    const { setFieldValue } = useFormikContext<QuotationFormValue>();
    const [selectedAgencyId] = useSelectedAgency();
    const [selectedPackageId] = useSelectedPackageId();
    const packageAgency = usePackageAgencyOfPackage(
        selectedAgencyId,
        selectedPackageId
    );
    const previousSelectedPackRef = useRef<string | undefined>(
        selectedPackageId
    );
    const [shouldUpdateField, setShouldUpdateField] = useState<boolean>(false);

    useEffect(() => {
        // ici on check si un nouveau pack vient d'être selectionné
        // si oui on peut indiquer qu'il faut update le champ.
        // Mais uniquement si c'est un changement de pack, on écrase pas la valeur initale du formulaire

        if (previousSelectedPackRef.current === selectedPackageId) {
            // pas de changement = on ignore
            return;
        }

        previousSelectedPackRef.current = selectedPackageId;

        // c'est un nouveau pack selectionné donc on doit mettre le champ à jour
        setShouldUpdateField(true);
    }, [selectedPackageId, setShouldUpdateField]);

    useEffect(() => {
        if (!shouldUpdateField) {
            return;
        }
        if (!packageAgency?.rate) {
            return;
        }

        setShouldUpdateField(false);
        setFieldValue('packFee', packageAgency.rate);
    }, [shouldUpdateField, packageAgency, setFieldValue]);

    return null;
}

export default AutoUpdatePackFee;
