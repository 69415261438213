import React, { useState } from 'react';

export const EditModeCtx = React.createContext<
    [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

export interface EditModeProps {
    children?: React.ReactNode;
}

function EditModeProvider({ children }: EditModeProps) {
    const state = useState<boolean>(false);

    return (
        <EditModeCtx.Provider value={state}>{children}</EditModeCtx.Provider>
    );
}

export default EditModeProvider;
