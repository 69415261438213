import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import type {
    PreferencesFormProps,
    PreferencesFormValue,
} from './PreferencesForm';
import Preferences from '../Preferences.model';
import usePreferences from '../usePreferences';

const useSubmitPreferencesForm = (
    onSuccess: PreferencesFormProps['onSuccess']
) => {
    const [, setPreferences] = usePreferences();

    return useCallback(
        (
            formValues: PreferencesFormValue,
            { setSubmitting }: FormikHelpers<PreferencesFormValue>
        ) => {
            const preferences: Preferences = {
                navigateWithButtons: formValues.navigateWithButtons,
            };
            setPreferences(preferences);
            onSuccess && onSuccess(preferences);
            setSubmitting(false);
        },
        [setPreferences, onSuccess]
    );
};

export default useSubmitPreferencesForm;
