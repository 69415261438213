import React, { useMemo, useCallback } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import type UserAgency from '@acdc/shared/src/features/userAgency/UserAgency.model';
import type UpdateUserAgencyResponse from '@acdc/shared/src/features/userAgency/UpdateUserAgencyResponse';
import type UserAgencyResponse from '@acdc/shared/src/features/userAgency/UserAgencyResponse';
import UserRole from '@acdc/shared/src/features/user/UserRole.enum';
import UserAgencyRoleInnerForm from './UserAgencyRoleFormInner';

export const UPDATE_AGENCY_USER = gql`
    mutation UpdateUserAgency($input: updateUserAgencyInput!) {
        updateUserAgency(input: $input) {
            userAgency {
                id
            }
        }
    }
`;

type UserAgencyRoleFormValue = {
    role: boolean;
};

const userAgencyRoleFormSchema = Yup.object().shape({
    role: Yup.boolean().label('Le role').required(),
});

const initValue = (value: UserAgencyResponse): UserAgencyRoleFormValue => ({
    role: value?.role === UserRole.ROLE_EDITOR_AGENCY,
});

export interface UserAgencyRoleFormProps {
    value?: UserAgencyResponse;
    onSuccess?: (userAgency: UserAgencyResponse) => void;
    onError?: (err: ApolloError) => void;
}

function UserAgencyRoleForm({
    value,
    onSuccess,
    onError,
}: UserAgencyRoleFormProps) {
    if (!value?.id) {
        throw Error('Un id est requis.');
    }

    const setAlert = useAlert();
    const getUserAgencyId = useCallback(() => value?.id || null, [value?.id]);

    const [mutate] = useMutation(UPDATE_AGENCY_USER);

    const initialValues: UserAgencyRoleFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    return (
        <Formik
            validationSchema={userAgencyRoleFormSchema}
            initialValues={initialValues}
            onSubmit={(
                { role: newValue }: UserAgencyRoleFormValue,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<UserAgencyRoleFormValue>
            ) => {
                const input: DeepPartial<UserAgency> = {
                    id: value?.id,
                    role:
                        newValue === true
                            ? UserRole.ROLE_EDITOR_AGENCY
                            : UserRole.ROLE_PRESENTER,
                };

                mutate({ variables: { input } })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<UpdateUserAgencyResponse>) => {
                        const resItem: UserAgencyResponse | null | undefined =
                            res.data?.updateUserAgency?.userAgency;

                        if (!resItem || !resItem.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res.data);
                            return;
                        }

                        onSuccess && onSuccess(resItem);
                    })
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            userAgencyRoleFormSchema,
                            onError
                        )
                    );
            }}
        >
            <Form data-testid="agencyUserForm">
                <UserAgencyRoleInnerForm getUserAgencyId={getUserAgencyId} />
            </Form>
        </Formik>
    );
}

export default UserAgencyRoleForm;
