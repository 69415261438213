const accordionSummarySx = {
    backgroundColor: 'primary.dark',
    color: 'common.white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'common.white',
    },
    fontFamily: 'Lexend',
    fontSize: '.9rem',
};

export default accordionSummarySx;
