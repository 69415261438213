import React from 'react';
import { FieldProps } from 'formik';
import { fieldToSwitch } from 'formik-mui';
import UiTriStateSwitch, {
    TriStateSwitchProps as UiTriStateSwitchProps,
} from '../ui/TriStateSwitch';

// Similaire à https://github.com/stackworx/formik-mui/blob/formik-mui%404.0.0-alpha.3/packages/formik-mui/src/Switch.tsx
export interface TriStateSwitchProps
    extends FieldProps,
        Omit<
            UiTriStateSwitchProps,
            | 'checked'
            | 'name'
            | 'value'
            | 'defaultChecked'
            | 'form'
            // Excluded for conflict with Field type
            | 'type'
        > {
    type?: string;
}

function TriStateSwitch(props: TriStateSwitchProps) {
    return <UiTriStateSwitch {...(fieldToSwitch(props as any) as any)} />;
}

export default TriStateSwitch;
