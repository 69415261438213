import { Box } from '@mui/material';
import React from 'react';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { slugify } from '@acdc/shared/src/utils/display-helpers';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import DisplayTemplate from '../template/DisplayTemplate';
import Quotation from '../quotation/Quotation';
import Packages from '../package/Packages';
import getSpecialModuleCode from './getSpecialModuleCode';
import EditTool from '../tools/EditTool';

const renderSpecialContent = (specialModuleCode: string, headingId: string) => {
    switch (specialModuleCode) {
        case ModuleCode.QUOTATION:
            return <Quotation headingId={headingId} />;
        case ModuleCode.PACKAGES:
            return <Packages headingId={headingId} />;
        default:
            return null;
    }
};

const rootSx = {
    position: 'relative',
    breakAfter: 'page',
    size: 'landscape',
};

const noFullPageSx = {
    height: '100vh',
};

interface ContentProps {
    content: ContentResponse;
    editMode?: boolean;
    toolId?: string;
    exportPdf?: boolean;
}

function Content({ content, editMode, toolId, exportPdf }: ContentProps) {
    const specialModule = getSpecialModuleCode(content);
    const headingId: string = slugify(`${content?.title}`);

    // On affiche pas le devis et les packs sur le pdf de la présentation
    if (specialModule && exportPdf) {
        return null;
    }

    if (specialModule) {
        return renderSpecialContent(specialModule, headingId);
    }

    const pageSx = exportPdf ? noFullPageSx : rootSx;

    return (
        <Box
            className="section"
            data-anchor={`${headingId}-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
            data-testid={headingId}
            sx={pageSx}
        >
            {content &&
                content.contentModules &&
                content.template &&
                content.template.sections && (
                    <DisplayTemplate
                        template={content.template}
                        sections={content.template.sections.collection}
                        contentModules={content.contentModules.collection}
                        exportPdf={exportPdf}
                    />
                )}
            {toolId && editMode && (
                <EditTool content={content} toolId={toolId} />
            )}
        </Box>
    );
}

export default Content;
