import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import { Palette, useTheme } from '@mui/material';

/**
 * Retourne la couleur a utiliser pour afficher la catégorie et ses outils.
 * La couleur retournée est vérifiée afin d'être sûr qu'elle est utilisable avec le theme de l'app.
 * Donc en cas d'ajout d'une nouvelle couleur, l'api peut la retourner sans que les versions actuelles de l'app plantent.
 */
function useToolCategoryThemeColor(
    toolCategory: ToolCategoryResponse | null | undefined
) {
    const theme = useTheme();

    if (!toolCategory?.themeColor) {
        return ThemeColor.PRIMARY;
    }

    const isThemeColorValid =
        theme.palette[
            (toolCategory.themeColor || ThemeColor.PRIMARY) as keyof Palette
        ] !== undefined;

    if (!isThemeColorValid) {
        return ThemeColor.PRIMARY;
    }

    return toolCategory.themeColor;
}

export default useToolCategoryThemeColor;
