import { Box, Fade, CircularProgress, Portal } from '@mui/material';
import React from 'react';
import BigRoundedButton, { BigRoundedButtonProps } from './BigRoundedButton';

interface FakeLoadingScreenButtonProps {
    children: React.ReactNode;
    buttonProps?: BigRoundedButtonProps & { 'data-testid': string };
    loadingDuration: number;
    onClick?: () => {};
}

const backgroundBoxSx = {
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(4px)',
};

function FakeLoadingScreenButton({
    children,
    buttonProps,
    loadingDuration,
    onClick,
}: FakeLoadingScreenButtonProps) {
    const [query, setQuery] = React.useState('idle');

    const handleClickQuery = () => {
        setQuery('progress');
        window.setTimeout(() => {
            setQuery('success');
            onClick && onClick();
        }, loadingDuration);
    };

    return (
        <>
            <Portal>
                <Fade in={query === 'progress'} unmountOnExit>
                    <Box sx={backgroundBoxSx}>
                        <CircularProgress
                            data-testid="LoadingSpinner"
                            size={60}
                            color="success"
                        />
                    </Box>
                </Fade>
            </Portal>
            <BigRoundedButton {...buttonProps} onClick={handleClickQuery}>
                {children}
            </BigRoundedButton>
        </>
    );
}

export default FakeLoadingScreenButton;
