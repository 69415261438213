import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import useSelectedAgency from '../../agency/useSelectedAgency';
import usePackageAgenciesOf from '../../packageAgency/usePackageAgenciesOf';
import ServicePrice from './ServicePrice';
import useIsExport from '../../useIsExport';

const white = 'common.white';
const grey = 'grey.50';
const exportGrey = '#f5f5f5';

export interface ServicesTableRowProps {
    service: ServiceResponse;
    valueCellWidth: string | number;
    splitUnit?: boolean;
}

/**
 * Les lignes de service d'un Package.
 */
function ServicesTableRow({
    service,
    valueCellWidth,
    splitUnit,
}: ServicesTableRowProps) {
    const packageAgencies = usePackageAgenciesOf(useSelectedAgency()[0], true);
    const isExport = useIsExport();

    return (
        <TableRow
            key={`${service.id}`}
            sx={{
                '&:nth-of-type(even)': {
                    backgroundColor: isExport ? white : grey,
                },
                '&:nth-of-type(odd)': {
                    backgroundColor: isExport ? exportGrey : white,
                },
                '& > td': {
                    border: 'none',
                },
            }}
        >
            <TableCell>{service.longLabel}</TableCell>
            {packageAgencies?.map((packageAgency) => (
                <TableCell
                    key={`${packageAgency.id}`}
                    width={valueCellWidth}
                    sx={{ maxWidth: valueCellWidth }}
                    align="center"
                >
                    <ServicePrice
                        service={service}
                        pack={packageAgency.package}
                        splitUnit={splitUnit}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}

export default ServicesTableRow;
