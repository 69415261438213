// see https://gist.github.com/jeneg/9767afdcca45601ea44930ea03e0febf
/**
 * @param defaultValue Valeur par défaut dans le cas où la propriété vaut undefined.
 */
function getProperty(
    obj: any,
    path: string,
    defaultValue: any = undefined
): any {
    const result = path.split('.').reduce((previousValue, pathItem) => {
        if (previousValue && typeof previousValue === 'object') {
            const index = pathItem.startsWith('[')
                ? pathItem.replace(/\D/g, '')
                : pathItem;

            return previousValue[index];
        }

        return undefined;
    }, obj);

    return result !== undefined ? result : defaultValue;
}

export default getProperty;
