import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListServicePackageOverridesResponse from '@acdc/shared/src/features/servicePackageOverride/ListServicePackageOverridesResponse';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const SERVICE_PACKAGE_OVERRIDE_FRAGMENT = gql`
    fragment ServicePackageOverrideFields on ServicePackageOverride {
        id
        unit
        price
        agency {
            id
        }
        service {
            id
        }
        package {
            id
        }
    }
`;

export const GET_SERVICE_PACKAGE_OVERRIDES = gql`
    ${SERVICE_PACKAGE_OVERRIDE_FRAGMENT}
    query GetServicePackageOverrides($agencyCode: String) {
        servicePackageOverrides(itemsPerPage: 10000, agency_code: $agencyCode) {
            collection {
                ...ServicePackageOverrideFields
            }
        }
    }
`;

const emptyResult: ServicePackageOverrideResponse[] = [];

const useServicePackageOverrides = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListServicePackageOverridesResponse>(
        GET_SERVICE_PACKAGE_OVERRIDES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des prix des prestations de l'agence.",
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<ServicePackageOverrideResponse[] | undefined>(() => {
        return data?.servicePackageOverrides?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useServicePackageOverrides;
