import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

/**
 * Indique si un service donné a été activé dans l'agence.
 */
function serviceIsEnabled(
    service: ServiceResponse,
    agency: AgencyResponse | undefined
): boolean {
    const serviceAgency = agency?.serviceAgencies?.collection?.find((sa) =>
        matchOnId(sa.service, service)
    );

    return serviceAgency?.disabled === false;
}

export default serviceIsEnabled;
