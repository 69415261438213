/**
 * @see https://stackoverflow.com/a/48768775
 */
function enumValues(someEnum: any): string[] {
    return Object.keys(someEnum).reduce((curr: string[], item: string) => {
        if (Number.isNaN(Number(item))) {
            curr.push(someEnum[item]);
        }

        return curr;
    }, []);
}

export default enumValues;
