import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import accordionSummarySx from './accordionSummarySx';
import AgencyCustomizePackageAgencyForm from '../../packageAgency/AgencyCustomizePackageAgencyForm';
import accordionDetailsSx from './accordionDetailsSx';
import ServicePackageOverridePricesForms from '../../servicePackageOverride/ServicePackageOverridePricesForms';
import useOverridedPackage from '../useOverridedPackage';

export interface CustomizePackageAccordionProps {
    pack: PackageResponse;
    agency: AgencyResponse;
    expanded: boolean;
    onAccordionChange: AccordionProps['onChange'];
    accordionTransitionProps?: AccordionProps['TransitionProps'] | undefined;
}

function CustomizePackageAccordion({
    pack,
    agency,
    expanded,
    onAccordionChange,
    accordionTransitionProps,
}: CustomizePackageAccordionProps) {
    const overridedPack = useOverridedPackage(pack);

    return (
        <Accordion
            expanded={expanded}
            onChange={onAccordionChange}
            key={`${pack.id}`}
            square
            TransitionProps={accordionTransitionProps}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`customisePack${getIdFromIri(
                    entityToId(pack)
                )}-body`}
                id={`customisePack${getIdFromIri(entityToId(pack))}-header`}
                sx={accordionSummarySx}
            >
                Personnalisation du pack «{overridedPack?.label}»
            </AccordionSummary>
            <AccordionDetails sx={accordionDetailsSx}>
                <AgencyCustomizePackageAgencyForm pack={pack} agency={agency} />
                <ServicePackageOverridePricesForms
                    pack={pack}
                    agency={agency}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomizePackageAccordion;
