import React from 'react';
import { CheckboxProps, fieldToCheckbox } from 'formik-mui';
import UiCheckButton, { CheckButtonOwnProps } from '../ui/CheckButton';

export type CheckButtonProps = CheckButtonOwnProps & CheckboxProps;

function CheckButton({ label, ...props }: CheckButtonProps) {
    return <UiCheckButton {...fieldToCheckbox(props)} label={label} />;
}

export default CheckButton;
