import sharedInitApollo from '@acdc/shared/src/apollo/initApollo';
import { ApolloCache, NormalizedCacheObject } from '@apollo/client';
import CONFIGS from '../configs';

function initApollo(cache: ApolloCache<NormalizedCacheObject>) {
    return sharedInitApollo(
        // si on est sur http://front on est dans le réseau interne du container donc pas
        // besoin de passer par internet pour contacter l'api.
        // todo : front et http://api depuis les configs.
        ['front', 'front-static'].includes(window.location.hostname)
            ? 'http://caddy'
            : CONFIGS.apiEntrypoint,
        CONFIGS.authStorageKeys,
        cache
    );
}

export default initApollo;
