import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListUserAgenciesResponse from '@acdc/shared/src/features/userAgency/ListUserAgenciesResponse';
import usePersistentState from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../configs';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_MY_USER_AGENCIES = gql`
    query GetMyUserAgencies($id: String!) {
        userAgencies(user_code: $id, itemsPerPage: 10000) {
            collection {
                id
                role
                agency {
                    id
                    code
                }
            }
        }
    }
`;

/**
 * Retourne les UserAgencies de l'utilisateur.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useMyUserAgencies = () => {
    const setAlert = useAlert();
    const [currentUser] = usePersistentState(
        CONFIGS.userDataStorageKeys.currentUser
    );

    if (currentUser === null) {
        throw new Error("L'utilisateur doit être connecté.");
    }

    const { data, error, loading } = useQuery<ListUserAgenciesResponse>(
        GET_MY_USER_AGENCIES,
        {
            ...persistentQueryOptions,
            variables: { id: currentUser.code },
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des droits de l'utilisateur dans ses agences.",
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useMyUserAgencies;
