import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import PackageAgency from '@acdc/shared/src/features/packageAgency/PackageAgency.model';
import MutatePackageAgencyResponse from '@acdc/shared/src/features/packageAgency/MutatePackageAgencyResponse';
import CreatePackageAgencyResponse from '@acdc/shared/src/features/packageAgency/CreatePackageAgencyResponse';
import UpdatePackageAgencyResponse from '@acdc/shared/src/features/packageAgency/UpdatePackageAgencyResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import {
    entityToId,
    OnSubmitError,
    OnSubmitSuccess,
} from '@acdc/shared/src/utils/form-helpers';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import useSelectedAgency from '../agency/useSelectedAgency';
import {
    GET_PACKAGE_AGENCIES,
    PACKAGE_AGENCY_FRAGMENT,
} from './usePackageAgencies';

export const CREATE_PACKAGE_AGENCY = gql`
    ${PACKAGE_AGENCY_FRAGMENT}
    mutation CreatePackageAgency($input: createPackageAgencyInput!) {
        createPackageAgency(input: $input) {
            packageAgency {
                ...PackageAgencyFields
            }
        }
    }
`;

export const UPDATE_PACKAGE_AGENCY = gql`
    ${PACKAGE_AGENCY_FRAGMENT}
    mutation UpdatePackageAgency($input: updatePackageAgencyInput!) {
        updatePackageAgency(input: $input) {
            packageAgency {
                ...PackageAgencyFields
            }
        }
    }
`;

/**
 * Submit une PackageAgency
 */
function doSubmit(
    values: DeepPartial<PackageAgency>,
    getId: () => string | null,
    create: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreatePackageAgencyResponse>>,
    update: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdatePackageAgencyResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void
) {
    return (
        Promise.resolve()
            // préparation du PackageAgency à envoyer
            .then(() => {
                const input: DeepPartial<PackageAgency> = {
                    ...values,
                };

                if (getId()) {
                    input.id = getId() || undefined;

                    // tous les champs non null sont requis avec graphql dans le cas d'une création
                } else if (input.disabled === undefined) {
                    input.disabled = true;
                }

                return input;
            })

            // création ou mise à jour du PackageAgency
            .then((input) =>
                (
                    (getId() ? update : create) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutatePackageAgencyResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

/**
 *
 * @param getId Fonction qui retourne l'id de la PackageAgency pour savoir si on doit faire une création ou une mise à jour.
 * @param setId Fonction qui set l'id d'une PackageAgency créé pour que le prochain submit sache qu'il doit faire une mise à jour.
 * @param setSaved
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans la PackageAgency
 * @returns
 */
function useSubmitPackageAgency<V = DeepPartial<PackageAgency>>(
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>> | null,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: OnSubmitSuccess<PackageAgencyResponse> | undefined,
    onError: OnSubmitError | undefined,
    fixedProperties: DeepPartial<PackageAgency>,
    filterFormValues: (value: V) => DeepPartial<PackageAgency>
) {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createPackageAgency] = useMutation(CREATE_PACKAGE_AGENCY, {
        // ajout du nouveau PackageAgency dans le cache de la requete GET_PACKAGE_AGENCIES
        update(cache, result) {
            const item = result.data?.createPackageAgency
                ?.packageAgency as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_PACKAGE_AGENCIES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        packageAgencies: {
                            ...data.packageAgencies,
                            collection: [
                                ...data.packageAgencies.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [updatePackageAgency] = useMutation(UPDATE_PACKAGE_AGENCY, {
        // pas d'eviction du cache
    });

    return useCallback(
        (formValues: V, { setSubmitting, setErrors }: FormikHelpers<V>) => {
            doSubmit(
                {
                    ...filterFormValues(formValues),
                    ...fixedProperties,
                },
                getId,
                createPackageAgency,
                updatePackageAgency,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    )
            )
                .then((res: FetchResult<MutatePackageAgencyResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: PackageAgencyResponse | null | undefined =
                        isUpdate
                            ? res.data?.updatePackageAgency?.packageAgency
                            : res.data?.createPackageAgency?.packageAgency;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    setSaved && setSaved(true);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createPackageAgency,
            updatePackageAgency,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
            filterFormValues,
        ]
    );
}

export default useSubmitPackageAgency;
