import { Button } from '@mui/material';
import React, { useCallback, useState, useMemo } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import EditToolSideBar from './EditToolSideBar';
import isToolEnabled from './isToolEnabled';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';

interface EditToolButtonProps {
    tool: ToolResponse;
}

function EditToolButton({ tool }: EditToolButtonProps) {
    const [open, setOpenSideBar] = useState(false);
    const handleClick = useCallback(() => {
        setOpenSideBar(true);
    }, [setOpenSideBar]);

    const handleToggleDrawer = useCallback(() => {
        setOpenSideBar((current) => !current);
    }, [setOpenSideBar]);

    const agency = useAgency(useSelectedAgency()[0]);

    const visibilityStatus = useMemo(() => {
        return isToolEnabled(tool, agency);
    }, [tool, agency]);

    return (
        <>
            <Button
                onClick={handleClick}
                className="active"
                title="Modifier l'outil"
            >
                {visibilityStatus ? <VisibilityIcon /> : <VisibilityOffIcon />}
                <EditIcon />
            </Button>
            {open && (
                <EditToolSideBar
                    tool={tool}
                    open={open}
                    toggleDrawer={handleToggleDrawer}
                />
            )}
        </>
    );
}

export default EditToolButton;
