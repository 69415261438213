import { RolesHierarchy } from '@acdc/shared/src/security/isGranted';

// à delete si on réutilise security dans un autre projet

const ACDC_AGENCY_ROLES_HIERARCHY: RolesHierarchy = {
    ROLE_PRESENTER: ['ROLE_USER'],
    ROLE_EDITOR_AGENCY: ['ROLE_PRESENTER'],
    ROLE_ADMIN_AGENCY: ['ROLE_EDITOR_AGENCY'],
    ROLE_CONSULTANT: ['ROLE_ADMIN_AGENCY'],
};

export default ACDC_AGENCY_ROLES_HIERARCHY;
