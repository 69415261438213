import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useTools from './useTools';

/**
 * Retourne un outil à partir de son id.
 */
function useTool(toolId: string | undefined) {
    const tools = useTools();

    return useMemo(() => {
        if (!toolId) {
            return undefined;
        }

        return tools?.find((el) => matchOnId(el, toolId));
    }, [tools, toolId]);
}

export default useTool;
