import PresentationStatistic from '@acdc/shared/src/features/presentationStatistic/PresentationStatistic.model';
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const CREATE_PRESENTATION_STATISTIC = gql`
    mutation CreatePresentationStatistic(
        $input: createPresentationStatisticInput!
    ) {
        createPresentationStatistic(input: $input) {
            clientMutationId
        }
    }
`;

function useSendPresentationStatistic() {
    const [create] = useMutation(CREATE_PRESENTATION_STATISTIC);

    return useCallback(
        (values: DeepPartial<PresentationStatistic>) => {
            return create({ variables: { input: values } });
        },
        [create]
    );
}

export default useSendPresentationStatistic;
