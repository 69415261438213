import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { slugify } from '@acdc/shared/src/utils/display-helpers';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import getSpecialModuleCode from '../content/getSpecialModuleCode';
import { Navigation } from '../navigation/Menu';
import useEditMode from '../template/useEditMode';
import filterVisibleChapterContents from './filterVisibleChapterContents';

function getSubMenus(content: ContentResponse | undefined) {
    if (!content) {
        return undefined;
    }

    const specialModule = getSpecialModuleCode(content);

    if (specialModule === ModuleCode.PACKAGES) {
        const contentSlug = slugify(content.title || '');

        return [
            `${contentSlug}-initiales-page`,
            `${contentSlug}-annexes-page`,
            `${contentSlug}-resume-page`,
        ];
    }

    return undefined;
}

function usePresentationFlowMenus(
    presentationFlow: PresentationFlowResponse | undefined
) {
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const [editMode] = useEditMode();

    return useMemo<Navigation[]>(
        () =>
            filterVisibleChapterContents(
                presentationFlow,
                editMode,
                selectedAgency,
                true
            )?.chapters?.collection.map((chapter) => ({
                title: chapter.title || '',
                link: `${slugify(chapter.title || '')}-categorie`,
                menus:
                    chapter.chapterContents?.collection.map(
                        (chapterContent) => {
                            const contentOverride =
                                selectedAgency?.contentOverrides?.collection.find(
                                    (override) =>
                                        matchOnId(
                                            chapterContent.content,
                                            override.content
                                        )
                                );
                            return {
                                name: chapterContent.content?.title || '',
                                link: `${slugify(
                                    chapterContent.content?.title || ''
                                )}-page`,
                                subMenus: getSubMenus(chapterContent.content),
                                disabled: contentOverride?.disabled || false,
                                content: chapterContent.content || {},
                            };
                        }
                    ) || [],
            })) || [],
        [presentationFlow, editMode, selectedAgency]
    );
}

/**
 * Converti un PresentationFlow en Navigation[]
 */
export default usePresentationFlowMenus;
