import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React from 'react';
import usePackages from '../package/usePackages';
import TogglePackageAgencyForm from './TogglePackageAgencyForm';
import usePackageAgenciesOf from './usePackageAgenciesOf';

export interface AgencyTogglePackageAgencyFormsProps {
    agency: AgencyResponse;
}

function findValue(
    pack: PackageResponse,
    packageAgencies: PackageAgencyResponse[]
) {
    return packageAgencies.find((pa) => matchOnId(pa.package, pack));
}

/**
 * Liste de formulaires pour chaque PackageAgency d'un Agency existant ou à créer.
 */
function AgencyTogglePackageAgencyForms({
    agency,
}: AgencyTogglePackageAgencyFormsProps) {
    const packages = usePackages();
    const packageAgencies = usePackageAgenciesOf(agency);

    if (!packages || !packageAgencies) {
        return null;
    }

    return (
        <>
            {packages?.map((pack) => (
                <TogglePackageAgencyForm
                    key={`${pack.id}`}
                    value={findValue(pack, packageAgencies)}
                    pack={pack}
                    agency={agency}
                />
            ))}
        </>
    );
}

export default AgencyTogglePackageAgencyForms;
