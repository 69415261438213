import { useMemo } from 'react';
import type AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import type ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import automaticallyDefinedVariableTypes from '@acdc/shared/src/features/variable/automaticallyDefinedVariableTypes';

export interface UseContentVariablesStatusProps {
    content: ContentResponse | undefined;
    agency: AgencyResponse | undefined;
}

export const findContentVariablesStatus = (
    content: ContentResponse | undefined,
    agency: AgencyResponse | undefined
): 'error' | 'warning' | 'success' => {
    let hasWarning = false;

    const isCritcal = content?.contentModules?.collection.some(
        (contentModule) => {
            if (
                ([ModuleCode.IMAGE, ModuleCode.PDF] as string[]).includes(
                    contentModule.module?.code as string
                )
            ) {
                const isEditableByAgency =
                    contentModule.settingValues?.collection.some(
                        (settingValue) => {
                            return Boolean(
                                settingValue.moduleSetting?.code ===
                                    ModuleSettingCode.IMAGE_EDITABLE &&
                                    settingValue.value === 'true'
                            );
                        }
                    );

                if (isEditableByAgency) {
                    const hasValue = agency?.imageOverrides?.collection.some(
                        (imageOverride) =>
                            matchOnId(
                                contentModule,
                                imageOverride.contentModule
                            ) && imageOverride.imageOverrideFile
                    );
                    const hasDefaultValue =
                        contentModule?.settingValues?.collection.some(
                            (settingValue) => {
                                return Boolean(
                                    settingValue.moduleSetting?.code ===
                                        ModuleSettingCode.IMAGE &&
                                        settingValue.file
                                );
                            }
                        );
                    if (!hasValue && hasDefaultValue) {
                        hasWarning = true;
                        return false;
                    }
                    return !hasValue;
                }

                return false;
            }

            return contentModule.variables?.collection.some((variable) => {
                if (
                    (
                        automaticallyDefinedVariableTypes as (
                            | VariableType
                            | undefined
                        )[]
                    ).includes(variable.type)
                ) {
                    return false;
                }
                const hasValue = agency?.variableValues?.collection.some(
                    (variableValue) =>
                        matchOnId(variable, variableValue.variable) &&
                        !!variableValue.value
                );

                if (!hasValue && variable.defaultValue) {
                    hasWarning = true;
                    return false;
                }

                return !hasValue;
            });
        }
    );

    if (isCritcal || isCritcal === undefined) {
        return 'error';
    }

    if (hasWarning) {
        return 'warning';
    }

    return 'success';
};

const useContentVariablesStatus = (
    content: ContentResponse | undefined,
    agency: AgencyResponse | undefined
) =>
    useMemo(
        () => findContentVariablesStatus(content, agency),
        [content, agency]
    );

export default useContentVariablesStatus;
