import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import React from 'react';
import useLocations from './useLocations';

export const PropertiesCtx = React.createContext<
    PropertyResponse[] | undefined
>(undefined);

export interface SearchesProviderProps {
    children?: React.ReactNode;
}

function SearchesProvider({ children }: SearchesProviderProps) {
    // Pas besoin de mettre la valeur dans un contexte car on ne fait pas d'opérations sur le résultat de la requête.
    // On peut laisser le cache Apollo gérer.
    useLocations();

    return <>{children}</>;
}

export default SearchesProvider;
