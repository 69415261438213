import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import DiscoveryCriteriaType from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaType.enum';
import InlineInputLabel from '../../../ui/InlineInputLabel';
import type { DiscoveryCriteriaOverrideFormValue } from './DiscoveryCriteriaOverrideForm';

function shouldDisableEnabledField(
    currentValue: boolean,
    discoveryCriteria: DiscoveryCriteriaResponse
): boolean {
    if (currentValue === false) {
        return false;
    }

    return discoveryCriteria.mandatory === true;
}

function getLabel(discoveryCriteria: DiscoveryCriteriaResponse): string {
    let criteriaType = 'le critère';

    if (discoveryCriteria.type === DiscoveryCriteriaType.GROUP) {
        if (!discoveryCriteria.parentCriteria) {
            criteriaType = 'la page';
        } else {
            criteriaType = 'la section';
        }
    }

    let label = `Afficher ${criteriaType} "${discoveryCriteria.label}"`;

    if (discoveryCriteria.hint) {
        label += ` (${discoveryCriteria.hint})`;
    }

    return label;
}

export interface DiscoveryCriteriaOverrideFormInnerProps {
    getDiscoveryCriteriaOverrideId: () => string | null;
    discoveryCriteria: DiscoveryCriteriaResponse;
}
function DiscoveryCriteriaOverrideFormInner({
    getDiscoveryCriteriaOverrideId,
    discoveryCriteria,
}: DiscoveryCriteriaOverrideFormInnerProps) {
    useAutoSubmitSingleFieldForm('enabled', getDiscoveryCriteriaOverrideId);
    const { values } = useFormikContext<DiscoveryCriteriaOverrideFormValue>();

    return (
        <InlineInputLabel
            htmlFor="discoveryCriteriaOverrideEnabled"
            label={getLabel(discoveryCriteria)}
        >
            <Field
                component={Switch}
                id="discoveryCriteriaOverrideEnabled"
                name="enabled"
                type="checkbox"
                // disabled vaut true si mandatory sinon false et pas undefined car il ne faut pas que formik disable le champ pendant le submit.
                disabled={shouldDisableEnabledField(
                    values.enabled,
                    discoveryCriteria
                )}
                color="success"
            />
        </InlineInputLabel>
    );
}

export default DiscoveryCriteriaOverrideFormInner;
