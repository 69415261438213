import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback } from 'react';
import CONFIGS from '../../configs';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import getSpecialModuleCode from '../content/getSpecialModuleCode';
import filterVisibleChapterContents from '../presentationFlow/filterVisibleChapterContents';
import usePresentationFlows from '../presentationFlow/usePresentationFlows';
import { usePresentationStatisticQueue } from './PresentationStatisticQueue';

function useInitPresentationStatistic() {
    const { setItem: setPresentationStatistic } =
        usePresentationStatisticQueue();
    const agency = useAgency(useSelectedAgency()[0]);
    const [currentUser] = usePersistentState(
        CONFIGS.userDataStorageKeys.currentUser
    );
    const presentationFlows = usePresentationFlows();

    return useCallback(
        (
            presentationUid: string,
            presentationFlowId: string | PresentationFlowResponse,
            property: PropertyResponse | null | undefined
        ) => {
            const presentationFlow = presentationFlows?.find((el) =>
                matchOnId(el, presentationFlowId)
            );
            const filteredPresentationFlow = filterVisibleChapterContents(
                presentationFlow,
                false,
                agency
            );
            let hasQuotation = false;
            const totalContents =
                filteredPresentationFlow?.chapters?.collection.reduce(
                    (total, chapter) => {
                        const contentsCount =
                            chapter.chapterContents?.collection.reduce(
                                (count, chapterContent) => {
                                    const specialModuleCode =
                                        getSpecialModuleCode(
                                            chapterContent.content
                                        );
                                    if (
                                        specialModuleCode ===
                                        ModuleCode.QUOTATION
                                    ) {
                                        hasQuotation = true;
                                    }
                                    const pages =
                                        specialModuleCode ===
                                        ModuleCode.PACKAGES
                                            ? 4
                                            : 1;

                                    return count + pages;
                                },
                                0
                            );

                        return total + (contentsCount || 0);
                    },
                    0
                ) || 0;

            setPresentationStatistic({
                uid: presentationUid,
                value: {
                    startedAt: new Date(),
                    propertyReference: property?.reference || null,
                    visitedContents: 0, // mis à jour avant d'envoyer à l'api
                    totalContents,
                    quotationSent: false,
                    hasQuotation,
                    agency: entityToId(agency) || '',
                    user: entityToId(currentUser) || '',
                    presentationFlow: entityToId(presentationFlow) || '',
                },
                visitedContentIds: [],
            });
        },
        [setPresentationStatistic, agency, currentUser, presentationFlows]
    );
}

export default useInitPresentationStatistic;
