import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListServiceAgenciesResponse from '@acdc/shared/src/features/serviceAgency/ListServiceAgenciesResponse';
import ServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/ServiceAgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const SERVICE_AGENCY_FRAGMENT = gql`
    fragment ServiceAgencyFields on ServiceAgency {
        id
        unit
        price
        disabled
        sortOrder
        agency {
            id
        }
        service {
            id
        }
        package {
            id
        }
    }
`;

export const GET_SERVICE_AGENCIES = gql`
    ${SERVICE_AGENCY_FRAGMENT}
    query GetServiceAgencies($agencyCode: String) {
        serviceAgencies(itemsPerPage: 10000, agency_code: $agencyCode) {
            collection {
                ...ServiceAgencyFields
            }
        }
    }
`;

const emptyResult: ServiceAgencyResponse[] = [];

const useServiceAgencies = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListServiceAgenciesResponse>(
        GET_SERVICE_AGENCIES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des prestations par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<ServiceAgencyResponse[] | undefined>(() => {
        return data?.serviceAgencies?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useServiceAgencies;
