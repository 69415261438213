import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';
import { GET_SERVICES } from './useLoadServices';

export const DELETE_SERVICE = gql`
    mutation DeleteService($id: ID!) {
        deleteService(input: { id: $id }) {
            clientMutationId
        }
    }
`;

function useDeleteService(
    onDeleted?: (serviceId: string) => void
): [(service: string | ServiceResponse) => void, boolean] {
    const [doDelete] = useMutation(DELETE_SERVICE, {
        // suppression du Service du cache de la requete GET_SERVICES
        update(cache, result, options) {
            cache.updateQuery(
                { query: GET_SERVICES, broadcast: false },
                (data) => {
                    return {
                        services: {
                            ...data.services,
                            collection: data.services.collection.filter(
                                (serv: any) =>
                                    !matchOnId(serv, options.variables?.id)
                            ),
                        },
                    };
                }
            );
        },
    });
    const setAlert = useAlert();
    const [isDeleting, setIsDeleteting] = useState<boolean>(false);

    const deleteItem = useCallback(
        (item: string | ServiceResponse) => {
            const id = entityToId(item);

            if (!id) {
                return;
            }

            setIsDeleteting(true);

            doDelete({ variables: { id } })
                .finally(() => {
                    setIsDeleteting(false);
                })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        'Une erreur est survenue lors de la suppression du service.',
                        'error'
                    );
                });
        },
        [onDeleted, setIsDeleteting, doDelete, setAlert]
    );

    return [deleteItem, isDeleting];
}

export default useDeleteService;
