import { IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useId, useRef } from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
// import MicOffIcon from '@mui/icons-material/MicOff';
// import MicNoneIcon from '@mui/icons-material/MicNone';
import { isBoolDefined } from '../../utils/form-helpers';

let listeningInstanceId: string | null = null;

export interface SpeechRecognitionButtonProps {
    /**
     * Callback appelée chaque fois qu'une parole est détectée pendant que la reconnaissance est active.
     */
    onSpeech?: ((speech: string) => void) | undefined;

    /**
     * False par défaut.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition/continuous
     */
    continuous?: boolean | undefined;
}

/**
 * Un ptit IconButton en form de micro qui active la reconnaissance vocale si dispo quand on clique dessus.
 * Exemple avec Formik:
 * <Field
 *     component={TextField}
 *     name="comment"
 *     InputProps={{
 *         endAdornment: (
 *             <InputAdornment position="start">
 *                 <SpeechRecognitionButton
 *                     onSpeech={(speech) => {
 *                         setFieldValue(
 *                             'comment',
 *                             speech
 *                         );
 *                     }}
 *                 />
 *             </InputAdornment>
 *         ),
 *     }}
 * />
 */
function SpeechRecognitionButton({
    onSpeech,
    continuous,
}: SpeechRecognitionButtonProps) {
    // Puisque toutes les instances de SpeechRecognitionButton partage le même SpeechRecognition, il faut
    // identifier chaque instance pour savoir laquelle doit déclencher onSpeech quand une parole est détectée.
    const instanceId = useId();

    // Booléen qui passe à true une fois qu'on a lancé une première fois la reconnaissance. On peut s'en
    // servir pour éviter de déclencher onSpeech pour rien lors de l'initialisation du composant.
    const shouldTransmitRef = useRef<boolean>(false);

    const {
        transcript,
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
        resetTranscript,
    } = useSpeechRecognition();

    const otherInstanceIsListening =
        listening && listeningInstanceId !== instanceId;

    // On met onSpeech dans une ref pour éviter qu'il ne déclenche l'effect si il change.
    const onSpeechRef = useRef(onSpeech);
    onSpeechRef.current = onSpeech;

    useEffect(() => {
        if (
            listeningInstanceId === instanceId && // si c'est cet instance de SpeechRecognitionButton qui écoute
            shouldTransmitRef.current && // et qu'on est prêt à transmettre la reconnaissance
            !continuous && // et qu'on est pas en mode continu (sinon on attend la fin de la session pour transmettre)
            transcript // et qu'on a une transcription
        ) {
            // alors on transmet
            onSpeechRef.current && onSpeechRef.current(transcript);
        }
    }, [instanceId, transcript, continuous]);

    // if (!browserSupportsSpeechRecognition) {
    //     return (
    //         <IconButton title="Reconnaissance non supportée" disabled>
    //             <MicNoneIcon />
    //         </IconButton>
    //     );
    // }

    // if (!isMicrophoneAvailable) {
    //     return (
    //         <IconButton title="Micro désactivé" disabled>
    //             <MicOffIcon />
    //         </IconButton>
    //     );
    // }

    if (!browserSupportsSpeechRecognition || !isMicrophoneAvailable) {
        return null;
    }

    if (otherInstanceIsListening) {
        return (
            <IconButton disabled>
                <MicIcon />
            </IconButton>
        );
    }

    if (listening) {
        return (
            <Tooltip open={continuous && listening} title={transcript}>
                <IconButton
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        if (continuous) {
                            if (transcript) {
                                // en mode continue on attend la fin de la session pour transmettre
                                // sinon ça transmettrait plusieurs fois le transcript depuis le début.
                                onSpeechRef.current &&
                                    onSpeechRef.current(transcript);
                            }
                        }
                    }}
                >
                    <StopIcon color="secondary" />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <IconButton
            onClick={() => {
                listeningInstanceId = instanceId;
                shouldTransmitRef.current = true;
                if (continuous) {
                    // On mode continue quand on lance plusieurs fois la reconnaissance il ne se reset pas automatiquement entre chaque.
                    resetTranscript();
                }
                SpeechRecognition.startListening({
                    continuous: isBoolDefined(continuous) ? continuous : false,
                    language: 'fr-FR',
                });
            }}
        >
            <MicIcon color="primary" />
        </IconButton>
    );
}

export default SpeechRecognitionButton;
