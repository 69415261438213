import React from 'react';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../configs';
import Preferences from './Preferences.model';

export const PreferencesCtx = React.createContext<
    [Preferences | undefined, SetValue<Preferences>]
>([undefined, () => {}]);

export interface PreferencesProviderProps {
    children?: React.ReactNode;
}

function PreferencesProvider({ children }: PreferencesProviderProps) {
    const state = usePersistentState<Preferences>(CONFIGS.preferencesKey);

    return (
        <PreferencesCtx.Provider value={state}>
            {children}
        </PreferencesCtx.Provider>
    );
}

export default PreferencesProvider;
