import DialogButton, {
    DialogButtonProps,
} from '@acdc/shared/src/ui/DialogButton';
import React from 'react';
import SendQuotationForm, { SendQuotationFormProps } from './SendQuotationForm';

export interface SendQuotationFormButtonProps {
    children: React.ReactNode;
    value?: SendQuotationFormProps['value'];
    buttonComponent?: DialogButtonProps['buttonComponent'];
    buttonProps?: DialogButtonProps['buttonProps'];
}

function SendQuotationFormButton({
    children,
    value,
    buttonComponent,
    buttonProps,
}: SendQuotationFormButtonProps) {
    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                'data-testid': 'SendQuotationFormButton',
                ...buttonProps,
            }}
            dialogProps={{
                id: 'SendQuotationFormButton',
                mainTitle: 'Envoyer le devis',
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            {(close) => (
                <SendQuotationForm
                    value={value}
                    onSuccess={() => {
                        close();
                    }}
                />
            )}
        </DialogButton>
    );
}

export default SendQuotationFormButton;
