import React from 'react';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import useLoadTools from './useLoadTools';

export const ToolsCtx = React.createContext<ToolResponse[] | undefined>(
    undefined
);

export interface ToolsProviderProps {
    children?: React.ReactNode;
}

function ToolsProvider({ children }: ToolsProviderProps) {
    const tools = useLoadTools();

    return <ToolsCtx.Provider value={tools}>{children}</ToolsCtx.Provider>;
}

export default ToolsProvider;
