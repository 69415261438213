import { useEffect } from 'react';
import useCacheAge from '../apollo/useCacheAge';
import usePresentationFlows from './presentationFlow/usePresentationFlows';

function SetCacheAgeAfterInitialization() {
    const [cacheAge, setCacheAge] = useCacheAge();
    const presentationFlows = usePresentationFlows();

    useEffect(() => {
        if (undefined === cacheAge && presentationFlows) {
            setCacheAge(new Date());
        }
    }, [cacheAge, setCacheAge, presentationFlows]);

    return null;
}

export default SetCacheAgeAfterInitialization;
