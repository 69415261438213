import { Box } from '@mui/material';
import React from 'react';
import { useHeaderHeight } from './HeaderHeightProvider';

export interface HeaderPaddingProps {
    children: React.ReactNode;
}

/**
 * Ajoute un padding top de la taille du header.
 */
function HeaderPadding({ children }: HeaderPaddingProps) {
    const [headerHeight] = useHeaderHeight();

    return <Box pt={headerHeight}>{children}</Box>;
}

export default HeaderPadding;
