import React, { useMemo } from 'react';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import {
    acceptPdf,
    acceptImage,
    megaBytesToBytes,
} from '@acdc/shared/src/utils/file-helpers';
import { ErrorMessage, useFormikContext } from 'formik';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import Dropzone, { ApiFile } from '@acdc/shared/src/ui/Dropzone';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import SavingStatus from '@acdc/shared/src/ui/SavingStatus';
import { Box } from '@mui/material';
import ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import type { ImageOverrideFormValue } from './ImageOverrideForm';
import CONFIGS from '../../../configs';

const imageMaxSize = megaBytesToBytes(5);

const savingStatusSx = {
    opacity: 0.9,
    position: 'absolute',
    right: (theme: any) => theme.spacing(2),
    top: '-44px',
    zIndex: 1,
};

export interface ImageOverrideFormInnerProps {
    moduleCode: string | undefined;
    initialValue: ImageOverrideResponse | undefined;
    contentModule: ContentModuleResponse;
    getId: () => string | null;
    saved: boolean;
}

function ImageOverrideFormInner({
    moduleCode,
    initialValue,
    contentModule,
    getId,
    saved,
}: ImageOverrideFormInnerProps) {
    const { values, handleChange, isSubmitting } =
        useFormikContext<ImageOverrideFormValue>();
    const pendingSave = useAutoSubmitSingleFieldForm('file', getId);
    const labelSetting = useMemo(() => {
        return contentModule.settingValues?.collection.find(
            (settingValue) =>
                settingValue.moduleSetting?.code ===
                ModuleSettingCode.IMAGE_LABEL
        );
    }, [contentModule]);
    const initialImages = useMemo<ApiFile[] | undefined>(() => {
        if (!initialValue) {
            return undefined;
        }
        const apiUrl = CONFIGS.apiEntrypoint;

        return [
            {
                ...initialValue,
                originalName: initialValue.imageOverrideFile?.originalName,
                publicUrl: `${apiUrl}${initialValue?.imageOverrideFile?.publicPath}`,
            },
        ];
    }, [initialValue]);

    const hasDefaultValue = useMemo<boolean>(
        () =>
            contentModule?.settingValues?.collection.some((settingValue) => {
                return Boolean(
                    settingValue.moduleSetting?.code ===
                        ModuleSettingCode.IMAGE && settingValue.file
                );
            }) || false,
        [contentModule]
    );

    const labelFile =
        moduleCode === ModuleCode.IMAGE
            ? 'Déposez votre image'
            : 'Déposez votre document';

    return (
        <>
            <Dropzone
                id={`ImageOverrideForm${entityToId(contentModule)}`}
                name="file"
                value={values.file}
                onChange={handleChange}
                label={`${labelSetting?.value || labelFile} ${
                    hasDefaultValue ? '' : '*'
                }`}
                multiple={false}
                accept={
                    moduleCode === ModuleCode.IMAGE ? acceptImage : acceptPdf
                }
                inputProps={{
                    'data-testid': 'ImageOverrideFormInput',
                }}
                rejectionsListProps={{
                    'data-testid': 'ImageOverrideFormInputRejections',
                }}
                validFilesListProps={{
                    'data-testid': 'ImageOverrideFormInputValidFiles',
                }}
                initialApiFilesListProps={{
                    'data-testid': 'ImageOverrideFormInputInitialApiFiles',
                }}
                initialApiFiles={initialImages}
                maxSize={imageMaxSize}
                clearable={false}
            />
            <ErrorMessage name="file" component={ErrorHelperText} />
            <Box position="relative">
                <SavingStatus
                    saved={saved}
                    pendingSave={pendingSave}
                    isSubmitting={isSubmitting}
                    sx={savingStatusSx}
                />
            </Box>
        </>
    );
}

export default ImageOverrideFormInner;
