import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { useMemo } from 'react';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useServices from './useServices';
import { sortServices } from './useSortedServices';

function useServicesOfAgency(agency: AgencyResponse | undefined) {
    const allServices = useServices();
    return useMemo(() => {
        if (!agency || !allServices) {
            return [];
        }
        const servicesOfAgency = allServices?.filter((service) => {
            return !service.agency || matchOnId(service.agency, agency);
        });

        return sortServices(servicesOfAgency, agency);
    }, [allServices, agency]);
}

export default useServicesOfAgency;
