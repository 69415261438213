import { useCallback } from 'react';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import PresentationFlowCode from '@acdc/shared/src/features/presentationFlow/PresentationFlowCode.enum';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import usePresentationFlows from '../presentationFlow/usePresentationFlows';

/**
 * Retourne une fonction qui indique si un ToolCategory est activé dans l'agence sélectionnée.
 * La fonction retourne undefined si les données ne sont pas encore chargées.
 */
function useIsToolCategoryEnabled(): (
    toolCategory: string | ToolCategoryResponse | undefined
) => boolean | undefined {
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const presentationFlows = usePresentationFlows();

    return useCallback(
        (toolCategory: string | ToolCategoryResponse | undefined) => {
            if (!selectedAgency || !presentationFlows || !toolCategory) {
                return undefined;
            }

            if (selectedAgency.syndicEnabled) {
                // Actuellement le seul cas où une rubrique d'outil est désactivée
                // est si l'agence n'a pas les modules syndic activés.
                return true;
            }

            const syndicPresentationFlow = presentationFlows.find(
                (presentationFlow) =>
                    matchOnId(
                        presentationFlow,
                        `/presentation-flows/${PresentationFlowCode.SYNDIC}`
                    )
            );

            if (!syndicPresentationFlow?.toolCategory) {
                // le PresentationFlow syndic n'est lié à aucun ToolCategory
                // donc aucun n'est désactivé
                return true;
            }

            // on désactive le ToolCategory si il est lié au PresentationFlow syndic
            return !matchOnId(
                toolCategory,
                syndicPresentationFlow.toolCategory
            );
        },
        [selectedAgency, presentationFlows]
    );
}

export default useIsToolCategoryEnabled;
