import React from 'react';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';

export interface UserAgencyRoleFormInnerProps {
    getUserAgencyId: () => string | null;
}
function UserAgencyRoleInnerForm({
    getUserAgencyId,
}: UserAgencyRoleFormInnerProps) {
    useAutoSubmitSingleFieldForm('role', getUserAgencyId);
    return (
        <Field
            component={Switch}
            id="role"
            name="role"
            type="checkbox"
            data-testid="switch"
        />
    );
}

export default UserAgencyRoleInnerForm;
