import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { entityToId, initString } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import VariableValueResponse from '@acdc/shared/src/features/variableValue/VariableValueResponse';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import VariableValue from '@acdc/shared/src/features/variableValue/VariableValue.model';
import useSubmitVariableValue from './useSubmitVariableValue';
import VariableValueFormInner from './VariableValueFormInner';

export interface VariableValueFormValue {
    value: string;
}

const initValue = (
    variableValue: VariableValueResponse
): VariableValueFormValue => ({
    value: initString(variableValue.value),
});

const getVariableValueFormSchema = (required: boolean) => {
    const valueSchema = Yup.string().label('La valeur');

    return Yup.object().shape({
        value: required ? valueSchema.required() : valueSchema,
    });
};

export type VariableValueFormProps = {
    value?: VariableValueResponse | undefined;
    variable: VariableResponse;
    agency: AgencyResponse;
    onSuccess?: (res: VariableValueResponse) => void;
    onError?: (err: ApolloError) => void;
};

function VariableValueForm({
    value,
    variable,
    agency,
    onSuccess,
    onError,
}: VariableValueFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: VariableValueFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const [saved, setSaved] = useState(Boolean(value?.id && value.value));

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<VariableValue>>(
        () => ({
            variable: entityToId(variable) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [variable, agency]
    );

    const variableValueFormSchema = useMemo(
        () => getVariableValueFormSchema(!variable?.defaultValue),
        [variable?.defaultValue]
    );

    const submit = useSubmitVariableValue(
        getId,
        setId,
        setSaved,
        variableValueFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={variableValueFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <VariableValueFormInner
                    variable={variable}
                    getId={getId}
                    saved={saved}
                />
            </Form>
        </Formik>
    );
}

export default VariableValueForm;
