import { useContext } from 'react';
import { PackagesCtx } from './PackagesProvider';

/**
 * Retourne tous les Packages.
 */
function usePackages() {
    return useContext(PackagesCtx);
}

export default usePackages;
