import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import React from 'react';

type RoundedSide = 'left' | 'right' | 'none';

function getButtonSx(roundedSide: RoundedSide, moreSx: any): SxProps<Theme> {
    switch (roundedSide) {
        case 'right':
            return {
                ...moreSx,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            };
        case 'left':
            return {
                ...moreSx,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            };
        default:
            return {
                ...moreSx,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            };
    }
}

export interface JoinedButtonProps extends ButtonProps {
    roundedSide: RoundedSide;
}

/**
 * Un bouton avec un arrondi que d'un coté qui peut être placé à coté d'un autre bouton avec l'arrondi du coté opposé pour avoir 2 boutons joints.
 */
function JoinedButton({ roundedSide, ...props }: JoinedButtonProps) {
    return <Button {...props} sx={getButtonSx(roundedSide, props.sx)} />;
}

export default JoinedButton;
