import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListPackageAgenciesResponse from '@acdc/shared/src/features/packageAgency/ListPackageAgenciesResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const PACKAGE_AGENCY_FRAGMENT = gql`
    fragment PackageAgencyFields on PackageAgency {
        id
        rate
        disabled
        labelOverride
        agency {
            id
        }
        package {
            id
        }
    }
`;

export const GET_PACKAGE_AGENCIES = gql`
    ${PACKAGE_AGENCY_FRAGMENT}
    query GetPackageAgencies($agencyCode: String) {
        packageAgencies(
            itemsPerPage: 10000
            order: [{ package_id: "ASC" }]
            agency_code: $agencyCode
        ) {
            collection {
                ...PackageAgencyFields
            }
        }
    }
`;

const emptyResult: PackageAgencyResponse[] = [];

/**
 * Retourne tous les PackageAgencies accessibles à l'utilisateur.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const usePackageAgencies = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListPackageAgenciesResponse>(
        GET_PACKAGE_AGENCIES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des taux des packs par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo(() => {
        return data?.packageAgencies?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default usePackageAgencies;
