import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import DialogButton from '@acdc/shared/src/ui/DialogButton';
import { Button, Stack } from '@mui/material';
import QuotationFormValue from './QuotationFormValue';
import BigRoundedButton from '../../../ui/buttons/BigRoundedButton';
import computePreviewTotalRentalFees from './computePreviewTotalRentalFees';

export interface AdaptedField {
    name: 'biddingAndNegotiation' | 'visitFee';
    values: QuotationFormValue;
    includingCharges: boolean;
}

/**
 * Check si un champ ajusté est toujours ajusté.
 */
export function isAdapted(
    adaptedField: AdaptedField | null,
    values: QuotationFormValue
): boolean {
    if (!adaptedField) {
        // aucun champ n'a été ajusté
        return false;
    }

    // check si un des champ dont dépend le calcul a changé
    // note: tous les champs utilisés dans le calcul de previewTotalRentalFees doivent être pris en compte.
    // On ne peut pas simplement check si previewTotalRentalFees car il change forcément après l'ajustement.
    const fieldThatCantChange: (keyof QuotationFormValue)[] = [
        'monthlyRent',
        'surface',
        'visitFee',
        'initialInventoryFee',
        'biddingAndNegotiation',
    ];
    const someFieldChanged = fieldThatCantChange.some(
        (field) => values[field] !== adaptedField.values[field]
    );
    if (someFieldChanged) {
        return false;
    }

    if (
        adaptedField.includingCharges === true &&
        values.monthlyProvisionsOnCharges !==
            adaptedField.values.monthlyProvisionsOnCharges
    ) {
        // les charges on changées alors qu'elles étaient prisent en compte donc le champ n'est plus ajusté
        return false;
    }

    // aucun changement n'impacte le champ ajusté donc il est toujours ajusté
    return true;
}

export function computeAdaptedField(
    formValues: QuotationFormValue,
    includingCharges: boolean
): AdaptedField | null {
    const monthlyProvisionsOnCharges = +(
        formValues.monthlyProvisionsOnCharges || 0
    );
    const monthlyRent = +(formValues.monthlyRent || 0); // loyer mensuel HC
    const surface = +(formValues.surface || 0);
    const biddingAndNegotiation = +(formValues.biddingAndNegotiation || 0); // frais d'entremise et négociation
    const initialInventoryFee = +(formValues.initialInventoryFee || 0); // honoraires état des lieux
    const previewTotalRentalFees = +(formValues.previewTotalRentalFees || 0);

    const chargesToInclude = includingCharges ? monthlyProvisionsOnCharges : 0;
    const fullMonthlyRent = monthlyRent + chargesToInclude;

    if (previewTotalRentalFees < fullMonthlyRent) {
        // On n'utilise pas formValues.previewTotalRentalFees car il a potentiellement été calculé
        // avec formValues.visitFeeUnrounded ce qui fausserait l'ajustement.
        const realPreviewTotalRentalFees = computePreviewTotalRentalFees(
            formValues.surface,
            formValues.initialInventoryFee,
            formValues.biddingAndNegotiation,
            formValues.visitFee,
            null // Donc on recalcul previewTotalRentalFees sans visitFeeUnrounded.
        );

        if (realPreviewTotalRentalFees === null) {
            // Le bouton devrait être disabled pour empêcher de tomber ici.
            throw Error(
                "Tentative d'ajustement du devis avec des champs requis manquants."
            );
        }

        const newValue = {
            ...formValues,
            biddingAndNegotiation:
                fullMonthlyRent -
                realPreviewTotalRentalFees +
                biddingAndNegotiation,
            visitFeeUnrounded: null, // on annule l'arrondi de visitFee si existant comme ce n'est plus lui l'ajusté
        };

        return {
            name: 'biddingAndNegotiation',
            values: newValue,
            includingCharges,
        };
    }
    if (previewTotalRentalFees > fullMonthlyRent) {
        const newVisitFee =
            (fullMonthlyRent -
                biddingAndNegotiation -
                initialInventoryFee * surface) /
            surface;
        const newValue: QuotationFormValue = {
            ...formValues,
            // honoraires dossier et visites
            visitFee: newVisitFee,
            visitFeeUnrounded: null,
        };
        const visitFeeRounded = Math.round(newVisitFee * 100) / 100;
        if (visitFeeRounded !== newValue.visitFee) {
            newValue.visitFeeUnrounded = newVisitFee;
            newValue.visitFee = visitFeeRounded;
        }

        return {
            name: 'visitFee',
            values: newValue,
            includingCharges,
        };
    }

    // previewTotalRentalFees === fullMonthlyRent
    return null;
}

export interface AdaptTotalRentalFeesPreviewButtonProps {
    children: React.ReactNode;
    setAdaptedField: (fieldInfo: AdaptedField | null) => void;
}

function AdaptTotalRentalFeesPreviewButton({
    children,
    setAdaptedField,
}: AdaptTotalRentalFeesPreviewButtonProps) {
    const { values, setValues } = useFormikContext<QuotationFormValue>();

    const disabled = Boolean(
        !values.monthlyRent || !values.previewTotalRentalFees
    );

    const adapt = useCallback(
        (includingCharges: boolean) => {
            setValues((prev) => {
                const newAdaptedField = computeAdaptedField(
                    prev,
                    includingCharges
                );
                if (!newAdaptedField) {
                    return prev;
                }

                setAdaptedField(newAdaptedField);

                return newAdaptedField.values;
            });
        },
        [setValues, setAdaptedField]
    );

    return (
        <DialogButton
            label={children}
            buttonComponent={BigRoundedButton}
            buttonProps={{
                type: 'button',
                color: 'rental',
                disabled,
            }}
            dialogProps={{
                id: 'AdaptTotalRentalFeesPreviewButtonDialog',
                mainTitle: children,
                maxWidth: 'sm',
                fullWidth: true,
                sxDialogTitle: {
                    backgroundColor: 'white',
                    color: 'primary',
                },
                sxCloseButton: {
                    color: 'primary',
                },
            }}
        >
            {(close) => (
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={() => {
                            close();
                            adapt(false);
                        }}
                        sx={{ flex: 1 }}
                    >
                        Hors charges
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={() => {
                            close();
                            adapt(true);
                        }}
                        sx={{ flex: 1 }}
                    >
                        Charges comprises
                    </Button>
                </Stack>
            )}
        </DialogButton>
    );
}

export default AdaptTotalRentalFeesPreviewButton;
