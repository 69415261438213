import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import React, { useCallback } from 'react';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import FlatIcon from '../../ui/icons/FlatIcon';
import ToolCard from '../../ui/ToolCard';
import usePresentationFlow from '../presentationFlow/usePresentationFlow';
import useStartPresentation from '../presentation/useStartPresentation';

export interface PropertyListItemProps {
    property: PropertyResponse;
    color: ThemeColor;
}

function PropertyListItem({ property, color }: PropertyListItemProps) {
    const presentationFlow = usePresentationFlow(
        entityToId(property.presentationFlow) || ''
    );
    const startPresentation = useStartPresentation();

    const handleClick = useCallback(
        (p: PropertyResponse) => {
            startPresentation(p.presentationFlow || '', p);
        },
        [startPresentation]
    );

    return (
        <ToolCard
            color={color}
            icon={FlatIcon}
            buttonText={presentationFlow?.label || ''}
            onClick={() => handleClick(property)}
            label={[property.clientLastname, property.clientFirstname]
                .filter((i) => Boolean(i))
                .join(' ')}
            body1={property.address}
        />
    );
}

export default PropertyListItem;
