import { useCallback } from 'react';
import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';
import { useNotificationQueue } from './NotificationQueue';

const useMarkNotificationUsersAsRead = (
    notificationUsers: NotificationUserResponse[] | null
) => {
    const { addItemUpdate } = useNotificationQueue();

    return useCallback(() => {
        if (notificationUsers) {
            const NotificationUsersIsntRead = notificationUsers
                .filter((notificationUser) => !notificationUser.isRead)
                .map((notificationUser) => {
                    if (notificationUser.id) {
                        return notificationUser.id;
                    }
                    return '';
                });

            addItemUpdate(NotificationUsersIsntRead);
        }
    }, [addItemUpdate, notificationUsers]);
};

export default useMarkNotificationUsersAsRead;
