import React from 'react';
import { NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';

// on attend pas de valeur undefined comme la prop persistor
// de PersistorProvider est requise. Donc on le met pas dans
// le type même si en réalité le contexte commence undefined,
// il n'est pas accessible tant qu'il n'a pas de valeur.
export const PersistorContext = React.createContext<
    CachePersistor<NormalizedCacheObject>
>(undefined as unknown as CachePersistor<NormalizedCacheObject>);

export interface PersistorProviderProps {
    children: React.ReactNode;
    persistor: CachePersistor<NormalizedCacheObject>;
}

function PersistorProvider({ children, persistor }: PersistorProviderProps) {
    return (
        <PersistorContext.Provider value={persistor}>
            {children}
        </PersistorContext.Provider>
    );
}

export default PersistorProvider;
