import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListDiscoveryCriteriaOverridesResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/ListDiscoveryCriteriaOverridesResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import DiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/DiscoveryCriteriaOverrideResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const DISCOVERY_CRITERIA_OVERRIDE_FRAGMENT = gql`
    fragment DiscoveryCriteriaOverrideFields on DiscoveryCriteriaOverride {
        id
        disabled
        agency {
            id
        }
        criteria {
            id
        }
    }
`;

export const GET_DISCOVERY_CRITERIA_OVERRIDES = gql`
    ${DISCOVERY_CRITERIA_OVERRIDE_FRAGMENT}
    query GetDiscoveryCriteriaOverrides($agencyCode: String) {
        discoveryCriteriaOverrides(
            itemsPerPage: 10000
            agency_code: $agencyCode
        ) {
            collection {
                ...DiscoveryCriteriaOverrideFields
            }
        }
    }
`;

const emptyResult: DiscoveryCriteriaOverrideResponse[] = [];

const useLoadDiscoveryCriteriaOverrides = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListDiscoveryCriteriaOverridesResponse>(
        GET_DISCOVERY_CRITERIA_OVERRIDES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des configurations des critères de découverte par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<
        DiscoveryCriteriaOverrideResponse[] | undefined
    >(() => {
        return data?.discoveryCriteriaOverrides?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useLoadDiscoveryCriteriaOverrides;
