import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import React, { useCallback, useMemo } from 'react';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../../configs';

export const QuotationQueueContext = React.createContext<{
    queue: DeepPartial<Quotation>[];
    setQueue: SetValue<DeepPartial<Quotation>[]>;
    addItem: (value: DeepPartial<Quotation>) => void;
}>({ queue: [], setQueue: () => {}, addItem: () => {} });

export interface QuotationQueueProviderProps {
    children: React.ReactNode;
}

function QuotationQueueProvider({ children }: QuotationQueueProviderProps) {
    const [queue, setQueue] = usePersistentState<DeepPartial<Quotation>[]>(
        CONFIGS.quotationQueueKey,
        []
    );

    const addItem = useCallback(
        (value: DeepPartial<Quotation>) => {
            setQueue((curr) => [...(curr || []), value]);
        },
        [setQueue]
    );

    const contextValue = useMemo(
        () => ({
            queue: queue || [],
            setQueue,
            addItem,
        }),
        [queue, setQueue, addItem]
    );

    return (
        <QuotationQueueContext.Provider value={contextValue}>
            {children}
        </QuotationQueueContext.Provider>
    );
}

export default QuotationQueueProvider;
