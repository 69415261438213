import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import React, { useCallback, useMemo } from 'react';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import arrayMapOrPush from '@acdc/shared/src/utils/arrayMapOrPush';
import CONFIGS from '../../../configs';

export const NotificationQueueContext = React.createContext<{
    queueUpdate: string[][];
    setQueueUpdate: SetValue<string[][]>;
    addItemUpdate: (iri: string[]) => void;
    queueDelete: string[];
    setQueueDelete: SetValue<string[]>;
    addItemDelete: (iri: string) => void;
}>({
    queueUpdate: [],
    queueDelete: [],
    setQueueUpdate: () => {},
    setQueueDelete: () => {},
    addItemDelete: () => {},
    addItemUpdate: () => {},
});

export interface NotificationQueueProviderProps {
    children: React.ReactNode;
}

function NotificationQueueProvider({
    children,
}: NotificationQueueProviderProps) {
    const [queueUpdate, setQueueUpdate] = usePersistentState<string[][]>(
        CONFIGS.notificationUpdateQueueKey,
        []
    );

    const [queueDelete, setQueueDelete] = usePersistentState<string[]>(
        CONFIGS.notificationDeleteQueueKey,
        []
    );

    const addItemUpdate = useCallback(
        (iris: string[]) => {
            setQueueUpdate((curr) => {
                return arrayMapOrPush(
                    curr || [],
                    iris,
                    (v, i) => JSON.stringify(v) === JSON.stringify(i)
                );
            });
        },
        [setQueueUpdate]
    );

    const addItemDelete = useCallback(
        (iri: string) => {
            setQueueDelete((curr) => {
                return arrayMapOrPush(curr || [], iri, (v, i) => v === i);
            });
        },
        [setQueueDelete]
    );

    const contextValue = useMemo(
        () => ({
            queueUpdate: queueUpdate || [],
            queueDelete: queueDelete || [],
            setQueueUpdate,
            setQueueDelete,
            addItemDelete,
            addItemUpdate,
        }),
        [
            queueUpdate,
            queueDelete,
            setQueueUpdate,
            setQueueDelete,
            addItemDelete,
            addItemUpdate,
        ]
    );

    return (
        <NotificationQueueContext.Provider value={contextValue}>
            {children}
        </NotificationQueueContext.Provider>
    );
}

export default NotificationQueueProvider;
