import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

export const DELETE_NOTIFICATION_USER = gql`
    mutation DeleteNotificationUser($id: ID!) {
        deleteNotificationUser(input: { id: $id }) {
            clientMutationId
        }
    }
`;

const useDeleteNotificationUser = () => {
    const [deleteNotificationUser] = useMutation(DELETE_NOTIFICATION_USER, {
        update(cache) {
            cache.evict({ fieldName: 'notificationUsers' });
        },
    });

    return useCallback(
        (el: string) => {
            const id = entityToId(el);
            return deleteNotificationUser({ variables: { id } });
        },
        [deleteNotificationUser]
    );
};

export default useDeleteNotificationUser;
