import { gql } from '@apollo/client';

const ContentFieldsFragment = gql`
    fragment ContentFields on Content {
        id
        title
        required
        template {
            id
        }
        toolCategory {
            id
        }
    }
`;

export default ContentFieldsFragment;
