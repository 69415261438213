import { Box, Stack } from '@mui/material';
import React from 'react';
import ContentHeading from '../content/ContentHeading';
import AdditionalServicesTable from './AdditionalServicesTable';
import InitialServicesTable from './InitialServicesTable';
import PackageSummariesList from './PackageSummariesList';
import PackageTeasersList from './PackageTeasersList';

interface PackagesProps {
    headingId: string;
    isExport?: boolean;
}

function Packages({ headingId, isExport }: PackagesProps) {
    const containerSx = isExport === true ? { height: '100vh' } : {};
    const contentHeadingSize = isExport ? 'normal' : 'big';
    const servicesTablesContainerSpacing = isExport ? 2 : 0;

    const summary = (
        <Box
            className="section export-page"
            data-anchor={`${headingId}-resume-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
            data-testid={`${headingId}-resume`}
            height="100vh"
        >
            <Stack
                direction="column"
                // pas de spacing en export car PackageSummariesList a plus de margin top en mode export qu'en mode normal
                spacing={isExport ? 0 : 2}
                height="100%"
                flexWrap="nowrap"
            >
                <Box>
                    <ContentHeading
                        size={contentHeadingSize}
                        mt={isExport ? 0 : undefined}
                    >
                        Nos packs en résumé
                    </ContentHeading>
                </Box>
                {/* En condition normal on met une marge négative pour gagner de la place en rognant sur les marges de la page. Dans l'export on a besoin d'une petite marge pour que le shadow des papers s'affiche sur les cotés */}
                <Box flex={1} mx={isExport ? 0.2 : -3}>
                    <PackageSummariesList />
                </Box>
            </Stack>
        </Box>
    );

    return (
        <>
            {!isExport && (
                <Box
                    className="section export-page"
                    data-anchor={`${headingId}-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                    data-testid={headingId}
                    {...containerSx}
                >
                    <ContentHeading
                        size={contentHeadingSize}
                        mt={isExport ? 0 : undefined}
                    >
                        Nos packs de gestion
                    </ContentHeading>
                    <Box mt={10}>
                        <PackageTeasersList />
                    </Box>
                </Box>
            )}
            {isExport && summary}
            <Box
                className="section export-page"
                data-anchor={`${headingId}-initiales-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={`${headingId}-initiales`}
            >
                <Stack
                    direction="column"
                    spacing={servicesTablesContainerSpacing}
                    height="100%"
                    flexWrap="nowrap"
                >
                    <Box>
                        <ContentHeading
                            size={contentHeadingSize}
                            mb={isExport ? 2 : 0}
                            mt={isExport ? 0 : undefined}
                        >
                            Les prestations initiales
                        </ContentHeading>
                    </Box>
                    <Box flex={isExport ? undefined : 1} pt={0} pb={2}>
                        <InitialServicesTable />
                    </Box>
                </Stack>
            </Box>
            <Box
                className="section export-page"
                data-anchor={`${headingId}-annexes-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
                data-testid={`${headingId}-annexes`}
            >
                <Stack
                    direction="column"
                    spacing={servicesTablesContainerSpacing}
                    height="100%"
                    flexWrap="nowrap"
                >
                    <Box>
                        <ContentHeading
                            size={contentHeadingSize}
                            mb={isExport ? 2 : 0}
                            mt={isExport ? 0 : undefined}
                        >
                            Les prestations annexes
                        </ContentHeading>
                    </Box>
                    <Box flex={1} pt={0} pb={2}>
                        <AdditionalServicesTable />
                    </Box>
                </Stack>
            </Box>
            {!isExport && summary}
        </>
    );
}

export default Packages;
