import React, { useMemo } from 'react';
import type ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import { Form, Formik } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { ApolloError } from '@apollo/client';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import useQuerySharedFiles from '@acdc/shared/src/features/moduleFile/useQuerySharedFiles';
import generateLocalUniqueId from '@acdc/shared/src/utils/generateLocalUniqueId';
import useSubmitImageOverrides from './useSubmitImageOverrides';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useAgency from '../../agency/useAgency';
import PartnersFormInner from './PartnersFormInner';

const partnersFormSchema = Yup.object().shape({
    files: Yup.array().label('Les partenaires').required().max(8),
});

export interface PartnersFormProps {
    contentModule: ContentModuleResponse;
    apiEntrypoint: string;
    onSuccess?: (res: SettingValueResponse[]) => void;
    onError?: (err: ApolloError) => void;
}

export interface PartnersFormValues {
    files: ModuleFile[];
}

function PartnersForm({
    contentModule,
    apiEntrypoint,
    onSuccess,
    onError,
}: PartnersFormProps) {
    const selectedAgency = useAgency(useSelectedAgency()[0]);

    const placeHolderPartners = useMemo(() => {
        const partners: ModuleFile[] = [];
        if (contentModule && contentModule.settingValues) {
            contentModule.settingValues.collection.forEach((item) => {
                if (item.sharedFile !== null) {
                    partners.push({
                        id: item.id,
                        uuid: generateLocalUniqueId(),
                        publicPath: item.sharedFile?.publicPath || undefined,
                        title: item.sharedFile?.title || '',
                        type: ModuleFileEnum.SHARED_FILE,
                        sharedFile: item.sharedFile,
                        moduleSetting: item.moduleSetting || undefined,
                    });
                }
            });
        }
        return partners;
    }, [contentModule]);

    const currentPartners: ModuleFile[] = (
        selectedAgency?.imageOverrides?.collection || []
    )
        .reduce((accumulator: ModuleFile[], item) => {
            if (
                (item.imageOverrideFile !== null || item.sharedFile !== null) &&
                item.moduleSetting?.code?.startsWith('image_partner') &&
                matchOnId(item.contentModule, contentModule)
            ) {
                accumulator.push({
                    id: item.id,
                    uuid: generateLocalUniqueId(),
                    publicPath:
                        item.sharedFile?.publicPath ||
                        item.imageOverrideFile?.publicPath ||
                        undefined,
                    title: item.sharedFile?.title || '',
                    type: item.sharedFile
                        ? ModuleFileEnum.SHARED_FILE
                        : ModuleFileEnum.IMAGE_OVERRIDE_FILE,
                    sharedFile: item.sharedFile || undefined,
                    imageOverrideFile: item.imageOverrideFile || undefined,
                    moduleSetting: item.moduleSetting || undefined,
                });
            }
            return accumulator;
        }, [])
        .sort((a, b) =>
            a.moduleSetting!.id!.localeCompare(b.moduleSetting!.id!)
        );

    const sharedFilesResponse = useQuerySharedFiles();

    const initialValues: PartnersFormValues = { files: currentPartners };

    const fixedProperties = useMemo(
        () => ({
            contentModule: entityToId(contentModule)!,
            agency: entityToId(selectedAgency)!,
        }),
        [contentModule, selectedAgency]
    );

    const imageOverridesForContentModule = useMemo(() => {
        return selectedAgency?.imageOverrides?.collection
            .filter(
                (item) =>
                    item.moduleSetting?.code?.startsWith('image_partner') &&
                    matchOnId(item.contentModule, contentModule)
            )
            .sort((a, b) => a.id!.localeCompare(b.id!));
    }, [contentModule, selectedAgency?.imageOverrides?.collection]);

    const submit = useSubmitImageOverrides(
        partnersFormSchema,
        onSuccess,
        onError,
        fixedProperties,
        imageOverridesForContentModule,
        selectedAgency,
        contentModule.module
    );

    return (
        <Formik
            validationSchema={partnersFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <PartnersFormInner
                    sharedFiles={sharedFilesResponse?.sharedFiles?.collection}
                    placeHolderPartners={placeHolderPartners}
                    apiEntrypoint={apiEntrypoint}
                />
            </Form>
        </Formik>
    );
}

export default PartnersForm;
