import {
    Button,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    RadioGroupProps,
} from '@mui/material';
import React from 'react';
import { visuallyHidden } from '@mui/utils';

const formControlLabelSx = {
    '& .MuiFormControlLabel-label': visuallyHidden,
    '& .MuiRadio-root': {
        width: '100%',
        '& .MuiButton-root': {
            width: '100%',
        },
    },
};

export type ButtonPickerOwnProps = {
    options: any[];
    getOptionValue: (option: any) => string;
    getOptionLabel: (option: any) => string;
    getOptionWidth?: (option: any) => number | 'auto' | boolean;
};

export type ButtonPickerProps = ButtonPickerOwnProps & RadioGroupProps;

/**
 * Comme un RadioGroup mais présenté avec des boutons à la place des radios.
 */
function ButtonPicker({
    options,
    getOptionValue,
    getOptionLabel,
    getOptionWidth,
    ...props
}: ButtonPickerProps) {
    return (
        <Grid
            container
            justifyContent="center"
            component={RadioGroup}
            {...props}
        >
            {options.map((option) => {
                const width = getOptionWidth && getOptionWidth(option);

                return (
                    <Grid
                        item
                        xs={width ? 12 : 'auto'}
                        sm={width || 'auto'}
                        component={FormControlLabel}
                        key={getOptionValue(option)}
                        value={getOptionValue(option)}
                        control={
                            <Radio
                                disableRipple
                                icon={
                                    <Button
                                        type="button"
                                        variant="contained"
                                        role="presentation"
                                    >
                                        {getOptionLabel(option)}
                                    </Button>
                                }
                                checkedIcon={
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="success"
                                        role="presentation"
                                    >
                                        {getOptionLabel(option)}
                                    </Button>
                                }
                            />
                        }
                        label={getOptionLabel(option)}
                        sx={formControlLabelSx}
                    />
                );
            })}
        </Grid>
    );
}

export default ButtonPicker;
