import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import { FormikHelpers } from 'formik';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import DiscoveryCriteria from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteria.model';
import DiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/DiscoveryCriteriaOverrideResponse';
import MutateDiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/MutateDiscoveryCriteriaOverrideResponse';
import DiscoveryCriteriaOverride from '@acdc/shared/src/features/discoveryCriteriaOverride/DiscoveryCriteriaOverride.model';
import UpdateDiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/UpdateDiscoveryCriteriaOverrideResponse';
import CreateDiscoveryCriteriaOverrideResponse from '@acdc/shared/src/features/discoveryCriteriaOverride/CreateDiscoveryCriteriaOverrideResponse';
import {
    type DiscoveryCriteriaOverrideFormProps,
    type DiscoveryCriteriaOverrideFormValue,
} from './DiscoveryCriteriaOverrideForm';
import {
    DISCOVERY_CRITERIA_OVERRIDE_FRAGMENT,
    GET_DISCOVERY_CRITERIA_OVERRIDES,
} from '../useLoadDiscoveryCriteriaOverrides';
import useSelectedAgency from '../../agency/useSelectedAgency';

export const UPDATE_DISCOVERY_CRITERIA_OVERRIDE = gql`
    ${DISCOVERY_CRITERIA_OVERRIDE_FRAGMENT}
    mutation UpdateDiscoveryCriteriaOverride(
        $input: updateDiscoveryCriteriaOverrideInput!
    ) {
        updateDiscoveryCriteriaOverride(input: $input) {
            discoveryCriteriaOverride {
                ...DiscoveryCriteriaOverrideFields
            }
        }
    }
`;

export const CREATE_DISCOVERY_CRITERIA_OVERRIDE = gql`
    ${DISCOVERY_CRITERIA_OVERRIDE_FRAGMENT}
    mutation CreateDiscoveryCriteriaOverride(
        $input: createDiscoveryCriteriaOverrideInput!
    ) {
        createDiscoveryCriteriaOverride(input: $input) {
            discoveryCriteriaOverride {
                ...DiscoveryCriteriaOverrideFields
            }
        }
    }
`;

function doSubmit(
    formValues: DiscoveryCriteriaOverrideFormValue,
    getId: () => string | null,
    createDiscoveryCriteriaOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateDiscoveryCriteriaOverrideResponse>>,
    updateDiscoveryCriteriaOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateDiscoveryCriteriaOverrideResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<DiscoveryCriteriaOverride>
) {
    return Promise.resolve()
        .then(() => {
            const input: DeepPartial<DiscoveryCriteriaOverride> = {
                disabled: !formValues.enabled,
                ...fixedProperties,
            };

            if (getId()) {
                input.id = getId() || undefined;
            }

            return input;
        })
        .then((input) =>
            (
                (getId()
                    ? updateDiscoveryCriteriaOverride
                    : createDiscoveryCriteriaOverride) as (
                    options: MutationFunctionOptions
                ) => Promise<
                    FetchResult<MutateDiscoveryCriteriaOverrideResponse>
                >
            )({ variables: { input } }).catch((err: ApolloError) => {
                handleError()(err);

                throw Error('cancelled');
            })
        );
}

const useSubmitDiscoveryCriteriaOverride = (
    getId: () => string | null,
    setId: (id: string) => void,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: DiscoveryCriteriaOverrideFormProps['onSuccess'],
    onError: DiscoveryCriteriaOverrideFormProps['onError'],
    fixedProperties: DeepPartial<DiscoveryCriteria>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createDiscoveryCriteriaOverride] = useMutation(
        CREATE_DISCOVERY_CRITERIA_OVERRIDE,
        {
            // ajout du nouveau DiscoveryCriteriaOverride dans le cache de la requete GET_DISCOVERY_CRITERIA_OVERRIDES
            update(cache, result) {
                const item = result.data?.createDiscoveryCriteriaOverride
                    ?.discoveryCriteriaOverride as StoreObject;
                if (!item) {
                    return;
                }
                cache.updateQuery(
                    {
                        query: GET_DISCOVERY_CRITERIA_OVERRIDES,
                        broadcast: false,
                        variables: {
                            agencyCode: getIdFromIri(
                                entityToId(agencyVariable)
                            ),
                        },
                    },
                    (data) => {
                        return {
                            discoveryCriteriaOverrides: {
                                ...data.discoveryCriteriaOverrides,
                                collection: [
                                    ...data.discoveryCriteriaOverrides
                                        .collection,
                                    item,
                                ],
                            },
                        };
                    }
                );
            },
        }
    );

    const [updateDiscoveryCriteriaOverride] = useMutation(
        UPDATE_DISCOVERY_CRITERIA_OVERRIDE,
        {
            // pas d'eviction du cache
        }
    );

    return useCallback(
        (
            formValues: DiscoveryCriteriaOverrideFormValue,
            {
                setSubmitting,
                setErrors,
            }: FormikHelpers<DiscoveryCriteriaOverrideFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createDiscoveryCriteriaOverride,
                updateDiscoveryCriteriaOverride,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then(
                    (
                        res: FetchResult<MutateDiscoveryCriteriaOverrideResponse>
                    ) => {
                        const isUpdate = Boolean(getId());
                        const resItem:
                            | DiscoveryCriteriaOverrideResponse
                            | null
                            | undefined = isUpdate
                            ? res.data?.updateDiscoveryCriteriaOverride
                                  ?.discoveryCriteriaOverride
                            : res.data?.createDiscoveryCriteriaOverride
                                  ?.discoveryCriteriaOverride;

                        if (!resItem?.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res.data);
                            return;
                        }

                        setId(resItem.id);
                        onSuccess && onSuccess(resItem);
                    }
                )
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createDiscoveryCriteriaOverride,
            updateDiscoveryCriteriaOverride,
            setId,
            onSuccess,
            onError,
            setAlert,
            yupSchema,
            fixedProperties,
        ]
    );
};

export default useSubmitDiscoveryCriteriaOverride;
