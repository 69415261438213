import React from 'react';
import { Grid } from '@mui/material';
import PackageSummarySkeleton from './PackageSummarySkeleton';

const getItems = (nb: number) => {
    const inputItems = [];

    for (let ind = 0; ind < nb; ind += 1) {
        inputItems.push(
            <Grid item key={ind} sm height="100%">
                <PackageSummarySkeleton />
            </Grid>
        );
    }

    return inputItems;
};

function PackageSummariesListSkeleton() {
    return (
        <Grid
            container
            data-testid="PackageSummariesListSkeleton"
            spacing={2}
            height="100%"
        >
            {getItems(4)}
        </Grid>
    );
}

export default PackageSummariesListSkeleton;
