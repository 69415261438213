import React, { useCallback, useState } from 'react';
import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LeftDrawer from '../../../ui/LeftDrawer';
import DiscoveryCriteriaOverridesForms from '../DiscoveryCriteriaOverridesForms/DiscoveryCriteriaOverridesForms';
import useEditMode from '../../template/useEditMode';

export interface ConfigureDiscoveryFormProps {
    discoveryForm: DiscoveryFormResponse;
    agency: AgencyResponse;
}

function ConfigureDiscoveryForm({
    discoveryForm,
    agency,
}: ConfigureDiscoveryFormProps) {
    const [editMode] = useEditMode();
    const [open, setOpen] = useState<boolean>(false);

    const toggleDrawer = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    if (!editMode) {
        return null;
    }

    return (
        <>
            <LeftDrawer
                open={open}
                title={`Configurer formulaire ${discoveryForm.label}`}
                toggleDrawer={toggleDrawer}
            >
                <DiscoveryCriteriaOverridesForms
                    discoveryForm={discoveryForm}
                    agency={agency}
                />
            </LeftDrawer>
            <Fab
                size="large"
                color="primary"
                aria-label="configurer"
                sx={{
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
                onClick={toggleDrawer}
            >
                <EditIcon />
            </Fab>
        </>
    );
}

export default ConfigureDiscoveryForm;
