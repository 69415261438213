// il faut définir des clés uniques par application pour chaque valeur du localstorage
// pour éviter que nos différentes applications partagent les mêmes clés sur
// localhost qui causent des problèmes en dev.
// todo : on aurait pu utiliser 1 suffix pour toutes les clés
const CONFIGS = {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
    fullPageLicenseKey: process.env.REACT_APP_FULLPAGE_JS_LICENSE_KEY || '',
    apiEntrypoint: process.env.REACT_APP_API_ENTRYPOINT || '',
    frontEntrypoint: process.env.REACT_APP_FRONT_ENTRYPOINT || '',
    casEntrypoint: process.env.REACT_APP_CAS_ENTRYPOINT || '',
    dockerApiEntrypoint: process.env.REACT_APP_DOCKER_API_ENTRYPOINT || '',
    propertyStorageKey: 'propertyzh46n46zan14',
    presentationProgressLocalStorageKey: 'presentationProgress9652722699',
    currentQuotationStorageKey: 'currentQuotation6685946233',
    authStorageKeys: {
        authToken: 'authToken7PrcVdYfgD',
        refreshToken: 'refreshTokenNVC6JbSwsj',
        roles: 'roles47AvLSxJzG',
    },
    userDataStorageKeys: {
        currentUser: 'user62e395b49e9a68.20725377',
        currentAgency: 'agency62e395eb382e07.91243484',
    },
    packageStorageKey: 'package62e91330b53e64.76122934',
    enableCasAuthentication:
        process.env.REACT_APP_ENABLE_CAS_AUTHENTICATION || 'true',
    storeAgeKey: 'storeCQAj3V6xPjnToksBcjJY',
    // heure à partir de laquelle les données doivent être rafraîchient pour
    // garantir d'avoir les biens à jour.
    // Le format doit être compatible avec le 2ème param de https://date-fns.org/v2.29.3/docs/set
    forceRefreshApiDataAt: {
        hours: 6,
        minutes: 0,
        seconds: 0,
    },
    quotationQueueKey: 'quotationQueueA8U4SCzyFRAcLprA',
    currentPresentationKey: 'currentPresentationALpKuXS5JRxHysKR',
    presentationStatisticsKey: 'presentationStatisticsXVCkP2uUt25QdUmp',
    notificationUpdateQueueKey: 'notificationUpdateQueue6dd306c1',
    notificationDeleteQueueKey: 'notificationDeleteQueuef0a2a8dd',
    preferencesKey: 'preferencesQXG61z',
    discoveryKey: 'discoveryQg94nz16',
    discoveryQueueKey: 'discoveryQueueAGbzt66622iy',
    discoveryCurrentPageKey: 'discoveryCurrentPageSdgl6tu9q2f95',
};

export default CONFIGS;
