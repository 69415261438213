function computePreviewTotalRentalFees(
    surface: string | number,
    initialInventoryFee: string | number,
    biddingAndNegotiation: string | number,
    visitFee: string | number,
    visitFeeUnrounded: number | null
): number | null {
    if (surface === '' || visitFee === '' || initialInventoryFee === '') {
        return null;
    }

    const visitFeeToUse =
        visitFeeUnrounded === null ? visitFee : visitFeeUnrounded;
    const landDeclaration: number = +surface * +visitFeeToUse;
    const inventoryFee: number = +surface * +initialInventoryFee;

    const result: number =
        landDeclaration + inventoryFee + +biddingAndNegotiation;

    return result;
}

export default computePreviewTotalRentalFees;
