import React from 'react';
import { Drawer, Toolbar, IconButton, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import Menu from '../../features/navigation/Menu';
import DrawerState, { DrawerStateEnum } from './DrawerState';
import usePresentationFlow from '../../features/presentationFlow/usePresentationFlow';
import useCurrentPresentation from '../../features/presentation/useCurrentPresentation';
import usePresentationFlowMenus from '../../features/presentationFlow/usePresentationFlowMenus';

const getOpenBoolean = (dState: DrawerState) => {
    return (
        dState.currentState === DrawerStateEnum.OPEN ||
        dState.currentState === DrawerStateEnum.FULLSCREEN
    );
};

interface SideBarProps {
    drawerState: DrawerState;
    toggleDrawer: () => void;
    toggleFullscreen: () => void;
}

function SideBar({
    drawerState,
    toggleDrawer,
    toggleFullscreen,
}: SideBarProps) {
    const [currentPresentation] = useCurrentPresentation();
    const navigation = usePresentationFlowMenus(
        usePresentationFlow(currentPresentation?.presentationFlowId)
    );

    return (
        <Drawer
            id="sideBar"
            anchor="right"
            open={getOpenBoolean(drawerState)}
            ModalProps={{ onBackdropClick: toggleDrawer }}
            sx={{
                '& .MuiDrawer-paper': {
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    backgroundColor: 'background.default',
                    width: '40%',
                    ...(drawerState.currentState === DrawerStateEnum.OPEN &&
                        drawerState.previousState ===
                            DrawerStateEnum.FULLSCREEN && {
                            transition: (theme) =>
                                `${theme.transitions.create('width', {
                                    easing: theme.transitions.easing.easeIn,
                                    duration:
                                        theme.transitions.duration.standard,
                                })}!important`,
                        }),
                    ...(drawerState.currentState ===
                        DrawerStateEnum.FULLSCREEN && {
                        width: '100%',
                        transition: (theme) =>
                            `${theme.transitions.create('width', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.standard,
                            })}!important`,
                    }),
                },
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    backgroundColor: 'background.default',
                    boxShadow: 'none',
                    width: '100%',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        px: [1],
                    }}
                >
                    <IconButton
                        onClick={toggleDrawer}
                        data-testid="sideBarCloseButton"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <IconButton
                        onClick={toggleFullscreen}
                        data-testid="sideBarFullscreenButton"
                    >
                        {drawerState.currentState ===
                        DrawerStateEnum.FULLSCREEN ? (
                            <ZoomInMapIcon />
                        ) : (
                            <ZoomOutMapIcon />
                        )}
                    </IconButton>
                </Toolbar>
            </Box>
            <Box
                mt="64px"
                sx={{
                    overflow: 'auto',
                }}
            >
                <Menu
                    navigation={navigation}
                    currentState={drawerState.currentState}
                    toggleDrawer={toggleDrawer}
                    currentPresentation={currentPresentation}
                />
            </Box>
        </Drawer>
    );
}

export default SideBar;
