import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import TemplateResponse from '@acdc/shared/src/features/template/TemplateResponse';
import SectionResponse from '@acdc/shared/src/features/section/SectionResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import useMediaQuery from '@mui/material/useMediaQuery';
import { headerHeight } from '../../mui/theme';
import DisplayContentModule from '../contentModule/DisplayContentModule';

/**
 * L'application est prévue pour fonctionner sur un écran de 1024px/768px.
 * Avec cette résolution la partie de l'écran dédiée à l'affichage du
 * Template fait 671px/922px. Donc on scale le Template par rapport à cette
 * résolution pour qu'il conserve le même ratio en occupant au maximum l'espace
 * disponible.
 */
const BASE_RATIO_H = 671;
const BASE_RATIO_W = 922;
const BASE_FONT_SIZE = 16; // px

const getCorrespondingContentModule = (
    section: SectionResponse,
    contentModules: ContentModuleResponse[]
): ContentModuleResponse | undefined => {
    return contentModules.find((element: ContentModuleResponse) =>
        matchOnId(element.section, section)
    );
};

const contentContainerSx = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const getResponsiveContentContainerSx = (
    isViewportSmallLandscapeOrPortrait: boolean,
    scaleFactor: number,
    exportPdf: boolean
) => ({
    aspectRatio: `${BASE_RATIO_W} / ${BASE_RATIO_H}`,
    ...(isViewportSmallLandscapeOrPortrait
        ? {
              width: '100%',
          }
        : {
              height: exportPdf ? '100vh' : `calc(100vh - ${headerHeight})`,
          }),
    maxWidth: '100%',
    fontSize: `${BASE_FONT_SIZE * scaleFactor}px`,
});

const sectionsListContainerSx = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
};

const getSectionContainerSx = (section: SectionResponse) => ({
    order: section.row,
    height: section.height,
    flex: `1 0 ${section.width}`,
    maxWidth: section.width,
    maxHeight: section.height,
    padding: '0.5em',
});

interface DisplayTemplateProps {
    template: TemplateResponse;
    sections: SectionResponse[];
    contentModules: ContentModuleResponse[];
    exportPdf?: boolean;
}

function DisplayTemplate({
    template,
    sections,
    contentModules,
    exportPdf,
}: DisplayTemplateProps) {
    const isViewportSmallLandscapeOrPortrait = useMediaQuery(
        '(max-aspect-ratio: 4/3)'
    );
    const containerRef = useRef<HTMLElement>(null);
    const [scaleFactor, setScaleFactor] = useState(1);

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }
        setScaleFactor(containerRef.current.offsetHeight / BASE_RATIO_H);
    }, [setScaleFactor]);

    const responsiveContentContainerSx = useMemo(
        () =>
            getResponsiveContentContainerSx(
                isViewportSmallLandscapeOrPortrait,
                scaleFactor,
                exportPdf || false
            ),
        [isViewportSmallLandscapeOrPortrait, scaleFactor, exportPdf]
    );

    return (
        <Box sx={contentContainerSx}>
            <Box
                key={template.id}
                sx={responsiveContentContainerSx}
                data-testid="displayTemplate"
            >
                <Box ref={containerRef} sx={sectionsListContainerSx}>
                    {sections
                        ?.slice()
                        .sort((a: SectionResponse, b: SectionResponse) => {
                            return a.col && b.col && a.col > b.col ? 1 : -1;
                        })
                        .map((section: SectionResponse) => (
                            <Box
                                key={section.id}
                                sx={getSectionContainerSx(section)}
                                data-testid="displayContentModule"
                            >
                                <DisplayContentModule
                                    contentModule={getCorrespondingContentModule(
                                        section,
                                        contentModules
                                    )}
                                    scaleFactor={scaleFactor}
                                />
                            </Box>
                        ))}
                </Box>
            </Box>
        </Box>
    );
}

export default DisplayTemplate;
