import React from 'react';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import useAutoSubmitForm from '@acdc/shared/src/utils/useAutoSubmitForm';
import { FormLabel } from '@mui/material';

function PreferencesFormInner() {
    useAutoSubmitForm();

    return (
        <>
            <FormLabel htmlFor="navigateWithButtonsSwitch">
                Activer la navigation par boutons
            </FormLabel>
            <Field
                component={Switch}
                id="navigateWithButtonsSwitch"
                name="navigateWithButtons"
                type="checkbox"
                disabled={false}
                color="success"
            />
        </>
    );
}

export default PreferencesFormInner;
