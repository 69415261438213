import { useContext } from 'react';
import { DiscoveryDrawerCtx } from './DiscoveryDrawerProvider';

/**
 * Utilisable uniquement dans un composant enfant de DiscoveryDrawerProvider.
 * Donc une page enfant de DiscoveryLayout pour l'instant.
 */
function useDiscoveryDrawer() {
    return useContext(DiscoveryDrawerCtx);
}

export default useDiscoveryDrawer;
