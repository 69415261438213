import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import React from 'react';
import CONFIGS from '../../configs';
import Presentation from './Presentation';

export const CurrentPresentationContext = React.createContext<
    [Presentation | undefined, SetValue<Presentation>]
>([undefined, () => {}]);

export interface CurrentPresentationProviderProps {
    children?: React.ReactNode;
}

function CurrentPresentationProvider({
    children,
}: CurrentPresentationProviderProps) {
    const value = usePersistentState<Presentation>(
        CONFIGS.currentPresentationKey
    );

    return (
        <CurrentPresentationContext.Provider value={value}>
            {children}
        </CurrentPresentationContext.Provider>
    );
}

export default CurrentPresentationProvider;
