import React, { useMemo } from 'react';
import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import User from '@acdc/shared/src/features/user/User.model';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import QuotationValuesModel from '../QuotationValues.model';
import useQuotationManagementTotals from './useQuotationManagementTotals';
import useQuotationRentTotals from './useQuotationRentTotals';
import QuotationRentTotals from '../QuotationRentTotals.model';
import QuotationManagementTotals from '../QuotationManagementTotals.model';
import CONFIGS from '../../../configs';
import useProperty from '../../property/useProperty';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import useCurrentPresentation from '../../presentation/useCurrentPresentation';
import { quotationUiDataToQuotationResponse } from '../convertQuotationResponse';
import QuotationResultUi from './QuotationResultUi';

function makeQuotation(
    value: Partial<QuotationValuesModel>,
    rentTotals: Partial<QuotationRentTotals>,
    managementTotals: Partial<QuotationManagementTotals>,
    presenter: string | Partial<User>,
    property: PropertyResponse | undefined,
    agency: AgencyResponse | string | undefined,
    pack: PackageResponse | string | null | undefined
): DeepPartial<Quotation> {
    return {
        presenter: entityToId(presenter) || undefined,
        agency: entityToId(agency) || undefined,
        pack: entityToId(pack) || undefined,
        clientEmailAddress: property?.clientEmail,
        clientLastname: property?.clientLastname,
        clientFirstname: property?.clientFirstname || undefined,
        clientPhone: property?.clientPhone,
        propertyId: property?.id
            ? parseInt(getIdFromIri(entityToId(property))!, 10)
            : undefined,
        propertyAddress: property?.address,
        propertyStreet: property?.street,
        propertyPostCode: property?.postCode,
        propertyCity: property?.city,
        ...(quotationUiDataToQuotationResponse(
            value,
            rentTotals,
            managementTotals
        ) as any),
    };
}

interface QuotationResultProps {
    value: QuotationValuesModel;
    onBack?: () => void;
}

function QuotationResult({ value, onBack }: QuotationResultProps) {
    const [presenter] = usePersistentState(
        CONFIGS.userDataStorageKeys.currentUser
    );
    const rentTotals = useQuotationRentTotals(value);
    const managementTotals = useQuotationManagementTotals(value);
    const [currentPresentation] = useCurrentPresentation();
    const selectedProperty = useProperty(currentPresentation?.propertyId);
    const [selectedAgencyId] = useSelectedAgency();
    const [selectedPackageId] = useSelectedPackageId();
    const valueToSend = useMemo(
        () =>
            makeQuotation(
                value || {},
                rentTotals || {},
                managementTotals || {},
                `${entityToId(presenter)}`,
                selectedProperty,
                selectedAgencyId,
                selectedPackageId
            ),
        [
            value,
            rentTotals,
            managementTotals,
            presenter,
            selectedProperty,
            selectedAgencyId,
            selectedPackageId,
        ]
    );

    return (
        <QuotationResultUi
            rentTotals={rentTotals}
            managementTotals={managementTotals}
            valueToSend={valueToSend}
            onBack={onBack}
        />
    );
}

export default QuotationResult;
