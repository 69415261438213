import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListUserAgenciesResponse from '@acdc/shared/src/features/userAgency/ListUserAgenciesResponse';

export const GET_AGENCY_USER_AGENCIES = gql`
    query GetAgencyUserAgencies($id: String!) {
        userAgencies(
            agency_code: $id
            user_type: "collaborateur"
            itemsPerPage: 10000
        ) {
            collection {
                user {
                    lastname
                    firstname
                    id
                }
                id
                role
            }
        }
    }
`;

/**
 * Retourne tous les UserAgencies accessibles à l'utilisateur.
 */
const useUserAgenciesOfAgency = (agencyId: string | null | undefined) => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListUserAgenciesResponse>(
        GET_AGENCY_USER_AGENCIES,
        {
            variables: { id: agencyId },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des utilisateurs par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useUserAgenciesOfAgency;
