import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import Agency from '@acdc/shared/src/features/agency/Agency.model';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { initBool } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import UseNewPackagesFormInner from './UseNewPackagesFormInner';
import useSubmitUseNewPackagesForm from './useSubmitUseNewPackagesForm';

export const useNewPackagesFormSchema = Yup.object().shape({
    useNewPackages: Yup.bool()
        .label('Utiliser le nouveau nom des packs')
        .required(),
});

export interface UseNewPackagesFormValue {
    useNewPackages: boolean;
}

export interface UseNewPackagesFormProps {
    value?: Partial<Pick<Agency, 'id' | 'useNewPackages'>> | undefined;
    onSuccess?: ((res: AgencyResponse) => void) | undefined;
}

/**
 * Formulaire pour changer la propriété useNewPackages de Agency.
 */
function UseNewPackagesForm({ value, onSuccess }: UseNewPackagesFormProps) {
    const initialValues: UseNewPackagesFormValue = useMemo(() => {
        return {
            useNewPackages: initBool(value?.useNewPackages, false),
        };
    }, [value]);
    const submit = useSubmitUseNewPackagesForm(
        useNewPackagesFormSchema,
        value?.id,
        onSuccess
    );

    if (!initialValues) {
        return <FormSkeleton nbInputs={1} />;
    }

    return (
        <Formik
            validationSchema={useNewPackagesFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <UseNewPackagesFormInner />
            </Form>
        </Formik>
    );
}

export default UseNewPackagesForm;
