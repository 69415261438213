import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Box } from '@mui/material';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import arrayMapOrPush from '@acdc/shared/src/utils/arrayMapOrPush';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import { SetAlert, useAlert } from '@acdc/shared/src/tools/alert';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import DiscoveryCriteriaSection from './DiscoveryCriteriaSection';
import useSubmitDiscovery from './useSubmitDiscovery';
import shouldShowDiscoveryCriteria from './shouldShowDiscoveryCriteria';
import NavigateDiscoveryFormSlideButton from './NavigateDiscoveryFormSlideButton';
import NavigationButtonsContainer from './NavigationButtonsContainer';
import { useHeaderHeight } from '../../../layout/HeaderHeightProvider';
import SubmitFormButton from './SubmitFormButton';
import findEmptyRequiredSubCriterias from './findEmptyRequiredSubCriterias';
import PagesSlider from '../../../layout/PagesSlider';
import getCriteriaValue from './getCriteriaValue';
import CommentDiscoverySlideFormDialogButton from './CommentDiscoverySlideFormDialogButton';
import DiscoveryDrawer from '../DiscoveryDrawer';
import useDiscoveryDrawer from '../../../layout/DiscoveryLayout/useDiscoveryDrawer';

function onTryToGoNextSlide(
    currentRootCriteria: DiscoveryCriteriaResponse,
    values: DiscoveryValue[],
    allDiscoveryCriterias: DiscoveryCriteriaResponse[],
    agency: AgencyResponse,
    setAlert: SetAlert,
    silently: boolean = false
): boolean {
    const emptyRequiredSubCriterias = findEmptyRequiredSubCriterias(
        currentRootCriteria,
        values,
        allDiscoveryCriterias,
        agency
    );
    if (!emptyRequiredSubCriterias.length) {
        return true;
    }

    if (!silently) {
        let message: string = '';
        if (emptyRequiredSubCriterias.length === 1) {
            message = `Le champ "${emptyRequiredSubCriterias[0].label}" est requis.`;
        } else {
            message = `Les champs ${emptyRequiredSubCriterias
                .map((c) => `"${c.label}"`)
                .join(', ')} sont requis.`;
        }
        setAlert(message, 'error');
    }

    return false;
}

const noValues: DiscoveryValue[] = [];

const slidePx = { sx: 0, sm: '10%', md: '15%' };

// marges négatives pour que les boutons de chaque cotés fusionnent avec et que le fab dépasse au dessus et en dessous.
const commentFabSx = { my: '-9.8px', mx: '-10px' };

export interface DiscoveryFormProps {
    discoveryForm: DiscoveryFormResponse;
    user: UserResponse;
    agency: AgencyResponse;

    /**
     * La clé de localstorage où le form est sauvegardé à chaque modification.
     */
    localStorageValuesKey: string;

    /**
     * La clé de localstorage où la page en cours du form est sauvegardée à chaque modification.
     */
    localStorageCurrentPageKey: string;
    onSuccess: () => void;
}

function DiscoveryForm({
    discoveryForm,
    user,
    agency,
    localStorageValuesKey,
    localStorageCurrentPageKey,
    onSuccess,
}: DiscoveryFormProps) {
    const { isOpen: isDrawerOpen, toggleDrawer } = useDiscoveryDrawer();

    const [headerHeight] = useHeaderHeight();
    const [values, setValues] = usePersistentState<DiscoveryValue[]>(
        localStorageValuesKey
    );
    const setAlert = useAlert();
    const [activePage, setActivePage] = usePersistentState<number>(
        localStorageCurrentPageKey,
        0
    );

    /**
     * Liste des critères racines et leur validité (true = valide, false = invalide).
     * Si un critère n'est pas présent il n'est pas considéré valide.
     */
    const [rootCriteriasValidity, setRootCriteriasValidity] = useState<{
        [id: string]: boolean;
    }>({});

    /**
     * Liste des ids de critères invalides dans la slide courante.
     */
    const [currentSlideErrors, setCurrentSlideErrors] = useState<string[]>([]);
    const addCurrentSlideError = useCallback(
        (discoveryCriteria: string | DiscoveryCriteriaResponse) => {
            const criteriaId = entityToId(discoveryCriteria);
            if (!criteriaId) {
                return;
            }
            setCurrentSlideErrors((curr) => {
                return [...curr, criteriaId];
            });
        },
        [setCurrentSlideErrors]
    );
    const removeCurrentSlideError = useCallback(
        (discoveryCriteria: string | DiscoveryCriteriaResponse) => {
            const criteriaId = entityToId(discoveryCriteria);
            if (!criteriaId) {
                return;
            }
            setCurrentSlideErrors((curr) => {
                return curr.filter((c) => c !== criteriaId);
            });
        },
        [setCurrentSlideErrors]
    );

    const validateSlide = useCallback(
        (
            rootCriteria: DiscoveryCriteriaResponse,
            silently: boolean = false
        ) => {
            if (currentSlideErrors.length > 0) {
                !silently &&
                    setAlert(
                        'Veuillez corriger les champs invalides.',
                        'error'
                    );
                return false;
            }

            const isValid = onTryToGoNextSlide(
                rootCriteria,
                values || [],
                discoveryForm.criterias?.collection || [],
                agency,
                setAlert,
                silently
            );

            setRootCriteriasValidity((curr) => ({
                ...curr,
                [`${rootCriteria.id}`]: isValid,
            }));

            return isValid;
        },
        [
            values,
            discoveryForm,
            agency,
            setAlert,
            currentSlideErrors,
            setRootCriteriasValidity,
        ]
    );

    const goPrevious = useCallback(() => {
        // Reset les erreurs (même si normalement les forms reset chacun leurs erreurs individuellement quand ils sont unmount)
        setCurrentSlideErrors([]);
        setActivePage((curr) => (curr || 0) - 1);
    }, [setActivePage, setCurrentSlideErrors]);
    const goNext = useCallback(
        (criteria: DiscoveryCriteriaResponse) => () => {
            if (!validateSlide(criteria)) {
                return;
            }
            setActivePage((curr) => (curr || 0) + 1);
        },
        [setActivePage, validateSlide]
    );

    const setCriteriaValue = useCallback(
        (newValue: string, discoveryCriteria: DiscoveryCriteriaResponse) => {
            // Si le critère en clone un autre on assigne la valeur au critère cloné.
            const realCriteria = discoveryCriteria.clone || discoveryCriteria;

            setValues((curr) => {
                return arrayMapOrPush(
                    curr || [],
                    {
                        discoveryCriteria: entityToId(realCriteria) || '',
                        value: newValue,
                    },
                    (a, b) =>
                        matchOnId(a.discoveryCriteria, b.discoveryCriteria)
                );
            });
        },
        [setValues]
    );

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const handleSubmitSuccess = useCallback(() => {
        setDisableSubmitButton(false);
        onSuccess?.();
    }, [setDisableSubmitButton, onSuccess]);

    const doSubmit = useSubmitDiscovery(handleSubmitSuccess);
    const submit = useCallback(() => {
        setDisableSubmitButton(true);
        doSubmit(values || [], agency, user, discoveryForm);
    }, [values, doSubmit, agency, user, discoveryForm, setDisableSubmitButton]);

    const filteredRootCriterias = useMemo(() => {
        return (
            discoveryForm.criterias?.collection.filter((criteria) => {
                return Boolean(
                    !criteria.parentCriteria &&
                        shouldShowDiscoveryCriteria(
                            criteria,
                            values || [],
                            discoveryForm.criterias?.collection!,
                            agency
                        )
                );
            }) || []
        );
    }, [discoveryForm, values, agency]);

    // initialisation de rootCriteriasValidity (grace au side effect dans validateSlide)
    const isValidityInitiated = useRef(false);
    useEffect(() => {
        if (!isValidityInitiated.current && filteredRootCriterias.length) {
            isValidityInitiated.current = true;
            const lastCompletedPage = (activePage || 0) - 1;
            // On ne valide que les slides déjà passées sinon l'utilisateur pourrait sauter des slides en avant comme elles sont toutes optionnelles
            // mais ce n'est pas ce qui est souhaité par le client.
            const maxI =
                lastCompletedPage < filteredRootCriterias.length
                    ? lastCompletedPage
                    : filteredRootCriterias.length;
            for (let i = 0; i < maxI; i += 1) {
                if (filteredRootCriterias[i]) {
                    validateSlide(filteredRootCriterias[i], true);
                }
            }
        }
    }, [validateSlide, filteredRootCriterias, activePage]);

    // On re-valide la slide courante en cas d'ouverture du menu
    // uniquement si c'est une slide qui avait déjà été validée.
    const previousIsDrawerOpen = useRef<boolean>(isDrawerOpen);
    useEffect(() => {
        if (
            isDrawerOpen === true &&
            previousIsDrawerOpen.current === false &&
            activePage !== undefined
        ) {
            const rootCriteria = filteredRootCriterias[activePage];
            if (
                rootCriteria &&
                rootCriteriasValidity[`${rootCriteria.id}`] !== undefined
            ) {
                validateSlide(filteredRootCriterias[activePage]);
            }
        }
        previousIsDrawerOpen.current = isDrawerOpen;
    }, [
        isDrawerOpen,
        activePage,
        filteredRootCriterias,
        validateSlide,
        rootCriteriasValidity,
    ]);

    const criteriaSections = useMemo(() => {
        const lastIndex = filteredRootCriterias.length - 1;
        return filteredRootCriterias.map((rootCriteria, index) => {
            const isFirst = index === 0;
            const isLast = index === lastIndex;
            const form: React.ReactNode = (
                <Box
                    className="testpage"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                >
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        px={slidePx}
                    >
                        <DiscoveryCriteriaSection
                            discoveryValues={values || noValues}
                            discoveryCriteria={rootCriteria}
                            agency={agency}
                            allDiscoveryCriterias={
                                discoveryForm.criterias?.collection!
                            }
                            onCriteriaValueChange={setCriteriaValue}
                            addCurrentSlideError={addCurrentSlideError}
                            removeCurrentSlideError={removeCurrentSlideError}
                        />
                    </Box>
                    <NavigationButtonsContainer>
                        <NavigateDiscoveryFormSlideButton
                            direction="left"
                            disabled={isFirst}
                            onClick={goPrevious}
                        />
                        <CommentDiscoverySlideFormDialogButton
                            value={
                                getCriteriaValue(
                                    rootCriteria,
                                    values || noValues
                                )?.value
                            }
                            onSuccess={(comment) => {
                                setCriteriaValue(comment, rootCriteria);
                            }}
                            sx={commentFabSx}
                            id={`commentSlide${entityToId(rootCriteria)}`}
                        />
                        {!isLast && (
                            <NavigateDiscoveryFormSlideButton
                                direction="right"
                                onClick={goNext(rootCriteria)}
                            />
                        )}
                        {isLast && (
                            <SubmitFormButton
                                canSubmit={() => {
                                    return validateSlide(rootCriteria);
                                }}
                                submit={submit}
                                disabled={disableSubmitButton}
                            />
                        )}
                    </NavigationButtonsContainer>
                </Box>
            );

            return form;
        });
    }, [
        filteredRootCriterias,
        discoveryForm,
        agency,
        setCriteriaValue,
        values,
        submit,
        goPrevious,
        goNext,
        disableSubmitButton,
        validateSlide,
        addCurrentSlideError,
        removeCurrentSlideError,
    ]);

    if (!filteredRootCriterias.length) {
        return null;
    }

    return (
        <>
            <DiscoveryDrawer
                isOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                title={`${discoveryForm.label}`}
                criterias={filteredRootCriterias}
                criteriasValidity={rootCriteriasValidity}
                activeStep={activePage || 0}
                goTo={setActivePage}
                color={(discoveryForm.color as ThemeColor) || 'primary'}
            />
            <PagesSlider
                pages={criteriaSections}
                activePage={activePage || 0}
                width="100%"
                height={`calc(100vh - ${headerHeight})`}
            />
        </>
    );
}

export default DiscoveryForm;
