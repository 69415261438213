import VariableType from './VariableType.enum';

/**
 * Les types de variables dont les valeurs sont automatiquements définies.
 * = qui ne sont pas saisies par les agences.
 */
const automaticallyDefinedVariableTypes = [
    VariableType.PROPERTY_FIELD,
    VariableType.AGENCY_FIELD,
    VariableType.USER_FIELD,
];

export default automaticallyDefinedVariableTypes;
