import React from 'react';
import { Button } from '@mui/material';
import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';

interface HeaderItemProps {
    children: React.ReactNode;
    to: To;
    disabled?: boolean | undefined;
}

function HeaderItem({ children, to, disabled }: HeaderItemProps) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Button
            to={to}
            component={Link}
            color="inherit"
            disabled={disabled}
            sx={{
                color: match
                    ? (theme) => theme.palette.text.primary
                    : (theme) => theme.palette.text.secondary,
                textTransform: 'unset',
                fontWeight: match ? 'bold' : 'unset',
                fontSize: '1rem',
                letterSpacing: 0,
            }}
        >
            {children}
        </Button>
    );
}

export default HeaderItem;
