import React from 'react';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import ServicesTable from './ServicesTable';
import useAdditionalServices from '../service/useAdditionalServices';

/**
 * Tableau de service additional de l'agency selectionnée.
 */
function AdditionalServicesTable() {
    const agency = useAgency(useSelectedAgency()[0]);
    const additionalServices = useAdditionalServices(agency);

    if (!additionalServices) {
        return null;
    }

    return <ServicesTable services={additionalServices} />;
}

export default AdditionalServicesTable;
