import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import shouldShowDiscoveryCriteria from './shouldShowDiscoveryCriteria';

function isMissingAValue(
    discoveryCriteria: DiscoveryCriteriaResponse,
    values: DiscoveryValue[] | undefined
): boolean {
    if (discoveryCriteria.valueIsRequired) {
        // Le critère requiert une valeur.

        if (!values?.length) {
            // aucune valeur n'est définie
            return true;
        }

        const discoveryValue = values.find(
            (value) => value.discoveryCriteria === discoveryCriteria.id
        );

        const value = discoveryValue?.value;

        if (
            value === '' ||
            value === null ||
            value === undefined ||
            (Array.isArray(value) && !value.length)
        ) {
            // ce critère n'a pas de valeur
            return true;
        }
    }

    // Pas requis ou a une valeur = ok.
    return false;
}

/**
 * Parcours tous les sous-critères d'un critère (et lui même) et vérifie si ils sont requis si ils ont une valeur.
 * Retourne la liste des critères qui sont requis et qui n'ont pas de valeur ou un tableau vide si tous les critères requis sont complétés.
 */
function findEmptyRequiredSubCriterias(
    discoveryCriteria: DiscoveryCriteriaResponse,
    values: DiscoveryValue[] | undefined,
    allDiscoveryCriterias: DiscoveryCriteriaResponse[],
    agency: AgencyResponse
): DiscoveryCriteriaResponse[] {
    const criteriasWithMissingValue = allDiscoveryCriterias.reduce(
        (res, subCriteria) => {
            if (!matchOnId(subCriteria.parentCriteria, discoveryCriteria)) {
                // Pas un enfant de ce critère donc on l'ignore.
                return res;
            }

            if (
                !shouldShowDiscoveryCriteria(
                    subCriteria,
                    values || [],
                    allDiscoveryCriterias,
                    agency
                )
            ) {
                // Pas affiché donc on l'ignore.
                return res;
            }

            return [
                ...res,
                ...findEmptyRequiredSubCriterias(
                    subCriteria,
                    values,
                    allDiscoveryCriterias,
                    agency
                ),
            ];
        },
        [] as DiscoveryCriteriaResponse[]
    );

    if (isMissingAValue(discoveryCriteria, values)) {
        criteriasWithMissingValue.push(discoveryCriteria);
    }

    return criteriasWithMissingValue;
}

export default findEmptyRequiredSubCriterias;
