import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaRequirementResponse from '@acdc/shared/src/features/discoveryCriteriaRequirement/DiscoveryCriteriaRequirementResponse';
import discoveryCriteriaIsEnabled from './discoveryCriteriaIsEnabled';
import discoveryValueToDiscoveryChoiceIds from '../discoveryValueToDiscoveryChoiceIds';

function shouldDiscoveryRequirementBeIgnored(
    discoveryCriteriaRequirement: DiscoveryCriteriaRequirementResponse,
    discoveryValues: DiscoveryValue[],
    allDiscoveryCriterias: DiscoveryCriteriaResponse[],
    agency: AgencyResponse
): boolean {
    if (!discoveryCriteriaRequirement.expectedValues?.length) {
        // requirement mal configuré
        return true;
    }

    if (!discoveryCriteriaRequirement.expectedCriteria) {
        // expectedCriteria n'est pas retourné par l'api ?
        return true;
    }

    if (
        !discoveryCriteriaIsEnabled(
            discoveryCriteriaRequirement.expectedCriteria,
            agency
        )
    ) {
        // si le critère dont dépend un autre est désactivé par l'agence on ignore le requirement
        return true;
    }

    const expectedCriteria = allDiscoveryCriterias.find((criteria) =>
        matchOnId(criteria, discoveryCriteriaRequirement.expectedCriteria)
    );
    if (!expectedCriteria) {
        // pas normal de tomber là
        return true;
    }

    if (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        !discoveryCriteriaRequirementIsSatisfied(
            expectedCriteria,
            discoveryValues,
            allDiscoveryCriterias,
            agency
        )
    ) {
        // si le critère dont dépend un autre est désactivé par un de ses requirement on ignore le requirement.
        // Note: c'est un peu bizarre comme fonctionnalité mais c'est utilisé:
        // On ne propose une piscine
        // que si l'utilisateur veut un terrain avec son bien. Mais si son bien est lui même un terrain on
        // ne lui demande pas si il veut un terrain avec son terrain. Mais il faut quand même lui propose
        // la piscine (qui a d'autre requirements).
        return true;
    }

    if (!expectedCriteria.parentCriteria) {
        return false;
    }

    let parentId: any = expectedCriteria.parentCriteria;
    while (parentId) {
        const parentOfExpectedCriteria = allDiscoveryCriterias.find(
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            (criteria) => matchOnId(criteria, parentId)
        );
        if (!parentOfExpectedCriteria) {
            // pas normal de tomber là
            return true;
        }
        if (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            !discoveryCriteriaRequirementIsSatisfied(
                parentOfExpectedCriteria,
                discoveryValues,
                allDiscoveryCriterias,
                agency
            )
        ) {
            // si un parent de expectedCriteria est masqué, c'est pareil que si expectedCriteria était masqué.
            return true;
        }

        parentId = parentOfExpectedCriteria.parentCriteria;
    }

    return false;
}

function expectedValueIsSatisfied(value: string, expectedValues: string[]) {
    const values = discoveryValueToDiscoveryChoiceIds(value);

    return expectedValues.some((expectedValue) => {
        return values.includes(expectedValue);
    });
}

/**
 * Check si on doit afficher un DiscoveryCriteria qui ne doit s'afficher que si un certain choix a été
 * sélectionné pour un autre DiscoveryCriteria. (cf DiscoveryCriteriaRequirement)
 */
function discoveryCriteriaRequirementIsSatisfied(
    discoveryCriteria: DiscoveryCriteriaResponse,
    discoveryValues: DiscoveryValue[],
    allDiscoveryCriterias: DiscoveryCriteriaResponse[],
    agency: AgencyResponse
): boolean {
    if (!discoveryCriteria.requiredFor?.collection?.length) {
        // pas de requirements
        return true;
    }

    /**
     * Les requirements qui doivent être évalués sans ceux qu'on ignore.
     */
    const filteredRequirements =
        discoveryCriteria.requiredFor.collection.filter((requirement) => {
            return !shouldDiscoveryRequirementBeIgnored(
                requirement,
                discoveryValues,
                allDiscoveryCriterias,
                agency
            );
        });

    return filteredRequirements.every((requirement) => {
        const value = discoveryValues.find((v) => {
            return matchOnId(v.discoveryCriteria, requirement.expectedCriteria);
        });

        if (!value) {
            return false;
        }

        return expectedValueIsSatisfied(
            value.value,
            requirement.expectedValues || []
        );
    });
}

export default discoveryCriteriaRequirementIsSatisfied;
