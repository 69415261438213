import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const UPDATE_NOTIFICATION_USER = gql`
    mutation UpdateNotificationUser(
        $input: updateIsReadNotificationUserInput!
    ) {
        updateIsReadNotificationUser(input: $input) {
            clientMutationId
        }
    }
`;

const useUpdateReadNotification = () => {
    const [updateNotificationUser] = useMutation(UPDATE_NOTIFICATION_USER, {
        update(cache) {
            cache.evict({ fieldName: 'notificationUsers' });
        },
    });

    return useCallback(
        (values: string[]) => {
            return updateNotificationUser({
                variables: {
                    input: { notificationUsers: values },
                },
            });
        },
        [updateNotificationUser]
    );
};

export default useUpdateReadNotification;
