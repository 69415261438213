import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Grid, Stack, Theme } from '@mui/material';
import QuotationInput from './QuotationInput';
import type QuotationFormValue from './QuotationFormValue';
import AutoPersistQuotation from './AutoPersistQuotation';
import AutoUpdatePackFee from './AutoUpdatePackFee';
import QuotationStack from '../QuotationStack';
import useIsExport from '../../useIsExport';
import PreviewTotalRentalFees from './PreviewTotalRentalFees';
import useOverridedPackage from '../../package/useOverridedPackage';
import useSelectedPackageId from '../../package/useSelectedPackageId';
import usePackage from '../../package/usePackage';
import QuotationActions from '../QuotationActions';
import AdaptTotalRentalFeesPreviewButton, {
    AdaptedField,
    isAdapted,
} from './AdaptTotalRentalFeesPreviewButton';
import FakeLoadingScreenButton from '../../../ui/buttons/FakeLoadingScreenButton';

/**
 * Du style pour le champ qui a été ajusté par le bouton "Adapter les honoraires à 1 mois de loyer"
 */
const adaptedInputProps = {
    color: 'rental',
    focused: true,
};

function QuotationFormInner() {
    const { values, setFieldValue, submitForm } =
        useFormikContext<QuotationFormValue>();
    const isExport = useIsExport();
    const selectedOverridedPackage = useOverridedPackage(
        usePackage(useSelectedPackageId()[0])
    );
    const [adaptedField, setAdaptedField] = useState<AdaptedField | null>(null);

    // clear adaptedField en cas de modification manuelle d'un champ lié.
    useEffect(() => {
        if (adaptedField && !isAdapted(adaptedField, values)) {
            setFieldValue('visitFeeUnrounded', null);
            setAdaptedField(null);
        }
    }, [values, adaptedField, setAdaptedField, setFieldValue]);

    const locationHasValues =
        values.surface !== '' ||
        values.visitFee !== '' ||
        values.initialInventoryFee !== '' ||
        values.biddingAndNegotiation !== '' ||
        values.previewTotalRentalFees !== '';
    const managementHasValues =
        values.packFee !== '' ||
        values.unpaidRentGuarantee !== '' ||
        values.annualNonOccupantOwnerInsurance !== '';
    const shouldShowLocationSection = !isExport || locationHasValues;
    const shouldShowManagementSection = !isExport || managementHasValues;

    return (
        <>
            {!isExport && (
                <>
                    {/* Sauvegarde auto dans le localstorage pour reprendre plus tard */}
                    <AutoPersistQuotation />
                    <AutoUpdatePackFee />
                </>
            )}
            <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
                <Stack direction="column" spacing={2}>
                    <QuotationInput
                        inputId="monthlyRent"
                        name="monthlyRent"
                        label="Loyer mensuel H.C"
                        type="number"
                        unit="€"
                        InputProps={{
                            inputProps: {
                                min: '0',
                                step: '0.01',
                                'data-testid': 'monthlyRentInput',
                            },
                        }}
                        keepBordersOnExport
                    />
                    <QuotationInput
                        inputId="monthlyProvisionsOnCharges"
                        name="monthlyProvisionsOnCharges"
                        label="Provisions sur charges"
                        type="number"
                        unit="€"
                        InputProps={{
                            inputProps: {
                                min: '0',
                                step: '0.01',
                                'data-testid':
                                    'monthlyProvisionsOnChargesInput',
                            },
                        }}
                        keepBordersOnExport
                    />
                </Stack>
                <Box width="100%">
                    {/* cette div est nécessaire sinon le Stack parent remplace le margin left de Grid */}
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {shouldShowLocationSection && (
                                <QuotationStack
                                    color={(theme: Theme) =>
                                        theme.palette.rental.main
                                    }
                                    title="location"
                                >
                                    <>
                                        <QuotationInput
                                            inputId="surface"
                                            name="surface"
                                            label="Surface"
                                            type="number"
                                            unit="m²"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '1',
                                                    'data-testid':
                                                        'surfaceInput',
                                                },
                                            }}
                                        />
                                        <QuotationInput
                                            inputId="visitFee"
                                            name="visitFee"
                                            label="Honoraires dossier et visites"
                                            type="number"
                                            unit="€ / m²"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '0.01',
                                                    'data-testid':
                                                        'visitFeeInput',
                                                },
                                            }}
                                            textFieldProps={
                                                adaptedField?.name ===
                                                'visitFee'
                                                    ? adaptedInputProps
                                                    : undefined
                                            }
                                        />
                                        <QuotationInput
                                            inputId="initialInventoryFee"
                                            name="initialInventoryFee"
                                            label="Honoraires état des lieux"
                                            type="number"
                                            unit="€ / m²"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '0.01',
                                                    'data-testid':
                                                        'initialInventoryFeeInput',
                                                },
                                            }}
                                        />
                                        <QuotationInput
                                            inputId="biddingAndNegotiation"
                                            name="biddingAndNegotiation"
                                            label="Frais d'entremise et négociation"
                                            type="number"
                                            unit="€"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '1',
                                                    'data-testid':
                                                        'biddingAndNegotiationInput',
                                                },
                                            }}
                                            textFieldProps={
                                                adaptedField?.name ===
                                                'biddingAndNegotiation'
                                                    ? adaptedInputProps
                                                    : undefined
                                            }
                                        />
                                        {isExport ? (
                                            <QuotationInput
                                                inputId="previewTotalRentalFees"
                                                name="previewTotalRentalFees"
                                                label="Honoraires prévisionnels"
                                                type="number"
                                                unit="€"
                                                bold
                                            />
                                        ) : (
                                            <PreviewTotalRentalFees
                                                id="previewTotalRentalFees"
                                                name="previewTotalRentalFees"
                                                label="Honoraires prévisionnels"
                                            />
                                        )}
                                    </>
                                </QuotationStack>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {shouldShowManagementSection && (
                                <QuotationStack
                                    color={(theme: Theme) =>
                                        theme.palette.warning.main
                                    }
                                    title="gestion"
                                >
                                    <>
                                        {/* <QuotationInput
                                            inputId="annualWasteTaxe"
                                            name="annualWasteTaxe"
                                            label="Taxe d'ordures ménagères"
                                            type="number"
                                            unit="€ / an"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '0.01',
                                                    'data-testid':
                                                        'annualWasteTaxeInput',
                                                },
                                            }}
                                        /> */}
                                        <QuotationInput
                                            inputId="packFee"
                                            name="packFee"
                                            label={`Honoraires pack ${
                                                selectedOverridedPackage?.label
                                                    ? `${
                                                          isExport
                                                              ? // eslint-disable-next-line max-len
                                                                selectedOverridedPackage?.label.toUpperCase()
                                                              : // eslint-disable-next-line max-len
                                                                selectedOverridedPackage?.label
                                                      } `
                                                    : ''
                                            }T.T.C`}
                                            type="number"
                                            unit="%"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '1',
                                                    'data-testid':
                                                        'packFeeInput',
                                                },
                                            }}
                                        />
                                        <QuotationInput
                                            inputId="unpaidRentGuarantee"
                                            name="unpaidRentGuarantee"
                                            label="Assurance loyers impayés T.T.C"
                                            type="number"
                                            unit="%"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '0.01',
                                                    'data-testid':
                                                        'unpaidRentGuaranteeInput',
                                                },
                                            }}
                                        />
                                        <QuotationInput
                                            inputId="annualNonOccupantOwnerInsurance"
                                            name="annualNonOccupantOwnerInsurance"
                                            label="Assurance PNO T.T.C"
                                            type="number"
                                            unit="€"
                                            InputProps={{
                                                inputProps: {
                                                    min: '0',
                                                    step: '1',
                                                    'data-testid':
                                                        'annualNonOccupantOwnerInsuranceInput',
                                                },
                                            }}
                                        />
                                    </>
                                </QuotationStack>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                {!isExport && (
                    <QuotationActions sx={{ width: '100%' }}>
                        <AdaptTotalRentalFeesPreviewButton
                            setAdaptedField={setAdaptedField}
                        >
                            Adapter les honoraires à 1 mois de loyer
                        </AdaptTotalRentalFeesPreviewButton>
                        <FakeLoadingScreenButton
                            buttonProps={{
                                type: 'button',
                                'data-testid': 'submitQuotationFormButton',
                                color: 'primary',
                            }}
                            loadingDuration={1000}
                            onClick={() => submitForm()}
                        >
                            Voir le résultat
                        </FakeLoadingScreenButton>
                    </QuotationActions>
                )}
            </Stack>
        </>
    );
}

export default QuotationFormInner;
