import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import React from 'react';
import useLoadServices from './useLoadServices';

export const ServicesCtx = React.createContext<ServiceResponse[] | undefined>(
    undefined
);

export interface PackagesProviderProps {
    children?: React.ReactNode;
}

function ServicesProvider({ children }: PackagesProviderProps) {
    const services = useLoadServices();

    return (
        <ServicesCtx.Provider value={services}>{children}</ServicesCtx.Provider>
    );
}

export default ServicesProvider;
