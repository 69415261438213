import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListPresentationFlowsResponse from '@acdc/shared/src/features/presentationFlow/ListPresentationFlowsResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import useChapters from '../chapter/useChapters';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_PRESENTATION_FLOWS = gql`
    query GetPresentationFlows {
        presentationFlows(order: [{ id: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                label
                toolCategory {
                    id
                }
                coverPage {
                    id
                }
            }
        }
    }
`;

/**
 * Retourne toutes les PresentationFlows.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useLoadPresentationFlows = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListPresentationFlowsResponse>(
        GET_PRESENTATION_FLOWS,
        persistentQueryOptions
    );

    const chapters = useChapters();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des déroulés.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<PresentationFlowResponse[] | undefined>(() => {
        if (!chapters) {
            return undefined;
        }

        return data?.presentationFlows?.collection.map((presentationFlow) => ({
            ...presentationFlow,
            chapters: {
                collection: chapters.filter((chapter) =>
                    matchOnId(chapter.presentationFlow, presentationFlow)
                ),
            },
        }));
    }, [data, chapters]);
};

export default useLoadPresentationFlows;
