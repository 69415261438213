import React, { useMemo } from 'react';
import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import useCurrentPresentation from '../presentation/useCurrentPresentation';
import usePresentationFlow from '../presentationFlow/usePresentationFlow';
import useToolCategory from '../toolsCategory/useToolCategory';
import useVisibleTools from './useVisibleTools';
import { getToolOverride } from '../toolOverride/useToolOverride';
import getToolTitle from './getToolTitle';

function getOptionLabel(
    option: string | Pick<ToolResponse, 'id' | 'title'> | null
): string {
    if (!option) {
        return '';
    }

    return typeof option === 'string' ? option : `${option.title}`;
}

function ToolSelect(props: AutocompleteProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const [currentPresentation] = useCurrentPresentation();

    const presentationFlow = usePresentationFlow(
        entityToId(currentPresentation?.presentationFlowId) || ''
    );

    const currentToolCategory = useToolCategory(
        presentationFlow?.toolCategory?.id
    );

    const visibleTools = useVisibleTools({
        toolCategory: currentToolCategory,
        editMode: false,
        agency,
    });
    const options = useMemo(() => {
        if (!visibleTools) {
            return [];
        }

        return visibleTools.map((tool) => {
            const toolOverride = getToolOverride(tool, agency);
            return {
                ...tool,
                title: getToolTitle(tool, toolOverride),
            };
        });
    }, [visibleTools, agency]);

    return (
        <Autocomplete
            {...props}
            options={options}
            disableClearable
            multiple
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={matchOnId}
            noOptionsText="Pas de résultats"
        />
    );
}

export default ToolSelect;
