import { Switch, SwitchProps, SxProps } from '@mui/material';
import React, { useCallback } from 'react';

type ValueType = boolean | null | undefined;

const switchSx: SxProps = {
    '&.MuiSwitch-root': {
        width: 78,
        '&.TriStateSwitch-checked': {
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(0px)',
            },
        },
        '&.TriStateSwitch-unchecked': {
            '& .MuiSwitch-switchBase': {
                transform: 'translateX(40px)',
                color: 'error.main',
            },
            '& .MuiSwitch-track': {
                backgroundColor: 'error.main',
            },
        },
        '&.TriStateSwitch-indeterminate': {
            '& .MuiSwitch-switchBase': {
                transform: 'translateX(20px)',
            },
        },
    },
};

function isIndeterminate(value: ValueType) {
    return value === null || value === undefined;
}

function getClassName(value: ValueType) {
    if (isIndeterminate(value)) {
        return 'TriStateSwitch-indeterminate';
    }
    return value ? 'TriStateSwitch-checked' : 'TriStateSwitch-unchecked';
}

function getNextValue(value: ValueType) {
    if (isIndeterminate(value)) {
        return true;
    }
    if (value === true) {
        return false;
    }
    return null;
}

export interface TriStateSwitchProps
    extends Omit<
        SwitchProps,
        'value' | 'onChange' | 'checked' | 'defaultChecked'
    > {
    value: ValueType;
    onChange: (ev: {
        target: {
            name: string | undefined;
            value: boolean | null;
        };
    }) => void;
}

/**
 * Un switch mais avec un 3ème état possible qui vaut null.
 * Il utilise la prop value et pas checked.
 */
function TriStateSwitch({
    value,
    onChange,
    ...switchProps
}: TriStateSwitchProps) {
    const { name } = switchProps;

    const handleChange = useCallback(() => {
        if (!onChange) {
            return;
        }

        onChange?.({
            target: {
                name,
                value: getNextValue(value),
            },
        });
    }, [onChange, value, name]);

    return (
        <Switch
            color="success"
            {...switchProps}
            checked={value === true}
            sx={switchSx}
            onChange={handleChange}
            className={getClassName(value)}
        />
    );
}

export default TriStateSwitch;
