// see https://stackoverflow.com/a/15604206
function strReplaceAll(str: string, mapping: { [tag: string]: string }) {
    const re = new RegExp(Object.keys(mapping).join('|'), 'gi');

    return str.replace(re, (matched) => {
        return mapping[matched];
    });
}

export default strReplaceAll;
