import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListToolsResponse from '@acdc/shared/src/features/tool/ListToolsResponse';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useToolContents from '../toolContent/useToolContents';

export const GET_TOOLS = gql`
    query GetTools {
        tools(itemsPerPage: 10000) {
            collection {
                id
                title
                required
                canUpdateTitle
                toolCategory {
                    id
                    label
                }
                toolThumbnail {
                    id
                    originalName
                    publicPath
                    size
                }
            }
        }
    }
`;
/**
 * Retourne toutes les outils.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useLoadTools = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListToolsResponse>(
        GET_TOOLS,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des outils.',
                'error'
            );
        }
    }, [error, setAlert]);

    const toolContents = useToolContents();

    return useMemo<ToolResponse[] | undefined>(() => {
        if (!toolContents) {
            return undefined;
        }

        return data?.tools?.collection.map((tool) => ({
            ...tool,
            toolContents: {
                collection: toolContents
                    .filter((toolContent) => matchOnId(toolContent.tool, tool))
                    .sort((a, b) => (a.position || 0) - (b.position || 0)),
            },
        }));
    }, [data, toolContents]);
};

export default useLoadTools;
