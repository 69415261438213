import React from 'react';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../configs';

export const SelectedPackageCtx = React.createContext<
    [string | undefined, SetValue<string>]
>([undefined, () => {}]);

export interface SelectedPackageProviderProps {
    children?: React.ReactNode;
}

function SelectedPackageProvider({ children }: SelectedPackageProviderProps) {
    const state = usePersistentState<string>(CONFIGS.packageStorageKey);

    return (
        <SelectedPackageCtx.Provider value={state}>
            {children}
        </SelectedPackageCtx.Provider>
    );
}

export default SelectedPackageProvider;
