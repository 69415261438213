import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Formik, Form, FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import {
    initNumber,
    isNumberDefined,
} from '@acdc/shared/src/utils/form-helpers';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import QuotationValuesModel from '../QuotationValues.model';
import CONFIGS from '../../../configs';
import quotationFormValueToModel from './quotationFormValueToModel';
import QuotationFormValue from './QuotationFormValue';
import ContentHeading from '../../content/ContentHeading';
import useIsExport from '../../useIsExport';
import QuotationFormInner from './QuotationFormInner';

const quotationFormSchema = Yup.object().shape({
    // au centre
    monthlyRent: Yup.number()
        .label('Le loyer mensuel')
        .min(0, 'La valeur doit être positive.')
        .optional(),
    monthlyProvisionsOnCharges: Yup.number()
        .label('Les provisions sur charges')
        .min(0, 'La valeur doit être positive.')
        .optional(),

    // à gauche
    surface: Yup.number()
        .label('La surface')
        .min(0, 'La valeur doit être positive.')
        .optional(),
    visitFee: Yup.number()
        .label('Les honoraires de visite')
        .min(0, 'La valeur doit être positive.')
        .optional(),
    initialInventoryFee: Yup.number()
        .label("Les honoraires d'état des lieux")
        .min(0, 'La valeur doit être positive.')
        .optional(),
    biddingAndNegotiation: Yup.number()
        .label('Entremise et négociation')
        .min(0, 'La valeur doit être positive.')
        .optional(),
    previewTotalRentalFees: Yup.number()
        .label('Les honoraires prévisionnels')
        .min(0, 'La valeur doit être positive.')
        .optional(),

    // à droite
    annualWasteTaxe: Yup.number()
        .label("Les taxes d'ordures ménagères")
        .min(0, 'La valeur doit être positive.')
        .optional(),
    packFee: Yup.number()
        .label('Les honoraires du pack')
        .min(0, 'La valeur doit être positive.')
        .optional(),
    unpaidRentGuarantee: Yup.number()
        .label("L'assurance loyers impayés")
        .min(0, 'La valeur doit être positive.')
        .optional(),
    annualNonOccupantOwnerInsurance: Yup.number()
        .label("L'assurance P.N.O")
        .min(0, 'La valeur doit être positive.')
        .optional(),
});

const initValue = (
    value: DeepPartial<QuotationValuesModel>
): QuotationFormValue => {
    let { visitFee } = value;
    let visitFeeUnrounded = null;
    if (isNumberDefined(visitFee)) {
        const visitFeeRounded = Math.round(visitFee! * 100) / 100;
        if (visitFee !== visitFeeRounded) {
            visitFeeUnrounded = visitFee!;
            visitFee = visitFeeRounded;
        }
    }
    return {
        monthlyRent: initNumber(value.monthlyRent),
        monthlyProvisionsOnCharges: initNumber(
            value.monthlyProvisionsOnCharges
        ),
        surface: initNumber(value.surface),
        visitFee: initNumber(visitFee),
        visitFeeUnrounded,
        initialInventoryFee: initNumber(value.initialInventoryFee),
        biddingAndNegotiation: initNumber(value.biddingAndNegotiation),
        previewTotalRentalFees: initNumber(value.previewTotalRentalFees),
        annualWasteTaxe: initNumber(value.annualWasteTaxe),
        packFee: initNumber(value.packFee),
        unpaidRentGuarantee: initNumber(value.unpaidRentGuarantee),
        annualNonOccupantOwnerInsurance: initNumber(
            value.annualNonOccupantOwnerInsurance
        ),
    };
};

interface QuotationFormProps {
    onSuccess?: (values: QuotationValuesModel) => void;
    value?: DeepPartial<QuotationValuesModel>;
}

function QuotationForm({ onSuccess, value: inputValue }: QuotationFormProps) {
    const [value, saveValue] = usePersistentState<
        DeepPartial<QuotationValuesModel>
    >(CONFIGS.currentQuotationStorageKey, inputValue);
    const isExport = useIsExport();

    const initialValues: QuotationFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const handleSubmit = useCallback(
        (
            formValues: QuotationFormValue,
            { setSubmitting }: FormikHelpers<QuotationFormValue>
        ) => {
            const modelValue = quotationFormValueToModel(formValues);
            setSubmitting(false);
            saveValue(modelValue);
            onSuccess && onSuccess(modelValue);
        },
        [onSuccess, saveValue]
    );

    return (
        <Box>
            <ContentHeading
                size={isExport ? 'normal' : 'big'}
                mb={isExport ? 4 : undefined}
                mt={isExport ? 0 : undefined}
            >
                <Box component="span" sx={visuallyHidden}>
                    Compléter
                </Box>{' '}
                Votre devis
            </ContentHeading>
            <Formik
                validationSchema={quotationFormSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                <Form>
                    <QuotationFormInner />
                </Form>
            </Formik>
        </Box>
    );
}

export default QuotationForm;
