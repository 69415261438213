import React from 'react';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../configs';

export const SelectedAgencyCtx = React.createContext<
    [string | undefined, SetValue<string>]
>([undefined, () => {}]);

export interface SelectedAgencyProviderProps {
    children?: React.ReactNode;
}

function SelectedAgencyProvider({ children }: SelectedAgencyProviderProps) {
    const state = usePersistentState<string>(
        CONFIGS.userDataStorageKeys.currentAgency
    );

    return (
        <SelectedAgencyCtx.Provider value={state}>
            {children}
        </SelectedAgencyCtx.Provider>
    );
}

export default SelectedAgencyProvider;
