import type Agency from '../agency/Agency.model';
import type DiscoveryForm from '../discoveryForm/DiscoveryForm.model';
import type User from '../user/User.model';
import type DiscoveryValue from './DiscoveryValue';

/**
 * Le délimiteur pour les réponses à choix multiples.
 */
export const DISCOVERY_VALUE_DELIMITER = ';';

export default interface Discovery {
    id: string;
    presenter: string | User | null;
    agency: string | Agency | null;
    form: string | DiscoveryForm;
    values: DiscoveryValue[];
}
