import { useMemo } from 'react';
import QuotationValuesModel from '../QuotationValues.model';
import QuotationRentTotals from '../QuotationRentTotals.model';

const useQuotationRentTotals = (
    value: QuotationValuesModel
): QuotationRentTotals | undefined =>
    useMemo(() => {
        const monthlyRentIncludingCharges =
            value.monthlyRent !== undefined &&
            value.monthlyProvisionsOnCharges !== undefined
                ? value.monthlyRent + value.monthlyProvisionsOnCharges
                : undefined;

        const visitFee =
            value.surface !== undefined && value.visitFee !== undefined
                ? value.surface * value.visitFee
                : undefined;

        const initialInventoryFee =
            value.surface !== undefined &&
            value.initialInventoryFee !== undefined
                ? value.surface * value.initialInventoryFee
                : undefined;

        const { biddingAndNegotiation } = value;

        const totalLocationFee =
            (visitFee || 0) +
            (initialInventoryFee || 0) +
            (biddingAndNegotiation || 0);

        // si on a rien calculé on retourne undefined pour que la section ne soit pas affichée
        if (
            monthlyRentIncludingCharges === undefined &&
            visitFee === undefined &&
            initialInventoryFee === undefined &&
            biddingAndNegotiation === undefined
        ) {
            return undefined;
        }

        return {
            monthlyRentIncludingCharges,
            visitFee,
            initialInventoryFee,
            biddingAndNegotiation,
            totalLocationFee,
        };
    }, [value]);

export default useQuotationRentTotals;
