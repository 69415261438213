import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import React from 'react';
import useLoadPresentationFlows from './useLoadPresentationFlows';

export const PresentationFlowsCtx = React.createContext<
    PresentationFlowResponse[] | undefined
>(undefined);

export interface PresentationFlowsProviderProps {
    children?: React.ReactNode;
}

function PresentationFlowsProvider({
    children,
}: PresentationFlowsProviderProps) {
    const presentationFlows = useLoadPresentationFlows();

    return (
        <PresentationFlowsCtx.Provider value={presentationFlows}>
            {children}
        </PresentationFlowsCtx.Provider>
    );
}

export default PresentationFlowsProvider;
