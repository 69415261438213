import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';

export function getServiceAgency(
    service: string | ServiceResponse,
    agency: AgencyResponse
) {
    return (
        agency.serviceAgencies?.collection.find((sa) => {
            return matchOnId(sa.service, service);
        }) || null
    );
}

function useServiceAgency(
    service: string | ServiceResponse | undefined | null,
    agency: AgencyResponse | undefined | null
) {
    return useMemo(() => {
        if (!service || !agency) {
            return null;
        }
        return getServiceAgency(service, agency);
    }, [service, agency]);
}

export default useServiceAgency;
