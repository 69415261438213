import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import uid from '@acdc/shared/src/utils/uid';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import CONFIGS from '../../configs';
import useSelectedPackageId from '../package/useSelectedPackageId';
import usePresentationFlowProgress from '../presentationFlow/usePresentationFlowProgress';
import useInitPresentationStatistic from '../presentationStatistic/useInitPresentationStatistic';
import useCurrentPresentation from './useCurrentPresentation';

function useStartPresentation() {
    const [, setProgress] = usePresentationFlowProgress();
    const [, setQuotation] = usePersistentState(
        CONFIGS.currentQuotationStorageKey
    );
    const [, setSelectedPackage] = useSelectedPackageId();
    const navigate = useNavigate();
    const [, setCurrentPresentation] = useCurrentPresentation();
    const initPresentationStatistic = useInitPresentationStatistic();

    return useCallback(
        (
            presentationFlow: string | PresentationFlowResponse,
            property: PropertyResponse | null | undefined = null
        ) => {
            const presentationUid = uid();
            const propertyId = entityToId(property);
            setCurrentPresentation({
                uid: presentationUid,
                presentationFlowId: entityToId(presentationFlow)!,
                propertyId: propertyId || undefined,
            });
            setProgress(undefined);
            setQuotation(undefined);
            setSelectedPackage(undefined);
            initPresentationStatistic(
                presentationUid,
                presentationFlow,
                property
            );
            // eslint-disable-next-line no-console
            console.log('Lancement de la présentation uid : ', presentationUid);
            navigate('/presentation');
        },
        [
            navigate,
            setProgress,
            setQuotation,
            setSelectedPackage,
            setCurrentPresentation,
            initPresentationStatistic,
        ]
    );
}

export default useStartPresentation;
