import React from 'react';
import { Box } from '@mui/material';
import PreferencesForm from '../features/preferences/PreferencesForm/PreferencesForm';
import usePreferences from '../features/preferences/usePreferences';

function PreferencesPage() {
    const [preferences] = usePreferences();

    return (
        <Box mt={3}>
            <PreferencesForm value={preferences} />
        </Box>
    );
}

export default PreferencesPage;
