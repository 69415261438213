import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListPackagesResponse from '@acdc/shared/src/features/package/ListPackagesResponse';
import { preloadImage } from '@acdc/shared/src/utils/file-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useApiEntrypoint from '../useApiEntrypoint';

export const GET_PACKAGES = gql`
    query GetPackages {
        packages(itemsPerPage: 10000, order: [{ id: "ASC" }]) {
            collection {
                id
                label
                color
                previous {
                    id
                }
                packageImage {
                    id
                    publicPath
                }
                newLabel
                newColor
                newPackageImage {
                    id
                    publicPath
                }
            }
        }
    }
`;

/**
 * Retourne tous les Packages.
 * Même ceux désactivés dans l'agence de l'utilisateur (mais potentiellement hérités par un des packs de l'agence).
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useLoadPackages = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListPackagesResponse>(
        GET_PACKAGES,
        persistentQueryOptions
    );
    const apiEntrypoint = useApiEntrypoint();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des packs.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo(() => {
        const items = data?.packages?.collection;

        items?.forEach((pack) => {
            if (pack.packageImage?.publicPath) {
                preloadImage(apiEntrypoint + pack.packageImage.publicPath);
            }
            if (pack.newPackageImage?.publicPath) {
                preloadImage(apiEntrypoint + pack.newPackageImage.publicPath);
            }
        });

        return items;
    }, [data, apiEntrypoint]);
};

export default useLoadPackages;
