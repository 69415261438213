import { Box, BoxProps, FormLabel } from '@mui/material';
import React from 'react';

export interface InlineInputLabelProps {
    /**
     * Le champ en fullWidth.
     */
    children: React.ReactNode;
    htmlFor: string;
    label: React.ReactNode;
    required?: boolean;
    /**
     * pour ajouter des boutons d'actions à la suite du label
     */
    labelActions?: React.ReactNode;
    inputWrapperProps?: BoxProps;
}

function InlineInputLabel({
    children,
    htmlFor,
    label,
    required,
    labelActions,
    inputWrapperProps,
}: InlineInputLabelProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: '100%',
            }}
        >
            <Box>
                <FormLabel htmlFor={htmlFor} sx={{ whiteSpace: 'normal' }}>
                    {label}
                    {required ? ' *' : ''}
                </FormLabel>
                {labelActions}
            </Box>
            <Box {...inputWrapperProps}>{children}</Box>
        </Box>
    );
}

export default InlineInputLabel;
