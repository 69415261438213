import React from 'react';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import InlineInputLabel from '../../ui/InlineInputLabel';

export interface ContentOverrideFormInnerProps {
    getContentOverrideId: () => string | null;
    disabled?: boolean | undefined;
}
function ContentOverrideInnerForm({
    getContentOverrideId,
    disabled,
}: ContentOverrideFormInnerProps) {
    useAutoSubmitSingleFieldForm('enabled', getContentOverrideId);
    return (
        <InlineInputLabel
            htmlFor="contentEnabled"
            label="Visibilité de la page"
        >
            <Field
                component={Switch}
                id="contentEnabled"
                name="enabled"
                type="checkbox"
                data-testid="disabledSwitch"
                disabled={disabled || false}
                color="success"
            />
        </InlineInputLabel>
    );
}

export default ContentOverrideInnerForm;
