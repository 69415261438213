import { useMemo } from 'react';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useDiscoveryForms from './useDiscoveryForms';

function useDiscoveryForm(id: string | undefined) {
    const discoveryForms = useDiscoveryForms();

    return useMemo(() => {
        return discoveryForms?.find((el) => matchOnId(id, el));
    }, [id, discoveryForms]);
}

export default useDiscoveryForm;
