import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetQuotationResponse from '@acdc/shared/src/features/quotation/GetQuotationResponse';

export const GET_QUOTATION = gql`
    query GetQuotation($id: ID!) {
        quotation(id: $id) {
            id
            enteredMonthlyRent
            enteredMonthlyProvisionsOnCharges
            surface
            enteredVisitFee
            enteredInitialInventoryFee
            enteredBiddingAndNegotiation
            previewTotalRentalFees
            enteredAnnualWasteTaxe
            pack {
                id
            }
            packFee
            unpaidRentGuarantee
            enteredAnnualNonOccupantOwnerInsurance
            monthlyRentIncludingCharges
            computedVisitFee
            computedInitialInventoryFee
            computedBiddingAndNegotiation
            totalLocationFee
            computedMonthlyRent
            annualRent
            computedMonthlyProvisionsOnCharges
            annualProvisionsOnCharges
            computedAnnualWasteTaxe
            totalMonthlyGross
            totalAnnualGross
            monthlyManagementFee
            annualManagementFee
            monthlyUnpaidRentInsurance
            annualUnpaidRentInsurance
            computedMonthlyNonOccupantOwnerInsurance
            computedAnnualNonOccupantOwnerInsurance
            totalMonthlyFees
            totalAnnualFees
            totalMonthlyNet
            totalAnnualNet
        }
    }
`;

const useLoadQuotation = (id: string | undefined) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<GetQuotationResponse>(GET_QUOTATION, {
        // pas besoin de persistentQueryOptions actuellement comme on l'utilise que dans l'export de devis pdf.
        variables: {
            id,
        },
        skip: !id,
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du devis.',
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.quotation;
};

export default useLoadQuotation;
