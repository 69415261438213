import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import discoveryCriteriaRequirementIsSatisfied from './criteriaRequirementIsSatisfied';
import discoveryCriteriaIsEnabled from './discoveryCriteriaIsEnabled';

/**
 * Check si on doit afficher un DiscoveryCriteria dans le form.
 * Il ne faut pas l'afficher si:
 * - il dépend d'un DiscoveryChoice qui n'a pas été selectionné
 * - il est désactivé par l'agence
 */
function shouldShowDiscoveryCriteria(
    discoveryCriteria: DiscoveryCriteriaResponse,
    discoveryValues: DiscoveryValue[],
    allDiscoveryCriterias: DiscoveryCriteriaResponse[],
    agency: AgencyResponse
): boolean {
    return (
        discoveryCriteriaIsEnabled(discoveryCriteria, agency) &&
        discoveryCriteriaRequirementIsSatisfied(
            discoveryCriteria,
            discoveryValues,
            allDiscoveryCriterias,
            agency
        )
    );
}

export default shouldShowDiscoveryCriteria;
