import { Box } from '@mui/material';
import React from 'react';
import type ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import usePresentationFlowProgress from '../presentationFlow/usePresentationFlowProgress';
import Category from './Category';
import Presentation from '../presentation/Presentation';

export interface LinkItem {
    name: string;
    link: string;
    subMenus: string[] | undefined;
    disabled: boolean;
    content: ContentResponse | undefined;
}

export interface Navigation {
    title: string;
    link: string;
    menus: LinkItem[];
}

interface MenuProps {
    navigation: Navigation[];
    currentState?: string;
    currentPresentation?: Presentation | undefined;
    toggleDrawer?: () => void;
}

function Menu({
    navigation,
    currentState,
    currentPresentation,
    toggleDrawer,
}: MenuProps) {
    const [progress] = usePresentationFlowProgress();

    let stepcomplete = true;

    /*
     * Permet de retourné un chiffre en fonction de l'ancre passé.
     * Mui step utilise un chiffre pour gerer l'affichage entre terminé, en cours et a faire
     * objet.length pour un bloc catégorie fini
     * navPosition pour un bloc qui est en cours
     * -1 pour un bloc pas commencé
     */
    const getSelectedMenuIndex = (menus: LinkItem[]) => {
        const navPosition = menus.findIndex((element: LinkItem) => {
            if (!progress) {
                return false;
            }
            if (element.link === progress) {
                return true;
            }
            if (element.subMenus?.includes(progress)) {
                return true;
            }

            return false;
        });

        // initailisation quand le localStorage est vide
        if (progress === undefined && stepcomplete === true) {
            stepcomplete = false;
            return 0;
        }

        if (navPosition > -1) {
            stepcomplete = false;
            return navPosition;
        }

        if (stepcomplete === false) {
            return -1;
        }

        return menus.length;
    };

    return (
        <Box
            margin="0rem 1rem 0rem 2rem"
            alignItems="stretch"
            display="flex"
            flexWrap="wrap"
            alignContent="space-evenly"
        >
            {navigation.map((item, index: number) => (
                <Category
                    key={item.title}
                    title={item.title}
                    currentNav={getSelectedMenuIndex(item.menus)}
                    nav={item.menus}
                    number={index + 1}
                    currentState={currentState}
                    toggleDrawer={toggleDrawer}
                    currentPresentation={currentPresentation}
                />
            ))}
        </Box>
    );
}

export default Menu;
