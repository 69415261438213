import { useMemo } from 'react';
import QuotationValuesModel from '../QuotationValues.model';
import QuotationManagementTotals from '../QuotationManagementTotals.model';

const useQuotationManagementTotals = (
    value: QuotationValuesModel
): QuotationManagementTotals | undefined =>
    useMemo(() => {
        // todo : gérer la taxe d'ordures ménagères quand on aura le bon calcul du client
        const annualWasteTaxe = undefined;

        const { monthlyRent } = value;

        const annualRent =
            monthlyRent !== undefined ? monthlyRent * 12 : undefined;

        const { monthlyProvisionsOnCharges } = value;

        const annualProvisionsOnCharges =
            monthlyProvisionsOnCharges !== undefined
                ? monthlyProvisionsOnCharges * 12
                : undefined;

        const totalMonthlyGross =
            (monthlyRent || 0) + (monthlyProvisionsOnCharges || 0);

        const totalAnnualGross =
            (annualRent || 0) + (annualProvisionsOnCharges || 0);

        const monthlyManagementFee =
            value.packFee !== undefined
                ? totalMonthlyGross * (value.packFee / 100)
                : undefined;

        const annualManagementFee =
            value.packFee !== undefined
                ? totalAnnualGross * (value.packFee / 100)
                : undefined;

        const monthlyUnpaidRentInsurance =
            value.unpaidRentGuarantee !== undefined
                ? totalMonthlyGross * (value.unpaidRentGuarantee / 100)
                : undefined;

        const annualUnpaidRentInsurance =
            value.unpaidRentGuarantee !== undefined
                ? totalAnnualGross * (value.unpaidRentGuarantee / 100)
                : undefined;

        const monthlyNonOccupantOwnerInsurance =
            value.annualNonOccupantOwnerInsurance !== undefined
                ? value.annualNonOccupantOwnerInsurance / 12
                : undefined;

        const { annualNonOccupantOwnerInsurance } = value;

        const totalMonthlyFees =
            (monthlyManagementFee || 0) +
            (monthlyUnpaidRentInsurance || 0) +
            (monthlyNonOccupantOwnerInsurance || 0);

        const totalAnnualFees =
            (annualManagementFee || 0) +
            (annualUnpaidRentInsurance || 0) +
            (annualNonOccupantOwnerInsurance || 0);

        const totalMonthlyNet = totalMonthlyGross - totalMonthlyFees;

        const totalAnnualNet = totalAnnualGross - totalAnnualFees;

        // si on a rien calculé on retourne undefined pour que la section ne soit pas affichée
        if (
            monthlyRent === undefined &&
            annualRent === undefined &&
            monthlyProvisionsOnCharges === undefined &&
            annualProvisionsOnCharges === undefined &&
            annualWasteTaxe === undefined &&
            monthlyManagementFee === undefined &&
            annualManagementFee === undefined &&
            monthlyUnpaidRentInsurance === undefined &&
            annualUnpaidRentInsurance === undefined &&
            annualNonOccupantOwnerInsurance === undefined &&
            monthlyNonOccupantOwnerInsurance === undefined
        ) {
            return undefined;
        }

        return {
            monthlyRent,
            annualRent,
            monthlyProvisionsOnCharges,
            annualProvisionsOnCharges,
            annualWasteTaxe,
            totalMonthlyGross,
            totalAnnualGross,
            monthlyManagementFee,
            annualManagementFee,
            monthlyUnpaidRentInsurance,
            annualUnpaidRentInsurance,
            annualNonOccupantOwnerInsurance,
            monthlyNonOccupantOwnerInsurance,
            totalMonthlyFees,
            totalAnnualFees,
            totalMonthlyNet,
            totalAnnualNet,
        };
    }, [value]);

export default useQuotationManagementTotals;
