import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';

const useNotificationsUserEditOffline = (
    notificationUsers: NotificationUserResponse[] | undefined,
    queueForDelete: string[],
    queueForUpdate: string[][],
    isOnline: boolean
) => {
    if (!notificationUsers) {
        return null;
    }

    if (isOnline) {
        return notificationUsers;
    }

    const NotificationWithoutDeleteItems = notificationUsers.filter(
        (notificationUser) =>
            !queueForDelete.includes(notificationUser.id || '')
    );

    // Mise a jour des lectures des notification
    const notificationUsersUpdatedWithQueue =
        NotificationWithoutDeleteItems.map((notificationUser) => {
            const findInQueue = queueForUpdate.findIndex((queueItem) =>
                queueItem.includes(notificationUser.id || '')
            );

            const updatedNotificationUser = { ...notificationUser };
            if (findInQueue !== -1) {
                updatedNotificationUser.isRead = true;
            }

            return updatedNotificationUser;
        });

    return notificationUsersUpdatedWithQueue;
};

export default useNotificationsUserEditOffline;
