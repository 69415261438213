import type SettingFileResponse from '@acdc/shared/src/features/settingFile/SettingFileResponse';
import { Box } from '@mui/material';
import React from 'react';
import type ImageOverrideFileResponse from '@acdc/shared/src/features/imageOverrideFile/ImageOverrideFileResponse';
import CONFIGS from '../../configs';

interface PdfAsImagesViewerProps {
    pdfPicture:
        | SettingFileResponse
        | ImageOverrideFileResponse
        | null
        | undefined;
}

function PdfAsImagesViewer({ pdfPicture }: PdfAsImagesViewerProps) {
    return (
        <>
            {pdfPicture?.pdfAsImages?.collection.map((pdfAsImage) => (
                <Box
                    key={pdfAsImage.id}
                    component="img"
                    src={CONFIGS.apiEntrypoint + pdfAsImage.publicPath}
                    alt=""
                    sx={{
                        width: '100%',
                        marginBottom: '1rem',
                        marginTop: '1rem',
                    }}
                />
            ))}
        </>
    );
}

export default PdfAsImagesViewer;
