import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useToolCategories from './useToolCategories';

/**
 * Retourne une rubriques des outils à partir de son id.
 */
function useToolCategory(categoryId: string | undefined) {
    const toolCategories = useToolCategories();

    return useMemo(() => {
        if (!toolCategories) {
            return undefined;
        }

        return toolCategories?.find((el) => matchOnId(el, categoryId));
    }, [toolCategories, categoryId]);
}

export default useToolCategory;
