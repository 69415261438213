import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import React from 'react';
import useLoadToolCategories from './useLoadToolCategories';

export const ToolCategoriesCtx = React.createContext<
    ToolCategoryResponse[] | undefined
>(undefined);

export interface ToolCategoriesProviderProps {
    children?: React.ReactNode;
}

function ToolCategoriesProvider({ children }: ToolCategoriesProviderProps) {
    const ToolCategories = useLoadToolCategories();

    return (
        <ToolCategoriesCtx.Provider value={ToolCategories}>
            {children}
        </ToolCategoriesCtx.Provider>
    );
}

export default ToolCategoriesProvider;
