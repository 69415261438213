import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import SpeechRecognitionButton from '@acdc/shared/src/tools/speech-recognition/SpeechRecognitionButton';
import { filterString, initString } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import { InputAdornment, Stack } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-mui';
import React, { useCallback, useMemo } from 'react';

const submitButtonProps = {
    sx: { width: '100%' },
};

const COMMENT_NAME = 'comment';

const commentDiscoverySlideFormSchema = Yup.object().shape({
    [COMMENT_NAME]: Yup.string().label('Le commentaire').optional(),
});

export interface CommentDiscoverySlideFormValue {
    [COMMENT_NAME]: string;
}

export interface CommentDiscoverySlideFormProps {
    value: string | null | undefined;
    onSuccess: (value: string) => void;
}

function CommentDiscoverySlideForm({
    value,
    onSuccess,
}: CommentDiscoverySlideFormProps) {
    const initialValues = useMemo(() => {
        return {
            [COMMENT_NAME]: initString(value),
        };
    }, [value]);

    const submit = useCallback(
        (
            values: CommentDiscoverySlideFormValue,
            { setSubmitting }: FormikHelpers<CommentDiscoverySlideFormValue>
        ) => {
            onSuccess && onSuccess(filterString(values[COMMENT_NAME]) || '');
            setSubmitting(false);
        },
        [onSuccess]
    );

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={commentDiscoverySlideFormSchema}
        >
            {({ setValues }) => (
                <Form>
                    <Stack spacing={2} mt={2}>
                        <Field
                            name={COMMENT_NAME}
                            label="Commentaire"
                            component={TextField}
                            multiline
                            rows={4}
                            autoComplete="off"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SpeechRecognitionButton
                                            onSpeech={(speech) => {
                                                setValues((curr: any) => {
                                                    // en cas de speech on l'ajoute à la valeur actuelle
                                                    const currVal =
                                                        curr[COMMENT_NAME];
                                                    let newVal = speech;
                                                    if (currVal) {
                                                        newVal = `${currVal} ${newVal}`;
                                                    }
                                                    return {
                                                        ...curr,
                                                        [COMMENT_NAME]: newVal,
                                                    };
                                                });
                                            }}
                                            continuous
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <SubmitButton buttonProps={submitButtonProps} />
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}

export default CommentDiscoverySlideForm;
