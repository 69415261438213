import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import useDeleteService from './useDeleteService';

export interface DeleteServiceButtonProps extends Omit<ButtonProps, 'onClick'> {
    service: string | ServiceResponse;
    onDeleted?: (contentId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DeleteServiceButton({
    service,
    buttonComponent,
    onDeleted,
    ...buttonProps
}: DeleteServiceButtonProps) {
    const [deleteService, isLoading] = useDeleteService(onDeleted);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            {...buttonProps}
            onClick={() => {
                const title =
                    typeof service !== 'string' && service.label
                        ? ` "${service.label}"`
                        : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer le service${title} ?`
                    )
                ) {
                    deleteService(service);
                }
            }}
            disabled={isLoading}
        />
    );
}

export default DeleteServiceButton;
