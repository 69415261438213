import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import React, { useMemo } from 'react';
import ServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/ServiceAgencyResponse';
import numberBetweenWithFewestDecimalPlaces from '@acdc/shared/src/utils/numberBetweenWithFewestDecimalPlaces';
import hasValue from '@acdc/shared/src/utils/hasValue';
import ToggleServiceAgencyForm from './ToggleServiceAgencyForm';
import { getServiceAgency } from './useServiceAgency';
import useServicesOfAgency from '../service/useServicesOfAgency';

/**
 * Retourne une valeur située entre les 2 valeurs données.
 * Cette valeur est choisie en fonction de différents critères pour optimiser au mieux.
 */
function findBestSortOrderBetween(
    smallerSortOrder: number | null,
    biggerSortOrder: number | null
): number | null {
    if (biggerSortOrder === null) {
        // si on a pas de ligne précédente on ne peut pas remonter l'item
        return null;
    }
    if (smallerSortOrder === null) {
        // si on a que 1 item avant on fait juste un -10 pour laisser de la place
        return biggerSortOrder - 10;
    }

    // sinon on se place entre les 2 items précédents en économisant les décimales au maximum
    return numberBetweenWithFewestDecimalPlaces(
        smallerSortOrder,
        biggerSortOrder
    );
}

export interface ServiceAgencyFormsProps {
    agency: AgencyResponse;
}

/**
 * Liste les formulaires des ServiceAgencies existant et à définir
 * d'une Agency pour activer ou non les services dans l'agence.
 * Donc un form pour chaque service commun ou spécifique à cette agency.
 */
function ServiceAgencyForms({ agency }: ServiceAgencyFormsProps) {
    const services = useServicesOfAgency(agency);

    /**
     * Les ServiceAgencies pour cette agence.
     * Les items sont soit vide à l'exception de la propriété ServiceAgency.service,
     * soit ce sont les ServiceAgencies existant avec leur propriété ServiceAgency.service
     * redéfini avec l'objet Service complet.
     */
    const formDatas = useMemo(() => {
        if (!agency || !services) {
            return [];
        }

        let previousOfPreviousSortOrder: number | null = null;
        let previousSortOrder: number | null = null;

        return services.map((service) => {
            const serviceAgency = getServiceAgency(service, agency);

            const sortOrderIfUp = findBestSortOrderBetween(
                previousOfPreviousSortOrder,
                previousSortOrder
            );

            // on prépare les variables pour la prochaine boucle
            previousOfPreviousSortOrder = previousSortOrder;
            const sortOrder = hasValue(serviceAgency?.sortOrder)
                ? serviceAgency?.sortOrder
                : service.sortOrder;
            previousSortOrder = hasValue(sortOrder) ? sortOrder! : 0;

            return {
                serviceAgency: {
                    ...serviceAgency,
                    service,
                } as ServiceAgencyResponse,
                sortOrderIfUp,
            };
        });
    }, [services, agency]);

    return (
        <>
            {formDatas.map(({ serviceAgency, sortOrderIfUp }) => (
                <ToggleServiceAgencyForm
                    key={`${serviceAgency.service?.id}`}
                    value={serviceAgency}
                    service={serviceAgency.service!}
                    agency={agency}
                    sortOrderIfUp={sortOrderIfUp}
                />
            ))}
        </>
    );
}

export default ServiceAgencyForms;
