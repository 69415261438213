import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Theme,
} from '@mui/material';
import React from 'react';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import type UserAgencyResponse from '@acdc/shared/src/features/userAgency/UserAgencyResponse';
import useSelectedAgency from '../agency/useSelectedAgency';
import useUserAgenciesOfAgency from './useUserAgenciesOfAgency';
import UserAgencyRoleForm from './UserAgencyRoleForm';
import TableSkeleton from '../../ui/TableSkeleton';

const tableRowSx = (index: number) => ({
    height: '10px',
    backgroundColor: (theme: Theme) => {
        if (index % 2 === 0) {
            return theme.palette.action.hover;
        }
        return '';
    },
});

const mainSxProps = {
    height: '25rem',
    width: '50rem',
    margin: 3,
};

function CollaboratorsOfAgency() {
    const { data, loading } = useUserAgenciesOfAgency(
        getIdFromIri(useSelectedAgency()[0])
    );

    return (
        <Box sx={mainSxProps}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Prénom
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Nom
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Éditeur
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.userAgencies?.collection.map(
                            (userAgency: UserAgencyResponse, idx: number) => {
                                return userAgency.user ? (
                                    <TableRow
                                        sx={tableRowSx(idx)}
                                        key={userAgency.user.id}
                                        data-testid={userAgency.user.id}
                                    >
                                        <TableCell>
                                            <Typography>
                                                {userAgency.user.firstname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {userAgency.user.lastname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <UserAgencyRoleForm
                                                value={userAgency}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ) : null;
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && <TableSkeleton withHeaderSkeleton={false} />}
        </Box>
    );
}

export default CollaboratorsOfAgency;
