import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import SelectAgencyFormInner from './SelectAgencyFormInner';
import SelectAgencySkeleton from './SelectAgencySkeleton';

/**
 * Formulaire pour changer d'agence.
 * Le form se sauvegarde automatiquement en cas de modification.
 */

export interface SelectAgencyFormValue {
    agency: string;
}
export interface SelectedAgencyFormProps {
    agencies: AgencyResponse[];
    currentAgency: string | undefined;
    onSuccess?: (agency: string) => void;
}

function SelectAgencyForm({
    currentAgency,
    agencies,
    onSuccess,
}: SelectedAgencyFormProps) {
    const initialValues: SelectAgencyFormValue = useMemo(() => {
        return { agency: initString(currentAgency) };
    }, [currentAgency]);

    if (!agencies) {
        return <SelectAgencySkeleton pt={2} />;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                onSuccess && onSuccess(values.agency);
            }}
        >
            <Form>
                <SelectAgencyFormInner agencies={agencies} />
            </Form>
        </Formik>
    );
}

export default SelectAgencyForm;
