import React from 'react';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import InlineInputLabel from '../../../ui/InlineInputLabel';

export interface LabelOverridePackageAgencyFormInnerProps {
    pack: PackageResponse;
    getId: () => string | null;
    saved: boolean;
    labelOverridePlaceholder: string | undefined;
}

const inputWrapperProps = { sx: { width: '180px', minWidth: '180px' } };

function LabelOverridePackageAgencyFormInner({
    pack,
    getId,
    saved,
    labelOverridePlaceholder,
}: LabelOverridePackageAgencyFormInnerProps) {
    const pendingSave = useAutoSubmitSingleFieldForm('labelOverride', getId);
    const isValid: boolean = Boolean(saved && !pendingSave);

    return (
        <InlineInputLabel
            htmlFor={`labelOverrideInput${pack.id}`}
            label="Libellé du pack"
            inputWrapperProps={inputWrapperProps}
        >
            <Field
                component={TextField}
                id={`labelOverrideInput${pack.id}`}
                name="labelOverride"
                size="small"
                disabled={false} // il ne faut pas bloquer le champ pendant une sauvegarde
                focused={isValid ? true : undefined} // pour afficher la couleur
                color={isValid ? 'success' : 'primary'}
                fullWidth
                InputProps={{
                    'data-testid': 'PackageAgencyLabelOverrideInput',
                    placeholder: labelOverridePlaceholder,
                }}
            />
        </InlineInputLabel>
    );
}

export default LabelOverridePackageAgencyFormInner;
