import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogButton from '@acdc/shared/src/ui/DialogButton';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import UserRole from '@acdc/shared/src/features/user/UserRole.enum';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { Link, useLocation } from 'react-router-dom';
import useAgencies from '../features/agency/useAgencies';
import SelectAgencyFormDialogMenuItem from '../features/agency/SelectAgencyFormDialogMenuItem';
import CollaboratorsOfAgency from '../features/userAgency/CollaboratorsOfAgency';
import useEditMode from '../features/template/useEditMode';
import useIsGrantedInAgency from '../security/useIsGrantedInAgency';
import RefreshApiDataButton from '../features/RefreshApiDataButton';
import LogoutButton from './LogoutButton';

export interface UserMenuProps {
    children: React.ReactNode;
}

const menuItemSx = {
    minWidth: 180,
};

interface MenuItemDialogButtonProps {
    onClick: () => void;
    disabled?: boolean | undefined;
    children?: React.ReactNode;
}
function MenuItemDialogButton({
    onClick,
    disabled,
    children,
    ...buttonProps
}: MenuItemDialogButtonProps) {
    return (
        <MenuItem
            component="button"
            data-testid="UserMenuCollab"
            onClick={onClick}
            disabled={disabled}
            {...buttonProps}
        >
            {children}
        </MenuItem>
    );
}

function UserMenu({ children }: UserMenuProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isFranchisee = useIsGranted(SecurityRole.ROLE_FRANCHISEE);
    const isEditor = useIsGrantedInAgency(UserRole.ROLE_EDITOR_AGENCY);
    const [editMode, setEditMode] = useEditMode();
    const open = Boolean(anchorEl);
    const isOnline = useOnlineStatus();

    useEffect(() => {
        if (editMode && !isOnline) {
            setEditMode(false);
        }
    }, [editMode, isOnline, setEditMode]);

    useEffect(() => {
        if (!(isFranchisee || isEditor) && editMode !== false) {
            setEditMode(false);
        }
    }, [isEditor, isFranchisee, setEditMode, editMode]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const editButtonAction = () => {
        if (
            location.pathname === '/presentation' ||
            location.pathname.startsWith('/outils')
        ) {
            // Le scroll sur les pages FullPage ne fonctionnent plus quand on change le mode alors qu'on est dessus.
            // C'est à cause du fait que les pages désactivées par l'agence s'ajoutent quand on passe en édition et s'enlèvent quand on passe en présentation.
            // FullPage semble ne pas aimer ça.
            navigate('/mes-rendez-vous');
        }
        setEditMode(!editMode);
    };
    const agencies = useAgencies();

    return (
        <>
            <Button
                variant="text"
                color="inherit"
                id="UserMenuButton"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    textTransform: 'none',
                }}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {children}
            </Button>
            <Menu
                id="UserMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'UserMenuButton',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem component={Link} to="/" sx={menuItemSx}>
                    Accueil
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="/mon-profil"
                    disabled={!isOnline}
                    sx={menuItemSx}
                >
                    Mon profil
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="/mes-preferences"
                    sx={menuItemSx}
                >
                    Mes préférences
                </MenuItem>
                {isFranchisee && (
                    <DialogButton
                        label="Mes collaborateurs"
                        buttonComponent={MenuItemDialogButton}
                        buttonProps={{
                            variant: 'contained',
                            'data-testid': 'collaboratorDialogButton',
                            disabled: !isOnline,
                            sx: menuItemSx,
                        }}
                        dialogProps={{
                            id: 'collaboratorDialog',
                            mainTitle: "Collaborateurs de l'agence",
                            maxWidth: false,
                            fullWidth: false,
                            sx: { '& .MuiDialogContent-root': { padding: 0 } },
                        }}
                    >
                        {() => <CollaboratorsOfAgency />}
                    </DialogButton>
                )}
                {(isFranchisee || isEditor) && (
                    <MenuItem
                        component="button"
                        data-testid="UserMenuEditMode"
                        onClick={editButtonAction}
                        disabled={!isOnline}
                        sx={menuItemSx}
                    >
                        {editMode ? 'Mode présentation' : 'Mode édition'}
                    </MenuItem>
                )}
                {agencies && Boolean(agencies?.length) && (
                    <SelectAgencyFormDialogMenuItem agencies={agencies}>
                        Changer d&apos;agence
                    </SelectAgencyFormDialogMenuItem>
                )}
                <RefreshApiDataButton component={MenuItem}>
                    Rafraîchir l&apos;application
                </RefreshApiDataButton>
                <LogoutButton component={MenuItem}>Déconnexion</LogoutButton>
            </Menu>
        </>
    );
}

export default UserMenu;
