import React from 'react';
import { RadioGroupProps, fieldToRadioGroup } from 'formik-mui';
import UiButtonPicker, { ButtonPickerOwnProps } from '../ui/ButtonPicker';

export type ButtonPickerProps = ButtonPickerOwnProps & RadioGroupProps;

function ButtonPicker(props: ButtonPickerProps) {
    return (
        <UiButtonPicker
            {...{
                ...props,
                ...fieldToRadioGroup(props),
            }}
        />
    );
}

export default ButtonPicker;
