import React, { useCallback } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';

import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/MutateServicePackageOverrideResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import UpdateServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/UpdateServicePackageOverrideResponse';
import CreateServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/CreateServicePackageOverrideResponse';
import { entityToId, filterNumber } from '@acdc/shared/src/utils/form-helpers';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import ServicePackageOverride from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverride.model';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import type {
    ServicePackageOverridePriceFormProps,
    ServicePackageOverridePriceFormValue,
} from './ServicePackageOverridePriceForm';
import {
    GET_SERVICE_PACKAGE_OVERRIDES,
    SERVICE_PACKAGE_OVERRIDE_FRAGMENT,
} from '../useServicePackageOverrides';
import useSelectedAgency from '../../agency/useSelectedAgency';

export const CREATE_SERVICE_PACKAGE_OVERRIDE = gql`
    ${SERVICE_PACKAGE_OVERRIDE_FRAGMENT}
    mutation CreateServicePackageOverride(
        $input: createServicePackageOverrideInput!
    ) {
        createServicePackageOverride(input: $input) {
            servicePackageOverride {
                ...ServicePackageOverrideFields
            }
        }
    }
`;

export const UPDATE_SERVICE_PACKAGE_OVERRIDE = gql`
    ${SERVICE_PACKAGE_OVERRIDE_FRAGMENT}
    mutation UpdateServicePackageOverride(
        $input: updateServicePackageOverrideInput!
    ) {
        updateServicePackageOverride(input: $input) {
            servicePackageOverride {
                ...ServicePackageOverrideFields
            }
        }
    }
`;

/**
 * Submit une ServicePackageOverride
 */
function doSubmit(
    values: ServicePackageOverridePriceFormValue,
    getId: () => string | null,
    createServicePackageOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateServicePackageOverrideResponse>>,
    updateServicePackageOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateServicePackageOverrideResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ServicePackageOverride>
) {
    return (
        Promise.resolve()
            // préparation du ServicePackageOverride à créer
            .then(() => {
                const input: DeepPartial<ServicePackageOverride> = {
                    ...values,
                    price: filterNumber(values.price),
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                return input;
            })

            // création du ServicePackageOverride
            .then((input) =>
                (
                    (getId()
                        ? updateServicePackageOverride
                        : createServicePackageOverride) as (
                        options: MutationFunctionOptions
                    ) => Promise<
                        FetchResult<MutateServicePackageOverrideResponse>
                    >
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

const useSubmitServicePackageOverridePrice = (
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ServicePackageOverridePriceFormProps['onSuccess'],
    onError: ServicePackageOverridePriceFormProps['onError'],
    fixedProperties: DeepPartial<ServicePackageOverride>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createServicePackageOverride] = useMutation(
        CREATE_SERVICE_PACKAGE_OVERRIDE,
        {
            // ajout du nouveau ServicePackageOverride dans le cache de la requete GET_SERVICE_PACKAGE_OVERRIDES
            update(cache, result) {
                const item = result.data?.createServicePackageOverride
                    ?.servicePackageOverride as StoreObject;
                if (!item) {
                    return;
                }
                cache.updateQuery(
                    {
                        query: GET_SERVICE_PACKAGE_OVERRIDES,
                        broadcast: false,
                        variables: {
                            agencyCode: getIdFromIri(
                                entityToId(agencyVariable)
                            ),
                        },
                    },
                    (data) => {
                        return {
                            servicePackageOverrides: {
                                ...data.servicePackageOverrides,
                                collection: [
                                    ...data.servicePackageOverrides.collection,
                                    item,
                                ],
                            },
                        };
                    }
                );
            },
        }
    );

    const [updateServicePackageOverride] = useMutation(
        UPDATE_SERVICE_PACKAGE_OVERRIDE,
        {
            // pas d'eviction du cache
        }
    );

    return useCallback(
        (
            formValues: ServicePackageOverridePriceFormValue,
            {
                setSubmitting,
                setErrors,
            }: FormikHelpers<ServicePackageOverridePriceFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createServicePackageOverride,
                updateServicePackageOverride,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then(
                    (
                        res: FetchResult<MutateServicePackageOverrideResponse>
                    ) => {
                        const isUpdate = Boolean(getId());
                        const resItem:
                            | ServicePackageOverrideResponse
                            | null
                            | undefined = isUpdate
                            ? res.data?.updateServicePackageOverride
                                  ?.servicePackageOverride
                            : res.data?.createServicePackageOverride
                                  ?.servicePackageOverride;

                        if (!resItem?.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res.data);
                            return;
                        }
                        setId(resItem.id);
                        setSaved(true);
                        onSuccess && onSuccess(resItem);
                    }
                )
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createServicePackageOverride,
            updateServicePackageOverride,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
        ]
    );
};

export default useSubmitServicePackageOverridePrice;
