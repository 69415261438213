import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { Box } from '@mui/material';
import React from 'react';
import hasContentModuleEditableFile from '../contentModule/hasContentModuleEditableFile';
import ImageOverrideForm from './ImageOverrideForm';

export interface ImageOverrideFormsProps {
    content: ContentResponse;
    agency: AgencyResponse;
}

/**
 * Liste de formulaires pour chaque image éditable d'un Content.
 * Avec les valeurs d'une Agency.
 */
function ImageOverrideForms({ content, agency }: ImageOverrideFormsProps) {
    return (
        <Box mt={2}>
            {content.contentModules?.collection.reduce<React.ReactNode[]>(
                (curr, contentModule) => {
                    if (hasContentModuleEditableFile(contentModule)) {
                        curr.push(
                            <ImageOverrideForm
                                key={`${contentModule.id}`}
                                value={agency.imageOverrides?.collection.find(
                                    (imageOverride) =>
                                        matchOnId(
                                            contentModule,
                                            imageOverride.contentModule
                                        )
                                )}
                                agency={agency}
                                contentModule={contentModule}
                            />
                        );
                    }

                    return curr;
                },
                []
            ) || null}
        </Box>
    );
}

export default ImageOverrideForms;
