import React from 'react';
import { Drawer, Box, Typography, Theme } from '@mui/material';

const drawerSx = {
    '& .MuiDrawer-paper': {
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: 'background.default',
        width: '50%',
        transition: (theme: Theme) =>
            `${theme.transitions.create('width', {
                easing: theme.transitions.easing.easeIn,
                duration: theme.transitions.duration.standard,
            })}!important`,
    },
};

const drawerBoxSx = {
    backgroundColor: 'background.default',
    boxShadow: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const titleBox = {
    backgroundColor: 'primary.dark',
    boxShadow: 'none',
    width: '100%',
    height: '40px',
    maxHeight: '40px',
    display: 'flex',
};

interface LeftDrawerProps {
    open: boolean;
    title: React.ReactNode;
    children: React.ReactNode;
    toggleDrawer?: () => void;
}

function LeftDrawer({ open, title, children, toggleDrawer }: LeftDrawerProps) {
    return (
        <Drawer
            id="LeftSideBar"
            anchor="left"
            open={open}
            ModalProps={{ onBackdropClick: toggleDrawer }}
            sx={drawerSx}
        >
            <Box sx={drawerBoxSx}>
                <Box sx={titleBox}>
                    <Typography
                        variant="h4"
                        component="h2"
                        color="white"
                        py={1}
                        ml={2}
                        textTransform="uppercase"
                    >
                        {title}
                    </Typography>
                </Box>
                <Box p={2} flexGrow={1}>
                    {children}
                </Box>
            </Box>
        </Drawer>
    );
}

export default LeftDrawer;
