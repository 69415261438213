import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import React, { useCallback, useMemo } from 'react';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import PresentationStatistic from '@acdc/shared/src/features/presentationStatistic/PresentationStatistic.model';
import arrayMapOrPush from '@acdc/shared/src/utils/arrayMapOrPush';
import CONFIGS from '../../../configs';

export interface PresentationStatisticQueueItem {
    uid: string;
    value: DeepPartial<PresentationStatistic>;
    visitedContentIds: string[];
}

export const PresentationStatisticQueueContext = React.createContext<{
    queue: PresentationStatisticQueueItem[];
    setQueue: SetValue<PresentationStatisticQueueItem[]>;
    setItem: (value: PresentationStatisticQueueItem) => void;
    addVisitedContent: (uid: string, contentId: string) => void;
    updateValue: (
        uid: string,
        newValue: DeepPartial<PresentationStatistic>
    ) => void;
}>({
    queue: [],
    setQueue: () => {},
    setItem: () => {},
    addVisitedContent: () => {},
    updateValue: () => {},
});

export interface PresentationStatisticQueueProviderProps {
    children: React.ReactNode;
}

function PresentationStatisticQueueProvider({
    children,
}: PresentationStatisticQueueProviderProps) {
    const [queue, setQueue] = usePersistentState<
        PresentationStatisticQueueItem[]
    >(CONFIGS.presentationStatisticsKey, []);

    const setItem = useCallback(
        (value: PresentationStatisticQueueItem) => {
            setQueue((curr) => {
                return arrayMapOrPush(
                    curr || [],
                    value,
                    (v, i) => v.uid === i.uid
                );
            });
        },
        [setQueue]
    );

    const updateValue = useCallback(
        (uid: string, newValue: DeepPartial<PresentationStatistic>) => {
            setQueue((currQueue) =>
                currQueue?.map((item) => {
                    if (item.uid === uid) {
                        return {
                            ...item,
                            value: {
                                ...item.value,
                                ...newValue,
                            },
                        };
                    }

                    return item;
                })
            );
        },
        [setQueue]
    );

    const addVisitedContent = useCallback(
        (uid: string, contentId: string) => {
            setQueue((curr) => {
                let replaced = false;
                const newQueue = curr?.map((queueItem) => {
                    if (queueItem?.uid === uid) {
                        replaced = true;
                        return {
                            ...queueItem,
                            visitedContentIds: [
                                ...(queueItem?.visitedContentIds || []),
                                contentId,
                            ],
                        };
                    }
                    return queueItem;
                });

                if (!replaced) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `Erreur lors de l'ajout d'un visitedContent au stats, 
                        sa présentation n'existe pas, uid: ${uid}, 
                        contentId: ${contentId}, queue: `,
                        curr
                    );
                }

                return newQueue;
            });
        },
        [setQueue]
    );

    const contextValue = useMemo(
        () => ({
            queue: queue || [],
            setQueue,
            setItem,
            addVisitedContent,
            updateValue,
        }),
        [queue, setQueue, setItem, addVisitedContent, updateValue]
    );

    return (
        <PresentationStatisticQueueContext.Provider value={contextValue}>
            {children}
        </PresentationStatisticQueueContext.Provider>
    );
}

export default PresentationStatisticQueueProvider;
