import { useContext } from 'react';
import { PresentationFlowsCtx } from './PresentationFlowsProvider';

/**
 * Retourne toutes les PresentationFlows.
 */
function usePresentationFlows() {
    return useContext(PresentationFlowsCtx);
}

export default usePresentationFlows;
