import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
    entityToId,
    initNumber,
    initString,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServicePackageOverride from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverride.model';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import useSubmitServicePackageOverridePrice from './useSubmitServicePackageOverridePrice';
import ServicePackageOverridePriceFormInner from './ServicePackageOverridePriceFormInner';

export interface ServicePackageOverridePriceFormValue {
    price: string | number;
    unit: string;
}

const initValue = (
    servicePackageOverride: ServicePackageOverrideResponse
): ServicePackageOverridePriceFormValue => ({
    price: initNumber(servicePackageOverride.price),
    unit: initString(servicePackageOverride.unit || ServiceUnit.EURO),
});

export const servicePackageOverridePriceFormSchema = Yup.object().shape({
    price: Yup.number().label('Le prix').optional(),
    unit: Yup.string().label("'unité").required(),
});

export type ServicePackageOverridePriceFormProps = {
    value?: ServicePackageOverrideResponse | undefined;
    service: ServiceResponse;
    agency: AgencyResponse;
    pack: PackageResponse;
    onSuccess?: (res: ServicePackageOverrideResponse) => void;
    onError?: (err: ApolloError) => void;
};

function ServicePackageOverridePriceForm({
    value,
    service,
    agency,
    pack,
    onSuccess,
    onError,
}: ServicePackageOverridePriceFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: ServicePackageOverridePriceFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const [saved, setSaved] = useState(
        Boolean(value?.id && (value.price || value.price === 0))
    );

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<ServicePackageOverride>>(
        () => ({
            service: entityToId(service) || undefined,
            agency: entityToId(agency) || undefined,
            package: entityToId(pack) || undefined,
        }),
        [service, agency, pack]
    );

    const submit = useSubmitServicePackageOverridePrice(
        getId,
        setId,
        setSaved,
        servicePackageOverridePriceFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={servicePackageOverridePriceFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <ServicePackageOverridePriceFormInner
                    getId={getId}
                    saved={saved}
                    service={service}
                />
            </Form>
        </Formik>
    );
}

export default ServicePackageOverridePriceForm;
