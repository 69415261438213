import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
// import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import findSettingValueBySettingCode from './findSettingValueBySettingCode';

export interface ModuleSettingCodes {
    [code: string]: SettingValueResponse | undefined;
}

const findSettingValuesByMultipleSettingCodes = (
    codes: ModuleSettingCode[],
    contentModule: ContentModuleResponse
) => {
    return codes.reduce((curr: ModuleSettingCodes, code: ModuleSettingCode) => {
        const settingValue = findSettingValueBySettingCode(code, contentModule);
        if (settingValue) {
            // eslint-disable-next-line no-param-reassign
            curr[code] = settingValue;
        }

        return curr;
    }, {});
};
export default findSettingValuesByMultipleSettingCodes;
