import React from 'react';
import { Box, FormLabel, InputAdornment } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field, useFormikContext } from 'formik';
import LineSeparator from '../../../ui/LineSeparator';
import useIsExport from '../../useIsExport';
import QuotationFormValue from './QuotationFormValue';

const textFieldSx = {
    width: '160px',
    minWidth: '160px',
    '&>.MuiFormHelperText-root': {
        // on repositionne les messages d'erreur en absolute pour ne pas décaler les inputs.
        position: 'absolute',
        top: '34px',
    },
};

interface QuotationInputProps {
    inputId: string;
    label: string;
    InputProps?: any;
    type?: React.HTMLInputTypeAttribute | undefined;
    name: string;
    unit?: string;
    keepBordersOnExport?: boolean;
    bold?: boolean;
    textFieldProps?: any;
}

/**
 * Input aligné avec son label avec une ligne pointillé entre les 2.
 */
function QuotationInput({
    inputId,
    label,
    InputProps,
    type,
    name,
    unit,
    keepBordersOnExport,
    bold,
    textFieldProps,
}: QuotationInputProps) {
    const isExport = useIsExport();
    const { values } = useFormikContext<QuotationFormValue>();
    const keepBordersOnExportSx = keepBordersOnExport
        ? {
              borderRadius: 1,
              border: '1px solid #c4c4c4',
              paddingRight: 2,
              py: 0.5,
              mb: -0.5,
              minWidth: '130px',
              ml: 4,
          }
        : {};
    const formLabelSx = bold ? { fontWeight: 'bold' } : {};
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'flex-end',
                }}
            >
                <FormLabel htmlFor={inputId} sx={formLabelSx}>
                    {label}
                </FormLabel>
                {isExport ? (
                    <Box flex={1} minWidth="9px" />
                ) : (
                    <LineSeparator
                        sx={{
                            flex: 1,
                            minWidth: '9px',
                        }}
                    />
                )}
                {isExport ? (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        fontWeight={bold ? 'bold' : 'normal'}
                        {...keepBordersOnExportSx}
                    >
                        {(values as any)[name] || null}{' '}
                        {unit?.replaceAll(' ', '') || null}
                    </Box>
                ) : (
                    <Field
                        component={TextField}
                        type={type}
                        name={name}
                        size="small"
                        sx={textFieldSx}
                        InputProps={{
                            id: inputId,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {unit}
                                </InputAdornment>
                            ),
                            ...InputProps,
                        }}
                        {...textFieldProps}
                    />
                )}
            </Box>
        </>
    );
}

export default QuotationInput;
