import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

export interface CategorySkeletonProps {
    pt?: any | undefined;
}

function CategorySkeleton({ pt }: CategorySkeletonProps) {
    return (
        <Box className="section" data-testid="CategorySkeleton" pt={pt}>
            <Typography variant="h1" component={Skeleton} width={350} />
            <Skeleton width="50%" />
            <Skeleton width="30%" />
        </Box>
    );
}

export default CategorySkeleton;
