import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListDiscoveryFormsResponse from '@acdc/shared/src/features/discoveryForm/ListDiscoveryFormsResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useLoadDiscoveryCriterias from './useLoadDiscoveryCriterias';

export const GET_DISCOVERY_FORMS = gql`
    query GetDiscoveryForms {
        discoveryForms(itemsPerPage: 10000) {
            collection {
                id
                label
                color
                icon
            }
        }
    }
`;

const useLoadDiscoveryForms = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListDiscoveryFormsResponse>(
        GET_DISCOVERY_FORMS,
        {
            ...persistentQueryOptions,
        }
    );

    const discoveryCriterias = useLoadDiscoveryCriterias(
        agency,
        skipAgencyRequests
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des types de découvertes.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<DiscoveryFormResponse[] | undefined>(() => {
        if (!discoveryCriterias) {
            return undefined;
        }

        return data?.discoveryForms?.collection.map((form) => ({
            ...form,
            criterias: {
                collection: discoveryCriterias.filter((criteria) =>
                    matchOnId(criteria.form, form)
                ),
            },
        }));
    }, [data, discoveryCriterias]);

    return result;
};

export default useLoadDiscoveryForms;
