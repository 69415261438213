import React from 'react';
import { Grid } from '@mui/material';
import PackageTeaserSkeleton from './PackageTeaserSkeleton';

const getItems = (nb: number) => {
    const inputItems = [];

    for (let ind = 0; ind < nb; ind += 1) {
        inputItems.push(
            <Grid item key={ind} sm>
                <PackageTeaserSkeleton />
            </Grid>
        );
    }

    return inputItems;
};

function PackageTeasersListSkeleton() {
    return (
        <Grid container data-testid="PackageTeasersListSkeleton" spacing={4}>
            {getItems(4)}
        </Grid>
    );
}

export default PackageTeasersListSkeleton;
