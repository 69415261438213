import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function AcquereurIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 64 73" data-testid="PresentationIcon">
            <path d="M13.6668 63.1387H12.0251V72.447H13.6668V63.1387Z" />
            <path d="M63.2618 72.4468H61.6202V58.6157C61.6202 54.6347 59.2726 51.023 55.6445 49.406L39.9172 42.396L31.6268 53.0669L23.4595 42.3878L7.64198 49.406C3.99747 51.023 1.64167 54.6429 1.64167 58.6239V72.4386H0V58.6157C0 53.9862 2.73338 49.7835 6.9689 47.9038L22.8849 40.8446C23.5252 40.5656 24.2639 40.7543 24.699 41.3043L31.6432 50.3745L38.6942 41.2961C39.1292 40.7461 39.8762 40.5656 40.5083 40.8611L56.3176 47.912C60.5366 49.7917 63.2618 53.9944 63.2618 58.6157V72.4468Z" />
            <path d="M51.2447 63.1387H49.603V72.447H51.2447V63.1387Z" />
            <path d="M32.4559 51.7207H30.8142V72.4468H32.4559V51.7207Z" />
            <path d="M42.2157 55.6854C42.1172 55.6854 42.0187 55.669 41.912 55.6444H41.8955L31.6269 52.5827L21.35 55.6444C20.9724 55.7511 20.562 55.6444 20.2829 55.3735C20.0038 55.1026 19.8889 54.7168 19.9792 54.3392L22.926 41.1895L24.5267 41.5506L21.7851 53.7975L31.6351 50.8671L41.5015 53.8057L38.8667 41.5506L40.4755 41.2059L43.2992 54.3639C43.373 54.7415 43.2499 55.1272 42.9708 55.3899C42.7492 55.5869 42.4865 55.6854 42.2157 55.6854Z" />
            <path d="M37.5203 66.8901H35.8787V69.5168H37.5203V66.8901Z" />
            <path d="M37.5203 59.6748H35.8787V62.3015H37.5203V59.6748Z" />
            <path d="M31.635 38.9897C26.3324 38.9897 21.6618 34.8609 18.7889 27.6458C17.3771 26.7675 15.957 24.7728 15.957 22.9506C15.957 21.7439 16.3674 20.9806 16.8189 20.5045C16.507 18.6001 16.3428 16.7204 16.3428 14.9228C16.3428 9.57915 17.6972 5.77869 20.488 3.30797C22.9998 1.0835 26.6443 0 31.6268 0C36.6093 0 40.2538 1.0835 42.7655 3.30797C45.5564 5.77869 46.9108 9.57915 46.9108 14.9228C46.9108 16.7615 46.7548 18.6412 46.4347 20.5045C46.8943 20.9724 47.313 21.7357 47.313 22.9506C47.313 24.7728 45.9011 26.7675 44.4811 27.6458C41.6082 34.8609 36.9376 38.9897 31.635 38.9897ZM31.6268 1.64167C27.0629 1.64167 23.7796 2.58563 21.5715 4.53922C19.1583 6.6816 17.9845 10.0799 17.9845 14.9228C17.9845 16.7779 18.1651 18.7151 18.5098 20.6769L18.6001 21.194L18.1651 21.4977C17.7793 21.7604 17.5905 22.2529 17.5905 22.9506C17.5905 24.1982 18.8053 25.8317 19.815 26.3488L20.094 26.4884L20.209 26.7757C22.8192 33.4901 26.9809 37.3398 31.635 37.3398C36.2891 37.3398 40.4508 33.4901 43.061 26.7757L43.176 26.4884L43.455 26.3488C44.4647 25.8317 45.6795 24.1982 45.6795 22.9506C45.6795 21.8999 45.2445 21.6044 45.0967 21.5059L44.6535 21.2022L44.752 20.6769C45.1049 18.7561 45.2773 16.8189 45.2773 14.9228C45.2773 10.0799 44.1035 6.6816 41.6902 4.53922C39.474 2.58563 36.1906 1.64167 31.6268 1.64167Z" />
            <path d="M18.0912 18.4689L16.5891 17.8123C17.4346 15.8669 18.477 14.1842 19.6837 12.8052C21.3992 10.8434 23.3938 9.56285 25.6101 8.99648L26.1518 8.85693L26.4802 9.30839C26.4884 9.32481 27.8099 11.0978 30.847 12.6902C33.6625 14.1677 38.5875 15.7848 46.0243 15.1281L46.172 16.7616C38.2674 17.4593 33.0058 15.6945 29.9852 14.0857C27.5719 12.8052 26.1519 11.4179 25.528 10.7202C21.6537 11.9433 19.3471 15.5796 18.0912 18.4689Z" />
        </SvgIcon>
    );
}

export default AcquereurIcon;
