import { useContext } from 'react';
import { IsExportCtx } from './IsExportProvider';

/**
 * Indique si l'application est lancée pour faire un export PDF ou si c'est pour une utilisation normale.
 */
function useIsExport() {
    return useContext(IsExportCtx);
}

export default useIsExport;
