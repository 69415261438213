import { Palette, Stepper, StepperProps, Theme } from '@mui/material';
import React from 'react';

export interface MenuStepperProps
    extends Omit<StepperProps, 'children' | 'color'> {
    /**
     * Attendu Des Step de Mui dans lesquelles il y a des MenuStep.
     * Malheureusement on n'a pas pu mettre Step directement dans MenuStep sinon le Stepper ne fonctionne plus.
     * Il a besoin que son children direct soit un Step.
     */
    children: React.ReactNode;
    color: string;
}

function MenuStepper({ children, color, sx, ...props }: MenuStepperProps) {
    const colorLight = (theme: Theme) =>
        (theme.palette[color as keyof Palette] as any)?.light || 'black';

    return (
        <Stepper
            orientation="vertical"
            sx={{
                marginTop: '0.5rem',
                '& .Mui-completed.MuiStep-root': {
                    '& .MenuStep-Button': {
                        color: colorLight,
                    },
                    '& .MuiStepLabel-iconContainer .MuiBox-root': {
                        background: colorLight,
                        borderColor: colorLight,
                    },
                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </Stepper>
    );
}

export default MenuStepper;
