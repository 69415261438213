const EXPORT_CONFIGS = {
    paperShadow:
        // l'export pdf ne supporte pas les ombres avec le la transparence
        // donc il faut convertir les couleurs rgba en rgb
        // par défaut pour un papaer avec élévation 1: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)
        // j'ai fait la convertion à vue d'oeil pour que le rendu soit bien sur le PDF.
        '0px 2px 1px -1px rgb(174,174,174),0px 1px 1px 0px rgb(189,189,189),0px 1px 3px 0px rgba(194,194,194)',
};

export default EXPORT_CONFIGS;
