import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/MutateServiceAgencyResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import ServiceAgency from '@acdc/shared/src/features/serviceAgency/ServiceAgency.model';
import UpdateServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/UpdateServiceAgencyResponse';
import CreateServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/CreateServiceAgencyResponse';
import ServiceAgencyResponse from '@acdc/shared/src/features/serviceAgency/ServiceAgencyResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import hasValue from '@acdc/shared/src/utils/hasValue';
import type {
    ToggleServiceAgencyFormProps,
    ToggleServiceAgencyFormValue,
} from './ToggleServiceAgencyForm';
import {
    GET_SERVICE_AGENCIES,
    SERVICE_AGENCY_FRAGMENT,
} from '../useServiceAgencies';
import useSelectedAgency from '../../agency/useSelectedAgency';

export const CREATE_SERVICE_AGENCY = gql`
    ${SERVICE_AGENCY_FRAGMENT}
    mutation CreateServiceAgency($input: createServiceAgencyInput!) {
        createServiceAgency(input: $input) {
            serviceAgency {
                ...ServiceAgencyFields
            }
        }
    }
`;

export const UPDATE_SERVICE_AGENCY = gql`
    ${SERVICE_AGENCY_FRAGMENT}
    mutation UpdateServiceAgency($input: updateServiceAgencyInput!) {
        updateServiceAgency(input: $input) {
            serviceAgency {
                ...ServiceAgencyFields
            }
        }
    }
`;

/**
 * Submit un ServiceAgency
 */
function doSubmit(
    values: ToggleServiceAgencyFormValue,
    getId: () => string | null,
    create: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateServiceAgencyResponse>>,
    update: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateServiceAgencyResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ServiceAgency>
) {
    return (
        Promise.resolve()
            // préparation du ServiceAgency à envoyer
            .then(() => {
                const input: DeepPartial<ServiceAgency> = {
                    disabled: !values.enabled,
                    sortOrder: hasValue(values.sortOrder)
                        ? values.sortOrder
                        : null,
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                return input;
            })

            // création ou mise à jour du ServiceAgency
            .then((input) =>
                (
                    (getId() ? update : create) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutateServiceAgencyResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

const useSubmitToggleServiceAgency = (
    getId: () => string | null,
    setId: (id: string) => void,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ToggleServiceAgencyFormProps['onSuccess'],
    onError: ToggleServiceAgencyFormProps['onError'],
    fixedProperties: DeepPartial<ServiceAgency>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createServiceAgency] = useMutation(CREATE_SERVICE_AGENCY, {
        // ajout du nouveau ServiceAgency dans le cache de la requete GET_SERVICE_AGENCIES
        update(cache, result) {
            const item = result.data?.createServiceAgency
                ?.serviceAgency as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_SERVICE_AGENCIES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        serviceAgencies: {
                            ...data.serviceAgencies,
                            collection: [
                                ...data.serviceAgencies.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [updateServiceAgency] = useMutation(UPDATE_SERVICE_AGENCY, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: ToggleServiceAgencyFormValue,
            {
                setSubmitting,
                setErrors,
            }: FormikHelpers<ToggleServiceAgencyFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createServiceAgency,
                updateServiceAgency,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateServiceAgencyResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: ServiceAgencyResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateServiceAgency?.serviceAgency
                            : res.data?.createServiceAgency?.serviceAgency;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createServiceAgency,
            updateServiceAgency,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            onSuccess,
        ]
    );
};

export default useSubmitToggleServiceAgency;
