import React, { useState } from 'react';
import { Box } from '@mui/material';
import QuotationForm from './QuotationForm';
import QuotationResult from './QuotationResult';
import QuotationValuesModel from './QuotationValues.model';

interface QuotationProps {
    headingId: string;
}

const sectionSx = {
    display: 'flex',
    flexFlow: 'column nowrap',
};

function Quotation({ headingId }: QuotationProps) {
    const [formResult, setFormResult] = useState<
        QuotationValuesModel | undefined
    >();

    return (
        <Box
            className="section"
            data-anchor={`${headingId}-page`} // need to be unique, if it's the same as an existing id bugs will occurs on scroll
            data-testid={headingId}
            sx={sectionSx}
        >
            <Box display={formResult ? 'none' : 'block'}>
                <QuotationForm
                    onSuccess={(values: QuotationValuesModel) => {
                        setFormResult(values);
                    }}
                />
            </Box>
            {formResult && (
                <QuotationResult
                    value={formResult}
                    onBack={() => setFormResult(undefined)}
                />
            )}
        </Box>
    );
}

export default Quotation;
