import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import setIntervalStartingNow from '@acdc/shared/src/utils/setIntervalStartingNow';
import set from 'date-fns/set';
import { useEffect } from 'react';
import useCacheAge from '../apollo/useCacheAge';
import CONFIGS from '../configs';
import useRefreshApiData from './useRefreshApiData';

/**
 * L'interval de temps en ms entre chaque tentative de rafraichissement des données.
 * Le rafraichissement ne se fera que si c'est pertinent.
 */
const TRY_REFRESH_EVERY = 1000 * 60 * 15;

function AutoRefreshApiData() {
    const isOnline = useOnlineStatus();
    const [cacheAge] = useCacheAge();
    const { refreshApiData } = useRefreshApiData();

    useEffect(() => {
        const interval = setIntervalStartingNow(() => {
            if (!isOnline) {
                // hors ligne
                return;
            }

            if (undefined === cacheAge) {
                // cache pas encore initialisé
                // en attente de SetCacheAgeAfterInitialization
                return;
            }

            const refreshAfter = set(new Date(), CONFIGS.forceRefreshApiDataAt);
            if (cacheAge >= refreshAfter) {
                // cache à jour
                return;
            }

            refreshApiData();
        }, TRY_REFRESH_EVERY);

        return () => {
            clearInterval(interval);
        };
    }, [isOnline, cacheAge, refreshApiData]);

    return null;
}

export default AutoRefreshApiData;
