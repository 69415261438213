import React from 'react';
import { Box } from '@mui/material';
import SideBar from './SideBar/SideBar';
import DrawerState from './SideBar/DrawerState';
import LeftSideBar from './LeftSideBar/LeftSideBar';
import LeftDrawerState from './LeftSideBar/LeftDrawerState';

interface LayoutSkeletonProps {
    children: React.ReactNode;
    width: any;
    gutter: any;
    drawerState?: DrawerState;
    toggleDrawer?: () => void;
    leftDrawerState?: LeftDrawerState;
    toggleLeftDrawer?: () => void;
    toggleFullScreen?: () => void;
}

function LayoutSkeleton({
    children,
    gutter,
    width,
    drawerState,
    toggleDrawer,
    leftDrawerState,
    toggleLeftDrawer,
    toggleFullScreen,
}: LayoutSkeletonProps) {
    // D'ou vient le boxSizing: inherit ?
    return (
        <Box sx={{ boxSizing: 'initial' }}>
            {drawerState && toggleDrawer && toggleFullScreen && (
                <SideBar
                    drawerState={drawerState}
                    toggleDrawer={toggleDrawer}
                    toggleFullscreen={toggleFullScreen}
                />
            )}
            {leftDrawerState && toggleLeftDrawer && (
                <LeftSideBar
                    toggleDrawer={toggleLeftDrawer}
                    drawerState={leftDrawerState}
                />
            )}
            <Box
                sx={{
                    pl: gutter,
                    pr: gutter,
                    width,
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

export default LayoutSkeleton;
