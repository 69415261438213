import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import type MutateAgencyResponse from '@acdc/shared/src/features/agency/MutateAgencyResponse';
import { FetchResult, gql, useMutation } from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { useAlert } from '@acdc/shared/src/tools/alert';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import Agency from '@acdc/shared/src/features/agency/Agency.model';
import Yup from '@acdc/shared/src/yup/yupFr';
import type { UseNewPackagesFormValue } from './UseNewPackagesForm';

const UPDATE_AGENCY = gql`
    mutation UpdateAgency($input: updateAgencyInput!) {
        updateAgency(input: $input) {
            agency {
                id
            }
        }
    }
`;

function useSubmitUseNewPackagesForm(
    formSchema: Yup.ObjectSchema<any>,
    agencyId?: string,
    onSuccess?: ((res: AgencyResponse) => void) | undefined
) {
    const isUpdate = Boolean(agencyId);
    if (!isUpdate) {
        throw Error("Création d'une agence non supportée.");
    }

    const [updateAgency] = useMutation(UPDATE_AGENCY, {
        update(cache) {
            cache.evict({ fieldName: 'agencies' });
        },
    });
    const setAlert = useAlert();

    return useCallback(
        (
            values: UseNewPackagesFormValue,
            { setSubmitting, setErrors }: FormikHelpers<UseNewPackagesFormValue>
        ) => {
            const input: DeepPartial<Agency> = {
                useNewPackages: values.useNewPackages,
            };

            if (isUpdate) {
                input.id = agencyId;
            }
            updateAgency({ variables: { input } })
                .finally(() => {
                    setSubmitting(false);
                })
                .then((res: FetchResult<MutateAgencyResponse>) => {
                    const resItem: AgencyResponse | null | undefined = isUpdate
                        ? res.data?.updateAgency?.agency
                        : res.data?.createAgency?.agency;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onSuccess && onSuccess(resItem);
                })
                .catch(handleApolloError(setErrors, setAlert, formSchema));
        },
        [isUpdate, agencyId, updateAgency, setAlert, onSuccess, formSchema]
    );
}

export default useSubmitUseNewPackagesForm;
