import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import LeftDrawerState from './LeftDrawerState';
import ContentOverrideForm from '../../features/contentOverride/ContentOverrideForm';
import useAgency from '../../features/agency/useAgency';
import useSelectedAgency from '../../features/agency/useSelectedAgency';
import ContentVariableValuesForm from '../../features/variableValue/ContentVariableValuesForm';
import getSpecialModuleCode from '../../features/content/getSpecialModuleCode';
import PackagesConfigurator from '../../features/package/PackagesConfigurator';
import ImageOverrideForms from '../../features/imageOverride/ImageOverrideForms';
import LeftDrawer from '../../ui/LeftDrawer';
import useHasContentEditableFiles from '../../features/content/useHasContentEditableFiles';
import CONFIGS from '../../configs';
import PartnersForm from '../../features/contentModule/sharedFiles/PartnersForm';

const sectionHeadingSx = {
    fontWeight: 'bold',
    color: 'primary.dark',
    my: 2,
    textDecoration: 'underline',
    textTransform: 'uppercase',
    fontSize: '1rem',
    whiteSpace: 'normal',
};

interface LeftSideBarProps {
    drawerState: LeftDrawerState;
    toggleDrawer?: () => void;
}

function LeftSideBar({
    drawerState: { state, content },
    toggleDrawer,
}: LeftSideBarProps) {
    const isOnline = useOnlineStatus();
    const open = isOnline ? state : false;
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const contentOverride = useMemo(
        () =>
            selectedAgency?.contentOverrides?.collection.find((override) =>
                matchOnId(content?.id, override.content)
            ),
        [selectedAgency, content]
    );

    const specialModuleCode = useMemo(
        () => getSpecialModuleCode(content),
        [content]
    );

    const hasVariables = useMemo(
        () =>
            content?.contentModules?.collection.some((contentModule) =>
                Boolean(contentModule.variables?.collection.length)
            ),
        [content]
    );

    const hasEditableFiles = useHasContentEditableFiles(content);

    const contentModulePartners = useMemo(() => {
        if (
            !content ||
            !content.contentModules ||
            !content.contentModules.collection
        ) {
            return [];
        }

        return content.contentModules.collection.filter(
            (contentModule) =>
                contentModule.module?.code === ModuleCode.PARTNERS
        );
    }, [content]);

    const hasEditablePartners = contentModulePartners.length > 0;

    const hasCustomisableContents =
        hasVariables || hasEditableFiles || hasEditablePartners;

    return (
        <LeftDrawer
            title={
                <>
                    <Box component="span" fontWeight="normal">
                        Édition :{' '}
                    </Box>
                    {content?.title}
                </>
            }
            open={open}
            toggleDrawer={toggleDrawer}
        >
            {content && selectedAgency && (
                <>
                    <ContentOverrideForm
                        value={contentOverride}
                        agency={selectedAgency}
                        content={content}
                    />
                    {hasCustomisableContents && (
                        <>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={sectionHeadingSx}
                            >
                                Personnaliser les contenus
                            </Typography>
                            {hasVariables && (
                                <ContentVariableValuesForm
                                    content={content}
                                    agency={selectedAgency}
                                />
                            )}
                            {hasEditableFiles && (
                                <ImageOverrideForms
                                    content={content}
                                    agency={selectedAgency}
                                />
                            )}
                            {hasEditablePartners &&
                                contentModulePartners.map(
                                    (contentModulePartner) => (
                                        <PartnersForm
                                            key={contentModulePartner.id}
                                            contentModule={contentModulePartner}
                                            apiEntrypoint={
                                                CONFIGS.apiEntrypoint
                                            }
                                        />
                                    )
                                )}
                        </>
                    )}
                    {specialModuleCode === ModuleCode.PACKAGES && (
                        <PackagesConfigurator agency={selectedAgency} />
                    )}
                </>
            )}
        </LeftDrawer>
    );
}

export default LeftSideBar;
