import React from 'react';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import WysiwygModule from './modules/WysiwygModule';
import ImageModule from './modules/ImageModule';
import PdfModule from './modules/PdfModule';
import PartnersModule from './modules/PartnersModule';

interface DisplayContentModuleProps {
    contentModule?: ContentModuleResponse | undefined;
    scaleFactor?: number;
}

function DisplayContentModule({
    contentModule,
    scaleFactor,
}: DisplayContentModuleProps) {
    switch (contentModule?.module?.code) {
        case ModuleCode.WYSIWYG:
            return (
                <WysiwygModule
                    contentModule={contentModule}
                    scaleFactor={scaleFactor || 1}
                />
            );
        case ModuleCode.IMAGE:
            return <ImageModule contentModule={contentModule} />;
        case ModuleCode.PDF:
            return <PdfModule contentModule={contentModule} />;
        case ModuleCode.PARTNERS:
            return <PartnersModule contentModule={contentModule} />;
        default:
            return null;
    }
}

export default DisplayContentModule;
