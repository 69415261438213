import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListVariableValuesResponse from '@acdc/shared/src/features/variableValue/ListVariableValuesResponse';
import VariableValueResponse from '@acdc/shared/src/features/variableValue/VariableValueResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const VARIABLE_VALUE_FRAGMENT = gql`
    fragment VariableValueFields on VariableValue {
        id
        value
        agency {
            id
        }
        variable {
            id
        }
    }
`;

export const GET_VARIABLE_VALUES = gql`
    ${VARIABLE_VALUE_FRAGMENT}
    query GetVariableValues($agencyCode: String) {
        variableValues(itemsPerPage: 10000, agency_code: $agencyCode) {
            collection {
                ...VariableValueFields
            }
        }
    }
`;

const emptyResult: VariableValueResponse[] = [];

const useVariableValues = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListVariableValuesResponse>(
        GET_VARIABLE_VALUES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des personnalisations de balises par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<VariableValueResponse[] | undefined>(() => {
        return data?.variableValues?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useVariableValues;
