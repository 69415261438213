import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';

const findSettingValueBySettingCode = (
    code: ModuleSettingCode,
    contentModule: ContentModuleResponse
) => {
    return contentModule?.settingValues?.collection?.find(
        (element) => element?.moduleSetting?.code === code
    );
};

export default findSettingValueBySettingCode;
