import DialogButton, {
    DialogButtonProps,
} from '@acdc/shared/src/ui/DialogButton';
import { Box } from '@mui/material';
import React from 'react';
import JoinedButton from './JoinedButton';

const buttonSx = {
    width: '220px',
};

export interface ConfirmButtonProps {
    children: React.ReactNode;
    title: React.ReactNode;
    onConfirm?: () => void;
    onCancel?: () => void;
    description?: React.ReactNode;
    buttonComponent?: DialogButtonProps['buttonComponent'];
    buttonProps?: DialogButtonProps['buttonProps'];

    /**
     * Si canSubmit est défini il doit retourner true pour que la confirmation et le submit soient proposés.
     */
    canSubmit?: DialogButtonProps['validateBeforeOpen'];
}

function ConfirmButton({
    children,
    canSubmit,
    onConfirm,
    onCancel,
    title,
    description,
    buttonComponent,
    buttonProps,
}: ConfirmButtonProps) {
    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                'data-testid': 'SendQuotationFormButton',
                ...buttonProps,
            }}
            dialogProps={{
                id: 'SendQuotationFormButton',
                mainTitle: title,
                maxWidth: 'sm',
                fullWidth: true,
                sxDialogTitle: {
                    backgroundColor: 'white',
                    color: 'primary',
                },
                sxCloseButton: {
                    color: 'primary',
                },
            }}
            validateBeforeOpen={canSubmit}
        >
            {(close) => (
                <Box>
                    {description}
                    <Box display="flex" justifyContent="center" mt={2}>
                        <JoinedButton
                            roundedSide="left"
                            color="error"
                            variant="contained"
                            type="button"
                            onClick={() => {
                                close();
                                onCancel?.();
                            }}
                            sx={buttonSx}
                        >
                            Annuler
                        </JoinedButton>
                        <JoinedButton
                            roundedSide="right"
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={() => {
                                close();
                                onConfirm?.();
                            }}
                            sx={buttonSx}
                        >
                            Confirmer
                        </JoinedButton>
                    </Box>
                </Box>
            )}
        </DialogButton>
    );
}

export default ConfirmButton;
