import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import GetContentResponse from '@acdc/shared/src/features/content/GetContentResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import GetToolContentResponse from '@acdc/shared/src/features/toolContent/GetToolContentResponse';
import useSections from '../section/useSections';
import useContentModules from '../contentModule/useContentModules';
import ContentFieldsFragment from './ContentFieldsFragment';
import populateContentRelationships from './populateContentRelationships';

export const GET_CONTENT = gql`
    ${ContentFieldsFragment}
    query GetContent($id: ID!) {
        content(id: $id) {
            ...ContentFields
        }
    }
`;

export const GET_TOOL_CONTENT = gql`
    query GetToolContent($id: ID!) {
        toolContent(id: $id) {
            id
            title
            required
            position
            tool {
                id
            }
            template {
                id
            }
        }
    }
`;
/**
 * Retourne un content.
 * Sans cache.
 */
const useContent = (
    contentId: string | ContentResponse | ToolResponse,
    type: ContentType | undefined
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<
        GetContentResponse | GetToolContentResponse
    >(type === ContentType.TOOL_CONTENT ? GET_TOOL_CONTENT : GET_CONTENT, {
        variables: {
            id: `${entityToId(contentId)}`,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement de la page.',
                'error'
            );
        }
    }, [error, setAlert]);

    useEffect(() => {
        if (
            data &&
            (type === ContentType.TOOL_CONTENT
                ? (data as GetToolContentResponse)?.toolContent === null
                : (data as GetContentResponse)?.content === null)
        ) {
            setAlert('Page introuvable.', 'error');
        }
    }, [data, setAlert, type]);

    const sections = useSections();
    const contentModules = useContentModules();

    const content =
        type === ContentType.TOOL_CONTENT
            ? (data as GetToolContentResponse)?.toolContent
            : (data as GetContentResponse)?.content;

    return useMemo<ContentResponse | undefined>(() => {
        if (!sections || !contentModules || !content || content === null) {
            return undefined;
        }

        return populateContentRelationships(content, sections, contentModules);
    }, [sections, contentModules, content]);
};

export default useContent;
