import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import DialogButton from '@acdc/shared/src/ui/DialogButton';
import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import MenuItemDialogButton from '../../ui/buttons/MenuItemDialogButton';
import SelectAgencyForm from './SelectAgencyForm';
import useSelectedAgency from './useSelectedAgency';

type SelectAgencyFormDialogMenuItemProps = {
    agencies: AgencyResponse[];
    children: React.ReactNode;
};

function SelectAgencyFormDialogMenuItem({
    agencies,
    children,
}: SelectAgencyFormDialogMenuItemProps) {
    const [selectedAgency, setSelectedAgency] = useSelectedAgency();
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const isOnline = useOnlineStatus();

    return (
        <DialogButton
            label={children}
            buttonComponent={MenuItemDialogButton}
            buttonProps={{
                'data-testid': 'selectAgency',
            }}
            dialogProps={{
                id: 'selectAgency',
                mainTitle: "Changer d'agence",
                maxWidth: false,
                fullWidth: false,
                sx: { '& .MuiDialogContent-root': { padding: 2 } },
            }}
        >
            {(close) => (
                <>
                    {isConsultant && !isOnline ? (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            <AlertTitle>
                                Changement d&apos;agence impossible hors ligne
                            </AlertTitle>
                            Dans un soucis de performance, en tant que
                            consultant, vous devez être connecté à internet pour
                            pouvoir changer d&apos;agence.
                            <br />
                            Cette restriction ne s&apos;applique pas aux
                            collaborateurs.
                        </Alert>
                    ) : (
                        <SelectAgencyForm
                            currentAgency={selectedAgency}
                            onSuccess={(agency: string) => {
                                setSelectedAgency(agency);
                                close();
                            }}
                            agencies={agencies}
                        />
                    )}
                </>
            )}
        </DialogButton>
    );
}

export default SelectAgencyFormDialogMenuItem;
