import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';

export function getToolOverride(
    tool: ToolResponse | string | undefined | null,
    agency: AgencyResponse | undefined
) {
    return agency?.toolOverrides?.collection.find((item) =>
        matchOnId(item.tool, tool)
    );
}

function useToolOverride(
    tool: ToolResponse | string | undefined | null,
    agency: AgencyResponse | undefined
) {
    return useMemo(() => {
        return getToolOverride(tool, agency);
    }, [tool, agency]);
}

export default useToolOverride;
