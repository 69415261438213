import { Box } from '@mui/material';
import React from 'react';

export interface NavigationButtonsContainerProps {
    children?: React.ReactNode;
}

function NavigationButtonsContainer({
    children,
}: NavigationButtonsContainerProps) {
    return (
        <Box display="flex" justifyContent="center" pb={8} pt={2}>
            {children}
        </Box>
    );
}

export default NavigationButtonsContainer;
