import { useCallback } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import Discovery from '@acdc/shared/src/features/discovery/Discovery.model';
import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import { useDiscoveryQueue } from '../DiscoveryQueue';

/**
 * Sauvegarde la découverte pour l'envoyer dés que possible.
 */
const useSubmitDiscovery = (onSuccess: (() => void) | null = null) => {
    const setAlert = useAlert();
    const { addItem: enqueueDiscovery } = useDiscoveryQueue();
    const isOnline = useOnlineStatus();

    return useCallback(
        (
            values: DiscoveryValue[],
            agency: AgencyResponse,
            user: UserResponse,
            discoveryForm: DiscoveryFormResponse
        ) => {
            const discovery: Partial<Discovery> = {
                presenter: entityToId(user),
                agency: entityToId(agency),
                form: entityToId(discoveryForm) || undefined,
                values,
            };

            enqueueDiscovery(discovery);

            if (!isOnline) {
                setAlert(
                    'Votre découverte sera envoyée une fois connecté à internet.',
                    'info'
                );
            }
            onSuccess && onSuccess();
        },
        [setAlert, onSuccess, enqueueDiscovery, isOnline]
    );
};

export default useSubmitDiscovery;
