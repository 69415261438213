import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import React, { useMemo } from 'react';
import DEFAULT_WYSIWYG_STYLE from '@acdc/shared/src/features/settingValue/DEFAULT_WYSIWYG_STYLE';
import { Box } from '@mui/material';
import WysiwygValue from '@acdc/shared/src/ui/WysiwygValue';
import strReplaceAll from '@acdc/shared/src/utils/strReplaceAll';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import VariableValueResponse from '@acdc/shared/src/features/variableValue/VariableValueResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import getProperty from '@acdc/shared/src/utils/getProperty';
import UserField from '@acdc/shared/src/features/user/UserField.enum';
import findSettingValueBySettingCode from '../findSettingValueBySettingCode';
import { normalContentHeadingSx } from '../../content/ContentHeading';
import useAgency from '../../agency/useAgency';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useCurrentPresentation from '../../presentation/useCurrentPresentation';
import useProperty from '../../property/useProperty';
import useAuthenticatedUser from '../../user/useAuthenticatedUser';
import useApiEntrypoint from '../../useApiEntrypoint';

const getVariableTag = (variable: VariableResponse) => `{{${variable.code}}}`;

const getValueForVariable = (
    variable: VariableResponse,
    variableValues: VariableValueResponse[],
    property: PropertyResponse | undefined,
    agency: AgencyResponse | undefined,
    user: UserResponse | undefined,
    apiEntrypoint: string
): string => {
    switch (variable.type) {
        case VariableType.PROPERTY_FIELD:
            if (!variable.propertyField) {
                return '';
            }
            return getProperty(property, variable.propertyField, '') || '';
        case VariableType.AGENCY_FIELD:
            if (!variable.propertyField) {
                return '';
            }
            return getProperty(agency, variable.propertyField, '') || '';
        case VariableType.USER_FIELD: {
            if (!variable.propertyField) {
                return '';
            }

            const val = getProperty(user, variable.propertyField, '');
            if (variable.propertyField === UserField.PHOTO && val) {
                return apiEntrypoint + val;
            }

            return val || '';
        }
        default:
            return (
                variableValues.find((variableValue) =>
                    matchOnId(variableValue.variable, variable)
                )?.value ||
                variable.defaultValue ||
                getVariableTag(variable)
            );
    }
};

const wysiwygSx: any = {
    ...DEFAULT_WYSIWYG_STYLE,
    '& img': {
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'contain',
    },
    '& h1': {
        ...normalContentHeadingSx,
        marginTop: '0.5em',
    },
};

const getResponsiveSx = (value: string, scaleFactor: number): any => {
    let responsiveSx: any = {};

    // On adapte les tailles des éléments qui ont les attributs width et height fixés en pixels.
    // Avec tinymce du BO ça concerne les images et les iframes de vidéos.
    // Par exemple si on a <img width="50" height="100" /> et un scaleFactor de 1.1 on ajoute le style:
    // *[width="50"]: { width: 55px !important; }; *[height="100"]: { height: 110px !important; };
    // La taille de l'élément sera adaptée à la taille du template (indquée par scaleFactor).
    const matches = value.match(/(width)="\d+"/g);
    if (matches) {
        responsiveSx = matches.reduce((curr, match) => {
            // le selector css pour cibler les éléments concernés par ce match
            const selector = `& *[${match}]`;
            if (curr[selector]) {
                // on a déjà croisé un match identique
                return curr;
            }

            // width ou height
            const attribut = match.substring(0, match.indexOf('='));

            // la taille définie dans width="55" (donc 55) par exemple
            const fixedSize = parseInt(
                match.substring(
                    attribut.length + 2, // l'attribut plus l'égal et le guillemet ouvrant
                    match.length - 1 // la fin moins le guillemet fermant
                ),
                10
            );

            // la nouvelle taille en px
            const responsiveSize = Math.round(fixedSize * scaleFactor);

            return {
                ...curr,
                [selector]: {
                    [attribut]: `${responsiveSize}px !important`,
                },
            };
        }, responsiveSx);
    }

    return {
        ...wysiwygSx,
        ...responsiveSx,
    };
};

export function replaceVariables(
    value: string,
    variables: VariableResponse[],
    variableValues: VariableValueResponse[],
    property: PropertyResponse | undefined,
    agency: AgencyResponse | undefined,
    user: UserResponse | undefined,
    apiEntrypoint: string
): string {
    if (!variables.length) {
        return value;
    }

    const mapping = variables.reduce(
        (curr: { [variable: string]: string }, v) => {
            let val = getValueForVariable(
                v,
                variableValues,
                property,
                agency,
                user,
                apiEntrypoint
            );

            if (v.type === VariableType.TEXT && v.isTextMulti) {
                val = `<span style="white-space: pre-line">${val}</span>`;
            }

            // eslint-disable-next-line no-param-reassign
            curr[getVariableTag(v)] = val;

            return curr;
        },
        {}
    );

    return strReplaceAll(value, mapping);
}

export interface WysiwygModuleProps {
    contentModule: ContentModuleResponse;
    /**
     * Si des éléments du wysiwyg ne sont pas nativement responsive il faut indiquer
     * avec ce scaleFactor le scalling à leur imposer pour les adapter à l'écran.
     */
    scaleFactor: number;
}

function WysiwygModule({ contentModule, scaleFactor }: WysiwygModuleProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const user = useAuthenticatedUser();
    const [currentPresentation] = useCurrentPresentation();
    const currentProperty = useProperty(currentPresentation?.propertyId);
    const apiEntrypoint = useApiEntrypoint();
    const value = useMemo<string>(() => {
        const contentModuleValue = findSettingValueBySettingCode(
            ModuleSettingCode.WYSIWYG_VALUE,
            contentModule
        )?.value;

        if (!contentModuleValue) {
            return '';
        }

        return replaceVariables(
            contentModuleValue,
            contentModule.variables?.collection || [],
            agency?.variableValues?.collection || [],
            currentProperty,
            agency,
            user,
            apiEntrypoint
        );
    }, [contentModule, currentProperty, agency, user, apiEntrypoint]);

    const responsiveSx = useMemo(() => {
        return getResponsiveSx(value, scaleFactor);
    }, [value, scaleFactor]);

    return (
        <Box overflow="hidden" maxHeight="100%" className="WysiwygModule">
            <WysiwygValue value={value} sx={responsiveSx} />
        </Box>
    );
}

export default WysiwygModule;
