import React from 'react';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import InlineInputLabel from '../../../ui/InlineInputLabel';
import { OverridedPackage } from '../../package/useOverridedPackage';

export interface TogglePackageAgencyFormInnerProps {
    getId: () => string | null;
    pack: PackageResponse;
    overridedPackage: OverridedPackage | null;
}

function TogglePackageAgencyFormInner({
    getId,
    pack,
    overridedPackage,
}: TogglePackageAgencyFormInnerProps) {
    useAutoSubmitSingleFieldForm('enabled', getId);

    return (
        <InlineInputLabel
            htmlFor={`enabledSwitch${pack.id}`}
            label={`Pack «${overridedPackage?.label}»`}
        >
            <Field
                component={Switch}
                type="checkbox"
                name="enabled"
                id={`enabledSwitch${pack.id}`}
                data-testid="TogglePackageAgency"
                disabled={false}
                color="success"
            />
        </InlineInputLabel>
    );
}

export default TogglePackageAgencyFormInner;
