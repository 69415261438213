import React, { useEffect, useState } from 'react';

export const OnlineStatusContext = React.createContext(true);

export interface OnlineStatusProviderProps {
    children: React.ReactNode;
}

function OnlineStatusProvider({ children }: OnlineStatusProviderProps) {
    const [onlineStatus, setOnlineStatus] = useState<boolean>(navigator.onLine);

    useEffect(() => {
        window.addEventListener('offline', () => {
            setOnlineStatus(false);
        });
        window.addEventListener('online', () => {
            setOnlineStatus(true);
        });

        return () => {
            window.removeEventListener('offline', () => {
                setOnlineStatus(false);
            });
            window.removeEventListener('online', () => {
                setOnlineStatus(true);
            });
        };
    }, []);

    return (
        <OnlineStatusContext.Provider value={onlineStatus}>
            {children}
        </OnlineStatusContext.Provider>
    );
}

export default OnlineStatusProvider;
