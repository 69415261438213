import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListChapterContentsResponse from '@acdc/shared/src/features/chapterContent/ListChapterContentsResponse';
import ChapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';
import useSections from '../section/useSections';
import useContentModules from '../contentModule/useContentModules';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import ContentFieldsFragment from '../content/ContentFieldsFragment';
import populateContentRelationships from '../content/populateContentRelationships';

export const GET_CHAPTER_CONTENTS = gql`
    ${ContentFieldsFragment}
    query GetChapterContents {
        chapterContents(order: [{ position: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                chapter {
                    id
                }
                content {
                    ...ContentFields
                }
            }
        }
    }
`;

/**
 * Retourne tous les ChapterContents.
 */
const useChapterContents = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListChapterContentsResponse>(
        GET_CHAPTER_CONTENTS,
        persistentQueryOptions
    );

    const sections = useSections();
    const contentModules = useContentModules();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des pages.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<ChapterContentResponse[] | undefined>(() => {
        if (!sections || !contentModules) {
            return undefined;
        }

        return data?.chapterContents?.collection.map((chapterContent) => ({
            ...chapterContent,
            content: chapterContent.content
                ? populateContentRelationships(
                      chapterContent.content,
                      sections,
                      contentModules
                  )
                : undefined,
        }));
    }, [data, sections, contentModules]);
};

export default useChapterContents;
