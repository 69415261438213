import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import { ModuleSettingCodes } from '../findImageOverrideByMultipleSettingCodes';

const hasSharedFileOrImageOverrideFile = (
    imageOverride: ModuleSettingCodes | undefined,
    settingsCode: ModuleSettingCode[] | undefined
) => {
    if (!settingsCode || !imageOverride) {
        return undefined;
    }
    return settingsCode.some(
        (settingCode) =>
            imageOverride[settingCode]?.sharedFile?.publicPath ||
            imageOverride[settingCode]?.imageOverrideFile?.publicPath
    );
};

export default hasSharedFileOrImageOverrideFile;
