import ButtonPicker, {
    ButtonPickerProps,
} from '@acdc/shared/src/formik/ButtonPicker';
import { Field, FieldProps } from 'formik';
import React, { useMemo } from 'react';

function getOptionValue(option: any) {
    return `${option}`;
}

export interface ButtonPickerNumberProps
    extends Omit<
        FieldProps<ButtonPickerProps>,
        'options' | 'getOptionValue' | 'getOptionLabel' | 'component'
    > {
    max: number;
}

function ButtonPickerNumber({ max, ...props }: ButtonPickerNumberProps) {
    const options = useMemo(() => {
        const nums: string[] = [];
        for (let i = 1; i < max; i += 1) {
            nums.push(`${i}`);
        }
        nums.push('+');
        return nums;
    }, [max]);

    return (
        <Field
            component={ButtonPicker}
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionValue}
            row
            {...props}
        />
    );
}

export default ButtonPickerNumber;
