import ButtonPicker, {
    ButtonPickerProps,
} from '@acdc/shared/src/formik/ButtonPicker';
import { Field, FieldProps } from 'formik';
import React from 'react';

const options = [
    {
        value: 'true', // si on met un bool ici ça le transforme en string
        label: 'Oui',
    },
    {
        value: 'false',
        label: 'Non',
    },
];

function getOptionValue(option: any) {
    return option.value;
}

function getOptionLabel(option: any) {
    return option.label;
}

export interface ButtonPickerBoolProps
    extends Omit<
        FieldProps<ButtonPickerProps>,
        'options' | 'getOptionValue' | 'getOptionLabel' | 'component'
    > {}

function ButtonPickerBool(props: ButtonPickerBoolProps) {
    return (
        <Field
            component={ButtonPicker}
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            row
            {...props}
        />
    );
}

export default ButtonPickerBool;
