import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import usePresentationFlows from './usePresentationFlows';

function usePresentationFlow(presentationFlowId: string | undefined) {
    const presentationFlows = usePresentationFlows();

    return useMemo(() => {
        if (!presentationFlowId) {
            return undefined;
        }

        return presentationFlows?.find((el) =>
            matchOnId(el, presentationFlowId)
        );
    }, [presentationFlows, presentationFlowId]);
}

/**
 * Retourne un PresentationFlow à partir de son id.
 */
export default usePresentationFlow;
