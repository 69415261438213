import { useContext } from 'react';
import { PropertiesCtx } from './PropertiesProvider';

/**
 * Retourne toutes les Properties accessibles à l'utilisateur.
 */
function useProperties() {
    return useContext(PropertiesCtx);
}

export default useProperties;
