import Yup from '@acdc/shared/src/yup/yupFr';

const userFormSchema = Yup.object().shape({
    lastname: Yup.string().label('Nom').optional(),
    firstname: Yup.string().label('Prénom').optional(),
    email: Yup.string().label('Email').optional(),
    phone: Yup.string().label('Téléphone').optional(),
    secondaryEmail: Yup.string().label('Email agence').optional(),
    profession: Yup.string().label('Métier').optional(),
    comment: Yup.string().label('Commentaire libre').optional(),
    photo: Yup.array().label('La photo').optional(),
});

export default userFormSchema;
