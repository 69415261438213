import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import isToolEnabled from './isToolEnabled';

export interface Filters {
    toolCategory?: ToolCategoryResponse | string | undefined;
    editMode?: boolean | undefined;
    agency?: AgencyResponse | undefined;
}

const filterVisibleTools = (
    allTools: ToolResponse[] | undefined,
    filters: Filters | null = null
) => {
    return allTools?.filter(
        (t) =>
            (filters?.editMode || isToolEnabled(t, filters?.agency)) &&
            matchOnId(t.toolCategory, filters?.toolCategory)
    );
};
export default filterVisibleTools;
