import Discovery from '@acdc/shared/src/features/discovery/Discovery.model';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import React, { useCallback, useMemo } from 'react';
import { SetValue } from '@acdc/shared/src/tools/pesistant-state/usePersistentState';
import CONFIGS from '../../../configs';

export const DiscoveryQueueContext = React.createContext<{
    queue: DeepPartial<Discovery>[];
    setQueue: SetValue<DeepPartial<Discovery>[]>;
    addItem: (value: DeepPartial<Discovery>) => void;
}>({ queue: [], setQueue: () => {}, addItem: () => {} });

export interface DiscoveryQueueProviderProps {
    children: React.ReactNode;
}

function DiscoveryQueueProvider({ children }: DiscoveryQueueProviderProps) {
    const [queue, setQueue] = usePersistentState<DeepPartial<Discovery>[]>(
        CONFIGS.discoveryQueueKey,
        []
    );

    const addItem = useCallback(
        (value: DeepPartial<Discovery>) => {
            setQueue((curr) => [...(curr || []), value]);
        },
        [setQueue]
    );

    const contextValue = useMemo(
        () => ({
            queue: queue || [],
            setQueue,
            addItem,
        }),
        [queue, setQueue, addItem]
    );

    return (
        <DiscoveryQueueContext.Provider value={contextValue}>
            {children}
        </DiscoveryQueueContext.Provider>
    );
}

export default DiscoveryQueueProvider;
