import { Button, Stack, SvgIconProps, Typography } from '@mui/material';
import React from 'react';
import { Link, To } from 'react-router-dom';

const iconSx = { fontSize: '90px' };
const buttonSx = { width: '100%' };

export interface AppModuleCardProps {
    label: string;
    icon: React.FunctionComponent<SvgIconProps>;
    to: To;
}

function AppModuleCard({ label, icon: Icon, to }: AppModuleCardProps) {
    return (
        <Stack
            direction="column"
            spacing={2}
            border={1}
            borderColor="primary.main"
            borderRadius={0.5}
            paddingX={3}
            paddingY={4}
            alignItems="center"
        >
            <Icon sx={iconSx} color="primary" />
            <Typography variant="h4" component="h2">
                {label}
            </Typography>
            <Button
                component={Link}
                to={to}
                variant="contained"
                color="primary"
                sx={buttonSx}
            >
                Accéder au module
            </Button>
        </Stack>
    );
}

export default AppModuleCard;
