import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListSettingValuesResponse from '@acdc/shared/src/features/settingValue/ListSettingValuesResponse';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import { preloadImage } from '@acdc/shared/src/utils/file-helpers';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import CONFIGS from '../../configs';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import usePdfAsImages from '../pdfAsImage/usePdfAsImages';

export const GET_SETTING_VALUES = gql`
    query GetSettingValues {
        settingValues(itemsPerPage: 10000) {
            collection {
                id
                value
                contentModule {
                    id
                }
                moduleSetting {
                    id
                    label
                    code
                }
                file {
                    id
                    publicPath
                    mimeType
                    needPostProcess
                }
                sharedFile {
                    id
                    publicPath
                    title
                }
            }
        }
    }
`;

/**
 * Retourne tous les SettingValues.
 */
const useSettingValues = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListSettingValuesResponse>(
        GET_SETTING_VALUES,
        persistentQueryOptions
    );

    const pdfAsImages = usePdfAsImages('settingFile');

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des configurations des pages.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<SettingValueResponse[] | undefined>(() => {
        if (!pdfAsImages) {
            return undefined;
        }

        const items = data?.settingValues?.collection;

        items?.forEach((settingValue) => {
            if (
                settingValue.file?.mimeType !== 'application/pdf' &&
                settingValue.file?.publicPath
            ) {
                preloadImage(
                    CONFIGS.apiEntrypoint + settingValue.file.publicPath
                );
            }
        });

        return items?.map((settingValue) => ({
            ...settingValue,
            file: {
                ...settingValue.file,
                pdfAsImages: {
                    collection: pdfAsImages.filter((pdfAsImage) =>
                        matchOnId(pdfAsImage.settingFile, settingValue.file)
                    ),
                },
            },
        }));
    }, [data, pdfAsImages]);
};

export default useSettingValues;
