import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface LineSeparatorProps {
    sx?: SxProps<Theme>;
}

/**
 * Une ligne en pointillé utilisée comme séparateur vertical entre des éléments.
 * à utiliser dans un container flexible (display flex).
 */
function LineSeparator({ sx }: LineSeparatorProps) {
    return (
        <Box
            data-testid="line-separator"
            sx={{
                borderBottom: '1px dashed',
                // même couleur que la bordure des inputs MUI
                // https://github.com/mui/material-ui/blob/4e833ecd6b5c71825f7bd4d9cdb8ff0ef69fc848/packages/mui-material/src/OutlinedInput/OutlinedInput.js#L42
                borderColor: 'rgba(0, 0, 0, 0.23)',
                mb: '0.4rem',
                mx: 2,
                alignSelf: 'flex-end',
                ...sx,
            }}
        />
    );
}

export default LineSeparator;
