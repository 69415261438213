import { useMemo } from 'react';
import filterVisibleTools, { Filters } from './filterVisibleTools';
import useTools from './useTools';

const useVisibleTools = ({ toolCategory, editMode, agency }: Filters) => {
    const allTools = useTools();
    return useMemo(
        () => filterVisibleTools(allTools, { toolCategory, editMode, agency }),
        [allTools, toolCategory, editMode, agency]
    );
};

export default useVisibleTools;
