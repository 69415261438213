import React from 'react';
import { Box } from '@mui/material';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import LeftDrawer from '../../ui/LeftDrawer';
import ToolOverrideForm from '../toolOverride/ToolOverrideForm';
import useToolOverride from '../toolOverride/useToolOverride';
import getToolTitle from './getToolTitle';

interface EditToolSideBarProps {
    tool: ToolResponse;
    toggleDrawer?: () => void;
    open: boolean;
}

function EditToolSideBar({ tool, toggleDrawer, open }: EditToolSideBarProps) {
    const isOnline = useOnlineStatus();
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const toolOverride = useToolOverride(tool, selectedAgency);

    return (
        <LeftDrawer
            title={
                <>
                    <Box component="span" fontWeight="normal">
                        Édition :{' '}
                    </Box>
                    {getToolTitle(tool, toolOverride)}
                </>
            }
            open={isOnline ? open : false}
            toggleDrawer={toggleDrawer}
        >
            {tool && selectedAgency && (
                <>
                    <ToolOverrideForm
                        value={toolOverride}
                        agency={selectedAgency}
                        tool={tool}
                    />
                </>
            )}
        </LeftDrawer>
    );
}

export default EditToolSideBar;
