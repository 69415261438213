import { Skeleton } from '@mui/material';
import React from 'react';
import { TOOL_CARD_IMAGE_SIZE } from './ToolCard';

function ToolCardSkeleton() {
    return (
        <>
            <Skeleton
                variant="circular"
                height={TOOL_CARD_IMAGE_SIZE}
                width={TOOL_CARD_IMAGE_SIZE}
            />
            <Skeleton
                data-testid="ToolCardSkeleton"
                width={TOOL_CARD_IMAGE_SIZE}
            />
        </>
    );
}

export default ToolCardSkeleton;
