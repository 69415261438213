import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useAgencies from './useAgencies';

/**
 * Retourne une Agency à partir de son id.
 */
function useAgency(agencyId: string | undefined) {
    const agencies = useAgencies();

    return useMemo(() => {
        if (!agencyId) {
            return undefined;
        }

        return agencies?.find((el) => matchOnId(el, agencyId));
    }, [agencies, agencyId]);
}

export default useAgency;
