import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListDiscoveryChoicesResponse from '@acdc/shared/src/features/discoveryChoice/ListDiscoveryChoicesResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import DiscoveryChoiceResponse from '@acdc/shared/src/features/discoveryChoice/DiscoveryChoiceResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

const discoveryChoiceFragment = gql`
    fragment DiscoveryChoiceFields on DiscoveryChoice {
        id
        label
        displayWidth
        agency {
            id
        }
        criteria {
            id
        }
    }
`;

export const GET_DISCOVERY_CHOICES_OF_AGENCY = gql`
    ${discoveryChoiceFragment}
    query GetDiscoveryChoicesOfAgency(
        $exists: [DiscoveryChoiceFilter_exists]
        $agencyCode: String
    ) {
        discoveryChoices(
            exists: $exists
            agency_code: $agencyCode
            itemsPerPage: 100000
        ) {
            collection {
                ...DiscoveryChoiceFields
            }
        }
    }
`;

export const GET_DISCOVERY_CHOICES_FOR_ALL = gql`
    ${discoveryChoiceFragment}
    query GetDiscoveryChoicesForAll($exists: [DiscoveryChoiceFilter_exists]) {
        discoveryChoices(exists: $exists, itemsPerPage: 100000) {
            collection {
                ...DiscoveryChoiceFields
            }
        }
    }
`;

/**
 *
 * @param onlyNoAgency Si true ne charge que les DiscoveryChoices avec agency=null sinon que ceux avec agency != null.
 * @param agency
 * @param skipAgencyRequests
 * @returns
 */
const useLoadDiscoveryChoices = (
    onlyNoAgency: boolean,
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListDiscoveryChoicesResponse>(
        onlyNoAgency
            ? GET_DISCOVERY_CHOICES_FOR_ALL
            : GET_DISCOVERY_CHOICES_OF_AGENCY,
        {
            ...persistentQueryOptions,
            variables: {
                exists: [{ agency: !onlyNoAgency }],
                ...(!onlyNoAgency
                    ? { agency_code: getIdFromIri(entityToId(agency)) }
                    : undefined),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des choix des critères de découvertes.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<DiscoveryChoiceResponse[] | undefined>(() => {
        return data?.discoveryChoices?.collection;
    }, [data]);

    return result;
};

export default useLoadDiscoveryChoices;
