import React from 'react';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import useLoadAuthenticatedUser from './useLoadAuthenticatedUser';

export const AuthenticatedUserCtx = React.createContext<
    UserResponse | undefined
>(undefined);

export interface AuthenticatedUserProviderProps {
    children?: React.ReactNode;
}

function AuthenticatedUserProvider({
    children,
}: AuthenticatedUserProviderProps) {
    const authenticatedUser = useLoadAuthenticatedUser();

    return (
        <AuthenticatedUserCtx.Provider value={authenticatedUser}>
            {children}
        </AuthenticatedUserCtx.Provider>
    );
}

export default AuthenticatedUserProvider;
