import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

function getCriteriaValue(
    discoveryCriteria: DiscoveryCriteriaResponse,
    discoveryValues: DiscoveryValue[]
): DiscoveryValue | null {
    // Si notre critère en clone un autre il faut qu'on trouve la valeur du critère cloné.
    const criteriaToFind = discoveryCriteria.clone || discoveryCriteria;

    return (
        discoveryValues.find((v) =>
            matchOnId(v.discoveryCriteria, criteriaToFind)
        ) || null
    );
}

export default getCriteriaValue;
