import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { displayCurrency } from '@acdc/shared/src/utils/display-helpers';
import LineSeparator from '../../../ui/LineSeparator';
import useIsExport from '../../useIsExport';

interface QuotationResultRowProps {
    label: React.ReactNode;
    monthly?: number;
    annual?: number;

    /**
     * Faut il mettre la ligne en gras ?
     */
    boldValues?: boolean;
    variant: 'values' | 'total';

    /**
     * Des props à faire passer au container de la ligne.
     */
    containerProps?: any;

    /**
     * La couleur pour les variants qui l'utilisent.
     */
    color?: any;
}

function QuotationResultRow({
    label,
    monthly,
    annual,
    boldValues,
    variant,
    containerProps,
    color,
}: QuotationResultRowProps) {
    const isExport = useIsExport();
    const containerStyle = useMemo(() => {
        return variant === 'total'
            ? {
                  backgroundColor: `${color || 'secondary'}.main`,
                  color: `${color || 'secondary'}.contrastText`,
                  py: isExport ? 0.5 : undefined,
              }
            : undefined;
    }, [variant, color, isExport]);
    const valuesStyle = useMemo(() => {
        return {
            color: 'common.black',
            ...(variant === 'total' && {
                color: 'secondary.contrastText',
            }),
            ...(boldValues === true && {
                fontWeight: 'bold',
            }),
        };
    }, [boldValues, variant]);
    const labelStyle = useMemo(() => {
        return {
            color: 'common.black',
            ...(variant === 'total' && {
                textTransform: 'uppercase',
                color: 'secondary.contrastText',
            }),
            ...(boldValues === true && {
                fontWeight: 'bold',
            }),
        };
    }, [boldValues, variant]);
    const separator = isExport ? (
        <Box flex={1} />
    ) : (
        <LineSeparator sx={{ flex: 1 }} />
    );

    return (
        <Box
            {...containerProps}
            sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                mx: -2,
                px: 2,
                ...containerStyle,
                ...containerProps?.sx,
            }}
        >
            <Typography
                variant="body1"
                sx={labelStyle}
                data-testid="quotation-label"
            >
                {label}
            </Typography>
            {monthly !== undefined && (
                <Box sx={{ flex: 1, display: 'flex' }}>
                    {variant === 'total' ? <Box sx={{ flex: 1 }} /> : separator}
                    <Typography
                        variant="body1"
                        sx={valuesStyle}
                        data-testid="quotation-monthly-value"
                    >
                        {displayCurrency(monthly, isExport)}
                    </Typography>
                </Box>
            )}
            {annual !== undefined && (
                <Box
                    sx={{
                        minWidth: '140px',
                        display: 'flex',
                        ...(monthly === undefined && { flex: 1 }),
                    }}
                >
                    {variant === 'total' ? <Box sx={{ flex: 1 }} /> : separator}
                    <Typography
                        variant="body1"
                        sx={valuesStyle}
                        data-testid="quotation-annual-value"
                    >
                        {displayCurrency(annual, isExport)}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default QuotationResultRow;
