import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React, { useMemo } from 'react';
import RatePackageAgencyForm from './RatePackageAgencyForm';
import LabelOverridePackageAgencyForm from './LabelOverridePackageAgencyForm';
import usePackageAgenciesOf from './usePackageAgenciesOf';

export interface AgencyCustomizePackageAgencyFormProps {
    agency: AgencyResponse;
    pack: PackageResponse;
}

function AgencyCustomizePackageAgencyForm({
    agency,
    pack,
}: AgencyCustomizePackageAgencyFormProps) {
    const packageAgencies = usePackageAgenciesOf(agency);
    const packageAgency = useMemo(
        () => packageAgencies?.find((pa) => matchOnId(pa.package, pack)),
        [packageAgencies, pack]
    );

    if (!packageAgencies) {
        return null;
    }

    return (
        <>
            <RatePackageAgencyForm
                value={packageAgency}
                pack={pack}
                agency={agency}
            />
            <LabelOverridePackageAgencyForm
                value={packageAgency}
                pack={pack}
                agency={agency}
            />
        </>
    );
}

export default AgencyCustomizePackageAgencyForm;
