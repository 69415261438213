import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import CONFIGS from '../../../configs';
import QuotationFormValue from './QuotationFormValue';
import quotationFormValueToModel from './quotationFormValueToModel';

function AutoPersistQuotation() {
    const { values } = useFormikContext<QuotationFormValue>();
    const [, persistQuotation] = usePersistentState(
        CONFIGS.currentQuotationStorageKey
    );

    useEffect(() => {
        persistQuotation(quotationFormValueToModel(values));
    }, [values, persistQuotation]);

    return null;
}

export default AutoPersistQuotation;
