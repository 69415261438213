import React from 'react';
import { Box, Skeleton } from '@mui/material';

const getSkeletons = (nb: number) => {
    const skeletons: React.ReactNode[] = [];

    for (let i = 0; i < nb; i += 1) {
        skeletons.push(
            <Skeleton
                key={i}
                variant="rectangular"
                width="100%"
                height="40px"
                data-testid={`tableSkeletonItem${i}`}
            />
        );
    }

    return skeletons;
};

const containerSx = {
    display: 'flex',
    flexFlow: 'row wrap',
    '& .MuiSkeleton-root:not(:last-of-type)': {
        marginBottom: 1,
    },
    marginTop: '10px',
};

interface TableSkeletonProps {
    withHeaderSkeleton: boolean;
}

function tableSkeleton({ withHeaderSkeleton }: TableSkeletonProps) {
    return (
        <Box>
            {withHeaderSkeleton && (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="80px"
                    data-testid="tableHeadSkeletonItem"
                />
            )}

            <Box sx={containerSx}>{getSkeletons(5)}</Box>
        </Box>
    );
}

export default tableSkeleton;
