import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { useMemo } from 'react';
import serviceIsEnabled from './serviceIsEnabled';
import serviceHasPriceInSomeEnabledPackage from './serviceHasPriceInSomeEnabledPackage';
import serviceIsDisabledInSomeEnabledPackage from './serviceIsDisabledInSomeEnabledPackage';
import useServicesOfAgency from './useServicesOfAgency';

/**
 * Un service est initial si il est activé dans l'agence
 * et qu'il n'a aucun prix défini dans les packs actifs
 * et qu'il n'est désactivé dans aucun pack actif spécifiquement.
 */
function useInitialServices(agency: AgencyResponse | undefined) {
    const services = useServicesOfAgency(agency);
    return useMemo(() => {
        if (!agency || !services) {
            return [];
        }
        const initialServices = services.filter((service) => {
            if (!serviceIsEnabled(service, agency)) {
                return false;
            }

            if (serviceIsDisabledInSomeEnabledPackage(service, agency)) {
                return false;
            }

            return !serviceHasPriceInSomeEnabledPackage(service, agency);
        });

        return initialServices;
    }, [services, agency]);
}

export default useInitialServices;
