import React from 'react';
import HeaderSkeleton from './HeaderSkeleton';
import NotificationDialog from '../features/notification/NotificationDialog';

export interface DefaultHeaderProps {
    width: any;
    gutter: any;
    children?: React.ReactNode;
}

function DefaultHeader({ width, gutter, children }: DefaultHeaderProps) {
    return (
        <HeaderSkeleton width={width} gutter={gutter}>
            <NotificationDialog />
            {children}
        </HeaderSkeleton>
    );
}

export default DefaultHeader;
