import { useContext } from 'react';
import { ToolsCtx } from './ToolsProvider';

/**
 * Retourne toutes les outils.
 */
function useTools() {
    return useContext(ToolsCtx);
}

export default useTools;
