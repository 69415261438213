import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListDiscoveryCriteriasResponse from '@acdc/shared/src/features/discoveryCriteria/ListDiscoveryCriteriasResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useLoadDiscoveryChoices from './useLoadDiscoveryChoices';
import useLoadDiscoveryCriteriaRequirements from './useLoadDiscoveryCriteriaRequirements';

export const GET_DISCOVERY_CRITERIAS = gql`
    query GetDiscoveryCriterias {
        discoveryCriterias(order: [{ position: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                label
                type
                inputOptions
                displayWidth
                position
                valueIsRequired
                mandatory
                showInEditionForm
                form {
                    id
                }
                parentCriteria {
                    id
                }
                clone {
                    id
                }
                hint
            }
        }
    }
`;

const useLoadDiscoveryCriterias = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListDiscoveryCriteriasResponse>(
        GET_DISCOVERY_CRITERIAS,
        {
            ...persistentQueryOptions,
        }
    );

    const discoveryChoicesForAll = useLoadDiscoveryChoices(true);
    const discoveryChoicesForAgency = useLoadDiscoveryChoices(
        false,
        agency,
        skipAgencyRequests
    );
    const discoveryCriteriaRequirements =
        useLoadDiscoveryCriteriaRequirements();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des critères de découvertes.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<DiscoveryCriteriaResponse[] | undefined>(() => {
        if (
            !discoveryChoicesForAll ||
            !discoveryChoicesForAgency ||
            !discoveryCriteriaRequirements
        ) {
            return undefined;
        }

        return data?.discoveryCriterias?.collection.map((criteria) => ({
            ...criteria,
            choices: {
                collection: [
                    ...discoveryChoicesForAll.filter((choice) =>
                        matchOnId(choice.criteria, criteria)
                    ),
                    ...discoveryChoicesForAgency.filter((choice) =>
                        matchOnId(choice.criteria, criteria)
                    ),
                ],
            },
            requiredFor: {
                collection: discoveryCriteriaRequirements.filter(
                    (requirement) => matchOnId(requirement.criteria, criteria)
                ),
            },
        }));
    }, [
        data,
        discoveryChoicesForAll,
        discoveryChoicesForAgency,
        discoveryCriteriaRequirements,
    ]);

    return result;
};

export default useLoadDiscoveryCriterias;
