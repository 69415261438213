import QuotationResponse from '@acdc/shared/src/features/quotation/QuotationResponse';
import QuotationValues from './QuotationValues.model';
import QuotationRentTotals from './QuotationRentTotals.model';
import QuotationManagementTotals from './QuotationManagementTotals.model';

/**
 * En clé ce sont TOUTES les propriétés de QuotationValues et en valeur
 * les propriétés de QuotationResponse.
 */
export const QUOTATION_VALUES_MAPPING: {
    [P in keyof QuotationValues]-?: keyof QuotationResponse;
} = {
    monthlyRent: 'enteredMonthlyRent',
    monthlyProvisionsOnCharges: 'enteredMonthlyProvisionsOnCharges',
    surface: 'surface',
    visitFee: 'enteredVisitFee',
    initialInventoryFee: 'enteredInitialInventoryFee',
    biddingAndNegotiation: 'enteredBiddingAndNegotiation',
    annualWasteTaxe: 'enteredAnnualWasteTaxe',
    previewTotalRentalFees: 'previewTotalRentalFees',
    packFee: 'packFee',
    unpaidRentGuarantee: 'unpaidRentGuarantee',
    annualNonOccupantOwnerInsurance: 'enteredAnnualNonOccupantOwnerInsurance',
};

export const QUOTATION_VALUES_MAPPING_ENTRIES = Object.entries(
    QUOTATION_VALUES_MAPPING
);

/**
 * En clé ce sont TOUTES les propriétés de QuotationRentTotals et en valeur
 * les propriétés de QuotationResponse.
 */
export const QUOTATION_RENT_TOTALS_MAPPING: {
    [P in keyof QuotationRentTotals]-?: keyof QuotationResponse;
} = {
    monthlyRentIncludingCharges: 'monthlyRentIncludingCharges',
    visitFee: 'computedVisitFee',
    initialInventoryFee: 'computedInitialInventoryFee',
    biddingAndNegotiation: 'computedBiddingAndNegotiation',
    totalLocationFee: 'totalLocationFee',
};

export const QUOTATION_RENT_TOTALS_MAPPING_ENTRIES = Object.entries(
    QUOTATION_RENT_TOTALS_MAPPING
);

/**
 * En clé ce sont TOUTES les propriétés de QuotationManagementTotals et en valeur
 * les propriétés de QuotationResponse.
 */
export const QUOTATION_MANAGEMENT_TOTALS_MAPPING: {
    [P in keyof QuotationManagementTotals]-?: keyof QuotationResponse;
} = {
    monthlyRent: 'computedMonthlyRent',
    annualRent: 'annualRent',
    monthlyProvisionsOnCharges: 'computedMonthlyProvisionsOnCharges',
    annualProvisionsOnCharges: 'annualProvisionsOnCharges',
    annualWasteTaxe: 'computedAnnualWasteTaxe',
    totalMonthlyGross: 'totalMonthlyGross',
    totalAnnualGross: 'totalAnnualGross',
    monthlyManagementFee: 'monthlyManagementFee',
    annualManagementFee: 'annualManagementFee',
    monthlyUnpaidRentInsurance: 'monthlyUnpaidRentInsurance',
    annualUnpaidRentInsurance: 'annualUnpaidRentInsurance',
    monthlyNonOccupantOwnerInsurance:
        'computedMonthlyNonOccupantOwnerInsurance',
    annualNonOccupantOwnerInsurance: 'computedAnnualNonOccupantOwnerInsurance',
    totalMonthlyFees: 'totalMonthlyFees',
    totalAnnualFees: 'totalAnnualFees',
    totalMonthlyNet: 'totalMonthlyNet',
    totalAnnualNet: 'totalAnnualNet',
};

export const QUOTATION_MANAGEMENT_TOTALS_MAPPING_ENTRIES = Object.entries(
    QUOTATION_MANAGEMENT_TOTALS_MAPPING
);

/**
 * Transforme une réponse de devis en données utilisables pour le formulaire
 * de devis et le composant d'affichage des résultats.
 */
export function quotationResponseToQuotationUiData(
    quotation: QuotationResponse
): {
    formValues: Partial<QuotationValues>;
    rentTotals: Partial<QuotationRentTotals>;
    managementTotals: Partial<QuotationManagementTotals>;
} {
    return Object.keys(quotation).reduce(
        (acc, key) => {
            let value = quotation[key as keyof QuotationResponse] as any;
            if (value === null) {
                value = undefined;
            }

            const valuesEntry = QUOTATION_VALUES_MAPPING_ENTRIES.find(
                ([, responseKey]) => responseKey === key
            );
            if (valuesEntry) {
                acc.formValues[valuesEntry[0] as keyof QuotationValues] = value;
                return acc;
            }

            const rentTotalsEntry = QUOTATION_RENT_TOTALS_MAPPING_ENTRIES.find(
                ([, responseKey]) => responseKey === key
            );
            if (rentTotalsEntry) {
                acc.rentTotals[
                    rentTotalsEntry[0] as keyof QuotationRentTotals
                ] = value;
            }

            const managementTotalsEntry =
                QUOTATION_MANAGEMENT_TOTALS_MAPPING_ENTRIES.find(
                    ([, responseKey]) => responseKey === key
                );
            if (managementTotalsEntry) {
                acc.managementTotals[
                    managementTotalsEntry[0] as keyof QuotationManagementTotals
                ] = value;
            }

            return acc;
        },
        {
            formValues: {} as Partial<QuotationValues>,
            rentTotals: {} as Partial<QuotationRentTotals>,
            managementTotals: {} as Partial<QuotationManagementTotals>,
        }
    );
}

function toResponse(values: any, mapping: any): QuotationResponse {
    return Object.keys(values).reduce((acc, key) => {
        const responseKey = mapping[key];
        if (responseKey) {
            const val = values[key as keyof QuotationValues];
            if (val !== undefined) {
                acc[responseKey as keyof QuotationResponse] =
                    val as unknown as any;
            }
        }

        return acc;
    }, {} as QuotationResponse);
}

export function quotationUiDataToQuotationResponse(
    formValues: Partial<QuotationValues>,
    rentTotals: Partial<QuotationRentTotals>,
    managementTotals: Partial<QuotationManagementTotals>
): QuotationResponse {
    const response: QuotationResponse = {
        ...toResponse(formValues, QUOTATION_VALUES_MAPPING),
        ...toResponse(rentTotals, QUOTATION_RENT_TOTALS_MAPPING),
        ...toResponse(managementTotals, QUOTATION_MANAGEMENT_TOTALS_MAPPING),
    };

    return response;
}
