import ListUserAgenciesResponse from '@acdc/shared/src/features/userAgency/ListUserAgenciesResponse';
import UserAgencyResponse from '@acdc/shared/src/features/userAgency/UserAgencyResponse';
import isGranted from '@acdc/shared/src/security/isGranted';

export interface RolesHierarchy {
    [role: string]: string[];
}

const isGrantedInAgency = (
    checkRole: string,
    agencyId: string,
    userAgenciesRoles: ListUserAgenciesResponse,
    hierarchy: RolesHierarchy | null = null
): boolean => {
    const userAgency: UserAgencyResponse | undefined =
        userAgenciesRoles.userAgencies?.collection.find(
            (uAgency: UserAgencyResponse) => uAgency.agency?.id === agencyId
        );

    if (userAgency?.role) {
        return isGranted(checkRole, [userAgency.role], hierarchy);
    }

    return false;
};

export default isGrantedInAgency;
