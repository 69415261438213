import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { useMemo } from 'react';
import hasContentModuleEditableFile from '../contentModule/hasContentModuleEditableFile';

function useHasContentEditableFiles(content: ContentResponse | undefined) {
    return useMemo(
        () =>
            content?.contentModules?.collection.some((contentModule) => {
                return hasContentModuleEditableFile(contentModule);
            }),
        [content]
    );
}

export default useHasContentEditableFiles;
