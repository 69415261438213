import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useApolloClient } from '@apollo/client';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import Content from '../features/content/Content';
import CategorySkeleton from '../features/category/CategorySkeleton';
import FullPageLayout from '../layout/FullPageLayout';
import useContent from '../features/content/useContent';
import { useHeaderHeight } from '../layout/HeaderHeightProvider';

export interface ContentPreviewProps {
    type?: ContentType | undefined;
}

function ContentPreview({ type }: ContentPreviewProps) {
    const { contentId } = useParams();
    const [headerHeight] = useHeaderHeight();

    const iriName =
        type === ContentType.TOOL_CONTENT ? 'tool-contents' : 'contents';
    const content = useContent(`/${iriName}/${contentId}`, type);

    const apollo = useApolloClient();

    useEffect(() => {
        apollo.refetchQueries({
            include: [
                'GetSections',
                'GetContentModules',
                'GetSettingValues',
                'GetVariables',
            ],
        });
    }, [apollo]);

    if (!content) {
        return <CategorySkeleton pt={headerHeight} />;
    }

    return (
        <FullPageLayout>
            <Content content={content} />
        </FullPageLayout>
    );
}

export default ContentPreview;
