import { useCallback } from 'react';
import { SetAlert, useAlert } from '@acdc/shared/src/tools/alert';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateServiceResponse from '@acdc/shared/src/features/service/MutateServiceResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import { entityToId, filterString } from '@acdc/shared/src/utils/form-helpers';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import Service from '@acdc/shared/src/features/service/Service.model';
import type { ServiceFormProps, ServiceFormValue } from './ServiceForm';
import { GET_SERVICES, SERVICE_FRAGMENT } from '../useLoadServices';

export const CREATE_SERVICE = gql`
    ${SERVICE_FRAGMENT}
    mutation CreateService($input: createServiceInput!) {
        createService(input: $input) {
            service {
                ...ServiceFields
            }
        }
    }
`;

export const UPDATE_SERVICE = gql`
    ${SERVICE_FRAGMENT}
    mutation UpdateService($input: updateServiceInput!) {
        updateService(input: $input) {
            service {
                ...ServiceFields
            }
        }
    }
`;

function getItemFromResult(
    result: FetchResult<MutateServiceResponse>,
    isUpdate: boolean
): ServiceResponse | null | undefined {
    return isUpdate
        ? result.data?.updateService?.service
        : result.data?.createService?.service;
}

/**
 * Submit un Service et son ServiceAgency de l'agency
 */
function doSubmit(
    initialValue: ServiceResponse | undefined,
    agency: AgencyResponse,
    isUpdate: boolean,
    values: ServiceFormValue,
    mutateService: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<MutateServiceResponse>>,
    yupSchema: Yup.ObjectSchema<any>,
    setErrors: FormikHelpers<ServiceFormValue>['setErrors'],
    setAlert: SetAlert,
    onError: ServiceFormProps['onError']
) {
    return (
        Promise.resolve()
            // préparation du Service à envoyer
            .then(() => {
                const input: DeepPartial<Service> = {
                    label: filterString(values.label) || undefined,
                    longLabel: filterString(values.longLabel) || undefined,
                    agency: entityToId(agency),
                    sortOrder: initialValue?.sortOrder || 0,
                };

                if (isUpdate) {
                    input.id = initialValue?.id;
                }

                return input;
            })

            // envoi du Service
            .then((input) =>
                mutateService({ variables: { input } }).catch(
                    (err: ApolloError) => {
                        handleApolloError(
                            setErrors,
                            setAlert,
                            yupSchema,
                            onError
                        )(err);

                        throw Error('cancelled');
                    }
                )
            )
    );
}

const useSubmitService = (
    initialValue: ServiceResponse | undefined,
    agency: AgencyResponse,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ServiceFormProps['onSuccess'],
    onError: ServiceFormProps['onError']
) => {
    const isUpdate: boolean = Boolean(initialValue?.id);
    const setAlert = useAlert();
    const [mutateService] = useMutation(
        isUpdate ? UPDATE_SERVICE : CREATE_SERVICE,
        {
            // ajout du nouveau service dans le cache de la requete GET_SERVICES
            update(cache, result) {
                if (isUpdate) {
                    return;
                }
                const item = getItemFromResult(result, isUpdate) as StoreObject;
                if (!item) {
                    return;
                }
                cache.updateQuery(
                    { query: GET_SERVICES, broadcast: false },
                    (data) => {
                        return {
                            services: {
                                ...data.services,
                                collection: [...data.services.collection, item],
                            },
                        };
                    }
                );
            },
        }
    );

    return useCallback(
        (
            formValues: ServiceFormValue,
            { setSubmitting, setErrors }: FormikHelpers<ServiceFormValue>
        ) => {
            doSubmit(
                initialValue,
                agency,
                isUpdate,
                formValues,
                mutateService,
                yupSchema,
                setErrors,
                setAlert,
                onError
            )
                .then((res: FetchResult<MutateServiceResponse>) => {
                    const resItem = getItemFromResult(res, isUpdate);

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            initialValue,
            agency,
            isUpdate,
            mutateService,
            setAlert,
            yupSchema,
            onError,
            onSuccess,
        ]
    );
};

export default useSubmitService;
