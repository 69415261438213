import { Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { TextField } from 'formik-mui';
import SubmitButton from '@acdc/shared/src/ui/SubmitButton';
import { Box, Stack } from '@mui/material';
import useSubmitService from './useSubmitService';
import DeleteServiceButton from '../DeleteServiceButton';

const LABEL_MAX_LENGTH = 26;
const LONG_LABEL_MAX_LENGTH = 90;

export interface ServiceFormValue {
    label: string;
    longLabel: string;
}

const initValue = (value: ServiceResponse): ServiceFormValue => ({
    label: initString(value.label),
    longLabel: initString(value.longLabel),
});

export const serviceFormSchema = Yup.object().shape({
    label: Yup.string()
        .label('Le libellé court')
        .max(LABEL_MAX_LENGTH)
        .required(),
    longLabel: Yup.string()
        .label('Le libellé long')
        .max(LONG_LABEL_MAX_LENGTH)
        .required(),
});

export type ServiceFormProps = {
    value?: ServiceResponse | undefined;
    agency: AgencyResponse;
    onSuccess?: (res: ServiceResponse) => void;
    onError?: (err: ApolloError) => void;
    onDeleted?: (serviceId: string) => void;
};

function ServiceForm({
    value,
    agency,
    onSuccess,
    onError,
    onDeleted,
}: ServiceFormProps) {
    const initialValues: ServiceFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const submit = useSubmitService(
        value,
        agency,
        serviceFormSchema,
        onSuccess,
        onError
    );

    return (
        <Formik
            validationSchema={serviceFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <Stack spacing={2} mt={2}>
                    <Field
                        component={TextField}
                        name="label"
                        id="serviceLabelInput"
                        label="Nom court de la prestation"
                        required
                        fullWidth
                        helperText={`${LABEL_MAX_LENGTH} caractères maximum.`}
                        InputProps={{
                            'data-testid': 'serviceLabelInput',
                            inputProps: {
                                maxLength: LABEL_MAX_LENGTH,
                            },
                        }}
                    />
                    <Field
                        component={TextField}
                        name="longLabel"
                        id="serviceLongLabelInput"
                        label="Nom long de la prestation"
                        required
                        fullWidth
                        helperText={`${LONG_LABEL_MAX_LENGTH} caractères maximum.`}
                        InputProps={{
                            'data-testid': 'serviceLongLabelInput',
                            inputProps: {
                                maxLength: LONG_LABEL_MAX_LENGTH,
                            },
                        }}
                    />
                    <Box
                        display="flex"
                        flexDirection="row-reverse"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <SubmitButton
                            wrapperSx={{ flex: 1, pl: value?.id ? 1 : 0 }}
                            buttonProps={{ sx: { width: '100%' } }}
                        />
                        {value?.id && (
                            <Box sx={{ flex: 1, pr: 1 }}>
                                <DeleteServiceButton
                                    service={value}
                                    onDeleted={onDeleted}
                                    variant="contained"
                                    color="error"
                                    sx={{ width: '100%' }}
                                >
                                    Supprimer
                                </DeleteServiceButton>
                            </Box>
                        )}
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
}

export default ServiceForm;
