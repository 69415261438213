import React, { useContext, useState } from 'react';
import { headerHeight } from '../mui/theme';

export const HeaderHeightCtx = React.createContext<
    [string, React.Dispatch<React.SetStateAction<string>>]
>(['0px', () => {}]);

export function useHeaderHeight() {
    return useContext(HeaderHeightCtx);
}

export interface HeaderHeightProviderProps {
    children?: React.ReactNode;
}

function HeaderHeightProvider({ children }: HeaderHeightProviderProps) {
    const state = useState<string>(headerHeight);

    return (
        <HeaderHeightCtx.Provider value={state}>
            {children}
        </HeaderHeightCtx.Provider>
    );
}

export default HeaderHeightProvider;
