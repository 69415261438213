import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { useEffect } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { ApolloError } from '@apollo/client';
import { concatViolations } from '@acdc/shared/src/utils/error-helpers';
import useDiscoveryQueue from './useDiscoveryQueue';
import useSendDiscovery from '../useSendDiscovery';

function DiscoveryQueueHandler() {
    const { queue, setQueue } = useDiscoveryQueue();
    const isOnline = useOnlineStatus();
    const sendDiscovery = useSendDiscovery();
    const setAlert = useAlert();

    useEffect(() => {
        if (isOnline && queue.length) {
            setQueue([]);

            // envoi des découvertes l'un après l'autre pour éviter de surcharger l'api
            queue
                .reduce((lastQuery: Promise<any>, discovery) => {
                    return lastQuery
                        .then(() => {
                            return sendDiscovery(discovery);
                        })
                        .catch((err: ApolloError) => {
                            setAlert(
                                // eslint-disable-next-line max-len
                                `Une erreur est survenue lors de l'envoi d'une découverte : ${concatViolations(
                                    err,
                                    'Erreur serveur.'
                                )}`,
                                'error'
                            );
                        });
                }, Promise.resolve())
                .then(() => {
                    const message =
                        queue.length === 1
                            ? 'La découverte a été envoyée.'
                            : `Les ${queue.length} découvertes en attente ont été envoyées.`;
                    setAlert(message, 'success');
                });
        }
    }, [queue, isOnline, setQueue, sendDiscovery, setAlert]);

    return null;
}

export default DiscoveryQueueHandler;
