import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListContentOverridesResponse from '@acdc/shared/src/features/contentOverride/ListContentOverridesResponse';
import ContentOverrideResponse from '@acdc/shared/src/features/contentOverride/ContentOverrideResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const CONTENT_OVERRIDE_FRAGMENT = gql`
    fragment ContentOverrideFields on ContentOverride {
        id
        disabled
        agency {
            id
        }
        content {
            id
        }
    }
`;

export const GET_CONTENT_OVERRIDES = gql`
    ${CONTENT_OVERRIDE_FRAGMENT}
    query GetContentOverrides($agencyCode: String) {
        contentOverrides(itemsPerPage: 10000, agency_code: $agencyCode) {
            collection {
                ...ContentOverrideFields
            }
        }
    }
`;

const emptyResult: ContentOverrideResponse[] = [];

const useContentOverrides = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListContentOverridesResponse>(
        GET_CONTENT_OVERRIDES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des configurations de page par agence.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<ContentOverrideResponse[] | undefined>(() => {
        return data?.contentOverrides?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useContentOverrides;
