import React from 'react';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import InlineInputLabel from '../../../ui/InlineInputLabel';

function UseNewPackagesFormInner() {
    useAutoSubmitSingleFieldForm('useNewPackages');

    return (
        <InlineInputLabel
            htmlFor="useNewPackages"
            label="Utiliser les nouveaux noms des packs"
        >
            <Field
                component={Switch}
                id="useNewPackages"
                name="useNewPackages"
                type="checkbox"
                disabled={false}
                color="success"
            />
        </InlineInputLabel>
    );
}

export default UseNewPackagesFormInner;
