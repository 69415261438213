import { DISCOVERY_VALUE_DELIMITER } from '@acdc/shared/src/features/discovery/Discovery.model';
import DiscoveryChoiceResponse from '@acdc/shared/src/features/discoveryChoice/DiscoveryChoiceResponse';
import { entitiesToIds } from '@acdc/shared/src/utils/form-helpers';

/**
 * Converti une liste de choix sélectionnés en une string contenant leurs identifiants.
 * Pour récupérer le tableau d'identifiants utilisez discoveryValueToDiscoveryChoiceIds().
 */
function discoveryChoicesToDiscoveryValue(
    discoveryChoices: (string | DiscoveryChoiceResponse)[]
): string {
    return entitiesToIds(discoveryChoices).join(DISCOVERY_VALUE_DELIMITER);
}

export default discoveryChoicesToDiscoveryValue;
