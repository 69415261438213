import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { useEffect, useRef, useState } from 'react';
import arrayUniq from '@acdc/shared/src/utils/arrayUniq';
import PresentationStatistic from '@acdc/shared/src/features/presentationStatistic/PresentationStatistic.model';
import useCurrentPresentation from '../../presentation/useCurrentPresentation';
import usePresentationStatisticQueue from './usePresentationStatisticQueue';
import useSendPresentationStatistic from '../useSendPresentationStatistic';

function PresentationStatisticQueueHandler() {
    const [presentation] = useCurrentPresentation();
    const { queue, setQueue } = usePresentationStatisticQueue();
    const isOnline = useOnlineStatus();
    const isProcessing = useRef<boolean>(false);
    const [shouldRetry, setShouldRetry] = useState<boolean>(false);
    const sendPresentationStatistic = useSendPresentationStatistic();

    useEffect(() => {
        if (isProcessing.current) {
            setShouldRetry(true);
            return;
        }

        if (!isOnline || !queue.length) {
            return;
        }

        const queueToHandle = queue.filter(
            (i) => i && i.uid !== presentation?.uid
        );

        if (!queueToHandle.length) {
            return;
        }

        isProcessing.current = true;
        // on supprime les items à traiter de la file
        try {
            setQueue((curr) =>
                curr?.filter((i) => {
                    return !i
                        ? false
                        : !queueToHandle.some((i2) => i2?.uid === i.uid);
                })
            );
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                "Une erreur est survenu lors de la suppression d'un item dans la queue",
                queue,
                error
            );
        }

        try {
            Promise.all(
                queueToHandle.map((queueItem) => {
                    const uniqContents = arrayUniq(queueItem.visitedContentIds);
                    const stat: DeepPartial<PresentationStatistic> = {
                        ...queueItem.value,
                        visitedContents: uniqContents.length,
                        visitedContentSlugs: uniqContents.map((pageSlug) => {
                            if (pageSlug.endsWith('-page')) {
                                return pageSlug.substring(
                                    0,
                                    pageSlug.length - 5
                                );
                            }
                            return pageSlug;
                        }),
                    };
                    return sendPresentationStatistic(stat).catch((err: any) => {
                        // eslint-disable-next-line no-console
                        console.error(err);
                    });
                })
            ).then(() => {
                isProcessing.current = false;
                setShouldRetry(false); // déclenche un effect si shouldRetry valait true
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                "une erreur est survenu lors de l'envoi des stats",
                queue,
                error
            );
        }
    }, [
        queue,
        isOnline,
        setQueue,
        presentation,
        shouldRetry,
        setShouldRetry,
        sendPresentationStatistic,
    ]);

    return null;
}

export default PresentationStatisticQueueHandler;
