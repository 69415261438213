import DialogButton, {
    DialogButtonProps,
} from '@acdc/shared/src/ui/DialogButton';
import React from 'react';
import ServiceForm from './ServiceForm';
import { ServiceFormProps } from './ServiceForm/ServiceForm';

export interface ServiceFormDialogButtonProps {
    children: React.ReactNode;
    value?: ServiceFormProps['value'];
    agency: ServiceFormProps['agency'];
    buttonComponent?: DialogButtonProps['buttonComponent'];
    buttonProps?: DialogButtonProps['buttonProps'];
    onDeleted?: ServiceFormProps['onDeleted'];
}

function ServiceFormDialogButton({
    children,
    value,
    agency,
    buttonComponent,
    buttonProps,
    onDeleted,
}: ServiceFormDialogButtonProps) {
    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                'data-testid': 'ServiceFormDialogButton',
                ...buttonProps,
            }}
            dialogProps={{
                id: 'selectAgency',
                mainTitle: 'Ajouter une prestation',
                maxWidth: 'md',
                fullWidth: true,
            }}
        >
            {(close) => (
                <ServiceForm
                    value={value}
                    agency={agency}
                    onSuccess={() => {
                        close();
                    }}
                    onDeleted={(itemId: string) => {
                        close();
                        onDeleted && onDeleted(itemId);
                    }}
                />
            )}
        </DialogButton>
    );
}

export default ServiceFormDialogButton;
