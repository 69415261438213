import React, { useCallback, useMemo, useState } from 'react';

export const DiscoveryDrawerCtx = React.createContext<{
    isOpen: boolean;
    toggleDrawer: () => void;
}>({ isOpen: false, toggleDrawer: () => {} });

export interface DiscoveryDrawerProviderProps {
    children?: React.ReactNode;
}

function DiscoveryDrawerProvider({ children }: DiscoveryDrawerProviderProps) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, [setIsOpen]);

    const value = useMemo(() => {
        return {
            isOpen,
            toggleDrawer,
        };
    }, [isOpen, toggleDrawer]);

    return (
        <DiscoveryDrawerCtx.Provider value={value}>
            {children}
        </DiscoveryDrawerCtx.Provider>
    );
}

export default DiscoveryDrawerProvider;
