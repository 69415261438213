import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuotationQueue } from '../features/quotation/QuotationQueue';

export interface LogoutButtonProps {
    children: React.ReactNode;
    component?: React.ComponentType<{
        onClick?: () => void;
        disabled?: boolean;
    }>;
}

function LogoutButton({ children, component }: LogoutButtonProps) {
    const ButtonComponent = component || Button;
    const { queue } = useQuotationQueue();
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (
            queue.length === 0 ||
            // eslint-disable-next-line no-alert
            window.confirm(
                // eslint-disable-next-line max-len
                `Vous avez ${queue.length} emails de devis en attente d'envoi. Vous devez vous connecter à internet pour que ces devis soient envoyés. Si vous vous déconnectez maintenant ces devis seront perdus. Êtes-vous sûr de vouloir vous déconnecter ?`
            )
        ) {
            navigate('/deconnexion');
        }
    }, [navigate, queue]);

    return <ButtonComponent onClick={handleClick}>{children}</ButtonComponent>;
}

export default LogoutButton;
