import isGranted from './isGranted';
import { usePermissions } from './PermissionsProvider';

/**
 * Retourne true si l'utilisateur a le role demandé.
 * Attention en cas de changement des roles de l'utilisateur authentifié ou en cas de désauthentification
 * ce hook ne trigger pas de re-rendu du composant où il est utilisé.
 */
const useIsGranted = (checkRole: string) => {
    const { getUserRoles, rolesHierarchy } = usePermissions();

    return isGranted(checkRole, getUserRoles(), rolesHierarchy);
};

export default useIsGranted;
