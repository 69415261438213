import React from 'react';
import QuotationQueueHandler from './QuotationQueueHandler';
import QuotationQueueProvider from './QuotationQueueProvider';

export interface QuotationQueueProps {
    children: React.ReactNode;
}

function QuotationQueue({ children }: QuotationQueueProps) {
    return (
        <QuotationQueueProvider>
            <QuotationQueueHandler />
            {children}
        </QuotationQueueProvider>
    );
}

export default QuotationQueue;
