import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultHeader from './DefaultHeader';
import LayoutSkeleton from './LayoutSkeleton';
import SizeInterface from './SizeInterface';

const gutter: SizeInterface = { xs: '2%', md: '5%' };
const width: SizeInterface = { xs: '94%', md: '90%' };

export interface LayoutPreviewProps {
    children?: React.ReactNode;
}

function LayoutPreview({ children }: LayoutPreviewProps) {
    return (
        <LayoutSkeleton width={width} gutter={gutter}>
            <DefaultHeader width={width} gutter={gutter} />
            {children || <Outlet />}
        </LayoutSkeleton>
    );
}

export default LayoutPreview;
