import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListDiscoveryCriteriaRequirementsResponse from '@acdc/shared/src/features/discoveryCriteriaRequirement/ListDiscoveryCriteriaRequirementsResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_DISCOVERY_CRITERIA_REQUIREMENTS = gql`
    query GetDiscoveryCriteriaRequirements {
        discoveryCriteriaRequirements(itemsPerPage: 10000) {
            collection {
                id
                criteria {
                    id
                }
                expectedCriteria {
                    id
                }
                expectedValues
                action
            }
        }
    }
`;

const useLoadDiscoveryCriteriaRequirements = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListDiscoveryCriteriaRequirementsResponse>(
        GET_DISCOVERY_CRITERIA_REQUIREMENTS,
        {
            ...persistentQueryOptions,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des conditions d'affichage des critères de découvertes.",
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.discoveryCriteriaRequirements?.collection;
};

export default useLoadDiscoveryCriteriaRequirements;
