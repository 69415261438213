import React from 'react';
import { Stack, SxProps } from '@mui/material';

interface QuotationActionsProps {
    children: React.ReactNode;
    sx?: SxProps;
}

/**
 * Un wrapper pour mettre les boutons d'action du devis.
 * Si 1 bouton il sera centré et très large.
 * Si 2 ou + boutons ils seront alignés et très larges.
 */
function QuotationActions({ children, sx }: QuotationActionsProps) {
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                justifyContent: 'center',
                '&>*': { flex: 1, maxWidth: '50%' },
                ...sx,
            }}
        >
            {children}
        </Stack>
    );
}

export default QuotationActions;
