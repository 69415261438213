import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';

const useNotReadNotificationUser = (
    notificationUsers: NotificationUserResponse[] | null
) => {
    if (!notificationUsers) {
        return null;
    }

    const notificationUsersNotRead = notificationUsers.filter(
        (notificationUser) => {
            return !notificationUser.isRead;
        }
    );

    return notificationUsersNotRead;
};

export default useNotReadNotificationUser;
