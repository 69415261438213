import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListChaptersResponse from '@acdc/shared/src/features/chapter/ListChaptersResponse';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useChapterContents from '../chapterContent/useChapterContents';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_CHAPTERS = gql`
    query GetChapters {
        chapters(order: [{ id: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                title
                subTitle
                presentationFlow {
                    id
                }
            }
        }
    }
`;

/**
 * Retourne tous les Chapters.
 */
const useChapters = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListChaptersResponse>(
        GET_CHAPTERS,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des chapitres de déroulés.',
                'error'
            );
        }
    }, [error, setAlert]);

    const chapterContents = useChapterContents();

    return useMemo<ChapterResponse[] | undefined>(() => {
        if (!chapterContents) {
            return undefined;
        }

        const items = data?.chapters?.collection;

        // à réactiver quand on les utilisera
        // items?.forEach((chapter) => {
        //     if (chapter.chapterImage?.publicPath) {
        //         preloadImage(CONFIGS.apiEntrypoint + chapter.chapterImage.publicPath);
        //     }
        // });

        return items?.map((chapter) => ({
            ...chapter,
            chapterContents: {
                collection: chapterContents.filter((chapterContent) =>
                    matchOnId(chapterContent.chapter, chapter)
                ),
            },
        }));
    }, [data, chapterContents]);
};

export default useChapters;
