import { useContext } from 'react';
import { CacheAgeContext } from './CacheAgeProvider';

/**
 * Retourne la date de dernier refresh du cache Apollo
 */
const useCacheAge = () => {
    return useContext(CacheAgeContext);
};

export default useCacheAge;
