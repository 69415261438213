import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';

/**
 * Si le Content contient un Module particulier (devis ou packs par exemple)
 * retourne le ModuleCode du Module particulier.
 */
const specialModules: string[] = [ModuleCode.QUOTATION, ModuleCode.PACKAGES];
const getSpecialModuleCode = (content: ContentResponse | undefined) => {
    if (!content) {
        return null;
    }

    return (
        content?.contentModules?.collection.find(
            (item: ContentModuleResponse) =>
                specialModules.includes(item?.module?.code || '')
        )?.module?.code || null
    );
};

export default getSpecialModuleCode;
