import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Formik, FormikHelpers } from 'formik';
import DiscoveryCriteriaType from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaType.enum';
import {
    boolToString,
    entityToId,
    matchOnId,
    stringToBool,
} from '@acdc/shared/src/utils/form-helpers';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryChoiceResponse from '@acdc/shared/src/features/discoveryChoice/DiscoveryChoiceResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import DiscoveryCriteriaFormInner from './DiscoveryCriteriaFormInner';
import discoveryValueToDiscoveryChoiceIds from '../discoveryValueToDiscoveryChoiceIds';
import discoveryChoicesToDiscoveryValue from '../discoveryChoicesToDiscoveryValue';

export interface DiscoveryCriteriaFormValue {
    value: any;
}

function getDefaultValue(discoveryCriteria: DiscoveryCriteriaResponse) {
    switch (discoveryCriteria.type) {
        case DiscoveryCriteriaType.SELECT_MULTIPLE:
        case DiscoveryCriteriaType.BUTTON_PICKER_MULTIPLE:
        case DiscoveryCriteriaType.LOCATION_SELECT:
            return [];
        case DiscoveryCriteriaType.INPUT_NUMBER:
        case DiscoveryCriteriaType.INPUT_TEXT:
        case DiscoveryCriteriaType.SLIDER:
        case DiscoveryCriteriaType.YEAR_OF_CONSTRUCTION:
        case DiscoveryCriteriaType.COUNTER:
            return '';
        case DiscoveryCriteriaType.SWITCH:
            return false;
        case DiscoveryCriteriaType.BUTTON_PICKER_BOOL:
            return 'false';
        // case DiscoveryCriteriaType.BUTTON_PICKER:
        // case DiscoveryCriteriaType.BUTTON_PICKER_NUMBER:
        // case DiscoveryCriteriaType.RADIOGROUP:
        // case DiscoveryCriteriaType.SELECT:
        // case DiscoveryCriteriaType.SWITCH_WITH_INDETERMINATE:
        default:
            return null;
    }
}

function shouldSubmitInitialValue(
    initialValues: DiscoveryCriteriaFormValue,
    discoveryValue: DiscoveryValue | null | undefined
) {
    if (
        // une valeur existe déjà
        discoveryValue?.value !== undefined ||
        // ou il n'y a pas de valeur par défaut
        ['', null, undefined].includes(initialValues.value) ||
        (Array.isArray(initialValues.value) && !initialValues.value.length)
    ) {
        return false;
    }

    // Si le critère n'a pas encore de valeur définie par l'utilisateur
    // mais qu'on lui en assigne une par défaut, il faut la submit directement
    // sinon l'utilisateur verra une valeur mais si il n'y touche pas elle ne sera jamais submit.
    return true;
}

function initValue(
    discoveryValue: DiscoveryValue | null | undefined,
    discoveryCriteria: DiscoveryCriteriaResponse
): any {
    if (discoveryValue?.value === undefined) {
        return getDefaultValue(discoveryCriteria);
    }

    switch (discoveryCriteria.type) {
        case DiscoveryCriteriaType.BUTTON_PICKER:
        case DiscoveryCriteriaType.RADIOGROUP:
            // l'id du choice si existant
            if (
                !discoveryCriteria.choices?.collection.some((c) =>
                    matchOnId(c, discoveryValue.value)
                )
            ) {
                return getDefaultValue(discoveryCriteria);
            }
            return discoveryValue.value;
        case DiscoveryCriteriaType.SELECT:
            // le choice
            return (
                discoveryCriteria.choices?.collection.find((c) =>
                    matchOnId(c, discoveryValue.value)
                ) || getDefaultValue(discoveryCriteria)
            );
        case DiscoveryCriteriaType.BUTTON_PICKER_MULTIPLE:
            // tableau d'iri choices
            return discoveryValueToDiscoveryChoiceIds(discoveryValue).reduce(
                (selectedChoices: string[], iri) => {
                    const choice = discoveryCriteria.choices?.collection.find(
                        (c) => matchOnId(c, iri)
                    );
                    if (choice?.id) {
                        selectedChoices.push(choice.id);
                    }

                    return selectedChoices;
                },
                []
            );
        case DiscoveryCriteriaType.SELECT_MULTIPLE:
            // tableau de choices
            return discoveryValueToDiscoveryChoiceIds(discoveryValue).reduce(
                (selectedChoices: DiscoveryChoiceResponse[], iri) => {
                    const choice = discoveryCriteria.choices?.collection.find(
                        (c) => matchOnId(c, iri)
                    );
                    if (choice) {
                        selectedChoices.push(choice);
                    }

                    return selectedChoices;
                },
                []
            );
        case DiscoveryCriteriaType.LOCATION_SELECT:
            // tableau de valeurs
            return discoveryValueToDiscoveryChoiceIds(discoveryValue);
        case DiscoveryCriteriaType.INPUT_NUMBER:
        case DiscoveryCriteriaType.INPUT_TEXT:
        case DiscoveryCriteriaType.BUTTON_PICKER_NUMBER:
        case DiscoveryCriteriaType.BUTTON_PICKER_BOOL: // ButtonPickerBool retourne "true" en string au lieu de bools.
        case DiscoveryCriteriaType.SLIDER:
        case DiscoveryCriteriaType.YEAR_OF_CONSTRUCTION:
        case DiscoveryCriteriaType.COUNTER:
            // la valeur
            return discoveryValue.value;
        case DiscoveryCriteriaType.SWITCH:
        case DiscoveryCriteriaType.SWITCH_WITH_INDETERMINATE:
            // un string en bool
            return (
                stringToBool(discoveryValue.value) ??
                getDefaultValue(discoveryCriteria)
            );
        default:
            return '';
    }
}

function formValueToString(
    value: any,
    discoveryCriteria: DiscoveryCriteriaResponse
): string {
    switch (discoveryCriteria.type) {
        case DiscoveryCriteriaType.BUTTON_PICKER:
        case DiscoveryCriteriaType.RADIOGROUP:
        case DiscoveryCriteriaType.SELECT:
            // l'id du choice
            return entityToId(value) || '';
        case DiscoveryCriteriaType.BUTTON_PICKER_MULTIPLE:
        case DiscoveryCriteriaType.SELECT_MULTIPLE:
        case DiscoveryCriteriaType.LOCATION_SELECT:
            // les ids des choices
            return discoveryChoicesToDiscoveryValue(value);
        case DiscoveryCriteriaType.INPUT_NUMBER:
        case DiscoveryCriteriaType.SLIDER:
        case DiscoveryCriteriaType.YEAR_OF_CONSTRUCTION:
        case DiscoveryCriteriaType.COUNTER:
            // valeur en string
            return `${value}`;
        case DiscoveryCriteriaType.INPUT_TEXT:
        case DiscoveryCriteriaType.BUTTON_PICKER_NUMBER:
        case DiscoveryCriteriaType.BUTTON_PICKER_BOOL: // ButtonPickerBool retourne "true" en string au lieu de bools.
            // la valeur
            return value;
        case DiscoveryCriteriaType.SWITCH:
        case DiscoveryCriteriaType.SWITCH_WITH_INDETERMINATE:
            // un bool en string
            return boolToString(value);
        default:
            return '';
    }
}

function getValidationSchema(discoveryCriteria: DiscoveryCriteriaResponse) {
    switch (discoveryCriteria.type) {
        case DiscoveryCriteriaType.INPUT_TEXT:
            if (discoveryCriteria.inputOptions?.type === 'email') {
                return Yup.object().shape({
                    value: Yup.string().email().label("L'email").required(),
                });
            }
            if (discoveryCriteria.inputOptions?.type === 'tel') {
                return Yup.object().shape({
                    value: Yup.string()
                        .phone('FR', 'Numéro de téléphone invalide')
                        .label('Le numéro de tél')
                        .required(),
                });
            }
            return undefined;
        // case DiscoveryCriteriaType.BUTTON_PICKER:
        // case DiscoveryCriteriaType.BUTTON_PICKER_MULTIPLE:
        // case DiscoveryCriteriaType.BUTTON_PICKER_NUMBER:
        // case DiscoveryCriteriaType.BUTTON_PICKER_BOOL:
        // case DiscoveryCriteriaType.RADIOGROUP:
        // case DiscoveryCriteriaType.SELECT:
        // case DiscoveryCriteriaType.SELECT_MULTIPLE:
        // case DiscoveryCriteriaType.INPUT_NUMBER:
        // case DiscoveryCriteriaType.SWITCH:
        // case DiscoveryCriteriaType.SWITCH_WITH_INDETERMINATE:
        // case DiscoveryCriteriaType.SLIDER:
        // case DiscoveryCriteriaType.YEAR_OF_CONSTRUCTION:
        // case DiscoveryCriteriaType.COUNTER:
        // case DiscoveryCriteriaType.LOCATION_SELECT:
        default:
            return undefined;
    }
}

export interface DiscoveryCriteriaFormProps {
    discoveryValue: DiscoveryValue | null | undefined;
    discoveryCriteria: DiscoveryCriteriaResponse;
    agency: AgencyResponse;
    onSuccess?: (newValue: string) => void;
    onError?: () => void;
    onClearErrors?: () => void;
}

function DiscoveryCriteriaForm({
    discoveryValue,
    discoveryCriteria,
    agency,
    onSuccess,
    onError,
    onClearErrors,
}: DiscoveryCriteriaFormProps) {
    const initialValues: DiscoveryCriteriaFormValue = useMemo(() => {
        return {
            value: initValue(discoveryValue, discoveryCriteria),
        };
    }, [discoveryCriteria, discoveryValue]);
    const onClearErrorsRef = useRef(onClearErrors);
    onClearErrorsRef.current = onClearErrors;

    const submit = useCallback(
        (
            values: DiscoveryCriteriaFormValue,
            { setSubmitting }: FormikHelpers<DiscoveryCriteriaFormValue>
        ) => {
            onSuccess &&
                onSuccess(formValueToString(values.value, discoveryCriteria));
            setSubmitting(false);
        },
        [onSuccess, discoveryCriteria]
    );

    const validationSchema = useMemo(() => {
        return getValidationSchema(discoveryCriteria);
    }, [discoveryCriteria]);

    useEffect(() => {
        if (shouldSubmitInitialValue(initialValues, discoveryValue)) {
            // Dans certains cas il faut submit directement la valeur par défaut.
            // Par exemple si type=BUTTON_PICKER_BOOL, le champ et initié avec la valeur "false", donc si l'utilisateur n'y touche pas
            // il faut que cette valeur soit transmise quand même.
            onSuccess &&
                onSuccess(
                    formValueToString(initialValues.value, discoveryCriteria)
                );
        }
    }, [discoveryCriteria, initialValues, discoveryValue, onSuccess]);

    useEffect(() => {
        return () => {
            // On indique au parent qu'il peut clear les erreurs si le composant est démonté.
            // On passe par une ref car il faut avoir la dernière version de onClearErrors mais il ne faut pas que cet effect se déclenche à chaque changement de dépendance.
            onClearErrorsRef.current && onClearErrorsRef.current();
        };
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
        >
            <DiscoveryCriteriaFormInner
                discoveryCriteria={discoveryCriteria}
                agency={agency}
                onError={onError}
                onClearErrors={onClearErrors}
            />
        </Formik>
    );
}

export default DiscoveryCriteriaForm;
