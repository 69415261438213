import React, { useMemo } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceFormDialogButton from './ServiceFormDialogButton';
import useServicesOfAgency from './useServicesOfAgency';

const iconSx = {
    mr: 1,
};

const serviceFormDialogButtonProps = {
    variant: 'contained',
    sx: {
        width: '100%',
    },
};

function getMaxSortOrder(items: { sortOrder?: number | null | undefined }[]) {
    return items.reduce((curr, item) => {
        if (item.sortOrder && item.sortOrder > curr) {
            return item.sortOrder;
        }

        return curr;
    }, 0);
}

export interface CreateServiceButtonProps {
    agency: AgencyResponse;
}

function CreateServiceButton({ agency }: CreateServiceButtonProps) {
    const services = useServicesOfAgency(agency);
    const value = useMemo(() => {
        const maxSortOrderFromServiceAgencies = getMaxSortOrder(
            agency.serviceAgencies?.collection || []
        );
        const maxSortOrderFromServices = getMaxSortOrder(services || []);

        const maxSortOrder =
            maxSortOrderFromServiceAgencies > maxSortOrderFromServices
                ? maxSortOrderFromServiceAgencies
                : maxSortOrderFromServices;

        return {
            agency,
            sortOrder: maxSortOrder + 10,
        };
    }, [services, agency]);

    return (
        <ServiceFormDialogButton
            value={value}
            agency={agency}
            buttonProps={serviceFormDialogButtonProps}
        >
            <AddCircleIcon sx={iconSx} />
            Ajouter une prestation
        </ServiceFormDialogButton>
    );
}

export default CreateServiceButton;
