import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useNavigate, useParams } from 'react-router-dom';
import useToolCategoryThemeColor from '@acdc/shared/src/features/toolCategory/useToolCategoryColor';
import Tool from '../features/tools/Tool';
import useEditMode from '../features/template/useEditMode';
import useAgency from '../features/agency/useAgency';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import ToolCardSkeleton from '../ui/ToolCardSkeleton';
import Breadcrumb from '../features/breadcrumb/Breadcrumb';
import useToolCategory from '../features/toolsCategory/useToolCategory';
import useVisibleTools from '../features/tools/useVisibleTools';
import useIsToolCategoryEnabled from '../features/toolsCategory/useIsToolCategoryEnabled';

const itemSize: number = 3; // sur 12

const previousPages = [
    {
        label: 'Outils',
        path: '/rubriques',
    },
];

function Tools() {
    const [editMode] = useEditMode();
    const agency = useAgency(useSelectedAgency()[0]);

    const { toolCategoryId } = useParams();
    const toolCategoryIri = `/tool-categories/${toolCategoryId}`;

    const currentToolCategory = useToolCategory(toolCategoryIri);

    const visibleTools = useVisibleTools({
        toolCategory: toolCategoryIri,
        editMode,
        agency,
    });

    const color = useToolCategoryThemeColor(currentToolCategory);

    const isToolCategoryEnabled = useIsToolCategoryEnabled();
    const navigate = useNavigate();

    useEffect(() => {
        if (isToolCategoryEnabled(toolCategoryIri) === false) {
            navigate('/rubriques');
        }
    }, [toolCategoryIri, isToolCategoryEnabled, navigate]);

    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Outils {currentToolCategory?.label}
            </Typography>
            <Breadcrumb
                previousPages={previousPages}
                currentPage={
                    currentToolCategory?.label
                        ? currentToolCategory?.label
                        : 'outils'
                }
            />

            <Grid container spacing={4} sx={{ mt: 2, mb: 2 }}>
                {visibleTools?.map((tool) => (
                    <Grid key={tool.id} item xs={itemSize}>
                        <Tool tool={tool} color={color} />
                    </Grid>
                ))}
                {!visibleTools &&
                    [1, 2].map((key) => (
                        <Grid key={key} item xs={itemSize}>
                            <ToolCardSkeleton />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default Tools;
