import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';

export const filterVisibleContentTool = (
    tool: ToolResponse | undefined,
    agency: AgencyResponse | undefined,
    editMode: boolean
) => {
    if (editMode) {
        return tool;
    }

    const contentOverrides = agency?.contentOverrides?.collection || [];

    const filteredContent = tool?.toolContents?.collection.filter(
        (toolContent) =>
            contentOverrides.find((override) =>
                matchOnId(override.content, toolContent)
            )?.disabled !== true
    );

    if (filteredContent) {
        const filterContentsTool: ToolResponse = {
            ...tool,
            toolContents: {
                collection: filteredContent,
            },
        };

        return filterContentsTool;
    }

    return null;
};

const useFilterVisibleContentTool = (
    tool: ToolResponse | undefined,
    agency: AgencyResponse | undefined,
    editMode: boolean
) => {
    return useMemo(() => {
        return filterVisibleContentTool(tool, agency, editMode);
    }, [tool, agency, editMode]);
};

export default useFilterVisibleContentTool;
