import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';
import {
    formatLocaleDistanceToNowStrict,
    toLocaleDateTimeString,
} from '@acdc/shared/src/utils/dateFormat';
import {
    Badge,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { isValid } from 'date-fns';
import WysiwygValue from '@acdc/shared/src/ui/WysiwygValue';
import DEFAULT_WYSIWYG_STYLE from '@acdc/shared/src/features/settingValue/DEFAULT_WYSIWYG_STYLE';
import NotificationPriorityIcon from './NotificationPriorityIcon';
import { useNotificationQueue } from './NotificationQueue';

interface NotificationListItemProps {
    notificationUser: NotificationUserResponse;
}

function NotificationListItem({ notificationUser }: NotificationListItemProps) {
    const { notification } = notificationUser;

    const { addItemDelete } = useNotificationQueue();
    const createdAt = useMemo(() => {
        if (!notification?.createdAt) {
            return null;
        }

        const toDate = new Date(notification.createdAt);

        if (!isValid(toDate)) {
            return null;
        }

        return toDate;
    }, [notification?.createdAt]);

    return (
        <>
            <ListItem
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        data-testid="deleteNotificationUser"
                        onClick={() => {
                            if (notificationUser.id) {
                                addItemDelete(notificationUser.id);
                            }
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                }
                sx={{
                    alignItems: 'flex-start',
                }}
            >
                <ListItemAvatar>
                    {!notificationUser.isRead ? (
                        <Badge
                            color="secondary"
                            variant="dot"
                            overlap="circular"
                            data-testid="badgeNotificationUserNotRead"
                        >
                            <NotificationPriorityIcon
                                notificationUser={notificationUser}
                            />
                        </Badge>
                    ) : (
                        <NotificationPriorityIcon
                            notificationUser={notificationUser}
                        />
                    )}
                </ListItemAvatar>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="p"
                            textAlign="center"
                            color="grey.900"
                            mr={2}
                            mb={0}
                        >
                            {notificationUser.notification?.title}
                        </Typography>
                        {notificationUser.notification?.createdAt && (
                            <time
                                title={
                                    createdAt
                                        ? toLocaleDateTimeString(createdAt)
                                        : ''
                                }
                            >
                                il y a{' '}
                                {createdAt
                                    ? formatLocaleDistanceToNowStrict(createdAt)
                                    : ''}
                            </time>
                        )}
                    </Box>
                    <WysiwygValue
                        value={notificationUser.notification?.description || ''}
                        sx={{ ...DEFAULT_WYSIWYG_STYLE, minWidth: 400 }}
                    />
                </Box>
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    );
}

export default NotificationListItem;
