import React from 'react';
import usePackage from '../usePackage';

export interface ServicesTableProps {
    packageId: string;
}

/**
 * Le label à mettre dans le header du tableau pour un Package.
 */
function ServicesTable({ packageId }: ServicesTableProps) {
    const pack = usePackage(packageId);

    if (!pack) {
        return null;
    }

    return <>{pack.label}</>;
}

export default ServicesTable;
