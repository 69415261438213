import CONFIGS from '../configs';
import useIsExport from './useIsExport';

function useApiEntrypoint() {
    const isExport = useIsExport();

    return isExport ? CONFIGS.dockerApiEntrypoint : CONFIGS.apiEntrypoint;
}

export default useApiEntrypoint;
