import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useAlertOptions } from '@acdc/shared/src/tools/alert';
import CONFIGS from '../configs';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import useCurrentPresentation from '../features/presentation/useCurrentPresentation';
import useUser from '../features/user/useUser';
import { useHeaderHeight } from '../layout/HeaderHeightProvider';

export interface LoginForExportProps {
    children: React.ReactNode;
}

function LoginForExport({ children }: LoginForExportProps) {
    const [loggedIn, setLoggedIn] = useState(false);
    const apollo = useApolloClient();
    const [, setCurrentPresentation] = useCurrentPresentation();
    const [, setAgency] = useSelectedAgency();
    const [, setAlertOptions] = useAlertOptions();
    const [, setHeaderHeight] = useHeaderHeight();

    const queryParameters = new URLSearchParams(window.location.search);
    const agency = queryParameters.get('agency');
    const user = queryParameters.get('user');
    const token = queryParameters.get('token');
    const presentation = queryParameters.get('presentation');
    const property = queryParameters.get('property');

    const iriProperty = property ? `/properties/${property}` : undefined;

    // on set le token avant pour pouvoir récuperer les info de l'utilisateur si le token est valide
    window.localStorage.setItem(CONFIGS.authStorageKeys.authToken, token || '');
    const userInfo = useUser(`/users/${user}`);
    useEffect(() => {
        if (!agency || !userInfo || !token) {
            apollo.resetStore().then(
                () => {
                    setLoggedIn(false);
                },
                () => {}
            );
        } else {
            window.localStorage.clear();
            window.localStorage.setItem(
                CONFIGS.authStorageKeys.authToken,
                token || ''
            );
            setAgency(`/agencies/${agency}`);
            setAlertOptions({ onlyError: true });
            setHeaderHeight('0px');

            window.localStorage.setItem(
                CONFIGS.userDataStorageKeys.currentUser,
                JSON.stringify({
                    id: userInfo?.id,
                    code: userInfo?.code,
                    role: userInfo?.roles,
                    firstname: userInfo?.firstname,
                    lastname: userInfo?.lastname,
                    __typename: 'loginCASUserPayloadData',
                })
            );
            window.localStorage.setItem(
                CONFIGS.authStorageKeys.roles,
                JSON.stringify(userInfo?.roles)
            );

            if (presentation) {
                setCurrentPresentation({
                    uid: '123',
                    presentationFlowId: `/presentation-flows/${presentation}`,
                    propertyId: iriProperty,
                });
            }
            apollo.resetStore().then(
                () => {
                    setLoggedIn(true);
                },
                () => {}
            );
        }
    }, [
        setLoggedIn,
        apollo,
        setCurrentPresentation,
        setAgency,
        agency,
        user,
        token,
        presentation,
        iriProperty,
        userInfo,
        setAlertOptions,
        setHeaderHeight,
    ]);

    if (!loggedIn) {
        return null;
    }

    return <>{children}</>;
}

export default LoginForExport;
