import { Button, ButtonProps } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';

export type BigRoundedButtonProps = ButtonProps;

function BigRoundedButton(props: BigRoundedButtonProps) {
    return (
        <Button
            variant="contained"
            {...props}
            sx={(theme: Theme) => ({
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                borderRadius: '30px / 50%',
                textTransform: 'none',
            })}
        />
    );
}

export default BigRoundedButton;
