import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import HandymanIcon from '@mui/icons-material/Handyman';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { Box } from '@mui/material';
import ToolCard, { TOOL_CARD_IMAGE_SIZE } from '../../ui/ToolCard';
import useEditMode from '../template/useEditMode';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import CONFIGS from '../../configs';
import useToolOverride from '../toolOverride/useToolOverride';
import getToolTitle from './getToolTitle';

interface ToolProps {
    tool: ToolResponse;
    color: ThemeColor;
}

const imgSx = {
    width: TOOL_CARD_IMAGE_SIZE,
    aspectRatio: '1 / 1',
    objectFit: 'cover',
};

function Tool({ tool, color }: ToolProps) {
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate(`/outils/${getIdFromIri(entityToId(tool))}`);
    }, [navigate, tool]);
    const [editMode] = useEditMode();
    const agency = useAgency(useSelectedAgency()[0]);
    const toolOverride = useToolOverride(tool, agency);

    const picture = tool.toolThumbnail?.publicPath ? (
        <Box
            component="img"
            src={`${CONFIGS.apiEntrypoint}/${tool.toolThumbnail?.publicPath}`}
            alt={tool.toolThumbnail?.name || ''}
            sx={imgSx}
        />
    ) : undefined;

    return (
        <ToolCard
            color={color}
            icon={HandymanIcon}
            buttonText="Lancer l'outil"
            label={getToolTitle(tool, toolOverride)}
            onClick={handleClick}
            canEdit={editMode}
            image={picture}
            tool={tool}
        />
    );
}

export default Tool;
