import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

/**
 * Indique si un service donné à un prix défini dans un des packs actifs
 * de l'agence.
 */
function serviceHasPriceInSomeEnabledPackage(
    service: ServiceResponse,
    agency: AgencyResponse | undefined
): boolean {
    return (
        agency?.servicePackageOverrides?.collection.some(
            (sp) =>
                matchOnId(sp.service, service) &&
                sp.price !== null &&
                // check si le pack du servicePackageOverride est activé
                agency.packageAgencies?.collection.some(
                    (pa) =>
                        matchOnId(pa.package, sp.package) &&
                        pa.disabled === false
                )
        ) || false
    );
}

export default serviceHasPriceInSomeEnabledPackage;
