import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { useEffect } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { ApolloError } from '@apollo/client';
import { concatViolations } from '@acdc/shared/src/utils/error-helpers';
import useQuotationQueue from './useQuotationQueue';
import useSendQuotation from '../useSendQuotation';

function QuotationQueueHandler() {
    const { queue, setQueue } = useQuotationQueue();
    const isOnline = useOnlineStatus();
    const sendQuotation = useSendQuotation();
    const setAlert = useAlert();

    useEffect(() => {
        if (isOnline && queue.length) {
            setQueue([]);

            // envoi des devis l'un après l'autre pour éviter de surcharger l'api
            queue
                .reduce((lastQuery: Promise<any>, quotation) => {
                    return lastQuery
                        .then(() => {
                            return sendQuotation(quotation);
                        })
                        .catch((err: ApolloError) => {
                            setAlert(
                                `Une erreur est survenue lors de l'envoi du devis à ${
                                    quotation.clientEmailAddress
                                } : ${concatViolations(
                                    err,
                                    'Erreur serveur.'
                                )}`,
                                'error'
                            );
                        });
                }, Promise.resolve())
                .then(() => {
                    const message =
                        queue.length === 1
                            ? `Le devis a été envoyé à ${queue[0].clientEmailAddress}.`
                            : `Les ${queue.length} devis en attente ont été envoyés.`;
                    setAlert(message, 'success');
                });
        }
    }, [queue, isOnline, setQueue, sendQuotation, setAlert]);

    return null;
}

export default QuotationQueueHandler;
