import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';

interface BreadcrumbLink {
    label: string;
    path: string;
}

const breadcrumbsSx = {
    mt: 2,
};

export interface BreadcrumbProps {
    currentPage: string;
    previousPages: BreadcrumbLink[];
}

function Breadcrumb({ currentPage, previousPages }: BreadcrumbProps) {
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={breadcrumbsSx}>
            {previousPages &&
                previousPages.map((previousPage: BreadcrumbLink) => (
                    <Link
                        component={LinkRouter}
                        to={previousPage.path}
                        key={previousPage.label}
                    >
                        {previousPage.label}
                    </Link>
                ))}
            <Typography color="text.primary">{currentPage}</Typography>
        </Breadcrumbs>
    );
}

export default Breadcrumb;
