import { Box, Drawer, IconButton, Toolbar } from '@mui/material';
import React, { useCallback } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DiscoveryMenu, { DiscoveryMenuProps } from './DiscoveryMenu';

export interface DiscoveryDrawerProps extends DiscoveryMenuProps {
    isOpen: boolean;
    toggleDrawer: () => void;
}

function DiscoveryDrawer({
    isOpen,
    toggleDrawer,
    goTo,
    ...props
}: DiscoveryDrawerProps) {
    const handleGoTo: DiscoveryMenuProps['goTo'] = useCallback(
        (index) => {
            goTo(index);
            toggleDrawer();
        },
        [goTo, toggleDrawer]
    );
    return (
        <Drawer
            id="discoveryDrawer"
            anchor="right"
            open={isOpen}
            ModalProps={{ onBackdropClick: toggleDrawer }}
            sx={{
                '& .MuiDrawer-paper': {
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    backgroundColor: 'background.default',
                    width: '40%',
                },
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    backgroundColor: 'background.default',
                    boxShadow: 'none',
                    width: '100%',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        px: [1],
                    }}
                >
                    <IconButton
                        onClick={toggleDrawer}
                        data-testid="sideBarCloseButton"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Toolbar>
            </Box>
            <Box mt="64px" overflow="auto" padding="0rem 1rem 0rem 2rem">
                <DiscoveryMenu {...props} goTo={handleGoTo} />
            </Box>
        </Drawer>
    );
}

export default DiscoveryDrawer;
