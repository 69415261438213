import { useCallback } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { FormikHelpers } from 'formik';
import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import type {
    SendQuotationFormProps,
    SendQuotationFormValue,
} from './SendQuotationForm';
import useQuotationQueue from '../QuotationQueue/useQuotationQueue';
import { usePresentationStatisticQueue } from '../../presentationStatistic/PresentationStatisticQueue';
import useCurrentPresentation from '../../presentation/useCurrentPresentation';

/**
 * Sauvegarde le devis pour l'envoyer dés que possible.
 */
const useSubmitSendQuotation = (
    initialValue: DeepPartial<Quotation> | undefined,
    onSuccess: SendQuotationFormProps['onSuccess']
) => {
    const setAlert = useAlert();
    const { addItem: enqueueQuotation } = useQuotationQueue();
    const isOnline = useOnlineStatus();
    const { updateValue: updatePresentationStatistic } =
        usePresentationStatisticQueue();
    const [currentPresentation] = useCurrentPresentation();

    return useCallback(
        (
            formValues: SendQuotationFormValue,
            { setSubmitting }: FormikHelpers<SendQuotationFormValue>
        ) => {
            const tools: (string | undefined)[] = formValues.tools.map(
                (tool) => tool?.id
            );

            const quotation: DeepPartial<Quotation> = {
                ...initialValue,
                ...formValues,
                tools,
            };

            enqueueQuotation(quotation);
            updatePresentationStatistic(currentPresentation?.uid || '', {
                quotationSent: true,
            });
            if (!isOnline) {
                setAlert(
                    'Votre devis sera envoyé une fois connecté à internet.',
                    'info'
                );
            }
            onSuccess && onSuccess();
            setSubmitting(false);
        },
        [
            setAlert,
            onSuccess,
            initialValue,
            enqueueQuotation,
            isOnline,
            updatePresentationStatistic,
            currentPresentation,
        ]
    );
};

export default useSubmitSendQuotation;
