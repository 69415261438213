import { useEffect } from 'react';
import useAgencies from './useAgencies';
import useSelectedAgency from './useSelectedAgency';

function SelectDefaultAgency() {
    const [selectedAgency, setSelectedAgency] = useSelectedAgency();
    const agencies = useAgencies();

    useEffect(() => {
        if (!selectedAgency && agencies?.length) {
            setSelectedAgency(agencies[0].id!);
        }
    }, [selectedAgency, setSelectedAgency, agencies]);

    return null;
}

export default SelectDefaultAgency;
