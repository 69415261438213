import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import isContentEnabled from '../content/isContentEnabled';

const filterVisibleChapterContents = (
    presentation: PresentationFlowResponse | undefined,
    editMode: boolean,
    selectedAgency: AgencyResponse | undefined,
    shouldAlwaysRenderChapters: boolean = false
) => {
    if (editMode) {
        return presentation;
    }
    return {
        ...presentation,
        chapters: {
            collection:
                presentation?.chapters?.collection.reduce((acc, chapter) => {
                    const filteredChapterContents = {
                        ...chapter,
                        chapterContents: {
                            collection:
                                chapter?.chapterContents?.collection.filter(
                                    (chapterContent) => {
                                        return isContentEnabled(
                                            chapterContent?.content?.id,
                                            selectedAgency
                                        );
                                    }
                                ) || [],
                        },
                    };

                    if (
                        filteredChapterContents?.chapterContents?.collection
                            .length !== 0 ||
                        shouldAlwaysRenderChapters
                    ) {
                        acc.push(filteredChapterContents);
                    }
                    return acc;
                }, [] as ChapterResponse[]) || [],
        },
    };
};

export default filterVisibleChapterContents;
