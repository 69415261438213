import { useAlert } from '@acdc/shared/src/tools/alert';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
    useMutation,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    useApolloClient,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import type CreateImageOverrideFileResponse from '@acdc/shared/src/features/imageOverrideFile/CreateImageOverrideFileResponse';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import type MutateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/MutateImageOverrideResponse';
import type ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import type ImageOverride from '@acdc/shared/src/features/imageOverride/ImageOverride.model';
import type CreateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/CreateImageOverrideResponse';
import type UpdateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/UpdateImageOverrideResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import arrayMapOrPush from '@acdc/shared/src/utils/arrayMapOrPush';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import type { PartnersFormProps, PartnersFormValues } from './PartnersForm';
import {
    CREATE_IMAGE_OVERRIDE,
    CREATE_IMAGE_OVERRIDE_FILE,
    UPDATE_IMAGE_OVERRIDE,
} from '../../imageOverride/ImageOverrideForm/useSubmitImageOverride';
import { GET_IMAGE_OVERRIDES } from '../../imageOverride/useImageOverrides';

function doSubmit(
    formValues: PartnersFormValues,
    allImageOverrides: ImageOverrideResponse[] | undefined,
    createImageOverride: (
        options: MutationFunctionOptions<CreateImageOverrideResponse>
    ) => Promise<FetchResult<CreateImageOverrideResponse>>,
    updateImageOverride: (
        options: MutationFunctionOptions<UpdateImageOverrideResponse>
    ) => Promise<FetchResult<UpdateImageOverrideResponse>>,
    uploadImageOverrideFile: (
        options: MutationFunctionOptions<CreateImageOverrideFileResponse>
    ) => Promise<FetchResult<CreateImageOverrideFileResponse>>,
    setUuidImageOverride: Dispatch<SetStateAction<string[]>>,
    uuidImageOverride: string[],
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ImageOverride>,
    module: ModuleResponse | undefined
) {
    const moduleSettings = module?.settings?.collection
        .filter((setting) => setting.code?.startsWith('image_partner'))
        .sort(
            (setting1, setting2) =>
                setting1.code?.localeCompare(setting2.code || '') || 0
        );

    const uploadImageOverrides: Promise<
        FetchResult<MutateImageOverrideResponse>
    >[] = moduleSettings!.map((moduleSetting, index) => {
        const formValue = formValues.files[index];

        return Promise.resolve()
            .then(() => {
                if (
                    formValue &&
                    formValue.type === ModuleFileEnum.FILE &&
                    formValue.file
                ) {
                    setUuidImageOverride([
                        ...uuidImageOverride,
                        formValue.uuid,
                    ]);
                    return uploadImageOverrideFile({
                        variables: {
                            input: {
                                file: formValue.file,
                            },
                        },
                    }).catch((err: ApolloError) => {
                        handleError()(err);
                        throw Error('cancelled');
                    });
                }
                return null;
            })
            .then(
                (
                    imageOverrideFile: FetchResult<CreateImageOverrideFileResponse> | null
                ): Promise<FetchResult<MutateImageOverrideResponse>> => {
                    const imageOverrideFileId = formValue?.imageOverrideFile
                        ? entityToId(formValue.imageOverrideFile)
                        : entityToId(
                              imageOverrideFile?.data?.createImageOverrideFile
                                  ?.imageOverrideFile
                          );

                    const input: DeepPartial<ImageOverride> = {
                        ...fixedProperties,
                        moduleSetting: entityToId(moduleSetting) || '',
                        sharedFile: formValue
                            ? entityToId(formValue?.sharedFile)
                            : null,
                        imageOverrideFile: imageOverrideFileId || null,
                    };

                    const oldValue = allImageOverrides?.find((imageOverride) =>
                        matchOnId(imageOverride.moduleSetting, moduleSetting)
                    );

                    const isUpdate = Boolean(oldValue?.id);

                    if (isUpdate) {
                        input.id = oldValue?.id || '';
                    }

                    const mutate = isUpdate
                        ? updateImageOverride
                        : createImageOverride;

                    return mutate({ variables: { input } }).catch(
                        (err: ApolloError) => {
                            handleError()(err);
                            throw Error('cancelled');
                        }
                    );
                }
            );
    });

    return Promise.all(uploadImageOverrides);
}
/**
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans le SettingValue
 * @param allImageOverrides
 * @returns
 */
const useSubmitImageOverrides = (
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: PartnersFormProps['onSuccess'],
    onError: PartnersFormProps['onError'],
    fixedProperties: DeepPartial<ImageOverride>,
    allImageOverrides: ImageOverrideResponse[] | undefined,
    selectedAgency: AgencyResponse | undefined,
    module: ModuleResponse | undefined
) => {
    const setAlert = useAlert();
    const [createImageOverride] = useMutation(CREATE_IMAGE_OVERRIDE, {
        // pas d'éviction du cache
    });

    const [updateImageOverride] = useMutation(UPDATE_IMAGE_OVERRIDE, {
        // pas d'éviction du cache
    });

    const [uploadImageOverrideFile] = useMutation(CREATE_IMAGE_OVERRIDE_FILE, {
        // pas d'éviction du cache
    });

    const [uuidImageOverride, setUuidImageOverride] = useState<string[]>([]);

    const client = useApolloClient();
    const { cache } = client;
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);

    return useCallback(
        (
            formValues: PartnersFormValues,
            { setSubmitting, setErrors }: FormikHelpers<PartnersFormValues>
        ) => {
            doSubmit(
                formValues,
                allImageOverrides,
                createImageOverride,
                updateImageOverride,
                uploadImageOverrideFile,
                setUuidImageOverride,
                uuidImageOverride,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties,
                module
            )
                .then((results: FetchResult<MutateImageOverrideResponse>[]) => {
                    const updatedImageOverrides = results.map((res) => {
                        const resItem:
                            | ImageOverrideResponse
                            | null
                            | undefined =
                            res?.data?.updateImageOverride?.imageOverride ||
                            res?.data?.createImageOverride?.imageOverride;

                        if (!resItem?.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res?.data);
                        }
                        return resItem;
                    });

                    cache.updateQuery(
                        {
                            query: GET_IMAGE_OVERRIDES,
                            broadcast: false,
                            variables: {
                                agencyCode: getIdFromIri(
                                    entityToId(
                                        isConsultant
                                            ? selectedAgency
                                            : undefined
                                    )
                                ),
                            },
                        },
                        (data) => {
                            const newCollection = updatedImageOverrides.reduce(
                                (currentCollection, updatedImageOverride) => {
                                    return arrayMapOrPush(
                                        currentCollection,
                                        updatedImageOverride,
                                        matchOnId
                                    );
                                },
                                data.imageOverrides.collection
                            );

                            return {
                                imageOverrides: {
                                    ...data.imageOverrides,
                                    collection: newCollection,
                                },
                            };
                        }
                    );

                    const filteredImageOverrides: ImageOverrideResponse[] =
                        updatedImageOverrides.filter(
                            (value): value is ImageOverrideResponse =>
                                value !== null && value !== undefined
                        );

                    onSuccess && onSuccess(filteredImageOverrides);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            allImageOverrides,
            createImageOverride,
            updateImageOverride,
            uploadImageOverrideFile,
            uuidImageOverride,
            fixedProperties,
            module,
            setAlert,
            yupSchema,
            onError,
            cache,
            isConsultant,
            selectedAgency,
            onSuccess,
        ]
    );
};
export default useSubmitImageOverrides;
