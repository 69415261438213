import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import React, { useEffect, useRef } from 'react';
import useAutoSubmitForm from '@acdc/shared/src/utils/useAutoSubmitForm';
import { useFormikContext } from 'formik';
import DiscoveryCriteriaInput from './DiscoveryCriteriaInput';

export interface DiscoveryCriteriaFormInnerProps {
    discoveryCriteria: DiscoveryCriteriaResponse;
    agency: AgencyResponse;
    onError?: () => void;
    onClearErrors?: () => void;
}

function DiscoveryCriteriaFormInner({
    discoveryCriteria,
    agency,
    onError,
    onClearErrors,
}: DiscoveryCriteriaFormInnerProps) {
    useAutoSubmitForm();

    const { isValid } = useFormikContext();
    const previousIsValid = useRef<boolean>(isValid);
    useEffect(() => {
        if (isValid === previousIsValid.current) {
            return;
        }
        previousIsValid.current = isValid;

        // Si le form change de statut de validité on prévient le parent.
        if (!isValid && onError) {
            onError();
        }
        if (isValid && onClearErrors) {
            onClearErrors();
        }
    }, [isValid, onError, onClearErrors]);

    return (
        <DiscoveryCriteriaInput
            discoveryCriteria={discoveryCriteria}
            agency={agency}
        />
    );
}

export default DiscoveryCriteriaFormInner;
