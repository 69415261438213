import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useProperties from './useProperties';

function useProperty(property: string | PropertyResponse | undefined) {
    const properties = useProperties();

    return useMemo(() => {
        if (!property) {
            return undefined;
        }

        return properties?.find((el) => matchOnId(el, property));
    }, [properties, property]);
}

/**
 * Retourne un Property à partir de son id.
 */
export default useProperty;
