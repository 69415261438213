import { Box } from '@mui/material';
import React from 'react';
import PackageLogo from '../PackageLogo';
import usePackage from '../usePackage';
import useOverridedPackage from '../useOverridedPackage';
import useIsExport from '../../useIsExport';
import EXPORT_CONFIGS from '../../EXPORT_CONFIGS';

const exportFontSize = '0.75rem';

export interface PackageIdentityProps {
    packageId: string;
    width: string | number;
}

/**
 * Logo et label représentant un Package.
 */
function PackageIdentity({ packageId, width }: PackageIdentityProps) {
    const pack = usePackage(packageId);
    const overridedPackage = useOverridedPackage(pack);
    const isExport = useIsExport();
    const packageLogoSx = isExport
        ? {
              boxShadow: EXPORT_CONFIGS.paperShadow,
              width: '30px',
          }
        : {
              width: '40px',
          };

    if (!overridedPackage) {
        return null;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={width}
        >
            <PackageLogo
                sx={{ padding: 0.5, mb: 1, ...packageLogoSx }}
                src={`${overridedPackage.packageImageUrl}`}
                backgroundColor={isExport ? 'common.white' : undefined}
                elevation={isExport ? 1 : undefined}
            />
            <Box
                color="primary.dark"
                textTransform="uppercase"
                fontFamily="Lexend"
                fontSize={isExport ? exportFontSize : '0.8rem'}
            >
                Pack
            </Box>
            <Box
                color={overridedPackage.color}
                fontWeight="bolder"
                fontFamily="Lexend"
                textTransform="uppercase"
                textAlign="center"
                fontSize={isExport ? exportFontSize : '0.9rem'}
            >
                {overridedPackage.label}
            </Box>
        </Box>
    );
}

export default PackageIdentity;
