import DialogButton from '@acdc/shared/src/ui/DialogButton';
import React from 'react';
import { Badge } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import MenuItemDialogButton from '../../ui/buttons/MenuItemDialogButton';
import NotificationList from './NotificationList';
import useNotificationsUserOfUser from '../notificationUser/useNotificationsUserOfUser';
import useNotificationUserNotRead from './useNotificationUserNotRead';
import useMarkNotificationUsersAsRead from './useMarkNotificationUsersAsRead';
import { useNotificationQueue } from './NotificationQueue';
import useNotificationsUserEditOffline from '../notificationUser/useNotificationsUserEditOffline';

function NotificationDialog() {
    const notificationUsers = useNotificationsUserOfUser();
    const { queueDelete, queueUpdate } = useNotificationQueue();
    const isOnline = useOnlineStatus();

    const notificationUsersWithOffline = useNotificationsUserEditOffline(
        notificationUsers,
        queueDelete,
        queueUpdate,
        isOnline
    );

    const notReadNotification = useNotificationUserNotRead(
        notificationUsersWithOffline
    );
    const markNotificationUsersAsRead =
        useMarkNotificationUsersAsRead(notReadNotification);

    return (
        <DialogButton
            label={
                <Badge
                    color="secondary"
                    badgeContent={notReadNotification?.length}
                >
                    <NotificationsNoneIcon />
                </Badge>
            }
            buttonComponent={MenuItemDialogButton}
            buttonProps={{
                'data-testid': 'listNotification',
            }}
            dialogProps={{
                id: 'listNotification',
                mainTitle: 'Notifications',
                maxWidth: false,
                fullWidth: false,
                sx: { '& .MuiDialogContent-root': { padding: 2 } },
            }}
            onClose={markNotificationUsersAsRead}
        >
            {() => {
                return notificationUsersWithOffline?.length ? (
                    <NotificationList
                        notificationUsers={notificationUsersWithOffline}
                    />
                ) : (
                    <p>Vous n&apos;avez pas de notifications</p>
                );
            }}
        </DialogButton>
    );
}

export default NotificationDialog;
