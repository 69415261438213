import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import useAgency from '../agency/useAgency';

/**
 * Retourne les PackageAgencies d'une agence.
 */
function usePackageAgenciesOf(
    agencyId: string | AgencyResponse | undefined,
    enabledOnly: boolean = false
) {
    const agency = useAgency(entityToId(agencyId) || undefined);

    return useMemo(() => {
        if (!agency) {
            return undefined;
        }

        const packageAgencies = agency.packageAgencies?.collection;
        let res = packageAgencies;

        if (enabledOnly) {
            res = packageAgencies?.filter(
                (packageAgency) => packageAgency.disabled === false
            );
        }

        // trie par id de pack pour les avoir dans l'ordre classique
        res?.sort((a, b) => {
            return a.package?.id && b.package?.id && a.package.id < b.package.id
                ? -1
                : 1;
        });

        return res;
    }, [agency, enabledOnly]);
}

export default usePackageAgenciesOf;
