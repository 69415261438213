import React from 'react';
import DiscoveryQueueHandler from './DiscoveryQueueHandler';
import DiscoveryQueueProvider from './DiscoveryQueueProvider';

export interface DiscoveryQueueProps {
    children: React.ReactNode;
}

function DiscoveryQueue({ children }: DiscoveryQueueProps) {
    return (
        <DiscoveryQueueProvider>
            <DiscoveryQueueHandler />
            {children}
        </DiscoveryQueueProvider>
    );
}

export default DiscoveryQueue;
