import { useAlert } from '@acdc/shared/src/tools/alert';
import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';
import useCacheAge from '../apollo/useCacheAge';

function useRefreshApiData() {
    const [, setCacheAge] = useCacheAge();
    const setAlert = useAlert();
    const apollo = useApolloClient();
    const [loading, setLoading] = useState<boolean>(false);

    const refreshApiData = useCallback(() => {
        setLoading(true);
        setCacheAge(new Date());
        setAlert('Rafraîchissement en cours.', 'warning');

        apollo.reFetchObservableQueries().then(
            () => {
                setLoading(false);
                setAlert('Application à jour.', 'success');
            },
            () => {
                setLoading(false);
                setAlert(
                    "Une erreur est survenue lors du rafraîchissement de l'application. Essayez de vous déconnecter et reconnecter.",
                    'error'
                );
            }
        );
    }, [setCacheAge, setAlert, apollo, setLoading]);

    return { refreshApiData, loading };
}

export default useRefreshApiData;
