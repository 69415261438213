import {
    Box,
    InputAdornment,
    Slider,
    TextField,
    TextFieldProps,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

const inputSx = {
    maxWidth: '40%',
};

export type SliderWithInputOwnProps = {
    min: number;
    max: number;
    step: number;
    unit?: string | undefined;
    disableMaxOnInput?: boolean | undefined;
};

export type SliderWithInputProps = TextFieldProps & SliderWithInputOwnProps;

/**
 * Comme un Checkbox mais présenté avec un bouton à la place d'une box.
 */
function SliderWithInput({
    min,
    max,
    step,
    unit,
    disableMaxOnInput,
    ...textFieldProps
}: SliderWithInputProps) {
    const { name, value, onChange, disabled } = textFieldProps;
    let sliderValue = parseFloat(`${value}`) || min;
    if (sliderValue < min) {
        sliderValue = min;
    }
    if (sliderValue > max) {
        sliderValue = max;
    }

    const handleSliderChange = useCallback(
        (ev: any) => {
            onChange &&
                onChange({
                    target: {
                        name: name || '',
                        value: `${ev.target.value}`,
                    },
                } as any);
        },
        [onChange, name]
    );

    const sliderMarks = useMemo(
        () => [
            { value: min, label: `${min}` },
            { value: max, label: `${max}` },
        ],
        [min, max]
    );

    return (
        <Box display="flex" width="100%">
            <Box flex="1" paddingX={3}>
                <Slider
                    value={parseFloat(`${value}`) || 0}
                    onChange={handleSliderChange}
                    disabled={disabled}
                    min={min}
                    max={max}
                    step={step}
                    valueLabelDisplay="auto"
                    marks={sliderMarks}
                />
            </Box>
            <TextField
                {...textFieldProps}
                type="number"
                InputProps={{
                    inputProps: {
                        ...textFieldProps.InputProps?.inputProps,
                        min,
                        step,
                        max: disableMaxOnInput ? undefined : max,
                    },
                    endAdornment: !unit ? undefined : (
                        <InputAdornment position="end">{unit}</InputAdornment>
                    ),
                }}
                autoComplete="off"
                sx={inputSx}
            />
        </Box>
    );
}

export default SliderWithInput;
