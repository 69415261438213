import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListPropertiesResponse from '@acdc/shared/src/features/property/ListPropertiesResponse';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

// on filtre sur l'agent pour éviter que les admins chargent des milliers de properties
export const GET_PROPERTIES = gql`
    query GetProperties($userCode: String!, $agencyCode: String) {
        properties(
            agent_code: $userCode
            agency_code: $agencyCode
            order: [{ clientLastname: "ASC" }]
            itemsPerPage: 10000
        ) {
            collection {
                id
                reference
                clientLastname
                clientFirstname
                clientEmail
                clientPhone
                address
                street
                postCode
                city
                agency {
                    id
                }
                presentationFlow {
                    id
                }
            }
        }
    }
`;

const emptyResult: PropertyResponse[] = [];

/**
 * Retourne toutes les Properties.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 *
 * @param user
 * @param agency Filtre optionnel sur une agence
 * @returns
 */
const useLoadProperties = (
    user: string | UserResponse,
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListPropertiesResponse>(GET_PROPERTIES, {
        ...persistentQueryOptions,
        variables: {
            userCode: getIdFromIri(entityToId(user)),
            agencyCode: getIdFromIri(entityToId(agency)),
        },
        skip: skipAgencyRequests || false,
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des biens.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<PropertyResponse[] | undefined>(() => {
        return data?.properties?.collection;
    }, [data]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useLoadProperties;
