import React from 'react';
import { Grid, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import useDiscoveryForms from '../useDiscoveryForms';
import ToolCardSkeleton from '../../../ui/ToolCardSkeleton';
import ToolCard from '../../../ui/ToolCard';
import LocataireIcon from '../../../ui/icons/LocataireIcon';
import AcquereurIcon from '../../../ui/icons/AcquereurIcon';

function getIcon(name: string) {
    switch (name) {
        case 'LocataireIcon':
            return LocataireIcon;
        default:
            return AcquereurIcon;
    }
}

const itemSize: number = 3; // sur 12

function DiscoveryFormsList() {
    const forms = useDiscoveryForms();
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Découvertes
            </Typography>
            <Grid container spacing={4} sx={{ marginTop: 2 }}>
                {forms?.map((discoveryForm) => (
                    <Grid
                        key={discoveryForm.id}
                        item
                        xs={itemSize}
                        data-testid={`discovery${discoveryForm.id}`}
                    >
                        <ToolCard
                            color={discoveryForm.color as ThemeColor}
                            icon={getIcon(discoveryForm.icon || '')}
                            buttonText={discoveryForm.label || ''}
                            onClick={() =>
                                navigate(
                                    `/decouvertes/${getIdFromIri(
                                        discoveryForm.id
                                    )}`
                                )
                            }
                        />
                    </Grid>
                ))}
                {!forms &&
                    [1, 2].map((key) => (
                        <Grid key={key} item xs={itemSize}>
                            <ToolCardSkeleton />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default DiscoveryFormsList;
