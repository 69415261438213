import {
    Box,
    Typography,
    Button,
    Stack,
    SvgIconProps,
    Theme,
} from '@mui/material';
import React from 'react';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import EditToolButton from '../features/tools/EditToolButton';

export const TOOL_CARD_IMAGE_SIZE = 150;

interface ToolCardIconProps {
    color: ThemeColor;
    icon: React.FunctionComponent<SvgIconProps>;
}

function ToolCardIcon({ color, icon }: ToolCardIconProps) {
    const IconComponent = icon;

    return (
        <Box
            sx={{
                height: TOOL_CARD_IMAGE_SIZE,
                width: TOOL_CARD_IMAGE_SIZE,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: (theme: Theme) =>
                    `2px solid ${theme.palette[color || 'primary']?.main}`,
            }}
        >
            <IconComponent color={color} sx={{ fontSize: '80px' }} />
        </Box>
    );
}

export interface ToolCardProps {
    color: ThemeColor;
    buttonText: string;
    label?: React.ReactNode | undefined;
    body1?: React.ReactNode | undefined;
    body2?: React.ReactNode | undefined;
    onClick?: () => void;
    /**
     * Icon a afficher à la place de l'image
     */
    icon?: ToolCardIconProps['icon'];
    /**
     * Image a afficher au début. Si undefined l'icon sera affiché
     */
    image?: React.ReactNode;
    tool?: ToolResponse;
    canEdit?: boolean | undefined;
}

function ToolCard({
    color,
    icon,
    label,
    body1,
    body2,
    buttonText,
    onClick,
    image: imageProp,
    tool,
    canEdit,
}: ToolCardProps) {
    let image = imageProp || null;

    if (!image && icon) {
        image = <ToolCardIcon color={color} icon={icon} />;
    }

    return (
        <Box>
            <Stack spacing={1} direction="column" alignItems="center">
                {image}
                <Box display="flex">
                    {canEdit && tool && <EditToolButton tool={tool} />}
                </Box>
                {label && (
                    <Typography variant="h4" textAlign="center">
                        {label}
                    </Typography>
                )}

                {Boolean(body1 || body2) && (
                    <Stack direction="column" alignItems="center">
                        {body1 && (
                            <Typography variant="body2" textAlign="center">
                                {body1}
                            </Typography>
                        )}
                        {body2 && (
                            <Typography variant="body2" textAlign="center">
                                {body2}
                            </Typography>
                        )}
                    </Stack>
                )}
                <Button
                    data-testid="toolCardButton"
                    variant="contained"
                    size="small"
                    color={color}
                    type="button"
                    sx={{ minWidth: '135px', cursor: 'pointer' }}
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            </Stack>
        </Box>
    );
}

export default ToolCard;
