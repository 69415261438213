import { Paper, Stack, Box } from '@mui/material';
import React from 'react';
import usePackage from './usePackage';
import PackageLogo from './PackageLogo';
import PackageTeaserSkeleton from './PackageTeaserSkeleton';
import useOverridedPackage from './useOverridedPackage';

interface PackageTeaserProps {
    rate: number | null | undefined;
    packageId: string;
}

/**
 * Petit aperçu d'un Package.
 */
function PackageTeaser({ packageId, rate }: PackageTeaserProps) {
    const pack = usePackage(packageId);
    const overridedPackage = useOverridedPackage(pack);

    if (!overridedPackage) {
        return <PackageTeaserSkeleton />;
    }

    return (
        <Paper sx={{ height: '100%', width: '100%' }}>
            <Stack
                py={2}
                px={1}
                direction="column"
                alignItems="center"
                spacing={2}
            >
                <PackageLogo
                    sx={{ width: '140px', height: '140px' }}
                    src={`${overridedPackage.packageImageUrl}`}
                />
                <Box>
                    <Box
                        sx={{
                            color: 'primary.dark',
                            fontSize: 20,
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            fontFamily: 'Lexend',
                        }}
                    >
                        Pack
                    </Box>
                    <Box
                        component="h4"
                        sx={{
                            color: overridedPackage.color,
                            fontWeight: 'bolder',
                            fontSize: 25,
                            textAlign: 'center',
                            textTransform: 'uppercase',
                            textDecoration: 'underline',
                            textUnderlineOffset: '5px',
                            textDecorationThickness: '4px',
                            fontFamily: 'Lexend',
                            whiteSpace: 'nowrap',
                            margin: 0,
                        }}
                    >
                        {overridedPackage.label}
                    </Box>
                </Box>
                <Box
                    sx={{
                        color: overridedPackage.color,
                        fontSize: 22,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontWeight: 'regular',
                        fontFamily: 'Lexend',
                    }}
                >
                    {rate !== undefined ? rate : '?'}% TTC
                </Box>
            </Stack>
        </Paper>
    );
}

export default PackageTeaser;
