import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function DiscoveryIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 81 81">
            <rect
                x="1.5"
                y="1.5"
                width="78"
                height="78"
                rx="39"
                fill="#376CD3"
            />
            <rect
                x="1.5"
                y="1.5"
                width="78"
                height="78"
                rx="39"
                stroke="#376CD3"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.4999 40.5C60.5067 45.6511 58.5193 50.6048 54.9542 54.3229C53.0957 56.2711 50.862 57.8227 48.3876 58.8844C45.9133 59.946 43.2496 60.4956 40.5571 60.5H40.4428C37.7503 60.4956 35.0866 59.946 32.6123 58.8844C30.138 57.8227 27.9042 56.2711 26.0457 54.3229C23.7856 51.9595 22.1394 49.078 21.2514 45.9307C20.3635 42.7834 20.2608 39.4665 20.9524 36.2703C21.644 33.0741 23.1087 30.0963 25.2183 27.5976C27.3279 25.099 30.0181 23.1557 33.0531 21.9381C36.088 20.7205 39.3753 20.2657 42.6269 20.6134C45.8785 20.9612 48.9952 22.101 51.704 23.9329C54.4128 25.7649 56.6311 28.2331 58.1646 31.1214C59.6981 34.0097 60.4999 37.2299 60.4999 40.5ZM52.6228 50.5C51.1498 48.7111 49.2987 47.2708 47.2025 46.2827C45.1064 45.2946 42.8173 44.7834 40.5 44.7857C38.1826 44.7834 35.8935 45.2946 33.7974 46.2827C31.7012 47.2708 29.8501 48.7111 28.3771 50.5C29.8501 52.289 31.7012 53.7293 33.7974 54.7173C35.8935 55.7054 38.1826 56.2166 40.5 56.2143C42.8173 56.2166 45.1064 55.7054 47.2025 54.7173C49.2987 53.7293 51.1498 52.289 52.6228 50.5ZM40.5 41.9286C42.3944 41.9286 44.2112 41.1761 45.5507 39.8365C46.8902 38.497 47.6428 36.6802 47.6428 34.7858C47.6428 32.8914 46.8902 31.0746 45.5507 29.735C44.2112 28.3955 42.3944 27.6429 40.5 27.6429C38.6056 27.6429 36.7887 28.3955 35.4492 29.735C34.1097 31.0746 33.3571 32.8914 33.3571 34.7858C33.3571 36.6802 34.1097 38.497 35.4492 39.8365C36.7887 41.1761 38.6056 41.9286 40.5 41.9286Z"
                fill="white"
            />
        </SvgIcon>
    );
}

export default DiscoveryIcon;
