import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import ServiceUnit from '@acdc/shared/src/features/service/ServiceUnit.enum';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

/**
 * Indique si un service donné est désactivé dans un des packs actifs
 * de l'agence.
 */
function serviceIsDisabledInSomeEnabledPackage(
    service: ServiceResponse,
    agency: AgencyResponse | undefined
): boolean {
    return (
        agency?.servicePackageOverrides?.collection.some(
            (sp) =>
                matchOnId(sp.service, service) &&
                sp.unit === ServiceUnit.DISABLED &&
                // check si le pack du servicePackageOverride est activé
                agency.packageAgencies?.collection.some(
                    (pa) =>
                        matchOnId(pa.package, sp.package) &&
                        pa.disabled === false
                )
        ) || false
    );
}

export default serviceIsDisabledInSomeEnabledPackage;
