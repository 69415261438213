import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import useToolCategories from '../features/toolsCategory/useToolCategories';
import ToolCategory from '../features/toolsCategory/ToolCategory';
import useIsToolCategoryEnabled from '../features/toolsCategory/useIsToolCategoryEnabled';

const itemSize: number = 3; // sur 12

function ToolCategories() {
    const allToolCategories = useToolCategories();
    const isToolCategoryEnabled = useIsToolCategoryEnabled();

    const enabledToolCategories = useMemo(() => {
        return allToolCategories?.filter(
            (toolCategory) => isToolCategoryEnabled(toolCategory) !== false
        );
    }, [allToolCategories, isToolCategoryEnabled]);

    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Outils
            </Typography>
            <Grid container spacing={4} sx={{ mt: 2, mb: 2 }}>
                {enabledToolCategories?.map((toolCategory) => (
                    <Grid key={toolCategory.id} item xs={itemSize}>
                        <ToolCategory toolCategory={toolCategory} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default ToolCategories;
