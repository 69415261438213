import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ListToolCategoryResponse';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_TOOL_CATEGORIES = gql`
    query GetToolCategories {
        toolCategories(itemsPerPage: 10000) {
            collection {
                id
                label
                themeColor
            }
        }
    }
`;
/**
 * Retourne toutes les rubriques d'outils.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useLoadToolCategories = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListToolCategoryResponse>(
        GET_TOOL_CATEGORIES,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des rubriques d'outils.",
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<ToolCategoryResponse[] | undefined>(() => {
        return data?.toolCategories?.collection;
    }, [data]);
};

export default useLoadToolCategories;
