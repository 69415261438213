import { Box, SxProps } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function isOverflowingOnHeight(element: Element) {
    return element.scrollHeight > element.clientHeight;
}

const absoluteSx = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'auto',
};

const moreIconContainerSx = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 10,
    background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%)',
    display: 'flex',
    justifyContent: 'center',
};

export interface FlexScrollableProps {
    sx?: SxProps;
    children?: React.ReactNode;

    /**
     * Affiche un icon de points de suspension si le contenu est plus grand que l'espace flexible.
     */
    showMoreIconIfOverflown?: boolean;
}

/**
 * Wrapper utilisable dans un container flex.
 * Il a flex:1 mais si son contenu est plus grand que l'espace flexible, le scroll
 * sera disponible.
 */
function FlexScrollable({
    sx,
    children,
    showMoreIconIfOverflown,
}: FlexScrollableProps) {
    const scrollableElementRef = useRef<HTMLDivElement>(null);
    const [overflown, setOverflown] = useState<boolean>(false);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (scrollableElementRef.current) {
                setOverflown(
                    isOverflowingOnHeight(scrollableElementRef.current)
                );
            }
        });

        // il faut lancer l'observation dans un timeout sinon ça ne fonctionne pas toujours dans l'export PDF.
        const timer = setTimeout(() => {
            if (scrollableElementRef.current) {
                resizeObserver.observe(scrollableElementRef.current);
            }
        }, 0);

        return () => {
            resizeObserver.disconnect();
            clearTimeout(timer);
        };
    }, [setOverflown]);

    const shouldShowMoreIcon = showMoreIconIfOverflown && overflown;

    return (
        <Box
            data-testid="FlexScrollable"
            sx={{ flex: 1, position: 'relative', ...sx }}
            className="FullPageScrollable"
        >
            <Box
                ref={scrollableElementRef}
                sx={absoluteSx}
                pb={shouldShowMoreIcon ? '35px' : 0}
            >
                {children}
            </Box>
            {shouldShowMoreIcon && (
                <Box sx={moreIconContainerSx}>
                    <MoreHorizIcon fontSize="large" />
                </Box>
            )}
        </Box>
    );
}

export default FlexScrollable;
