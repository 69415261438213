import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListSectionsResponse from '@acdc/shared/src/features/section/ListSectionsResponse';
import SectionResponse from '@acdc/shared/src/features/section/SectionResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_SECTIONS = gql`
    query GetSections {
        sections(itemsPerPage: 10000) {
            collection {
                id
                height
                width
                row
                col
                template {
                    id
                }
            }
        }
    }
`;

/**
 * Retourne toutes les Sections.
 */
const useSections = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListSectionsResponse>(
        GET_SECTIONS,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des sections de pages.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<SectionResponse[] | undefined>(() => {
        return data?.sections?.collection;
    }, [data]);
};

export default useSections;
