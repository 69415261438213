import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { preloadImage } from '@acdc/shared/src/utils/file-helpers';
import ListPdfAsImagesResponse from '@acdc/shared/src/features/pdfAsImage/ListPdfAsImagesResponse';
import PdfAsImageResponse from '@acdc/shared/src/features/pdfAsImage/PdfAsImageResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import CONFIGS from '../../configs';

export const GET_PDF_AS_IMAGES = gql`
    query GetPdfAsImage($exists: [PdfAsImageFilter_exists]) {
        pdfAsImages(
            itemsPerPage: 10000
            order: [{ id: "ASC" }]
            exists: $exists
        ) {
            collection {
                id
                name
                publicPath
                dimensions
                settingFile {
                    id
                }
                imageOverrideFile {
                    id
                }
            }
        }
    }
`;

const emptyResult: PdfAsImageResponse[] = [];

/**
 * Retourne les images des PDF des ImageOverrideFiles ou des SettingFiles.
 */
const usePdfAsImages = (
    whereExists: 'imageOverrideFile' | 'settingFile',
    skipAgencyRequests: boolean | null | undefined = null,
    enabledPreloadImage: boolean | null | undefined = true
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListPdfAsImagesResponse>(
        GET_PDF_AS_IMAGES,
        {
            ...persistentQueryOptions,
            variables: {
                exists: [{ [whereExists]: true }],
            },
            skip: skipAgencyRequests || false,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des pdfs.',
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<PdfAsImageResponse[] | undefined>(() => {
        const items = data?.pdfAsImages?.collection;

        if (enabledPreloadImage) {
            items?.forEach((pdfAsImage) => {
                if (pdfAsImage.publicPath) {
                    preloadImage(CONFIGS.apiEntrypoint + pdfAsImage.publicPath);
                }
            });
        }

        return items;
    }, [data?.pdfAsImages?.collection, enabledPreloadImage]);

    return skipAgencyRequests ? emptyResult : result;
};

export default usePdfAsImages;
