import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListToolContentsResponse from '@acdc/shared/src/features/toolContent/ListToolContentsResponse';
import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useContentModules from '../contentModule/useContentModules';
import useSections from '../section/useSections';

export const GET_SECTIONS = gql`
    query GetToolContents {
        toolContents(itemsPerPage: 10000) {
            collection {
                id
                title
                required
                position
                tool {
                    id
                }
                template {
                    id
                }
            }
        }
    }
`;

/**
 * Retourne tous les ToolContent.
 */
const useToolContents = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListToolContentsResponse>(
        GET_SECTIONS,
        persistentQueryOptions
    );

    const contentModules = useContentModules();
    const sections = useSections();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des pages des outils.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<ToolContentResponse[] | undefined>(() => {
        if (!contentModules || !sections) {
            return undefined;
        }

        return data?.toolContents?.collection.map((toolContent) => ({
            ...toolContent,
            contentModules: {
                collection: contentModules.filter((contentModule) =>
                    matchOnId(contentModule.content, toolContent)
                ),
            },
            template: {
                sections: {
                    collection: sections.filter((section) =>
                        matchOnId(section.template, toolContent.template)
                    ),
                },
            },
        }));
    }, [contentModules, data, sections]);
};

export default useToolContents;
