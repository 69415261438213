import React, { useMemo, useCallback, useRef } from 'react';
import { Formik, Form } from 'formik';
import {
    initBool,
    entityToId,
    initString,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import type AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { ApolloError } from '@apollo/client';
import type ToolOverrideResponse from '@acdc/shared/src/features/toolOverride/ToolOverrideResponse';
import type ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import type ToolOverride from '@acdc/shared/src/features/toolOverride/ToolOverride.model';
import ToolOverrideFormInner from './ToolOverrideFormInner';
import useSubmitToolOverride from './useSubmitToolOverride';

export interface ToolOverrideFormValue {
    enabled: boolean;
    title: string;
}

export interface ToolOverrideFormProps {
    value?: ToolOverrideResponse;
    agency?: AgencyResponse;
    tool?: ToolResponse;
    onSuccess?: ((res: ToolOverrideResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

export const toolOverrideFormSchema = Yup.object().shape({
    enabled: Yup.bool().label("La visibilité de l'outil").required(),
    title: Yup.string().label("Le titre de l'outil").optional(),
});

const initValue = (
    toolOverride: ToolOverrideResponse
): ToolOverrideFormValue => ({
    enabled: !initBool(toolOverride.disabled, false),
    title: initString(toolOverride.title),
});

function ToolOverrideForm({
    value,
    agency,
    tool,
    onSuccess,
    onError,
}: ToolOverrideFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const fixedProperties = useMemo<DeepPartial<ToolOverride>>(
        () => ({
            agency: entityToId(agency) || undefined,
            tool: entityToId(tool) || undefined,
        }),
        [agency, tool]
    );

    const submit = useSubmitToolOverride(
        getId,
        setId,
        toolOverrideFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );
    const initialValue = useMemo(() => initValue(value || {}), [value]);

    const disableEnabledInput =
        initialValue.enabled === true && tool?.required === true;

    return (
        <Formik
            validationSchema={toolOverrideFormSchema}
            initialValues={initialValue}
            onSubmit={(values: ToolOverrideFormValue, formikHelpers) => {
                submit(values, formikHelpers);
            }}
        >
            {() => (
                <Form data-testid="toolOverrideForm">
                    <ToolOverrideFormInner
                        tool={tool}
                        getToolOverrideId={getId}
                        disableEnabledInput={disableEnabledInput}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default ToolOverrideForm;
