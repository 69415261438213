import React from 'react';
import { Skeleton } from '@mui/material';

function PackageTeaserSkeleton() {
    return (
        <Skeleton
            variant="rectangular"
            data-testid="PackageTeaserSkeleton"
            width="100%"
            height={300}
            sx={{ borderRadius: 1 }}
        />
    );
}

export default PackageTeaserSkeleton;
