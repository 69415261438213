import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListContentModulesResponse from '@acdc/shared/src/features/contentModule/ListContentModulesResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useSettingValues from '../settingValue/useSettingValues';
import useVariables from '../variable/useVariables';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useModuleSettings from './sharedFiles/useModuleSettings';

export const GET_CONTENT_MODULES = gql`
    query GetContentModules {
        contentModules(itemsPerPage: 10000) {
            collection {
                id
                content {
                    id
                }
                section {
                    id
                }
                module {
                    id
                    code
                }
            }
        }
    }
`;

/**
 * Retourne tous les ContentModules.
 */
const useContentModules = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListContentModulesResponse>(
        GET_CONTENT_MODULES,
        persistentQueryOptions
    );

    const settingValues = useSettingValues();
    const variables = useVariables();
    const settings = useModuleSettings();

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des modules des pages.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<ContentModuleResponse[] | undefined>(() => {
        if (!settingValues || !variables || !settings) {
            return undefined;
        }

        return data?.contentModules?.collection.map((contentModule) => ({
            ...contentModule,
            settingValues: {
                collection: settingValues.filter((settingValue) =>
                    matchOnId(settingValue.contentModule, contentModule)
                ),
            },
            variables: {
                collection: variables.filter((variable) =>
                    matchOnId(variable.contentModule, contentModule)
                ),
            },
            module: {
                ...contentModule.module,
                settings: {
                    collection: settings.filter((setting) =>
                        matchOnId(setting.module, contentModule.module)
                    ),
                },
            },
        }));
    }, [data?.contentModules?.collection, settingValues, settings, variables]);
};

export default useContentModules;
