import Quotation from '@acdc/shared/src/features/quotation/Quotation.model';
import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const CREATE_QUOTATION = gql`
    mutation CreateQuotation($input: createQuotationInput!) {
        createQuotation(input: $input) {
            clientMutationId
        }
    }
`;

function useSendQuotation() {
    const [create] = useMutation(CREATE_QUOTATION);

    return useCallback(
        (quotation: DeepPartial<Quotation>) => {
            return create({ variables: { input: quotation } });
        },
        [create]
    );
}

export default useSendQuotation;
