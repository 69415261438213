import React from 'react';
import PresentationStatisticQueueHandler from './PresentationStatisticQueueHandler';
import PresentationStatisticQueueProvider from './PresentationStatisticQueueProvider';

export interface PresentationStatisticQueueProps {
    children: React.ReactNode;
}

function PresentationStatisticQueue({
    children,
}: PresentationStatisticQueueProps) {
    return (
        <PresentationStatisticQueueProvider>
            <PresentationStatisticQueueHandler />
            {children}
        </PresentationStatisticQueueProvider>
    );
}

export default PresentationStatisticQueue;
