import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import SectionResponse from '@acdc/shared/src/features/section/SectionResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

function populateContentRelationships(
    content: ContentResponse,
    sections: SectionResponse[],
    contentModules: ContentModuleResponse[]
) {
    return {
        ...content,
        template: {
            ...content?.template,
            sections: {
                collection: sections.filter((section) =>
                    matchOnId(section.template, content.template)
                ),
            },
        },
        contentModules: {
            collection: contentModules.filter((contentModule) =>
                matchOnId(contentModule.content, content)
            ),
        },
    } as ContentResponse;
}

export default populateContentRelationships;
