import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { Field } from 'formik';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import { RadioGroup } from 'formik-mui';

interface SelectAgencyFormInnerProps {
    agencies: AgencyResponse[];
}

function SelectAgencyFormInner({ agencies }: SelectAgencyFormInnerProps) {
    useAutoSubmitSingleFieldForm('agency');

    return (
        <Field component={RadioGroup} name="agency">
            {agencies?.map((agency: AgencyResponse) => (
                <FormControlLabel
                    key={agency.id}
                    value={agency.id}
                    control={<Radio />}
                    label={agency.name}
                    data-testid={agency.id}
                />
            ))}
        </Field>
    );
}

export default SelectAgencyFormInner;
