import { Box } from '@mui/material';
import React from 'react';

interface CustomStepIconProps {
    active: boolean;
    error: boolean;
}

function CustomStepIcon({ active, error }: CustomStepIconProps) {
    let color = 'common.white';
    active ? (color = 'secondary.light') : color;
    error ? (color = 'error.light') : color;

    let borderColor = 'grey.500';
    active ? (borderColor = 'secondary.light') : borderColor;
    error ? (borderColor = 'error.light') : borderColor;

    return (
        <Box
            sx={{
                height: '0.5rem',
                width: '0.5rem',
                marginLeft: '0.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                borderWidth: '1px',
                borderStyle: 'solid',
                transition: '0.5s',
            }}
            data-testid="CircleStepIcon"
            bgcolor={color}
            borderColor={borderColor}
        />
    );
}

export default CustomStepIcon;
