import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import ListModuleSettingsResponse from '@acdc/shared/src/features/moduleSetting/ListModuleSettingsResponse';

export const GET_MODULE_SETTINGS = gql`
    query GetModuleSettings {
        moduleSettings(itemsPerPage: 10000) {
            collection {
                id
                label
                code
                module {
                    id
                }
            }
        }
    }
`;

const useModuleSettings = () => {
    const setAlert = useAlert();
    const { data, error } =
        useQuery<ListModuleSettingsResponse>(GET_MODULE_SETTINGS);

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des options des modules.',
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.moduleSettings?.collection;
};

export default useModuleSettings;
