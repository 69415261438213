import React from 'react';
import { Grid } from '@mui/material';
import PackageSummary from './PackageSummary';
import useSelectedAgency from '../agency/useSelectedAgency';
import PackageSummariesListSkeleton from './PackageSummariesListSkeleton';
import useAgency from '../agency/useAgency';
import usePackagesSummaries from './usePackagesSummaries';
import useIsExport from '../useIsExport';

/**
 * Liste des résumés des Packages.
 */
function PackageSummariesList() {
    const agency = useAgency(useSelectedAgency()[0]);
    const summaries = usePackagesSummaries(agency);
    const isExport = useIsExport();

    if (!summaries?.length) {
        return <PackageSummariesListSkeleton />;
    }

    return (
        <Grid
            container
            data-testid="PackageTeasersList"
            spacing={2}
            height="100%"
            justifyContent={isExport ? 'center' : undefined}
            // avec spacing=2, Grid ajoute un mt -2 ce qui laisse un espace de 2 en bas. Mais dans le pdf on veut atteindre le bas de page pour avoir un max de place.
            // on laisse quand même une petite marge pour que le shadow des papers s'affiche en bas
            mt={isExport ? -0.2 : undefined}
        >
            {summaries?.map((summary) => (
                <Grid
                    item
                    key={`${summary.overridedPackage.id}`}
                    xs={isExport ? 6 : undefined}
                    sm={isExport ? undefined : true}
                >
                    <PackageSummary summary={summary} />
                </Grid>
            ))}
        </Grid>
    );
}

export default PackageSummariesList;
