import React from 'react';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Fab } from '@mui/material';
import EditContentLink from '../navigation/EditContentLink';
import usePreferences from '../preferences/usePreferences';

interface EditToolProps {
    content: ContentResponse;
    toolId: string | undefined;
}

function EditTool({ content, toolId }: EditToolProps) {
    const [preferences] = usePreferences();
    return (
        <Fab
            size="large"
            color="primary"
            aria-label="modifier"
            sx={{
                position: 'absolute',
                // On remonte le boutons pour ne pas qu'il soit derrière les flêches de navigation.
                bottom: preferences?.navigateWithButtons ? 260 : 20,
                right: 20,
            }}
        >
            <EditContentLink
                content={content}
                to={`/outils/${getIdFromIri(toolId)}`}
                color="white"
            />
        </Fab>
    );
}

export default EditTool;
