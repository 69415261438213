import React, { useMemo, useCallback, useRef } from 'react';
import { Formik, Form } from 'formik';
import ContentOverride from '@acdc/shared/src/features/contentOverride/ContentOverride.model';
import { initBool, entityToId } from '@acdc/shared/src/utils/form-helpers';
import type ContentOverrideResponse from '@acdc/shared/src/features/contentOverride/ContentOverrideResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import type AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import type ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { ApolloError } from '@apollo/client';
import ContentOverrideInnerForm from './ContentOverrideFormInner';
import useSubmitContentOverride from './useSubmitContentOverride';

export interface ContentOverrideFormValue {
    enabled: boolean;
}

export interface ContentOverrideFormProps {
    value?: ContentOverrideResponse;
    agency?: AgencyResponse;
    content?: ContentResponse;
    onSuccess?: ((res: ContentOverrideResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

export const contentOverrideFormSchema = Yup.object().shape({
    enabled: Yup.bool().label('La visibilité de la page').required(),
});

const initValue = (
    contentOverride: ContentOverrideResponse
): ContentOverrideFormValue => ({
    enabled: !initBool(contentOverride.disabled, false),
});

function ContentOverrideForm({
    value,
    agency,
    content,
    onSuccess,
    onError,
}: ContentOverrideFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const fixedProperties = useMemo<DeepPartial<ContentOverride>>(
        () => ({
            agency: entityToId(agency) || undefined,
            content: entityToId(content) || undefined,
        }),
        [agency, content]
    );

    const submit = useSubmitContentOverride(
        getId,
        setId,
        contentOverrideFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );
    const initialValue = useMemo(() => initValue(value || {}), [value]);

    const disabled =
        initialValue.enabled === true && content?.required === true;

    return (
        <Formik
            validationSchema={contentOverrideFormSchema}
            initialValues={initialValue}
            onSubmit={(values: ContentOverrideFormValue, formikHelpers) => {
                submit(values, formikHelpers);
            }}
        >
            {() => (
                <Form data-testid="contentOverrideForm">
                    <ContentOverrideInnerForm
                        getContentOverrideId={getId}
                        disabled={disabled}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default ContentOverrideForm;
