import React, { useCallback, useEffect, useMemo } from 'react';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useNavigate } from 'react-router-dom';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import { Box } from '@mui/material';
import useAuthenticatedUser from '../features/user/useAuthenticatedUser';
import UserForm from '../features/user/UserForm';

function MyProfile() {
    const isOnline = useOnlineStatus();
    const authenticatedUser = useAuthenticatedUser();
    const val = useMemo(
        () => ({
            id: authenticatedUser?.id,
        }),
        [authenticatedUser]
    );
    const setAlert = useAlert();
    const navigate = useNavigate();
    const handleSuccess = useCallback(() => {
        setAlert('Profil modifié.', 'success');
        navigate('/mes-rendez-vous');
    }, [setAlert, navigate]);

    useEffect(() => {
        if (!isOnline) {
            setAlert(
                'Vous ne pouvez pas modifier votre profil hors ligne.',
                'error'
            );
        }
    }, [isOnline, setAlert]);

    if (!authenticatedUser?.id || !isOnline) {
        return <FormSkeleton nbInputs={6} />;
    }

    return (
        <Box mt={3}>
            <UserForm value={val} onSuccess={handleSuccess} />
        </Box>
    );
}

export default MyProfile;
