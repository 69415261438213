import OfficialReactFullpage from '@fullpage/react-fullpage';
import React, { forwardRef } from 'react';
import CONFIGS from '../configs';

// Redéfini parceque la valeur par défaut de ReactFullpage bug https://github.com/alvarotrigo/react-fullpage/issues/435#issuecomment-2020284718
export const SLIDE_SELECTOR = 'slide';
export const FULLPAGE_CREDITS = { enabled: false };

const ReactFullpage = forwardRef(
    (
        {
            slideSelector,
            ...props
        }: Omit<OfficialReactFullpage['props'], 'credits' | 'licenseKey'>,
        ref: any
    ) => {
        return (
            <OfficialReactFullpage
                ref={ref}
                licenseKey={CONFIGS.fullPageLicenseKey}
                credits={FULLPAGE_CREDITS}
                slideSelector={slideSelector || `.${SLIDE_SELECTOR}`}
                {...props}
            />
        );
    }
);

export default ReactFullpage;
