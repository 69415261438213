import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import ToolOverrideResponse from '@acdc/shared/src/features/toolOverride/ToolOverrideResponse';

function getToolTitle(
    tool: ToolResponse,
    toolOverride: ToolOverrideResponse | null | undefined
): string | undefined {
    if (tool.canUpdateTitle && toolOverride?.title) {
        return toolOverride.title;
    }

    return tool.title;
}

export default getToolTitle;
