import React from 'react';
import { Outlet } from 'react-router-dom';
import LayoutSkeleton from '../LayoutSkeleton';
import SizeInterface from '../SizeInterface';
import DiscoveryHeader from './DiscoveryHeader';
import DiscoveryDrawerProvider from './DiscoveryDrawerProvider';

const gutter: SizeInterface = { xs: '2%', md: '5%' };
const width: SizeInterface = { xs: '94%', md: '90%' };

export interface DiscoveryLayoutProps {
    children?: React.ReactNode;
}

function DiscoveryLayout({ children }: DiscoveryLayoutProps) {
    return (
        <DiscoveryDrawerProvider>
            <LayoutSkeleton width={width} gutter={gutter}>
                <DiscoveryHeader width={width} gutter={gutter} />
                {children || <Outlet />}
            </LayoutSkeleton>
        </DiscoveryDrawerProvider>
    );
}

export default DiscoveryLayout;
