import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { To } from 'history';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useLeftSideBarState from '../../layout/LeftSideBar/useLeftSideBarState';

export interface EditContentLinkProps {
    content: ContentResponse | undefined;
    to: To;
    onClick?: () => void;
    color?: string;
}

function EditContentLink({
    content,
    to,
    onClick,
    color,
}: EditContentLinkProps) {
    const navigate = useNavigate();
    const [, setSideBarState] = useLeftSideBarState();

    const handleClick = useCallback(() => {
        onClick && onClick();
        setSideBarState({
            state: true,
            content,
        });
        navigate(to);
    }, [navigate, to, setSideBarState, content, onClick]);

    return (
        <IconButton onClick={handleClick} className="active">
            <EditIcon sx={{ color: color || 'primary.main' }} />
        </IconButton>
    );
}

export default EditContentLink;
