import { fullpageApi as FullpageApi } from '@fullpage/react-fullpage';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface FullPageRouterListenerProps {
    children: React.ReactNode;
    fullpageApi: FullpageApi;
}

/**
 * Quand la location de react-router-dom change appele le moveTo de FullPage pour aller sur la bonne page.
 */
function FullPageRouterListener({
    children,
    fullpageApi,
}: FullPageRouterListenerProps) {
    const location = useLocation();

    useEffect(() => {
        if (location.hash && fullpageApi) {
            fullpageApi.moveTo(location.hash.substring(1), 0);
        }
    }, [location, fullpageApi]);

    return <>{children}</>;
}

export default FullPageRouterListener;
