import React from 'react';
import { Box } from '@mui/material';
import FullPageLayout from './FullPageLayout';

interface ContainerPresentationProps {
    exportPdf?: boolean;
    children: React.ReactNode;
}

const containerPage = {
    width: '90%',
    marginLeft: '5%',
};

function ContainerPresentation({
    exportPdf,
    children,
}: ContainerPresentationProps) {
    if (exportPdf) {
        return <Box sx={containerPage}>{children}</Box>;
    }

    return <FullPageLayout>{children}</FullPageLayout>;
}

export default ContainerPresentation;
