import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import usePackages from './usePackages';

/**
 * Retourne un Package à partir de son id.
 */
function usePackage(packageId: string | undefined) {
    const packages = usePackages();

    return useMemo(() => {
        if (!packageId) {
            return undefined;
        }

        return packages?.find((el) => matchOnId(el, packageId));
    }, [packages, packageId]);
}

export default usePackage;
