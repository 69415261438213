import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import RequireAnonymous from '@acdc/shared/src/security/RequireAnonymous';
import RequireAuth from '@acdc/shared/src/security/RequireAuth';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import Presentation from './Presentation';
import Tools from './Tools';
import RendezVous from './RendezVous';
import ContentPreview from './ContentPreview';
import LayoutPreview from '../layout/LayoutPreview';
import NoMatchPage from './NoMatchPage';
import AuthPages from './Auth/AuthPages';
import Logout from './Logout';
import ApiDataProvider from '../features/ApiDataProvider';
import SelectDefaultAgency from '../features/agency/SelectDefaultAgency';
import HeaderPadding from '../layout/HeaderPadding';
import ToolPage from '../features/tools/ToolPage';
import ToolCategories from './ToolCategories';
import MyProfile from './MyProfile';
import QuotationQueue from '../features/quotation/QuotationQueue';
import PresentationStatisticQueue from '../features/presentationStatistic/PresentationStatisticQueue';
import NotificationQueue from '../features/notification/NotificationQueue';
import LoginForExport from '../security/LoginForExport';
import ListTools from './ListTools';
import PreferencesPage from './PreferencesPage';
import DiscoveryFormsPage from './DiscoveryFormsPage';
import DiscoveryPage from './DiscoveryPage';
import HomePage from './HomePage';
import PresentationLayout from '../layout/PresentationLayout';
import DefaultLayout from '../layout/DefaultLayout';
import DiscoveryQueue from '../features/discovery/DiscoveryQueue';
import DiscoveryLayout from '../layout/DiscoveryLayout/DiscoveryLayout';
import QuotationExport from './QuotationExport';

function PageLoader() {
    return null;
}

function Pages() {
    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                {/* les pages d'authentification */}
                <Route
                    path="/auth/*"
                    element={
                        <RequireAnonymous>
                            <AuthPages />
                        </RequireAnonymous>
                    }
                />

                {/* les pages rendues par l'api */}
                <Route
                    path="/api"
                    element={
                        <LoginForExport>
                            {/*
                                ici on est authentifié donc on peut charger les données en fonction de l'utilisateur connecté.
                                Elles seront en cache prêtes à être utilisées dans les pages.
                            */}
                            <ApiDataProvider export>
                                <Outlet />
                            </ApiDataProvider>
                        </LoginForExport>
                    }
                >
                    <Route
                        path="presentations/:type/export"
                        element={<Presentation exportPdf />}
                    />
                    <Route path="outils/export" element={<ListTools />} />
                    <Route
                        path="quotations/:quotationId/export"
                        element={<QuotationExport />}
                    />
                </Route>

                {/* les pages accédées par les utilisateurs connectés = agents Laforêt */}
                <Route
                    path="/"
                    element={
                        <QuotationQueue>
                            <DiscoveryQueue>
                                <PresentationStatisticQueue>
                                    <NotificationQueue>
                                        <RequireAuth>
                                            {/*
                                            ici on est authentifié donc on peut charger les données en fonction de l'utilisateur connecté.
                                            Elles seront en cache prêtes à être utilisées dans les pages.
                                        */}
                                            <ApiDataProvider>
                                                {/*
                                                on selectionne automatiquement la première agence de l'utilisateur
                                                dés que possible
                                            */}
                                                <SelectDefaultAgency />
                                                <Outlet />
                                            </ApiDataProvider>
                                        </RequireAuth>
                                    </NotificationQueue>
                                </PresentationStatisticQueue>
                            </DiscoveryQueue>
                        </QuotationQueue>
                    }
                >
                    {/* l'accueil */}
                    <Route
                        index
                        element={
                            <DefaultLayout>
                                <HeaderPadding>
                                    <HomePage />
                                </HeaderPadding>
                            </DefaultLayout>
                        }
                    />

                    {/* les pages accédés par les admins depuis le BO dans une iframe */}
                    <Route path="pages" element={<LayoutPreview />}>
                        <Route index element={<Navigate replace to="/404" />} />
                        <Route
                            path=":contentId/previsualiser"
                            element={<ContentPreview />}
                        />
                        <Route
                            path="outils/:contentId/previsualiser"
                            element={
                                <ContentPreview
                                    type={ContentType.TOOL_CONTENT}
                                />
                            }
                        />
                    </Route>

                    {/* les pages du module découverte */}
                    <Route
                        path="decouvertes/:discoveryFormId"
                        element={<DiscoveryLayout />}
                    >
                        <Route
                            index
                            element={
                                <HeaderPadding>
                                    <DiscoveryPage />
                                </HeaderPadding>
                            }
                        />
                    </Route>
                    <Route path="decouvertes" element={<DefaultLayout />}>
                        <Route
                            index
                            element={
                                <HeaderPadding>
                                    <DiscoveryFormsPage />
                                </HeaderPadding>
                            }
                        />
                    </Route>

                    {/* les pages du module présentation */}
                    <Route path="*" element={<PresentationLayout />}>
                        <Route path="presentation" element={<Presentation />} />
                        <Route path="outils/:toolId" element={<ToolPage />} />
                        <Route path="rubriques">
                            <Route
                                index
                                element={
                                    <HeaderPadding>
                                        <ToolCategories />
                                    </HeaderPadding>
                                }
                            />
                            <Route
                                path=":toolCategoryId"
                                element={
                                    <HeaderPadding>
                                        <Tools />
                                    </HeaderPadding>
                                }
                            />
                        </Route>
                        <Route
                            path="mes-rendez-vous"
                            element={
                                <HeaderPadding>
                                    <RendezVous />
                                </HeaderPadding>
                            }
                        />
                        <Route
                            path="mon-profil"
                            element={
                                <HeaderPadding>
                                    <MyProfile />
                                </HeaderPadding>
                            }
                        />
                        <Route
                            path="mes-preferences"
                            element={
                                <HeaderPadding>
                                    <PreferencesPage />
                                </HeaderPadding>
                            }
                        />
                        <Route
                            path="*"
                            element={<Navigate replace to="/404" />}
                        />
                    </Route>
                </Route>
                <Route path="/deconnexion" element={<Logout />} />
                <Route path="/404" element={<NoMatchPage />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </Suspense>
    );
}

export default Pages;
