import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDebounce, {
    DebounceOptions,
} from '@acdc/shared/src/utils/useDebounce';

/**
 * Permet de submit automatiquement un formulaire formik si un de ses champs a été modifié mais n'est plus touché pendant 400ms.
 * note: Par contre contrairement à useAutoSubmitSingleFieldForm, si on modifie un form et qu'on remet sa valeur initiale avant le
 * submit, on aura quand même un changement détecté et un submit pour rien.
 * @param {(() => string|null) | undefined} getEntityId Une fonction qui retourne l'id de l'entité du formulaire. Permet de savoir si le premier submit d'une nouvelle entité est passé. Sinon on l'attend pour ne pas submit plusieur fois une création d'entité.
 * @return {boolean} true si un modification n'a pas encore été submit, false si tout est à jour.
 */
function useAutoSubmitForm(getEntityId?: () => string | null) {
    // indique si la sauvegarde est en attente
    const [pending, setPending] = useState(false);

    // le contexte de formik
    const { values, submitForm, isSubmitting } = useFormikContext();

    // une ref qui indique si une submission est en attente
    const delayedSubmission = useRef(false);

    // une ref pour savoir si le formulaire est en cours de submission
    const isSubmittingRef = useRef(false);
    isSubmittingRef.current = isSubmitting;

    // submit le form si il n'est pas déjà en cours de submission pour une création, sinon prépare une submission dés que possible
    const submit = useCallback(() => {
        if (isSubmittingRef.current && getEntityId && !getEntityId()) {
            // la requete de création est en cours, on attend la réponse pour avoir l'id pour la modification
            delayedSubmission.current = true;
        } else {
            setPending(false);
            submitForm();
        }
    }, [submitForm, getEntityId, setPending]);

    // appelle submit() dés que le champ n'est plus touché
    const initialValue = useMemo(() => {
        return values;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const debounceOptions = useMemo<DebounceOptions>(
        () => ({
            onDirectChange: () => {
                // loading quand le timer du debounce se lance
                setPending(true);

                const onChangeCancelled = () => {
                    // annulation du loader si la modification est annulée
                    setPending(false);
                };
                return onChangeCancelled;
            },
        }),
        [setPending]
    );
    const [, debounceHandleChange] = useDebounce(
        // on envoit la valeur initiale à chaque fois car on ne veut pas que useDebounce
        // tente de garder l'input controllé, là on veut juste qu'il debounce.
        initialValue,
        submit,
        debounceOptions
    );

    // si le form ne submit plus et qu'on avait une submission en attente on la lance
    useEffect(() => {
        if (!isSubmitting && delayedSubmission.current) {
            delayedSubmission.current = false;
            submit();
        }
    }, [isSubmitting, submit]);
    const firstCall = useRef(true);

    // si la valeur du champ change on appelle la fonction du debounce pour submit dés que la valeur ne change plus
    useEffect(() => {
        if (!firstCall.current) {
            debounceHandleChange({
                target: {
                    value: values,
                },
            });
        } else {
            firstCall.current = false;
        }
    }, [values, debounceHandleChange]);

    return pending;
}

export default useAutoSubmitForm;
