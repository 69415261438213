import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import useDiscoveryForm from '../features/discovery/useDiscoveryForm';
import DiscoveryForm from '../features/discovery/DiscoveryForm/DiscoveryForm';
import useAgency from '../features/agency/useAgency';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import CONFIGS from '../configs';
import useAuthenticatedUser from '../features/user/useAuthenticatedUser';
import ConfigureDiscoveryForm from '../features/discovery/ConfigureDiscoveryForm/ConfigureDiscoveryForm';

function DiscoveryPage() {
    const { discoveryFormId } = useParams();
    const discoveryFormIri = `/discovery-forms/${discoveryFormId}`;
    const discoveryForm = useDiscoveryForm(discoveryFormIri);
    const agency = useAgency(useSelectedAgency()[0]);
    const user = useAuthenticatedUser();
    const localStorageValuesKey = CONFIGS.discoveryKey + discoveryFormIri;
    const localStorageCurrentPageKey =
        CONFIGS.discoveryCurrentPageKey + discoveryFormIri;
    const navigate = useNavigate();

    const handleSuccess = useCallback(() => {
        navigate('/decouvertes');
        if (typeof window !== 'undefined') {
            window.localStorage.removeItem(localStorageValuesKey);
            window.localStorage.removeItem(localStorageCurrentPageKey);
        }
    }, [localStorageValuesKey, localStorageCurrentPageKey, navigate]);

    if (!agency) {
        return (
            <Alert severity="warning">
                Vous devez sélectionner une agence.
            </Alert>
        );
    }
    if (!user) {
        return <Alert severity="warning">Vous devez vous connecter.</Alert>;
    }
    if (!discoveryForm || !agency) {
        return <Skeleton />; // todo: meilleur skeleton
    }

    return (
        <>
            <ConfigureDiscoveryForm
                discoveryForm={discoveryForm}
                agency={agency}
            />
            <DiscoveryForm
                discoveryForm={discoveryForm}
                user={user}
                agency={agency}
                localStorageValuesKey={localStorageValuesKey}
                localStorageCurrentPageKey={localStorageCurrentPageKey}
                onSuccess={handleSuccess}
            />
        </>
    );
}

export default DiscoveryPage;
