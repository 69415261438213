import { Form, Formik } from 'formik';
import React, {
    useMemo,
    useRef,
    useCallback,
    useState,
    useEffect,
} from 'react';
import { ApolloError } from '@apollo/client';
import {
    entityToId,
    filterNumber,
    initNumber,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import PackageAgency from '@acdc/shared/src/features/packageAgency/PackageAgency.model';
import RatePackageAgencyFormInner from './RatePackageAgencyFormInner';
import useSubmitPackageAgency from '../useSubmitPackageAgency';

export interface RatePackageAgencyFormValue {
    rate: string | number;
}

const initValue = (
    value: PackageAgencyResponse
): RatePackageAgencyFormValue => ({
    rate: initNumber(value.rate),
});

const filterFormValues = (
    formValue: RatePackageAgencyFormValue
): DeepPartial<PackageAgency> => ({
    rate: filterNumber(formValue.rate),
});

export const ratePackageAgencyFormSchema = Yup.object().shape({
    rate: Yup.number().label('Le taux').required(),
});

export type RatePackageAgencyFormProps = {
    value?: PackageAgencyResponse | undefined;
    pack: PackageResponse;
    agency: AgencyResponse;
    onSuccess?: (res: PackageAgencyResponse) => void;
    onError?: (err: ApolloError) => void;
};

function RatePackageAgencyForm({
    value,
    pack,
    agency,
    onSuccess,
    onError,
}: RatePackageAgencyFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: RatePackageAgencyFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const [saved, setSaved] = useState(
        Boolean(value?.id && (value.rate || value.rate === 0))
    );

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<PackageAgency>>(
        () => ({
            package: entityToId(pack) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [pack, agency]
    );

    const submit = useSubmitPackageAgency<RatePackageAgencyFormValue>(
        getId,
        setId,
        setSaved,
        ratePackageAgencyFormSchema,
        onSuccess,
        onError,
        fixedProperties,
        filterFormValues
    );

    useEffect(() => {
        if (value?.id && !getId()) {
            // si l'entité a été créée depuis ailleurs
            // on set l'id utilisé dans useSubmitPackageAgency
            // pour assurer une requete Update et pas Create
            setId(value.id);
        }
    }, [value, getId, setId]);

    return (
        <Formik
            validationSchema={ratePackageAgencyFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <RatePackageAgencyFormInner
                    pack={pack}
                    getId={getId}
                    saved={saved}
                />
            </Form>
        </Formik>
    );
}

export default RatePackageAgencyForm;
