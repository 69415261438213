import React, { useCallback } from 'react';
import LogoutPage from '@acdc/shared/src/features/auth/LogoutPage';
import CONFIGS from '../configs';
import usePersistor from '../apollo/usePersistor';

function Logout() {
    const persistor = usePersistor();
    const onLoggedOut = useCallback(() => {
        // clear du cache persistant dans indexedDb
        persistor && persistor.purge();
    }, [persistor]);

    return (
        <LogoutPage
            loginUrl="/auth/connexion"
            casEntrypoint={CONFIGS.casEntrypoint}
            onLoggedOut={onLoggedOut}
        />
    );
}

export default Logout;
