import { Step } from '@mui/material';
import React from 'react';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import ThemeColor from '@acdc/shared/src/mui/ThemeColor.enum';
import MenuStepper from '../navigation/MenuStepper';
import MenuStep from '../navigation/MenuStep';
import MenuTitleTypography from '../navigation/MenuTitleTypography';

export interface DiscoveryMenuProps {
    title: string;
    criterias: DiscoveryCriteriaResponse[];
    activeStep: number;
    goTo: (index: number) => void;
    criteriasValidity: { [id: string]: boolean };
    color: ThemeColor;
}

function DiscoveryMenu({
    title,
    criterias,
    activeStep,
    goTo,
    criteriasValidity,
    color,
}: DiscoveryMenuProps) {
    let lastValidStep: number | null = null;
    return (
        <>
            <MenuTitleTypography color={`${color}.light`}>
                {title}
            </MenuTitleTypography>
            <MenuStepper activeStep={activeStep} color={color}>
                {criterias.map((criteria, index) => {
                    const isCompleted = index < activeStep;
                    // si isCompleted on considère la step valide pour éviter d'empécher l'utilisateur de revenir en arrière.
                    if (
                        !isCompleted &&
                        lastValidStep === null &&
                        criteriasValidity[`${criteria.id}`] !== true
                    ) {
                        // Dés qu'on rencontre une slide invalide, on disable tous les menus à suivre
                        lastValidStep = index;
                    }
                    return (
                        <Step key={criteria.id}>
                            <MenuStep
                                onClick={() => goTo(index)}
                                button
                                disabled={
                                    lastValidStep !== null &&
                                    index > lastValidStep
                                }
                            >
                                {criteria.label}
                            </MenuStep>
                        </Step>
                    );
                })}
            </MenuStepper>
        </>
    );
}

export default DiscoveryMenu;
