import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateVariableValueResponse from '@acdc/shared/src/features/variableValue/MutateVariableValueResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import UpdateVariableValueResponse from '@acdc/shared/src/features/variableValue/UpdateVariableValueResponse';
import CreateVariableValueResponse from '@acdc/shared/src/features/variableValue/CreateVariableValueResponse';
import { entityToId, filterString } from '@acdc/shared/src/utils/form-helpers';
import VariableValueResponse from '@acdc/shared/src/features/variableValue/VariableValueResponse';
import VariableValue from '@acdc/shared/src/features/variableValue/VariableValue.model';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import {
    GET_VARIABLE_VALUES,
    VARIABLE_VALUE_FRAGMENT,
} from '../useVariableValues';
import type {
    VariableValueFormProps,
    VariableValueFormValue,
} from './VariableValueForm';
import useSelectedAgency from '../../agency/useSelectedAgency';

export const CREATE_VARIABLE_VALUE = gql`
    ${VARIABLE_VALUE_FRAGMENT}
    mutation CreateVariableValue($input: createVariableValueInput!) {
        createVariableValue(input: $input) {
            variableValue {
                ...VariableValueFields
            }
        }
    }
`;

export const UPDATE_VARIABLE_VALUE = gql`
    ${VARIABLE_VALUE_FRAGMENT}
    mutation UpdateVariableValue($input: updateVariableValueInput!) {
        updateVariableValue(input: $input) {
            variableValue {
                ...VariableValueFields
            }
        }
    }
`;

/**
 * Submit une VariableValue
 */
function doSubmit(
    values: VariableValueFormValue,
    getId: () => string | null,
    createVariableValue: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateVariableValueResponse>>,
    updateVariableValue: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateVariableValueResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<VariableValue>
) {
    return (
        Promise.resolve()
            // préparation de la VariableValue à créer
            .then(() => {
                const input: DeepPartial<VariableValue> = {
                    ...values,
                    value: filterString(values.value),
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                return input;
            })

            // création de la VariableValue
            .then((input) =>
                (
                    (getId() ? updateVariableValue : createVariableValue) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutateVariableValueResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

/**
 *
 * @param getId Fonction qui retourne l'id de la VariableValue pour savoir si on doit faire une création ou une mise à jour.
 * @param setId Fonction qui set l'id d'une VariableValue créé pour que le prochain submit sache qu'il doit faire une mise à jour.
 * @param setSaved
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans la VariableValue
 * @returns
 */
const useSubmitVariableValue = (
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: VariableValueFormProps['onSuccess'],
    onError: VariableValueFormProps['onError'],
    fixedProperties: DeepPartial<VariableValue>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createVariableValue] = useMutation(CREATE_VARIABLE_VALUE, {
        // ajout du nouveau VariableValue dans le cache de la requete GET_VARIABLE_VALUES
        update(cache, result) {
            const item = result.data?.createVariableValue
                ?.variableValue as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_VARIABLE_VALUES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        variableValues: {
                            ...data.variableValues,
                            collection: [
                                ...data.variableValues.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [updateVariableValue] = useMutation(UPDATE_VARIABLE_VALUE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: VariableValueFormValue,
            { setSubmitting, setErrors }: FormikHelpers<VariableValueFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createVariableValue,
                updateVariableValue,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateVariableValueResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: VariableValueResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateVariableValue?.variableValue
                            : res.data?.createVariableValue?.variableValue;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    setSaved(true);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createVariableValue,
            updateVariableValue,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
        ]
    );
};

export default useSubmitVariableValue;
