import { Box, BoxProps, Slide, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

const containerSx: BoxProps['sx'] = {
    overflowX: 'hidden',
};

const slideSx = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
};

export interface StateItem {
    key: number;
    children: React.ReactNode;
    transition: {
        appear: boolean;
        in: boolean;
        direction: 'left' | 'right';
    };
}

export interface PagesSliderProps {
    pages: React.ReactNode[];
    activePage: number;
    width?: BoxProps['width'];
    height?: BoxProps['height'];
}

/**
 * Un composant qui permet de faire défiler des pages horizontallement avec une animation de slide.
 * Seule la page courante reste montée.
 */
function PagesSlider({ pages, activePage, width, height }: PagesSliderProps) {
    const containerRef = useRef();
    const prevActiveSlide = useRef(activePage);

    const [state, setState] = useState<StateItem[]>([
        {
            key: activePage,
            children: pages[activePage],
            transition: {
                appear: false,
                in: true,
                direction: 'left',
            },
        },
    ]);

    useEffect(() => {
        if (prevActiveSlide.current === activePage) {
            setState((curr) =>
                curr.map((item) => {
                    if (item.key === activePage) {
                        return {
                            ...item,
                            // on met juste à jour le contenu
                            children: pages[activePage],
                        };
                    }

                    return item;
                })
            );
            return;
        }
        if (activePage < 0 || activePage >= pages.length) {
            return;
        }
        let newState: StateItem[];
        if (prevActiveSlide.current < activePage) {
            // vers la slide suivante
            newState = [
                {
                    key: prevActiveSlide.current,
                    children: pages[prevActiveSlide.current],
                    transition: {
                        appear: false,
                        in: false,
                        direction: 'right',
                    },
                },
                {
                    key: activePage,
                    children: pages[activePage],
                    transition: {
                        appear: true,
                        in: true,
                        direction: 'left',
                    },
                },
            ];
        } else {
            // vers la slide précédente
            newState = [
                {
                    key: activePage,
                    children: pages[activePage],
                    transition: {
                        appear: true,
                        in: true,
                        direction: 'right',
                    },
                },
                {
                    key: prevActiveSlide.current,
                    children: pages[prevActiveSlide.current],
                    transition: {
                        appear: false,
                        in: false,
                        direction: 'left',
                    },
                },
            ];
        }
        setState(newState);
        prevActiveSlide.current = activePage;
    }, [pages, activePage, setState]);
    const theme = useTheme();

    return (
        <Box
            className="PagesSlider-root"
            ref={containerRef}
            sx={containerSx}
            position="relative"
            width={width}
            height={height}
        >
            {state.map((item: StateItem) => {
                return (
                    <Slide
                        key={item.key}
                        container={containerRef.current}
                        timeout={1000}
                        easing={{
                            // transition identique pour que les slides s'alignent bien
                            enter: theme.transitions.easing.easeOut,
                            exit: theme.transitions.easing.easeOut,
                        }}
                        mountOnEnter
                        unmountOnExit
                        {...item.transition}
                    >
                        <Box sx={slideSx}>{item.children}</Box>
                    </Slide>
                );
            })}
        </Box>
    );
}

export default PagesSlider;
