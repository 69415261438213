/* eslint-disable no-case-declarations */
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryChoiceResponse from '@acdc/shared/src/features/discoveryChoice/DiscoveryChoiceResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import DiscoveryCriteriaType from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaType.enum';
import SpeechRecognitionButton from '@acdc/shared/src/tools/speech-recognition/SpeechRecognitionButton';
import Autocomplete from '@acdc/shared/src/formik/Autocomplete';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { FormControlLabel, InputAdornment, Radio } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { RadioGroup, Switch, TextField } from 'formik-mui';
import React, { useMemo } from 'react';
import ButtonPicker from '@acdc/shared/src/formik/ButtonPicker';
import SliderWithInput from '@acdc/shared/src/formik/SliderWithInput';
import TriStateSwitch from '@acdc/shared/src/formik/TriStateSwitch';
import ButtonPickerNumber from './inputs/ButtonPickerNumber';
import ButtonPickerBool from './inputs/ButtonPickerBool';
import type { DiscoveryCriteriaFormValue } from './DiscoveryCriteriaForm';
import ButtonPickerMultiple from './inputs/ButtonPickerMultiple';
import MaxRentCalculator from './inputs/MaxRentCalculator';
import LocationSelect from './inputs/LocationSelect';

export const INPUT_NAME = 'value';

function getChoiceLabel(choice: DiscoveryChoiceResponse): string {
    return choice.label || '';
}

function getChoiceId(choice: DiscoveryChoiceResponse): string {
    return entityToId(choice) || '';
}

function getChoiceWidth(choice: DiscoveryChoiceResponse): number {
    return choice.displayWidth || 12;
}

function SpeechRecognitionAdornment({ setValues }: any) {
    return (
        <InputAdornment position="end">
            <SpeechRecognitionButton
                onSpeech={(speech) => {
                    setValues((curr: any) => {
                        // en cas de speech on l'ajoute à la valeur actuelle
                        const currVal = curr[INPUT_NAME];
                        let newVal = speech;
                        if (currVal) {
                            newVal = `${currVal} ${newVal}`;
                        }
                        return {
                            ...curr,
                            [INPUT_NAME]: newVal,
                        };
                    });
                }}
                continuous
            />
        </InputAdornment>
    );
}

function getTextFieldEndAdornment(
    discoveryCriteria: DiscoveryCriteriaResponse,
    setValues: any
) {
    if (!discoveryCriteria.inputOptions) {
        return undefined;
    }

    if (discoveryCriteria.inputOptions.enableSpeechRecognition === true) {
        return <SpeechRecognitionAdornment setValues={setValues} />;
    }

    if (discoveryCriteria.inputOptions.unit) {
        return (
            <InputAdornment position="end">
                {discoveryCriteria.inputOptions.unit}
            </InputAdornment>
        );
    }

    return undefined;
}

export interface DiscoveryCriteriaInputProps {
    discoveryCriteria: DiscoveryCriteriaResponse;
    agency: AgencyResponse;
}

function DiscoveryCriteriaInput({
    discoveryCriteria,
    agency,
}: DiscoveryCriteriaInputProps) {
    const discoveryChoices = useMemo(() => {
        return (
            discoveryCriteria.choices?.collection.filter(
                (choice) => !choice.agency || matchOnId(agency, choice.agency)
            ) || []
        );
    }, [discoveryCriteria, agency]);
    const { setValues } = useFormikContext<DiscoveryCriteriaFormValue>();

    switch (discoveryCriteria.type) {
        case DiscoveryCriteriaType.BUTTON_PICKER:
            return (
                <Field
                    name={INPUT_NAME}
                    component={ButtonPicker}
                    options={discoveryChoices}
                    getOptionValue={getChoiceId}
                    getOptionLabel={getChoiceLabel}
                    getOptionWidth={getChoiceWidth}
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.BUTTON_PICKER_MULTIPLE:
            return (
                <ButtonPickerMultiple
                    name={INPUT_NAME}
                    options={discoveryChoices}
                    getOptionValue={getChoiceId}
                    getOptionLabel={getChoiceLabel}
                    getOptionWidth={getChoiceWidth}
                    uniqueOptions={
                        discoveryCriteria.inputOptions?.uniqueChoices
                    }
                />
            );
        case DiscoveryCriteriaType.BUTTON_PICKER_NUMBER:
            return (
                <Field
                    name={INPUT_NAME}
                    component={ButtonPickerNumber}
                    max={discoveryCriteria.inputOptions?.max || 1}
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.BUTTON_PICKER_BOOL:
            return (
                <Field
                    name={INPUT_NAME}
                    component={ButtonPickerBool}
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.RADIOGROUP:
            return (
                <Field
                    name={INPUT_NAME}
                    component={RadioGroup}
                    row
                    required={discoveryCriteria.valueIsRequired === true}
                >
                    {discoveryChoices.map((choice) => (
                        <FormControlLabel
                            key={choice.id}
                            value={choice.id}
                            control={<Radio />}
                            label={choice.label}
                        />
                    )) || []}
                </Field>
            );
        case DiscoveryCriteriaType.SELECT:
        case DiscoveryCriteriaType.SELECT_MULTIPLE:
            return (
                <Field
                    name={INPUT_NAME}
                    component={Autocomplete}
                    label={discoveryCriteria.label}
                    options={discoveryChoices}
                    getOptionLabel={getChoiceLabel}
                    isOptionEqualToValue={matchOnId}
                    multiple={
                        discoveryCriteria.type ===
                        DiscoveryCriteriaType.SELECT_MULTIPLE
                    }
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.LOCATION_SELECT:
            return (
                <Field
                    name={INPUT_NAME}
                    component={LocationSelect}
                    label={discoveryCriteria.label}
                    multiple
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.INPUT_NUMBER:
        case DiscoveryCriteriaType.COUNTER: // todo
            return (
                <Field
                    name={INPUT_NAME}
                    label={discoveryCriteria.label}
                    type="number"
                    component={TextField}
                    required={discoveryCriteria.valueIsRequired === true}
                    fullWidth
                    InputProps={{
                        endAdornment: getTextFieldEndAdornment(
                            discoveryCriteria,
                            setValues
                        ),
                        inputProps: {
                            min: discoveryCriteria.inputOptions?.min,
                            max: discoveryCriteria.inputOptions?.max,
                            step: discoveryCriteria.inputOptions?.step,
                        },
                    }}
                    autoComplete="off"
                />
            );
        case DiscoveryCriteriaType.INPUT_TEXT:
            return (
                <Field
                    name={INPUT_NAME}
                    label={discoveryCriteria.label}
                    component={TextField}
                    type={discoveryCriteria.inputOptions?.type || 'text'}
                    multiline={
                        discoveryCriteria.inputOptions?.multiline || false
                    }
                    rows={discoveryCriteria.inputOptions?.rows || 1}
                    InputProps={{
                        endAdornment: getTextFieldEndAdornment(
                            discoveryCriteria,
                            setValues
                        ),
                    }}
                    helperText={
                        discoveryCriteria.inputOptions?.helperText || undefined
                    }
                    required={discoveryCriteria.valueIsRequired === true}
                    fullWidth
                    autoComplete="off"
                />
            );
        case DiscoveryCriteriaType.SWITCH_WITH_INDETERMINATE:
            return (
                <Field
                    name={INPUT_NAME}
                    component={TriStateSwitch}
                    type="checkbox"
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.SWITCH:
            return (
                <Field
                    name={INPUT_NAME}
                    component={Switch}
                    type="checkbox"
                    required={discoveryCriteria.valueIsRequired === true}
                />
            );
        case DiscoveryCriteriaType.SLIDER:
            const minSlider = discoveryCriteria.inputOptions?.min || 0;
            const maxSlider =
                discoveryCriteria.inputOptions?.max || minSlider + 10;
            return (
                <Field
                    name={INPUT_NAME}
                    component={SliderWithInput}
                    required={discoveryCriteria.valueIsRequired === true}
                    min={minSlider}
                    max={maxSlider}
                    step={discoveryCriteria.inputOptions?.step || 1}
                    unit={discoveryCriteria.inputOptions?.unit}
                    disableMaxOnInput={
                        discoveryCriteria.inputOptions?.disableMaxOnInput ===
                        undefined
                            ? false
                            : discoveryCriteria.inputOptions?.disableMaxOnInput
                    }
                />
            );
        case DiscoveryCriteriaType.YEAR_OF_CONSTRUCTION: // todo : avec un < 1900 comme dans les specs. Mais il faut peut être un slider range finalement
            const minYearOfConstruction =
                discoveryCriteria.inputOptions?.min || 0;
            const maxYearOfConstruction = new Date().getFullYear();
            return (
                <Field
                    name={INPUT_NAME}
                    component={SliderWithInput}
                    required={discoveryCriteria.valueIsRequired === true}
                    min={minYearOfConstruction}
                    max={maxYearOfConstruction}
                    step={discoveryCriteria.inputOptions?.step || 1}
                    disableMaxOnInput={
                        discoveryCriteria.inputOptions?.disableMaxOnInput ===
                        undefined
                            ? false
                            : discoveryCriteria.inputOptions?.disableMaxOnInput
                    }
                />
            );
        case DiscoveryCriteriaType.MAX_RENT_CALCULATOR:
            return (
                <MaxRentCalculator
                    netIncomeLabel={
                        discoveryCriteria.inputOptions?.netIncomeLabel ||
                        'Revenus nets mensuels'
                    }
                    netIncomeHelperText={
                        discoveryCriteria.inputOptions?.netIncomeHelperText
                    }
                    netIncomeMin={
                        discoveryCriteria.inputOptions?.netIncomeMin || 0
                    }
                    netIncomeStep={
                        discoveryCriteria.inputOptions?.netIncomeStep || 1
                    }
                    divider={discoveryCriteria.inputOptions?.divider || 1}
                />
            );
        default:
            return null;
    }
}

export default DiscoveryCriteriaInput;
