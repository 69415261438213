import FlexScrollable from '@acdc/shared/src/ui/FlexScrollable';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { visuallyHidden } from '@mui/utils';
import ServiceResponse from '@acdc/shared/src/features/service/ServiceResponse';
import useSelectedAgency from '../../agency/useSelectedAgency';
import usePackageAgenciesOf from '../../packageAgency/usePackageAgenciesOf';
import PackageIdentity from './PackageIdentity';
import ServicesTableHeader from './ServicesTableHeader';
import ServicesTableRow from './ServicesTableRow';
import useIsExport from '../../useIsExport';

export interface ServicesTableProps {
    services: ServiceResponse[];
}

function ServicesTable({ services }: ServicesTableProps) {
    const packageAgencies = usePackageAgenciesOf(useSelectedAgency()[0], true);
    const isExport = useIsExport();
    const valueCellWidth = isExport ? '77px' : '94px';

    const TableWrapper = isExport ? Box : FlexScrollable;

    if (!packageAgencies?.length) {
        return null;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
            width="100%"
        >
            <Box flex={0} display="flex" justifyContent="flex-end" width="100%">
                {packageAgencies.map((packageAgency) => (
                    <PackageIdentity
                        key={`${packageAgency.id}`}
                        packageId={`${entityToId(packageAgency.package)}`}
                        width={valueCellWidth}
                    />
                ))}
            </Box>
            <TableWrapper sx={{ width: '100%', bgColor: 'red' }}>
                <Table
                    size="small"
                    sx={{ borderRadius: 1, overflow: 'hidden' }}
                >
                    <TableHead sx={visuallyHidden}>
                        <TableRow>
                            <TableCell>Prestations</TableCell>
                            {packageAgencies.map((packageAgency) => (
                                <TableCell
                                    key={`${packageAgency.id}`}
                                    width={valueCellWidth}
                                >
                                    <ServicesTableHeader
                                        packageId={`${entityToId(
                                            packageAgency.package
                                        )}`}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services?.map((service) => (
                            <ServicesTableRow
                                key={`${service.id}`}
                                service={service}
                                valueCellWidth={valueCellWidth}
                                splitUnit={isExport}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableWrapper>
        </Box>
    );
}

export default ServicesTable;
