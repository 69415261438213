import React from 'react';
import { Stack, Typography } from '@mui/material';
import useIsExport from '../useIsExport';

interface QuotationStackProps {
    children: React.ReactNode;
    color: any;
    title: string;
    spacing?: any;
}

const rootSx = {
    padding: 2,
    py: 1,
    borderStyle: 'solid',
    borderWidth: 'thick',
    borderRadius: 1,
    height: '100%',
    position: 'relative',
};

const getTitleSx = (isExport: boolean | undefined) => ({
    position: 'absolute',
    textTransform: 'uppercase',
    top: -14,
    left: 16,
    backgroundColor: isExport ? 'common.white' : 'background.default',
    fontFamily: 'Lexend',
    fontWeight: '600',
    px: 1,
});

function QuotationStack({
    children,
    color,
    title,
    spacing,
}: QuotationStackProps) {
    const isExport = useIsExport();
    return (
        <Stack
            spacing={spacing !== undefined ? spacing : 2}
            direction="column"
            sx={rootSx}
            borderColor={color}
            pb={isExport ? 2 : 0}
        >
            <Typography sx={getTitleSx(isExport)} color={color}>
                {title}
            </Typography>
            {children}
        </Stack>
    );
}

export default QuotationStack;
