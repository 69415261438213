import { useContext } from 'react';
import { AgenciesCtx } from './AgenciesProvider';

/**
 * Retourne toutes les Agencies.
 */
function useAgencies() {
    return useContext(AgenciesCtx);
}

export default useAgencies;
