import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useMemo } from 'react';
import usePackageAgenciesOf from './usePackageAgenciesOf';

/**
 * Retourne un PackageAgency d'une Agency à partir de l'id de son Package.
 */
function usePackageAgencyOfPackage(
    agencyId: string | AgencyResponse | undefined,
    packageId: string | PackageResponse | undefined
) {
    const packageAgencies = usePackageAgenciesOf(agencyId);

    return useMemo(() => {
        if (!packageId) {
            return undefined;
        }
        if (!packageAgencies?.length) {
            return undefined;
        }

        return packageAgencies.find((el) => matchOnId(el.package, packageId));
    }, [packageAgencies, packageId]);
}

export default usePackageAgencyOfPackage;
