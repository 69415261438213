import { SxProps, Theme } from '@mui/material';
import React from 'react';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import JoinedButton from '../../../ui/buttons/JoinedButton';

export const baseSx = {
    width: '220px',
};

export const arrowLeftIconSx = {
    marginRight: 2,
};
export const arrowRightIconSx = {
    marginLeft: 2,
};

function getButtonSx(direction: 'left' | 'right'): SxProps<Theme> {
    if (direction === 'left') {
        return {
            ...baseSx,
            backgroundColor: 'primary.dark',
        };
    }
    return baseSx;
}

export interface PreviousDiscoveryFormSlideButtonProps {
    direction: 'left' | 'right';
    disabled?: boolean;
    onClick?: () => void;
}

function NavigateDiscoveryFormSlideButton({
    disabled,
    direction,
    onClick,
}: PreviousDiscoveryFormSlideButtonProps) {
    return (
        <JoinedButton
            roundedSide={direction === 'right' ? 'right' : 'left'}
            variant="contained"
            disabled={disabled}
            onClick={() => {
                onClick?.();
            }}
            sx={getButtonSx(direction)}
        >
            {direction === 'right' ? (
                <>
                    Suivant <EastIcon fontSize="small" sx={arrowRightIconSx} />
                </>
            ) : (
                <>
                    <WestIcon fontSize="small" sx={arrowLeftIconSx} /> Précédent
                </>
            )}
        </JoinedButton>
    );
}

export default NavigateDiscoveryFormSlideButton;
