import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

/**
 * Check si un criteria a été désactivé par l'agence.
 */
function discoveryCriteriaIsEnabled(
    discoveryCriteria: string | DiscoveryCriteriaResponse,
    agency: AgencyResponse
): boolean {
    const override = agency.discoveryCriteriaOverrides?.collection.find((o) =>
        matchOnId(o.criteria, discoveryCriteria)
    );

    return override?.disabled !== true;
}

export default discoveryCriteriaIsEnabled;
