import React from 'react';
import ServicesTable from './ServicesTable';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import useInitialServices from '../service/useInitialServices';

function InitialServicesTable() {
    const agency = useAgency(useSelectedAgency()[0]);
    const initialServices = useInitialServices(agency);

    if (!initialServices) {
        return null;
    }

    return <ServicesTable services={initialServices} />;
}

export default InitialServicesTable;
