import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/MutateImageOverrideResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import ImageOverride from '@acdc/shared/src/features/imageOverride/ImageOverride.model';
import ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import CreateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/CreateImageOverrideResponse';
import UpdateImageOverrideResponse from '@acdc/shared/src/features/imageOverride/UpdateImageOverrideResponse';
import CreateImageOverrideFileResponse from '@acdc/shared/src/features/imageOverrideFile/CreateImageOverrideFileResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import type {
    ImageOverrideFormProps,
    ImageOverrideFormValue,
} from './ImageOverrideForm';
import {
    GET_IMAGE_OVERRIDES,
    IMAGE_OVERRIDE_FRAGMENT,
} from '../useImageOverrides';
import useSelectedAgency from '../../agency/useSelectedAgency';

export const CREATE_IMAGE_OVERRIDE = gql`
    ${IMAGE_OVERRIDE_FRAGMENT}
    mutation CreateImageOverride($input: createImageOverrideInput!) {
        createImageOverride(input: $input) {
            imageOverride {
                ...ImageOverrideFields
            }
        }
    }
`;

export const UPDATE_IMAGE_OVERRIDE = gql`
    ${IMAGE_OVERRIDE_FRAGMENT}
    mutation UpdateImageOverride($input: updateImageOverrideInput!) {
        updateImageOverride(input: $input) {
            imageOverride {
                ...ImageOverrideFields
            }
        }
    }
`;

export const CREATE_IMAGE_OVERRIDE_FILE = gql`
    mutation CreateImageOverrideFile($input: createImageOverrideFileInput!) {
        createImageOverrideFile(input: $input) {
            imageOverrideFile {
                id
            }
        }
    }
`;

/**
 * Submit une ImageOverride
 */
function doSubmit(
    values: ImageOverrideFormValue,
    getId: () => string | null,
    create: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateImageOverrideResponse>>,
    update: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateImageOverrideResponse>>,
    createImageOverrideFile: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateImageOverrideFileResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ImageOverride>
) {
    return (
        Promise.resolve()
            // upload des fichiers à créer d'abord
            .then(() => {
                const imageOverrideFile: File | null = values.file[0] || null;
                const promises: [
                    Promise<FetchResult<CreateImageOverrideFileResponse>> | null
                ] = [
                    imageOverrideFile
                        ? createImageOverrideFile({
                              variables: {
                                  input: {
                                      file: imageOverrideFile,
                                  },
                              },
                          })
                        : null,
                ];

                return Promise.all(promises).catch((err: ApolloError) => {
                    handleError(
                        err?.graphQLErrors?.[0]?.path?.[0] ===
                            'createImageOverrideFile'
                            ? 'file'
                            : undefined
                    )(err);

                    throw Error('cancelled');
                });
            })

            // préparation de l'ImageOverride à créer
            .then(([imageOverrideFileRes]) => {
                const input: DeepPartial<ImageOverride> = {
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                const imageOverrideFileId: string | undefined =
                    imageOverrideFileRes?.data?.createImageOverrideFile
                        ?.imageOverrideFile?.id;
                input.imageOverrideFile = imageOverrideFileId;

                return input;
            })

            // création de la ImageOverride
            .then((input) =>
                (
                    (getId() ? update : create) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutateImageOverrideResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

const useSubmitImageOverride = (
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ImageOverrideFormProps['onSuccess'],
    onError: ImageOverrideFormProps['onError'],
    fixedProperties: DeepPartial<ImageOverride>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [create] = useMutation(CREATE_IMAGE_OVERRIDE, {
        // ajout du nouveau ImageOverride dans le cache de la requete GET_IMAGE_OVERRIDES
        update(cache, result) {
            const item = result.data?.createImageOverride
                ?.imageOverride as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_IMAGE_OVERRIDES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        imageOverrides: {
                            ...data.imageOverrides,
                            collection: [
                                ...data.imageOverrides.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [update] = useMutation(UPDATE_IMAGE_OVERRIDE, {
        // pas d'eviction du cache
    });

    const [createImageOverrideFile] = useMutation(CREATE_IMAGE_OVERRIDE_FILE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: ImageOverrideFormValue,
            { setSubmitting, setErrors }: FormikHelpers<ImageOverrideFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                create,
                update,
                createImageOverrideFile,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateImageOverrideResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: ImageOverrideResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateImageOverride?.imageOverride
                            : res.data?.createImageOverride?.imageOverride;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    setSaved(true);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            create,
            update,
            createImageOverrideFile,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
        ]
    );
};

export default useSubmitImageOverride;
