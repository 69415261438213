import ContentOverride from '@acdc/shared/src/features/contentOverride/ContentOverride.model';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
    StoreObject,
} from '@apollo/client';
import MutateContentOverrideResponse from '@acdc/shared/src/features/contentOverride/MutateContentOverrideResponse';
import UpdateContentOverrideResponse from '@acdc/shared/src/features/contentOverride/UpdateContentOverrideResponse';
import CreateContentOverrideResponse from '@acdc/shared/src/features/contentOverride/CreateContentOverrideResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import { FormikHelpers } from 'formik';
import type ContentOverrideResponse from '@acdc/shared/src/features/contentOverride/ContentOverrideResponse';
import useIsGranted from '@acdc/shared/src/security/useIsGranted';
import SecurityRole from '@acdc/shared/src/features/user/SecurityRole.enum';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import {
    type ContentOverrideFormProps,
    type ContentOverrideFormValue,
} from './ContentOverrideForm';
import {
    CONTENT_OVERRIDE_FRAGMENT,
    GET_CONTENT_OVERRIDES,
} from './useContentOverrides';
import useSelectedAgency from '../agency/useSelectedAgency';

export const UPDATE_CONTENT_OVERRIDE = gql`
    ${CONTENT_OVERRIDE_FRAGMENT}
    mutation UpdateContentOverride($input: updateContentOverrideInput!) {
        updateContentOverride(input: $input) {
            contentOverride {
                ...ContentOverrideFields
            }
        }
    }
`;

export const CREATE_CONTENT_OVERRIDE = gql`
    ${CONTENT_OVERRIDE_FRAGMENT}
    mutation CreateContentOverride($input: createContentOverrideInput!) {
        createContentOverride(input: $input) {
            contentOverride {
                ...ContentOverrideFields
            }
        }
    }
`;

function doSubmit(
    formValues: ContentOverrideFormValue,
    getId: () => string | null,
    createContentOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateContentOverrideResponse>>,
    updateContentOverride: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateContentOverrideResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<ContentOverride>
) {
    return Promise.resolve()
        .then(() => {
            const input: DeepPartial<ContentOverride> = {
                disabled: !formValues.enabled,
                ...fixedProperties,
            };

            if (getId()) {
                input.id = getId() || undefined;
            }

            return input;
        })
        .then((input) =>
            (
                (getId() ? updateContentOverride : createContentOverride) as (
                    options: MutationFunctionOptions
                ) => Promise<FetchResult<MutateContentOverrideResponse>>
            )({ variables: { input } }).catch((err: ApolloError) => {
                handleError()(err);

                throw Error('cancelled');
            })
        );
}

const useSubmitContentOverride = (
    getId: () => string | null,
    setId: (id: string) => void,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: ContentOverrideFormProps['onSuccess'],
    onError: ContentOverrideFormProps['onError'],
    fixedProperties: DeepPartial<ContentOverride>
) => {
    // dans le cas d'un consultant le cache à modifier est celui filtrés par agence
    const isConsultant = useIsGranted(SecurityRole.ROLE_CONSULTANT);
    const [selectedAgency] = useSelectedAgency();
    const agencyVariable = isConsultant ? selectedAgency : null;

    const setAlert = useAlert();
    const [createContentOverride] = useMutation(CREATE_CONTENT_OVERRIDE, {
        // ajout du nouveau ContentOverride dans le cache de la requete GET_CONTENT_OVERRIDES
        update(cache, result) {
            const item = result.data?.createContentOverride
                ?.contentOverride as StoreObject;
            if (!item) {
                return;
            }
            cache.updateQuery(
                {
                    query: GET_CONTENT_OVERRIDES,
                    broadcast: false,
                    variables: {
                        agencyCode: getIdFromIri(entityToId(agencyVariable)),
                    },
                },
                (data) => {
                    return {
                        contentOverrides: {
                            ...data.contentOverrides,
                            collection: [
                                ...data.contentOverrides.collection,
                                item,
                            ],
                        },
                    };
                }
            );
        },
    });

    const [updateContentOverride] = useMutation(UPDATE_CONTENT_OVERRIDE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: ContentOverrideFormValue,
            {
                setSubmitting,
                setErrors,
            }: FormikHelpers<ContentOverrideFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createContentOverride,
                updateContentOverride,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateContentOverrideResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: ContentOverrideResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateContentOverride?.contentOverride
                            : res.data?.createContentOverride?.contentOverride;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    setId(resItem.id);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createContentOverride,
            updateContentOverride,
            setId,
            onSuccess,
            onError,
            setAlert,
            yupSchema,
            fixedProperties,
        ]
    );
};

export default useSubmitContentOverride;
