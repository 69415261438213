// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { SxProps, Theme } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAgency from '../agency/useAgency';
import useSelectedAgency from '../agency/useSelectedAgency';
import useContentVariablesStatus from '../content/useContentVariablesStatus';

interface StatusPageProps {
    content: ContentResponse | undefined;
    iconSx?: SxProps | undefined;
}

function StatusPage({ content, iconSx }: StatusPageProps) {
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const status = useContentVariablesStatus(content, selectedAgency);

    const iconSxWithColor = {
        ...iconSx,
        color: (theme: Theme) => theme.palette[status].main,
    };

    return status === 'success' ? (
        <CheckCircleIcon sx={iconSxWithColor} data-testid="SuccessCheckbox" />
    ) : (
        <WarningIcon sx={iconSxWithColor} data-testid="WarningOrError" />
    );
}

export default StatusPage;
