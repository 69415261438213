import DialogButton from '@acdc/shared/src/ui/DialogButton';
import React from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { Fab } from '@mui/material';
import { DialogProps } from '@acdc/shared/src/ui/Dialog';
import CommentDiscoverySlideForm, {
    CommentDiscoverySlideFormProps,
} from './CommentDiscoverySlideForm';

export interface CommentDiscoverySlideFormDialogButtonProps {
    value: CommentDiscoverySlideFormProps['value'];
    onSuccess: CommentDiscoverySlideFormProps['onSuccess'];
    id: DialogProps['id'];
    sx?: any;
}

function CommentDiscoverySlideFormDialogButton({
    value,
    onSuccess,
    id,
    sx,
}: CommentDiscoverySlideFormDialogButtonProps) {
    return (
        <DialogButton
            label={<CommentIcon />}
            buttonComponent={Fab}
            buttonProps={{
                color: 'primary',
                'aria-label': 'comment',
                sx,
            }}
            dialogProps={{
                id,
                mainTitle: 'Commentaire',
                maxWidth: 'sm',
                fullWidth: true,
            }}
        >
            {(close) => (
                <CommentDiscoverySlideForm
                    value={value}
                    onSuccess={(comment) => {
                        onSuccess && onSuccess(comment);
                        close();
                    }}
                />
            )}
        </DialogButton>
    );
}

export default CommentDiscoverySlideFormDialogButton;
