import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetSearchResponse from '@acdc/shared/src/features/search/GetSearchResponse';
import SearchCode from '@acdc/shared/src/features/search/SearchCode.enum';

export const GET_LOCATION_SEARCH = gql`
    query GetLocationSearch($id: ID!) {
        search(id: $id) {
            id
            code
            options
        }
    }
`;

const useLocations = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<GetSearchResponse>(GET_LOCATION_SEARCH, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only', // cache-only pour exclure son rafraichissement de apps/front/src/features/useRefreshApiData.ts
        variables: {
            id: `/searches/${SearchCode.LOCATION}`,
        },
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des localisations.',
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.search;
};

export default useLocations;
