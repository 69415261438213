import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListVariablesResponse from '@acdc/shared/src/features/variable/ListVariablesResponse';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const GET_VARIABLES = gql`
    query GetVariables {
        variables(itemsPerPage: 10000, order: [{ code: "ASC" }]) {
            collection {
                id
                code
                label
                type
                isTextMulti
                maxChar
                maxLine
                defaultValue
                contentModule {
                    id
                }
                propertyField
            }
        }
    }
`;

/**
 * Retourne tous les Variables.
 */
const useVariables = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListVariablesResponse>(
        GET_VARIABLES,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des balises.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<VariableResponse[] | undefined>(() => {
        return data?.variables?.collection;
    }, [data]);
};

export default useVariables;
