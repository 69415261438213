import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListNotificationsResponse from '@acdc/shared/src/features/notification/ListNotificationsResponse';
import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';

export const GET_NOTIFICATIONS = gql`
    query GetNotifications {
        notifications(itemsPerPage: 10000) {
            collection {
                id
                title
                description
                createdAt
                notificationStatus {
                    id
                    code
                    label
                }
            }
        }
    }
`;

/**
 * Retourne toutes les notifications.
 */
const useNotifications = () => {
    const setAlert = useAlert();
    const isOnline = useOnlineStatus();

    const { data, error } = useQuery<ListNotificationsResponse>(
        GET_NOTIFICATIONS,
        {
            fetchPolicy: isOnline ? 'cache-and-network' : 'cache-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des notifications',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<NotificationResponse[] | undefined>(() => {
        return data?.notifications?.collection;
    }, [data]);
};

export default useNotifications;
