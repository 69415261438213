import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListNotificationUsersResponse from '@acdc/shared/src/features/notificationUser/ListNotificationUsersResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';
import useOnlineStatus from '@acdc/shared/src/tools/use-online-status';
import useNotifications from '../notification/useNotifications';

export const GET_NOTIFICATIONS_USER = gql`
    query GetNotificationsUser {
        notificationUsers(order: [{ id: "DESC" }], itemsPerPage: 10000) {
            collection {
                id
                isRead
                notification {
                    id
                }
            }
        }
    }
`;

/**
 * Retourne tous les NotificationUsers accessibles à l'utilisateur.
 */
const useNotificationsUserOfUser = () => {
    const setAlert = useAlert();
    const isOnline = useOnlineStatus();

    const { data, error } = useQuery<ListNotificationUsersResponse>(
        GET_NOTIFICATIONS_USER,
        {
            fetchPolicy: isOnline ? 'cache-and-network' : 'cache-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement de vos notifications.',
                'error'
            );
        }
    }, [error, setAlert]);
    const notifications = useNotifications();

    return useMemo<NotificationUserResponse[] | undefined>(() => {
        if (!notifications) {
            return undefined;
        }

        return data?.notificationUsers?.collection.map((notificationUser) => ({
            ...notificationUser,
            notification: notifications.filter((notification) =>
                matchOnId(notification, notificationUser.notification)
            )[0],
        }));
    }, [data, notifications]);
};

export default useNotificationsUserOfUser;
