import React from 'react';
import { Link } from 'react-router-dom';

function Logo() {
    return (
        <Link data-testid="headerLogo" to="/">
            <img
                src="/images/logo-laforet.png"
                alt="Logo LaForêt"
                data-testid="logoLaForet"
                style={{
                    width: 'auto',
                    height: '40px',
                    marginRight: '10px',
                }}
            />
        </Link>
    );
}

export default Logo;
