import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import VariableValue from '@acdc/shared/src/features/variableValue/VariableValue.model';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import ImageOverrideFormInner from './ImageOverrideFormInner';
import useSubmitImageOverride from './useSubmitImageOverride';

export interface ImageOverrideFormValue {
    file: File[];
}

const initValue = (): ImageOverrideFormValue => ({
    file: [],
});

export const imageOverrideFormSchema = Yup.object().shape({
    file: Yup.array()
        .label('Le fichier')
        .test(
            'requiredImage',
            'Le fichier est requis',
            (images: any[] | undefined) => {
                return images === undefined || images.length > 0;
            }
        ),
});

export type ImageOverrideFormProps = {
    value?: ImageOverrideResponse | undefined;
    contentModule: ContentModuleResponse;
    agency: AgencyResponse;
    onSuccess?: (res: ImageOverrideResponse) => void;
    onError?: (err: ApolloError) => void;
};

function ImageOverrideForm({
    value,
    contentModule,
    agency,
    onSuccess,
    onError,
}: ImageOverrideFormProps) {
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: ImageOverrideFormValue = useMemo(
        () => initValue(),
        []
    );

    const [saved, setSaved] = useState(Boolean(value?.id && value.publicPath));

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<VariableValue>>(
        () => ({
            contentModule: entityToId(contentModule) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [contentModule, agency]
    );

    const submit = useSubmitImageOverride(
        getId,
        setId,
        setSaved,
        imageOverrideFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={imageOverrideFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <ImageOverrideFormInner
                    moduleCode={contentModule.module?.code}
                    initialValue={value}
                    contentModule={contentModule}
                    getId={getId}
                    saved={saved}
                />
            </Form>
        </Formik>
    );
}

export default ImageOverrideForm;
