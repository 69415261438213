import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ServicePackageOverrideResponse from '@acdc/shared/src/features/servicePackageOverride/ServicePackageOverrideResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React, { useMemo } from 'react';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import ServicePackageOverridePriceForm from './ServicePackageOverridePriceForm';
import { getServiceAgency } from '../../serviceAgency/useServiceAgency';
import useServicesOfAgency from '../../service/useServicesOfAgency';

export interface ServicePackageOverridePricesFormsProps {
    agency: AgencyResponse;
    pack: PackageResponse;
}

/**
 * Liste de formulaires de prix des Services activés dans l'agence.
 * Donc un form pour chaque service avec ServiceAgency.disabled=false.
 */
function ServicePackageOverridePricesForms({
    agency,
    pack,
}: ServicePackageOverridePricesFormsProps) {
    const services = useServicesOfAgency(agency);
    const servicePackageOverrides = useMemo(() => {
        if (!agency || !services) {
            return [];
        }

        return (
            services.reduce((curr, service) => {
                const serviceAgency = getServiceAgency(service, agency);
                if (serviceAgency?.disabled !== false) {
                    // service désactivé dans cette agence
                    return curr;
                }

                const currentServicePackageOverride =
                    agency.servicePackageOverrides?.collection?.find(
                        (sp) =>
                            matchOnId(sp.service, service) &&
                            matchOnId(sp.package, pack)
                    );
                curr.push({
                    ...currentServicePackageOverride,
                    service,
                });

                return curr;
            }, [] as ServicePackageOverrideResponse[]) || []
        );
    }, [services, pack, agency]);

    return (
        <>
            {servicePackageOverrides.map((servicePackageOverride) => (
                <ServicePackageOverridePriceForm
                    key={`${servicePackageOverride.service?.id}`}
                    value={servicePackageOverride}
                    service={servicePackageOverride.service!}
                    agency={agency}
                    pack={pack}
                />
            ))}
        </>
    );
}

export default ServicePackageOverridePricesForms;
