import { useEffect } from 'react';
import {
    gql,
    LazyQueryResultTuple,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import type GetUserResponse from '@acdc/shared/src/features/user/GetUserResponse';

export const GET_USER_TO_UPDATE = gql`
    query GetUserToUpdate($id: ID!) {
        user(id: $id) {
            id
            lastname
            firstname
            email
            phone
            secondaryEmail
            profession
            comment
            photo {
                id
                publicPath
                size
                originalName
            }
        }
    }
`;

const useLazyUserToUpdate = (
    id: string | null | undefined
): LazyQueryResultTuple<GetUserResponse, OperationVariables> => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetUserResponse>(GET_USER_TO_UPDATE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du profil.',
                'error'
            );
        }
    }, [error, setAlert]);

    return res;
};

export default useLazyUserToUpdate;
