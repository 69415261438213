import React, { useEffect } from 'react';
import useServiceWorker from './useServiceWorker';

export interface ServiceWorkerProps {
    children: React.ReactNode;
}

function ServiceWorker({ children }: ServiceWorkerProps) {
    const { showReload, waitingWorker, reloadPage } = useServiceWorker();

    useEffect(() => {
        if (showReload && waitingWorker) {
            // eslint-disable-next-line no-console
            console.log('rafraichissement du ServiceWorker');
            reloadPage();
        }
    }, [waitingWorker, showReload, reloadPage]);

    return <>{children}</>;
}

export default ServiceWorker;
