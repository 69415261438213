import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import React from 'react';
import useLoadPackages from './useLoadPackages';

export const PackagesCtx = React.createContext<PackageResponse[] | undefined>(
    undefined
);

export interface PackagesProviderProps {
    children?: React.ReactNode;
}

function PackagesProvider({ children }: PackagesProviderProps) {
    const packages = useLoadPackages();

    return (
        <PackagesCtx.Provider value={packages}>{children}</PackagesCtx.Provider>
    );
}

export default PackagesProvider;
