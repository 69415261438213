import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PropertyResponse from '@acdc/shared/src/features/property/PropertyResponse';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import { usePersistentState } from '@acdc/shared/src/tools/pesistant-state';
import React from 'react';
import CONFIGS from '../../configs';
import useLoadProperties from './useLoadProperties';

export const PropertiesCtx = React.createContext<
    PropertyResponse[] | undefined
>(undefined);

export interface PropertiesProviderProps {
    children?: React.ReactNode;
    agency?: string | AgencyResponse | null | undefined;
    skipAgencyRequests?: boolean | undefined;
}

function PropertiesProvider({
    children,
    agency,
    skipAgencyRequests,
}: PropertiesProviderProps) {
    const [user] = usePersistentState<UserResponse>(
        CONFIGS.userDataStorageKeys.currentUser
    );
    if (user === undefined) {
        throw Error(
            'Tentative de chargement des properties sans utilisateur connecté.'
        );
    }
    const items = useLoadProperties(user, agency, skipAgencyRequests);

    return (
        <PropertiesCtx.Provider value={items}>
            {children}
        </PropertiesCtx.Provider>
    );
}

export default PropertiesProvider;
