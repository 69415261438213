import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import { Stack, Typography } from '@mui/material';
import JoinedButton from '../../../ui/buttons/JoinedButton';
import { arrowRightIconSx, baseSx } from './NavigateDiscoveryFormSlideButton';
import ConfirmButton, {
    ConfirmButtonProps,
} from '../../../ui/buttons/ConfirmButton';

const titleIconSx = { fontSize: '70px' };

export interface SubmitFormButtonProps {
    submit: ConfirmButtonProps['onConfirm'];
    disabled?: boolean;
    canSubmit?: ConfirmButtonProps['canSubmit'];
}

function SubmitFormButton({
    submit,
    disabled,
    canSubmit,
}: SubmitFormButtonProps) {
    return (
        <ConfirmButton
            buttonComponent={JoinedButton as any}
            buttonProps={{
                sx: baseSx,
                roundedSide: 'right',
                variant: 'contained',
                color: 'success',
                disabled,
            }}
            canSubmit={canSubmit}
            onConfirm={submit}
            title={
                <Stack direction="column" alignItems="center">
                    <InfoIcon sx={titleIconSx} color="primary" />
                    <Typography variant="h4">
                        Validation du formulaire
                    </Typography>
                </Stack>
            }
        >
            Valider <CheckIcon fontSize="small" sx={arrowRightIconSx} />
        </ConfirmButton>
    );
}

export default SubmitFormButton;
