import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import useTools from '../features/tools/useTools';
import Content from '../features/content/Content';
import { filterVisibleContentTool } from '../features/tools/useFilterVisibleContentTool';
import isToolEnabled from '../features/tools/isToolEnabled';
import useAgency from '../features/agency/useAgency';
import useSelectedAgency from '../features/agency/useSelectedAgency';

const containerPageSx = {
    width: '90%',
    marginLeft: '5%',
};

function ListTools() {
    const toolsIds = useMemo(() => {
        const urlQuery = decodeURI(window.location.search).replace(
            /\[\d+\]/g,
            '[]'
        );
        const urlParams = new URLSearchParams(urlQuery);
        return urlParams.getAll('tools[]');
    }, []);

    const allTools = useTools();
    const agency = useAgency(useSelectedAgency()[0]);

    const selectedTools = useMemo(() => {
        const filteredTools: ToolResponse[] =
            allTools?.reduce((curr: ToolResponse[], tool: ToolResponse) => {
                if (
                    toolsIds.includes(tool.id || '') &&
                    isToolEnabled(tool, agency)
                ) {
                    const filteredTool = filterVisibleContentTool(
                        tool,
                        agency,
                        false
                    );
                    if (filteredTool) {
                        curr.push(filteredTool);
                    }
                }

                return curr;
            }, []) || [];

        // on met les outils dans le meme ordre que les paramètres dans l'url
        filteredTools?.sort(
            (a, b) => toolsIds.indexOf(a.id!) - toolsIds.indexOf(b.id!)
        );

        return filteredTools;
    }, [allTools, toolsIds, agency]);

    return (
        <Box sx={containerPageSx}>
            {selectedTools?.map((selectedTool) => {
                return selectedTool.toolContents?.collection.map(
                    (toolContent) => (
                        <Content
                            content={toolContent}
                            editMode={false}
                            toolId={selectedTool.id}
                            exportPdf
                            key={toolContent.id}
                        />
                    )
                );
            })}
        </Box>
    );
}

export default ListTools;
