import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListServicesResponse from '@acdc/shared/src/features/service/ListServicesResponse';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';

export const SERVICE_FRAGMENT = gql`
    fragment ServiceFields on Service {
        id
        label
        longLabel
        sortOrder
        type {
            id
            label
            code
        }
        agency {
            id
        }
    }
`;

export const GET_SERVICES = gql`
    ${SERVICE_FRAGMENT}
    query GetServices {
        services(itemsPerPage: 10000, order: [{ id: "ASC" }]) {
            collection {
                ...ServiceFields
            }
        }
    }
`;

/**
 * Retourne tous les Services.
 * Les initiaux + les additionnels partagés + les additionnels de mes agences.
 * Donc tous les services auquels j'ai accès.
 */
const useLoadServices = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListServicesResponse>(
        GET_SERVICES,
        persistentQueryOptions
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des services.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo(() => {
        return data?.services?.collection;
    }, [data]);
};

export default useLoadServices;
