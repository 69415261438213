import React from 'react';
import { Skeleton } from '@mui/material';

function PackageSummarySkeleton() {
    return (
        <Skeleton
            variant="rectangular"
            data-testid="PackageSummarySkeleton"
            width="100%"
            height="100%"
            sx={{ borderRadius: 1 }}
        />
    );
}

export default PackageSummarySkeleton;
