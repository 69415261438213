import GetUserResponse from '@acdc/shared/src/features/user/GetUserResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_USER = gql`
    query GetUser($id: ID!) {
        user(id: $id) {
            id
            code
            firstname
            lastname
            roles
        }
    }
`;

const useUser = (id: string | null | undefined) => {
    const setAlert = useAlert();
    // useLazyQuery
    const { data, error } = useQuery<GetUserResponse>(GET_USER, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement de l'utilisateur.",
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.user;
};

export default useUser;
