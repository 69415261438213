import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';
import { List } from '@mui/material';
import React from 'react';
import NotificationListItem from './NotificationListItem';

interface NotificationListProps {
    notificationUsers: NotificationUserResponse[] | null;
}

function NotificationList({ notificationUsers }: NotificationListProps) {
    return (
        <List>
            {notificationUsers &&
                notificationUsers.map(
                    (notificationUser: NotificationUserResponse) => (
                        <NotificationListItem
                            key={notificationUser.id}
                            notificationUser={notificationUser}
                        />
                    )
                )}
        </List>
    );
}

export default NotificationList;
