import { filterNumber } from '@acdc/shared/src/utils/form-helpers';
import QuotationFormValue from './QuotationFormValue';
import type QuotationValuesModel from '../QuotationValues.model';

function quotationFormValueToModel({
    visitFeeUnrounded,
    ...formValues
}: QuotationFormValue): QuotationValuesModel {
    const modelValue: any = {};
    const keys = Object.keys(formValues);
    keys.forEach((key) => {
        if (key === 'visitFee' && visitFeeUnrounded !== null) {
            // On sauvegarde la valeur non arrondie si dispo.
            // Elle sera de nouveau arrondie à l'initialisation du formulaire.
            modelValue[key] = visitFeeUnrounded;
            return;
        }
        const filtered = filterNumber((formValues as any)[key]);
        modelValue[key] = filtered === null ? undefined : filtered;
    });

    return modelValue as QuotationValuesModel;
}

export default quotationFormValueToModel;
