import React, { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import PresentationHeader from './PresentationHeader';
import DrawerState, { DrawerStateEnum } from './SideBar/DrawerState';
import useLeftDrawerState from './LeftSideBar/useLeftSideBarState';
import LayoutSkeleton from './LayoutSkeleton';

const gutter = { xs: '2%', md: '5%' };
const width = { xs: '94%', md: '90%' };

function PresentationLayout() {
    const [drawerState, setDrawerState] = React.useState<DrawerState>({
        currentState: DrawerStateEnum.CLOSE,
        previousState: DrawerStateEnum.CLOSE,
    });
    const [leftDrawerState, setLeftDrawerState] = useLeftDrawerState();

    const toggleDrawer = useCallback(() => {
        setDrawerState((current) => ({
            previousState: current.currentState,
            currentState:
                current.currentState === DrawerStateEnum.CLOSE
                    ? DrawerStateEnum.OPEN
                    : DrawerStateEnum.CLOSE,
        }));
    }, [setDrawerState]);

    const toggleFullScreen = useCallback(() => {
        setDrawerState((current) => ({
            previousState: current.currentState,
            currentState:
                current.currentState === DrawerStateEnum.OPEN
                    ? DrawerStateEnum.FULLSCREEN
                    : DrawerStateEnum.OPEN,
        }));
    }, [setDrawerState]);

    const toggleLeftDrawer = useCallback(() => {
        setLeftDrawerState((current) => ({
            ...current,
            state: !current?.state,
        }));
    }, [setLeftDrawerState]);
    return (
        <LayoutSkeleton
            width={width}
            gutter={gutter}
            drawerState={drawerState}
            toggleDrawer={toggleDrawer}
            toggleFullScreen={toggleFullScreen}
            leftDrawerState={leftDrawerState}
            toggleLeftDrawer={toggleLeftDrawer}
        >
            <PresentationHeader
                width={width}
                gutter={gutter}
                toggleDrawer={toggleDrawer}
            />
            <Outlet />
        </LayoutSkeleton>
    );
}

export default PresentationLayout;
