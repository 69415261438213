import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryCriteriaResponse from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaResponse';
import DiscoveryCriteriaType from '@acdc/shared/src/features/discoveryCriteria/DiscoveryCriteriaType.enum';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import DiscoveryValue from '@acdc/shared/src/features/discovery/DiscoveryValue';
import DiscoveryCriteriaForm from './DiscoveryCriteriaForm';
import shouldShowDiscoveryCriteria from './shouldShowDiscoveryCriteria';
import getCriteriaValue from './getCriteriaValue';

function getLabelWidth(isRootCriteria: boolean): number {
    return isRootCriteria ? 12 : 4;
}

function getBodyWidth(isRootCriteria: boolean, displayLabel: boolean): number {
    if (isRootCriteria) {
        return 12;
    }

    return displayLabel ? 8 : 12;
}

function shouldDisplayLabel(
    discoveryCriteria: DiscoveryCriteriaResponse
): boolean {
    return discoveryCriteria.type === DiscoveryCriteriaType.GROUP;
}

export interface DiscoveryCriteriaSectionProps {
    discoveryValues: DiscoveryValue[];
    discoveryCriteria: DiscoveryCriteriaResponse;
    agency: AgencyResponse;
    allDiscoveryCriterias: DiscoveryCriteriaResponse[];
    onCriteriaValueChange: (
        newValue: string,
        discoveryCriteria: DiscoveryCriteriaResponse
    ) => void;
    addCurrentSlideError: (
        criteria: string | DiscoveryCriteriaResponse
    ) => void;
    removeCurrentSlideError: (
        criteria: string | DiscoveryCriteriaResponse
    ) => void;
}

function DiscoveryCriteriaSection({
    discoveryCriteria,
    ...propsForChild
}: DiscoveryCriteriaSectionProps) {
    const {
        discoveryValues,
        agency,
        allDiscoveryCriterias,
        onCriteriaValueChange,
        addCurrentSlideError,
        removeCurrentSlideError,
    } = propsForChild;

    const isRootCriteria = !discoveryCriteria.parentCriteria;
    const displayLabel = shouldDisplayLabel(discoveryCriteria);

    const getBody = () => {
        switch (discoveryCriteria.type) {
            case DiscoveryCriteriaType.GROUP:
                return (
                    <Grid container spacing={2}>
                        {allDiscoveryCriterias.reduce(
                            (
                                validSubCriterias: React.ReactNode[],
                                subCriteria
                            ) => {
                                if (
                                    !matchOnId(
                                        subCriteria.parentCriteria,
                                        discoveryCriteria
                                    )
                                ) {
                                    // pas un sous critère de ce groupe
                                    return validSubCriterias;
                                }
                                if (
                                    !shouldShowDiscoveryCriteria(
                                        subCriteria,
                                        discoveryValues,
                                        allDiscoveryCriterias,
                                        agency
                                    )
                                ) {
                                    return validSubCriterias;
                                }

                                validSubCriterias.push(
                                    <Grid
                                        item
                                        key={subCriteria.id}
                                        xs={subCriteria.displayWidth}
                                    >
                                        <DiscoveryCriteriaSection
                                            discoveryCriteria={subCriteria}
                                            {...propsForChild}
                                        />
                                    </Grid>
                                );

                                return validSubCriterias;
                            },
                            []
                        )}
                    </Grid>
                );
            default:
                return (
                    <DiscoveryCriteriaForm
                        discoveryValue={getCriteriaValue(
                            discoveryCriteria,
                            discoveryValues
                        )}
                        discoveryCriteria={discoveryCriteria}
                        agency={agency}
                        onSuccess={(v) => {
                            return onCriteriaValueChange(v, discoveryCriteria);
                        }}
                        onError={() => addCurrentSlideError(discoveryCriteria)}
                        onClearErrors={() =>
                            removeCurrentSlideError(discoveryCriteria)
                        }
                    />
                );
        }
    };

    return (
        <Grid container spacing={isRootCriteria ? 3 : 0}>
            {displayLabel && (
                <Grid
                    item
                    xs={getLabelWidth(isRootCriteria)}
                    display="flex"
                    justifyContent={isRootCriteria ? 'center' : 'flex-start'}
                    alignItems={isRootCriteria ? 'flex-start' : 'center'}
                >
                    <Typography
                        variant={isRootCriteria ? 'h2' : 'h4'}
                        color="primary.dark"
                    >
                        {discoveryCriteria.label}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={getBodyWidth(isRootCriteria, displayLabel)}>
                {getBody()}
            </Grid>
        </Grid>
    );
}

export default DiscoveryCriteriaSection;
