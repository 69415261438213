import { Alert, InputAdornment, TextField } from '@mui/material';
import React, { useCallback } from 'react';

const alertSx = {
    marginTop: 2,
};

function computeSuggestedMaxRent(
    netIncomeValue: string | undefined,
    divider: number
): string {
    if (!netIncomeValue) {
        return '';
    }

    const netIncome = parseFloat(netIncomeValue);

    if (Number.isNaN(netIncome)) {
        return '';
    }

    return `${(netIncome / divider).toFixed(0)} €`;
}

export interface MaxRentCalculatorProps {
    netIncomeLabel: string;
    divider: number;
    netIncomeHelperText?: string | undefined;
    netIncomeMin?: number | undefined;
    netIncomeStep?: number | undefined;
}

function MaxRentCalculator({
    netIncomeLabel,
    divider,
    netIncomeHelperText,
    netIncomeMin,
    netIncomeStep,
}: MaxRentCalculatorProps) {
    const [netIncomeValue, setNetIncomeValue] = React.useState<string>('');

    const handleNetIncomeValueChange = useCallback(
        (ev: any) => {
            setNetIncomeValue(ev.target.value);
        },
        [setNetIncomeValue]
    );

    const suggestedMaxRent = computeSuggestedMaxRent(netIncomeValue, divider);

    return (
        <>
            <TextField
                label={netIncomeLabel}
                value={netIncomeValue}
                onChange={handleNetIncomeValueChange}
                type="number"
                helperText={netIncomeHelperText}
                InputProps={{
                    inputProps: {
                        min: netIncomeMin,
                        step: netIncomeStep,
                    },
                    endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                    ),
                }}
                fullWidth
                autoComplete="off"
            />
            <Alert severity="info" variant="outlined" sx={alertSx}>
                Loyer maximum suggéré: {suggestedMaxRent}
            </Alert>
        </>
    );
}

export default MaxRentCalculator;
