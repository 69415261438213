import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import React, { useMemo } from 'react';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import DialogButton from '@acdc/shared/src/ui/DialogButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import findSettingValueByMultipleSettingCodes from '../findSettingValuesByMultipleSettingCodes';
import useSelectedAgency from '../../agency/useSelectedAgency';
import useAgency from '../../agency/useAgency';
import PdfAsImagesViewer from '../../pdfAsImage/PdfAsImagesViewer';

export interface PdfModuleProps {
    contentModule: ContentModuleResponse;
}

const containerViewerSx = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const containerLoadingSX = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
};

function PdfModule({ contentModule }: PdfModuleProps) {
    const agency = useAgency(useSelectedAgency()[0]);
    const value = useMemo(
        () =>
            findSettingValueByMultipleSettingCodes(
                [ModuleSettingCode.IMAGE, ModuleSettingCode.ALT_NAME],
                contentModule
            ),
        [contentModule]
    );
    const imageOverride = useMemo(() => {
        if (!agency) {
            return undefined;
        }

        return agency.imageOverrides?.collection.find((io) =>
            matchOnId(io.contentModule, contentModule)
        );
    }, [agency, contentModule]);

    const file = imageOverride?.imageOverrideFile
        ? imageOverride?.imageOverrideFile
        : value[ModuleSettingCode.IMAGE]?.file;

    const dialogProps = useMemo<any>(
        () => ({
            id: file?.id,
            mainTitle:
                value[ModuleSettingCode.ALT_NAME]?.value ||
                'Visualisation du pdf',
            maxWidth: false,
            fullWidth: false,
            fullScreen: true,
            sxDialogContent: {
                background: (Theme: any) => Theme.palette.background.default,
            },
        }),
        [file?.id, value]
    );

    return (
        <Box padding={1} sx={containerViewerSx}>
            {file?.needPostProcess ? (
                <Box sx={containerLoadingSX}>
                    <PictureAsPdfIcon
                        fontSize="large"
                        color="primary"
                        sx={{ marginBottom: 2 }}
                    />
                    <Alert severity="warning">
                        <AlertTitle>Traitement en cours</AlertTitle>
                        Votre PDF est en cours de traitement. L&apos;opération
                        peut prendre jusqu&apos;à 5 minutes. Essayez de
                        rafraîchir votre application.
                    </Alert>
                </Box>
            ) : (
                <DialogButton
                    label="Visualiser le pdf"
                    buttonComponent={Button}
                    buttonProps={{
                        variant: 'contained',
                        'data-testid': 'PdfModuleDialogButton',
                    }}
                    dialogProps={dialogProps}
                    onClose={() => {}}
                >
                    {() => (
                        <Box pt={2}>
                            <PdfAsImagesViewer pdfPicture={file} />
                        </Box>
                    )}
                </DialogButton>
            )}
        </Box>
    );
}

export default PdfModule;
