import { Box, Theme } from '@mui/material';
import React from 'react';
import QuotationRentTotals from '../QuotationRentTotals.model';
import QuotationStack from '../QuotationStack';
import QuotationResultRow from './QuotationResultRow';

export interface QuotationRentResultProps {
    rentTotals: QuotationRentTotals;
}

function QuotationRentResult({ rentTotals }: QuotationRentResultProps) {
    return (
        <QuotationStack
            title="Location"
            color={(theme: Theme) => theme.palette.rental.main}
            spacing={0.5}
        >
            {rentTotals.visitFee !== undefined && (
                <QuotationResultRow
                    variant="values"
                    label="Visite du logement, constitution du dossier et rédaction du bail"
                    annual={rentTotals.visitFee}
                    containerProps={{
                        'data-testid': 'visitFeeRow',
                    }}
                />
            )}
            {rentTotals.initialInventoryFee !== undefined && (
                <QuotationResultRow
                    variant="values"
                    label="Honoraires état des lieux d'entrée"
                    annual={rentTotals.initialInventoryFee}
                    containerProps={{
                        'data-testid': 'initialInventoryFeeRow',
                    }}
                />
            )}
            {rentTotals.biddingAndNegotiation !== undefined && (
                <QuotationResultRow
                    variant="values"
                    label="Frais d'entremise et négociation"
                    annual={rentTotals.biddingAndNegotiation}
                    containerProps={{
                        'data-testid': 'biddingAndNegotiationRow',
                    }}
                />
            )}
            <QuotationResultRow
                variant="total"
                color="rental"
                label={
                    <span>
                        TOTAL HONORAIRES LOCATION{' '}
                        <Box
                            component="span"
                            textTransform="lowercase"
                            fontSize={13}
                        >
                            (Déductibles des revenus locatifs)
                        </Box>
                    </span>
                }
                annual={rentTotals.totalLocationFee}
                containerProps={{
                    'data-testid': 'totalLocationFeeRow',
                }}
            />
        </QuotationStack>
    );
}

export default QuotationRentResult;
