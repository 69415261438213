import React from 'react';
import { TextFieldProps, fieldToTextField } from 'formik-mui';
import UiSliderWithInput, {
    SliderWithInputOwnProps,
} from '../ui/SliderWithInput';

export type SliderWithInputProps = SliderWithInputOwnProps & TextFieldProps;

function SliderWithInput(props: SliderWithInputProps) {
    return <UiSliderWithInput {...(fieldToTextField(props) as any)} />;
}

export default SliderWithInput;
