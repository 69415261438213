import React from 'react';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import VariableValueInput from '@acdc/shared/src/features/variableValue/VariableValueInput';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';

export interface VariableValueFormInnerProps {
    variable: VariableResponse;
    getId: () => string | null;
    saved: boolean;
}

function VariableValueFormInner({
    variable,
    getId,
    saved,
}: VariableValueFormInnerProps) {
    const pendingSave = useAutoSubmitSingleFieldForm('value', getId);
    const isValid: boolean = Boolean(saved && !pendingSave);

    return (
        <VariableValueInput
            variable={variable}
            name="value"
            id="valueInput"
            fullWidth
            color={isValid ? 'success' : 'primary'}
            focused={isValid ? true : undefined} // pour afficher la couleur
            disabled={false} // il ne faut pas bloquer le champ pendant une sauvegarde
            required={!variable.defaultValue}
            size="small"
        />
    );
}

export default VariableValueFormInner;
