import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { Stack } from '@mui/material';
import React from 'react';
import automaticallyDefinedVariableTypes from '@acdc/shared/src/features/variable/automaticallyDefinedVariableTypes';
import VariableValueForm from './VariableValueForm';

export interface ContentVariableValuesFormProps {
    content: ContentResponse;
    agency: AgencyResponse;
}

/**
 * Liste de formulaires pour chaque variable d'un Content.
 * Avec les valeurs d'une Agency.
 */
function ContentVariableValuesForm({
    content,
    agency,
}: ContentVariableValuesFormProps) {
    return (
        <Stack direction="column" spacing={2}>
            {content.contentModules?.collection.reduce<React.ReactNode[]>(
                (curr, contentModule) => [
                    ...curr,
                    ...(contentModule.variables?.collection.reduce(
                        (forms: React.ReactNode[], variable) => {
                            if (
                                (
                                    automaticallyDefinedVariableTypes as (
                                        | VariableType
                                        | undefined
                                    )[]
                                ).includes(variable.type) === false
                            ) {
                                forms.push(
                                    <VariableValueForm
                                        key={`${variable.id}`}
                                        value={agency.variableValues?.collection.find(
                                            (variableValue) =>
                                                matchOnId(
                                                    variable,
                                                    variableValue.variable
                                                )
                                        )}
                                        variable={variable}
                                        agency={agency}
                                    />
                                );
                            }
                            return forms;
                        },
                        []
                    ) || []),
                ],
                []
            ) || null}
        </Stack>
    );
}

export default ContentVariableValuesForm;
