import React, { useEffect, useState } from 'react';
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    NormalizedCacheObject,
} from '@apollo/client';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localforage from 'localforage';
import initApollo from './initApollo';
import PersistorProvider from './PersistorProvider';
import CacheAgeProvider from './CacheAgeProvider';

async function init(
    setPersistor: React.Dispatch<
        React.SetStateAction<CachePersistor<NormalizedCacheObject> | undefined>
    >,
    setClient: React.Dispatch<
        React.SetStateAction<ApolloClient<NormalizedCacheObject> | undefined>
    >
) {
    const cache = new InMemoryCache();
    const newPersistor = new CachePersistor({
        cache,
        storage: new LocalForageWrapper(localforage),
        maxSize: false,
    });
    await newPersistor.restore();
    setPersistor(newPersistor);
    setClient(initApollo(cache));
}

export interface PersistedApolloProviderProps {
    children: React.ReactNode;
}

function PersistedApolloProvider({ children }: PersistedApolloProviderProps) {
    const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
    const [persistor, setPersistor] =
        useState<CachePersistor<NormalizedCacheObject>>();

    useEffect(() => {
        init(setPersistor, setClient);
    }, [setPersistor, setClient]);

    if (!client || !persistor) {
        return null;
    }

    return (
        <ApolloProvider client={client}>
            <PersistorProvider persistor={persistor}>
                <CacheAgeProvider>{children}</CacheAgeProvider>
            </PersistorProvider>
        </ApolloProvider>
    );
}

export default PersistedApolloProvider;
