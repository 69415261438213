import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import Yup from '@acdc/shared/src/yup/yupFr';
import { initBool } from '@acdc/shared/src/utils/form-helpers';
import Preferences from '../Preferences.model';
import PreferencesFormInner from './PreferencesFormInner';
import useSubmitPreferencesForm from './useSubmitPreferencesForm';

const preferencesFormSchema = Yup.object().shape({
    navigateWithButtons: Yup.bool()
        .label('Activer la navigation par boutons')
        .required(),
});

export interface PreferencesFormValue {
    navigateWithButtons: boolean;
}

const initValue = (value: Preferences): PreferencesFormValue => ({
    navigateWithButtons: initBool(value.navigateWithButtons, false),
});

export interface PreferencesFormProps {
    value?: Preferences | undefined;
    onSuccess?: ((res: Preferences) => void) | undefined;
}

function PreferencesForm({ value, onSuccess }: PreferencesFormProps) {
    const initialValues = useMemo(() => initValue(value || {}), [value]);

    const submit = useSubmitPreferencesForm(onSuccess);

    if (!initialValues) {
        return <FormSkeleton nbInputs={1} />;
    }

    return (
        <Formik
            validationSchema={preferencesFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <PreferencesFormInner />
            </Form>
        </Formik>
    );
}

export default PreferencesForm;
