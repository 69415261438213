import React from 'react';
import NotificationQueueHandler from './NotificationQueueHandler';
import NotificationQueueProvider from './NotificationQueueProvider';

export interface NotificationQueueProps {
    children: React.ReactNode;
}

function NotificationQueue({ children }: NotificationQueueProps) {
    return (
        <NotificationQueueProvider>
            <NotificationQueueHandler />
            {children}
        </NotificationQueueProvider>
    );
}

export default NotificationQueue;
