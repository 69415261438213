/**
 * Compte les enfants directs dans un React.ReactNode.
 * Donc les enfants indirectes ne sont pas comptés (ex: si j'ai plusieurs divs dans
 * une div, ça ne compte que pour 1 = la div parente).
 *
 * @param stopAt Pour optimiser et éviter de parcourir tous les children, on peut indiquer à combien de children on arrète de compte.
 */
function countChildren(
    children: React.ReactNode,
    stopAt: number | null = null
): number {
    if (!children) {
        // un child false ou null est ignoré par React
        return 0;
    }

    if (!Array.isArray(children)) {
        // pas un Array donc c'est un node
        return 1;
    }

    if (stopAt !== null && stopAt < 1) {
        // si stopAt est négatif on ne compte rien
        return 0;
    }

    let count: number = 0;

    for (let i = 0; i < children.length; i += 1) {
        const child = children[i];
        const stopAt2 = stopAt ? stopAt - count : null; // le nombre d'items restants à compter
        const childChildren = countChildren(child, stopAt2);
        count += childChildren;

        if (stopAt && count >= stopAt) {
            return stopAt;
        }
    }

    return count;
}

export default countChildren;
