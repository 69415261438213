import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import DiscoveryFormResponse from '@acdc/shared/src/features/discoveryForm/DiscoveryFormResponse';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React from 'react';
import DiscoveryCriteriaOverrideForm from '../DiscoveryCriteriaOverrideForm/DiscoveryCriteriaOverrideForm';

export interface DiscoveryCriteriaOverridesFormsProps {
    discoveryForm: DiscoveryFormResponse;
    agency: AgencyResponse;
}

function DiscoveryCriteriaOverridesForms({
    discoveryForm,
    agency,
}: DiscoveryCriteriaOverridesFormsProps) {
    return (
        <>
            {discoveryForm.criterias?.collection.reduce(
                (curr: React.ReactNode[], discoveryCriteria) => {
                    if (discoveryCriteria.showInEditionForm === false) {
                        return curr;
                    }
                    curr.push(
                        <React.Fragment key={entityToId(discoveryCriteria)}>
                            {!discoveryCriteria.parentCriteria && <hr />}
                            <DiscoveryCriteriaOverrideForm
                                value={agency.discoveryCriteriaOverrides?.collection.find(
                                    (o) =>
                                        matchOnId(o.criteria, discoveryCriteria)
                                )}
                                discoveryCriteria={discoveryCriteria}
                                agency={agency}
                            />
                        </React.Fragment>
                    );
                    return curr;
                },
                []
            )}
        </>
    );
}

export default DiscoveryCriteriaOverridesForms;
