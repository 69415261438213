import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListAgenciesResponse from '@acdc/shared/src/features/agency/ListAgenciesResponse';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import useContentOverrides from '../contentOverride/useContentOverrides';
import useVariableValues from '../variableValue/useVariableValues';
import useServiceAgencies from '../serviceAgency/useServiceAgencies';
import usePackageAgencies from '../packageAgency/usePackageAgencies';
import useImageOverrides from '../imageOverride/useImageOverrides';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import useToolOverrides from '../toolOverride/useToolOverrides';
import useServicePackageOverrides from '../servicePackageOverride/useServicePackageOverrides';
import useLoadDiscoveryCriteriaOverrides from '../discovery/useLoadDiscoveryCriteriaOverrides';

export const GET_AGENCIES = gql`
    query GetAgencies {
        agencies(itemsPerPage: 10000, order: [{ name: "ASC" }]) {
            collection {
                id
                name
                code
                address
                useNewPackages
                syndicEnabled
            }
        }
    }
`;

/**
 *
 * @param onlyConfigsOfAgency Filtre sur une agence. Toutes les agences seront retournées mais uniquement les configurations de l'agence indiquée seront chargées. Sans ce filtre toutes les configurations sont chargées.
 * @param skipAgencyRequests Si true les requêtes filtrées par agence seront skip.
 * @returns
 */
const useLoadAgencies = (
    onlyConfigsOfAgency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null,
    enabledPreloadImage: boolean | undefined | null = true
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListAgenciesResponse>(
        GET_AGENCIES,
        persistentQueryOptions
    );

    const contentOverrides = useContentOverrides(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const variableValues = useVariableValues(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const serviceAgencies = useServiceAgencies(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const packageAgencies = usePackageAgencies(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const imageOverrides = useImageOverrides(
        onlyConfigsOfAgency,
        skipAgencyRequests,
        enabledPreloadImage
    );
    const toolOverrides = useToolOverrides(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const servicePackageOverrides = useServicePackageOverrides(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );
    const discoveryCriteriaOverrides = useLoadDiscoveryCriteriaOverrides(
        onlyConfigsOfAgency,
        skipAgencyRequests
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des agences.',
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo<AgencyResponse[] | undefined>(() => {
        if (
            !contentOverrides ||
            !variableValues ||
            !serviceAgencies ||
            !packageAgencies ||
            !imageOverrides ||
            !toolOverrides ||
            !servicePackageOverrides ||
            !discoveryCriteriaOverrides
        ) {
            return undefined;
        }
        const agencies = data?.agencies?.collection.map((agency) => ({
            ...agency,
            contentOverrides: {
                collection: contentOverrides.filter((contentOverride) =>
                    matchOnId(contentOverride.agency, agency)
                ),
            },
            variableValues: {
                collection: variableValues.filter((variableValue) =>
                    matchOnId(variableValue.agency, agency)
                ),
            },
            serviceAgencies: {
                collection: serviceAgencies.filter((serviceAgencie) =>
                    matchOnId(serviceAgencie.agency, agency)
                ),
            },
            packageAgencies: {
                collection: packageAgencies.filter((packageAgencie) =>
                    matchOnId(packageAgencie.agency, agency)
                ),
            },
            imageOverrides: {
                collection: imageOverrides.filter((imageOverride) =>
                    matchOnId(imageOverride.agency, agency)
                ),
            },
            toolOverrides: {
                collection: toolOverrides?.filter((toolOverride) =>
                    matchOnId(toolOverride.agency, agency)
                ),
            },
            servicePackageOverrides: {
                collection: servicePackageOverrides?.filter(
                    (servicePackageOverride) =>
                        matchOnId(servicePackageOverride.agency, agency)
                ),
            },
            discoveryCriteriaOverrides: {
                collection: discoveryCriteriaOverrides.filter(
                    (discoveryCriteriaOverride) =>
                        matchOnId(discoveryCriteriaOverride.agency, agency)
                ),
            },
        }));

        return agencies;
    }, [
        contentOverrides,
        variableValues,
        serviceAgencies,
        packageAgencies,
        imageOverrides,
        toolOverrides,
        servicePackageOverrides,
        discoveryCriteriaOverrides,
        data,
    ]);
};

export default useLoadAgencies;
