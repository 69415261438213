import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';

function isContentEnabled(
    content: string | ContentResponse | undefined | null,
    selectedAgency: AgencyResponse | undefined
) {
    const currentContentOverride =
        selectedAgency?.contentOverrides?.collection.find((cO) =>
            matchOnId(cO.content, content)
        );

    return !currentContentOverride || !currentContentOverride.disabled;
}

export default isContentEnabled;
