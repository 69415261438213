import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { entityToId, initBool } from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import PackageAgencyResponse from '@acdc/shared/src/features/packageAgency/PackageAgencyResponse';
import PackageResponse from '@acdc/shared/src/features/package/PackageResponse';
import PackageAgency from '@acdc/shared/src/features/packageAgency/PackageAgency.model';
import TogglePackageAgencyFormInner from './TogglePackageAgencyFormInner';
import useSubmitPackageAgency from '../useSubmitPackageAgency';
import useOverridedPackage from '../../package/useOverridedPackage';

export interface TogglePackageAgencyFormValue {
    enabled: boolean;
}

const initValue = (
    value: PackageAgencyResponse
): TogglePackageAgencyFormValue => ({
    enabled: !initBool(value.disabled, true),
});

const filterFormValues = (
    formValue: TogglePackageAgencyFormValue
): DeepPartial<PackageAgency> => ({
    disabled: !formValue.enabled,
});

export const togglePackageAgencyFormSchema = Yup.object().shape({
    enabled: Yup.string().label("L'état d'activation du pack").required(),
});

export type TogglePackageAgencyFormProps = {
    value?: PackageAgencyResponse | undefined;
    pack: PackageResponse;
    agency: AgencyResponse;
    onSuccess?: (res: PackageAgencyResponse) => void;
    onError?: (err: ApolloError) => void;
};

function TogglePackageAgencyForm({
    value,
    pack,
    agency,
    onSuccess,
    onError,
}: TogglePackageAgencyFormProps) {
    const overridedPackage = useOverridedPackage(pack);
    const idRef = useRef(value?.id);
    const getId = useCallback(() => idRef.current || null, []);
    const setId = useCallback((id: string) => {
        idRef.current = id;
    }, []);

    const initialValues: TogglePackageAgencyFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<PackageAgency>>(
        () => ({
            package: entityToId(pack) || undefined,
            agency: entityToId(agency) || undefined,
        }),
        [pack, agency]
    );

    const submit = useSubmitPackageAgency<TogglePackageAgencyFormValue>(
        getId,
        setId,
        null,
        togglePackageAgencyFormSchema,
        onSuccess,
        onError,
        fixedProperties,
        filterFormValues
    );

    return (
        <Formik
            validationSchema={togglePackageAgencyFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <TogglePackageAgencyFormInner
                    getId={getId}
                    pack={pack}
                    overridedPackage={overridedPackage}
                />
            </Form>
        </Formik>
    );
}

export default TogglePackageAgencyForm;
