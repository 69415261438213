import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListImageOverridesResponse from '@acdc/shared/src/features/imageOverride/ListImageOverridesResponse';
import { preloadImage } from '@acdc/shared/src/utils/file-helpers';
import { matchOnId, entityToId } from '@acdc/shared/src/utils/form-helpers';
import AgencyResponse from '@acdc/shared/src/features/agency/AgencyResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import ImageOverrideResponse from '@acdc/shared/src/features/imageOverride/ImageOverrideResponse';
import usePdfAsImages from '../pdfAsImage/usePdfAsImages';
import persistentQueryOptions from '../../apollo/persistentQueryOptions';
import CONFIGS from '../../configs';

export const IMAGE_OVERRIDE_FRAGMENT = gql`
    fragment ImageOverrideFields on ImageOverride {
        id
        agency {
            id
        }
        contentModule {
            id
        }
        imageOverrideFile {
            id
            publicPath
            originalName
            needPostProcess
        }
        sharedFile {
            id
            publicPath
            title
        }
        moduleSetting {
            id
            code
        }
    }
`;

export const GET_IMAGE_OVERRIDES = gql`
    ${IMAGE_OVERRIDE_FRAGMENT}
    query GetImageOverrides($agencyCode: String) {
        imageOverrides(itemsPerPage: 100000, agency_code: $agencyCode) {
            collection {
                ...ImageOverrideFields
            }
        }
    }
`;

const emptyResult: ImageOverrideResponse[] = [];

/**
 * Retourne tous les ImageOverrides accessibles à l'utilisateur.
 * L'api n'est fetch qu'une fois toutes les 24h, sinon c'est le cache Apollo qui est retourné.
 */
const useImageOverrides = (
    agency: string | AgencyResponse | null | undefined = null,
    skipAgencyRequests: boolean | null | undefined = null,
    enabledPreloadImage: boolean | null | undefined = true
) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListImageOverridesResponse>(
        GET_IMAGE_OVERRIDES,
        {
            ...persistentQueryOptions,
            variables: {
                agencyCode: getIdFromIri(entityToId(agency)),
            },
            skip: skipAgencyRequests || false,
        }
    );

    const pdfAsImages = usePdfAsImages(
        'imageOverrideFile',
        skipAgencyRequests,
        enabledPreloadImage
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des images d'agences.",
                'error'
            );
        }
    }, [error, setAlert]);

    const result = useMemo<ImageOverrideResponse[] | undefined>(() => {
        if (!pdfAsImages) {
            return undefined;
        }

        const items = data?.imageOverrides?.collection;

        if (enabledPreloadImage) {
            items?.forEach((imageOverride) => {
                if (
                    imageOverride.imageOverrideFile?.mimeType !==
                        'application/pdf' &&
                    imageOverride.imageOverrideFile?.publicPath
                ) {
                    preloadImage(
                        CONFIGS.apiEntrypoint +
                            imageOverride.imageOverrideFile.publicPath
                    );
                }
            });
        }

        return items?.map((imageOverride) => ({
            ...imageOverride,
            imageOverrideFile: imageOverride.imageOverrideFile && {
                ...imageOverride.imageOverrideFile,
                pdfAsImages: {
                    collection: pdfAsImages.filter((pdfAsImage) =>
                        matchOnId(
                            pdfAsImage.imageOverrideFile,
                            imageOverride.imageOverrideFile
                        )
                    ),
                },
            },
        }));
    }, [data?.imageOverrides?.collection, enabledPreloadImage, pdfAsImages]);

    return skipAgencyRequests ? emptyResult : result;
};

export default useImageOverrides;
