import useSelectedAgency from '../features/agency/useSelectedAgency';
import useMyUserAgencies from '../features/userAgency/useMyUserAgencies';
import ACDC_AGENCY_ROLES_HIERARCHY from './ACDC_AGENCY_ROLES_HIERARCHY';
import isGrantedInAgency from './isGrantedInAgency';

/**
 * Retourne true si l'utilisateur a le role demandé.
 * Attention en cas de changement des roles de l'utilisateur authentifié ou en cas de désauthentification
 * ce hook ne trigger pas de re-rendu du composant où il est utilisé.
 */
const useIsGrantedInAgency = (checkRole: string) => {
    const { data } = useMyUserAgencies();
    const [selectedAgency] = useSelectedAgency();

    if (selectedAgency && data) {
        return isGrantedInAgency(
            checkRole,
            selectedAgency,
            data,
            ACDC_AGENCY_ROLES_HIERARCHY
        );
    }

    return false;
};

export default useIsGrantedInAgency;
