import { Theme, Typography } from '@mui/material';
import React from 'react';

export interface MenuTitleTypographyProps {
    children?: React.ReactNode;
    color?: string | ((theme: Theme) => string);
}

function MenuTitleTypography({ children, color }: MenuTitleTypographyProps) {
    return (
        <Typography
            variant="h6"
            sx={(theme) => ({
                whiteSpace: 'normal',
                color: color || 'primary',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '0.9rem',
                },
                [theme.breakpoints.up('lg')]: {
                    fontSize: '1.2rem',
                },
            })}
        >
            {children}
        </Typography>
    );
}

export default MenuTitleTypography;
