import NotificationUserResponse from '@acdc/shared/src/features/notificationUser/NotificationUserResponse';
import { Avatar } from '@mui/material';
import React from 'react';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { grey } from '@mui/material/colors';

interface NotificationPriorityIconProps {
    notificationUser: NotificationUserResponse;
}

function NotificationPriorityIcon({
    notificationUser,
}: NotificationPriorityIconProps) {
    return (
        <Avatar sx={{ bgcolor: grey[300] }}>
            {notificationUser.notification?.notificationStatus?.code ===
            'IMPORTANT' ? (
                <NotificationImportantIcon
                    color="error"
                    data-testid={
                        notificationUser.notification?.notificationStatus?.code
                    }
                />
            ) : (
                <NotificationsIcon
                    color="sale"
                    data-testid={
                        notificationUser.notification?.notificationStatus?.code
                    }
                />
            )}
        </Avatar>
    );
}

export default NotificationPriorityIcon;
